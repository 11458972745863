import { createSelector, createSlice } from "@reduxjs/toolkit";

export const SOCKET_FEATURE_KEY = "socketState";

/*
 * Change this from `any` if there is a more specific error type.
 */
/*
 * Update these interfaces according to your requirements.
 */

const initialSocketState = {
  error: null,
  loaded: false,
  general: {
    hasUnread: false,
    notifications: [],
    unReadCount: 0,
    hasMore: false,
    limit: 10,
    pageNo: 0,
    lastUpdate: null,
  },
  notificationsSuccess: false,
};

export const socketSlice = createSlice({
  name: SOCKET_FEATURE_KEY,
  initialState: initialSocketState,
  reducers: {
    openSocket: (state, action) => {},
    openSocketSuccess: (state, action) => {},
    openSocketFailure: (state, action) => {},
    sendPayLoadSocketChannel: (state, action) => {},
    getNotificationStart: (state, action) => {
      state.loaded = false;
    },
    getNotificationSuccess: (state, action) => {
      state.loaded = true;
      const { type, hasMore, notifications } = action.payload;
      state.general = {
        ...state.general,
        notifications: [
          ...state.general.notifications,
          ...notifications.map((item) => {
            if (!item.isRead) {
              state.general.hasUnread = true;
              ++state.general.unReadCount;
            }
            return item;
          }),
        ],
        pageNo: state.general.pageNo + 1,
        hasMore,
        lastUpdate: Date.now(),
      };
    },
    getNotificationFailure: (state, action) => {
      state.error = action.payload;
    },
    setNotificationStatusStart: (state, action) => {},
    setNotificationStatusSuccess: (state, action) => {
      const { id, isRead, type } = action.payload;
      state.loaded = true;
      state.general.notifications = state.general.notifications.map(
        (notification) => {
          if (notification._id === id) {
            notification.isRead = isRead;
          }
          return notification;
        }
      );
      state.general.hasUnread =
        (state.general.unReadCount = state.general.notifications.filter(
          ({ isRead }) => !isRead
        ).length) > 0;
    },
    setNotificationStatusFailure: (state, action) => {
      state.loaded = true;
      state.error = action.payload;
    },
    addNotificationFromSocket: (state, action) => {
      const { notification } = action.payload;
      if (false) {
      } else {
        state.general.hasUnread = true;
        ++state.general.unReadCount;
        state.general.notifications = [
          notification,
          ...state.general.notifications,
        ];
      }
    },
    setMarkAsReadSocketData: (state, action) => {
      const { notificationType } = action.payload;
      switch (notificationType) {
        case "GENERAL":
          // no need to reduce the count of unread as this case won't come for this type
          state.general.hasUnread = false;
          break;
        default:
          // Handle unexpected cases or log an error
          console.error("Unexpected notification type: " + notificationType);
          break;
      }
    },
    resetNotificationFromSocket: (state, action) => {
      state.feedbackTrigger = false;
      state.onboardingTrigger = false;
    },
    closeSocket: (state, action) => {
      return initialSocketState;
    },
  },
});

export const {
  openSocket,
  openSocketSuccess,
  openSocketFailure,
  sendPayLoadSocketChannel,
  getNotificationStart,
  getNotificationSuccess,
  getNotificationFailure,
  setNotificationStatusStart,
  setNotificationStatusSuccess,
  setNotificationStatusFailure,
  addNotificationFromSocket,
  setMarkAsReadSocketData,
  resetNotificationFromSocket,
  getTrialCourseNotificationSuccess,
  closeSocket,
} = socketSlice.actions;

export const getSocketState = (rootState) => rootState[SOCKET_FEATURE_KEY];

export const selectNotificationLoaded = createSelector(
  getSocketState,
  (s) => s.loaded
);

export const selectGeneralNotifications = createSelector(
  getSocketState,
  (s) => s.general
);

export const selectNotificationPaginationData = (type) =>
  createSelector(getSocketState, (s) => {
    switch (type) {
      case "GENERAL":
        return s?.general?.pagination;
    }
    return {
      skip: 0,
      limit: 10,
    };
  });

/*
 * Export reducer for store configuration.
 */
export const socketReducer = socketSlice.reducer;
