import { Box, Typography } from "@mui/material";
import React from "react";
import CustomInput from "../../components/Input";
import CustomButton from "../../components/Button";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import alertSvg from "../../../images/alert.svg";
import { ApiRequest } from "../../../services/api-request";
import { useAppContext } from "../../../context/AppContext";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "../../../context/useSnackbar";

const DeleteAccount = (props) => {
  const schema = yup
    .object({
      reason: yup.string().required("Reason is required."),
    })
    .required();

  const { handleSubmit, control, setValue } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { reason: "" },
  });

  const { user } = useAppContext();

  const { openSnackbar } = useSnackbar();

  const navigate = useNavigate();

  const onSubmit = (data) => {
    const formData = {
      reasonForDelete: data?.reason,
    };
    ApiRequest({
      url: `user/delete-account/${user?.id}`,
      data: formData,
      method: "DELETE",
    })
      .then((res) => {
        if (res) {
          openSnackbar(
            "Your account has been successfully deleted.",
            "success"
          );
          localStorage.removeItem("user");
          localStorage.removeItem("loginStatus", "false");
          localStorage.removeItem("selectedLocality");
          navigate("/login");
          window.location.reload();
        }
      })
      .catch((err) => {
        // openSnackbar(err?.response?.data?.message, "error");
      });
  };
  return (
    <>
      <Box textAlign={"center"}>
        <img src={alertSvg} width={"70px"} alt="aert" />
        <Typography
          fontFamily={"MetropolisMedium"}
          fontSize={"16px"}
          fontWeight={"600"}
          color={"#FF9100"}
        >
          Delete Account
        </Typography>
        <Typography
          mt={"10px"}
          fontFamily={"MetropolisMedium"}
          fontSize={"13px"}
          fontWeight={"500"}
          color={"#000000"}
        >
          Are you sure you want to delete your account ?
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box mt={2}>
            <Controller
              name="reason"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <CustomInput
                  {...field}
                  borderRadius={"10px"}
                  multiline={true}
                  type={"text"}
                  placeHolder={"Mention your Reason"}
                  error={!!error}
                  helperText={error ? error.message : ""}
                />
              )}
            />
          </Box>
          <Box display={"flex"} justifyContent={"center"} mt={2}>
            <Box width={"50%"}>
              <CustomButton
                title={"Yes, Delete Account"}
                borderButton={false}
                type={"submit"}
                padding={"11px"}
                margin={"10px"}
              />
            </Box>
          </Box>
          <Typography
            sx={{ cursor: "pointer" }}
            onClick={() => props.handleClose()}
            mt={2}
            fontFamily={"MetropolisMedium"}
            fontSize={"11px"}
            fontWeight={"500"}
            color={"#000000"}
          >
            Cancel
          </Typography>
        </form>
      </Box>
    </>
  );
};

export default DeleteAccount;
