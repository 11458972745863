import React from "react";
import { Grid, Box, Typography } from "@mui/material";
import Colors from "../../constants/Colors";
import Footer from "../../components/Footer";
import aboutCurve from "../../../images/about-curve.svg";
import linkedIn from "../../../images/orangelinkedin.svg";
import aboutBg from "../../../images/about-bg.png";
import "./about.css";
import MetaTags from "../../components/ReactMetaTags/meta-tags";
import { Link } from "react-router-dom";

const About = () => {
  const AboutUs = () => {
    return (
      <>
        <Box>
          <Box>
            <Box
              style={{
                backgroundImage: `url(${aboutBg})`,
                backgroundSize: "cover",
                backgroundPositionX: "center"
              }}
              className={"breadSec"}
              padding={"60px 60px 140px 60px"}
              display={"flex"}
              justifyContent={"center"}
              flexDirection={"column"}
              alignItems={"center"}
            >
              <Box className="overlay">

              </Box>
              <Box position={"relative"} justifyContent={"center"} flexDirection={"column"} display={"flex"} alignItems={"center"}>
                
              <Box
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"center"}
                alignItems={"center"}
                gap={2}
              >
                <h1 style={styles.aboutHeading}>About</h1>
                <h1 style={styles.aboutSubHeading}>Thrillh</h1>
              </Box>
              <Box width={"53%"} className={"breadText"}>
                <p style={styles.desc}>
                  We offer customized discounts on a broad range of brands and
                  services tailored specifically to students and corporates at
                  Thrillh. We understand your individual needs and preferences,
                  and we designed our app to revolutionize how you save and
                  shop. We provide our users with a secure and tailored
                  experience through AI-driven smart and authentic verification.
                </p>
              </Box>
              </Box>
            </Box>
            <Box className={"curve"}>
              <img
                src={aboutCurve}
                alt="curve"
                width={"100%"}
                style={styles.aboutImage}
              />
            </Box>
          </Box>
          <Grid container justifyContent={"center"}>
            <Grid
              item
              justifyContent={"center"}
              display={"flex"}
              className={"aboutImage"}
            >
              <img alt="image" src={require("../../../images/forAbout.png")} />
            </Grid>
          </Grid>
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            mt={5}
          >
            <Box display={"flex"} flexDirection={"row"} gap={1}>
              <h1 style={styles.storyHeading}>Our</h1>
              <h1 style={styles.storySubHeading}>Story</h1>
            </Box>
            <Box>
              <p style={styles.bottomLine}></p>
            </Box>
            <Box width={"85%"}>
              <p style={styles.storydesc}>
                Thrillh, is your ultimate destination for personalized discounts
                on a wide range of brands and services tailored specifically for
                students and corporates. We understand your unique needs and
                preferences, and our app is designed to revolutionize the way
                you save and shop through a bonanza of deals. With the power of
                AI-driven smart and authentic verification, we ensure a secure
                and tailored experience for our users.
              </p>
              <p style={styles.storydesc}>
                We have partnered with a diverse array of brands spanning
                multiple industries to bring you exclusive discounts and offers
                that are personalized to your interests, ensuring you get the
                most out of your shopping experience. For students, we recognize
                the importance of balancing academics, personal growth, and
                budget constraints. Our app provides a convenient platform where
                students can access incredible deals on everything from
                textbooks and electronics to fashion and entertainment.
              </p>
              <p style={styles.storydesc}>
                For corporates, we understand the urge to have feasibility for
                valuable perks while maintaining cost-effectiveness. Our app is
                designed to enhance your employee benefits program by offering
                personalized discounts on a wide range of brands and services.
                Whether it's travel, business services, or entertainment, we
                have you covered. Employees can now enjoy the added benefit of
                saving money with no compromise on their satisfaction and
                engagement.
              </p>

              <Box py={3}>
                <Box>
                  <Box
                    display={"flex"}
                    flexDirection={"row"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    gap={2}
                  >
                    <h1 style={styles.storyHeading}>Our</h1>
                    <h1 style={styles.storySubHeading}>Team</h1>
                  </Box>
                  <Box display={"flex"} justifyContent={"center"}>
                    <p style={styles.bottomLine}></p>
                  </Box>
                </Box>

                <Grid container className={"fullTeam"}>
                  <Grid item className={"teamBox"}>
                    <Box className={"teamImg"}>
                      <img alt="ram" src={require("../../../images/Ram Vaggisetty.png")} />
                    </Box>
                    <Typography className="teamName">
                      Ram Vaggisetty
                    </Typography>
                    <Typography className="teamDesig">
                      Founder
                    </Typography>
                    <Typography className="teamCon">
                    Founder, drives strategic marketing and sales initiatives, fostering brand awareness and revenue growth. Avid snorkeler exploring vibrant underwater ecosystems.
                    </Typography>
                    <Box className={"teamMedia"}>
                      <Link to={"https://www.linkedin.com/in/ram-vaggisetty/"} target="_blank">
                        <Box className={"teamMediaIcon"}>
                          <img alt="linkedin4" src={linkedIn} width={"20px"} />
                        </Box>
                      </Link>
                    </Box>
                  </Grid>
                  <Grid item className={"teamBox"}>
                    <Box className={"teamImg"}>
                      <img alt="akhil" src={require("../../../images/Akhil Devarkonda.png")} />
                    </Box>
                    <Typography className="teamName">
                      Akhil Devarkonda
                    </Typography>
                    <Typography className="teamDesig">
                      Co-Founder
                    </Typography>
                    <Typography className="teamCon">
                    Co-founder, leads operational excellence, ensuring seamless workflow and efficient resource allocation. Passionate equestrian, enjoys the thrill of horseback riding.
                    </Typography>
                  </Grid>
                  <Grid item className={"teamBox"}>
                    <Box className={"teamImg"}>
                      <img alt="mahesh" src={require("../../../images/Mahesh Vaggisetty.png")} />
                    </Box>
                    <Typography className="teamName">
                      Mahesh Vaggisetty
                    </Typography>
                    <Typography className="teamDesig">
                      Co-Founder
                    </Typography>
                    <Typography className="teamCon">
                    Co-founder, unlocks data insights, leveraging analytics to inform strategic decision-making. Seasoned traveller, seeking cultural diversity and global understanding.
                    </Typography>
                  </Grid>
                  <Grid item className={"teamBox"}>
                    <Box className={"teamImg"}>
                      <img alt="pravalika" src={require("../../../images/Saisree Vaggisetty.png")} />
                    </Box>
                    <Typography className="teamName">
                      Saisree Vaggisetty
                    </Typography>
                    <Typography className="teamDesig">
                      HR Manager
                    </Typography>
                    <Typography className="teamCon">
                    HR Manager, cultivates a positive and engaging work environment, attracting and retaining top talent. Talented Artist, expressing creativity through visual storytelling.
                    </Typography>
                  </Grid>
                  <Grid item className={"teamBox"}>
                    <Box className={"teamImg"}>
                      <img alt="shubham" src={require("../../../images/Shubham Pardikar.png")} />
                    </Box>
                    <Typography className="teamName">
                      Shubham Pardikar
                    </Typography>
                    <Typography className="teamDesig">
                      Digital Marketing Specialist
                    </Typography>
                    <Typography className="teamCon">
                    Digital Marketing Specialist, executes successful campaigns across digital platforms. Enthusiastic cricketer, honing strategic thinking and teamwork on the field.
                    </Typography>
                    <Box className={"teamMedia"}>
                      <Link to={"https://www.linkedin.com/in/shubhampardikar/"} target="_blank">
                        <Box className={"teamMediaIcon"}>
                          <img alt="linkedin1" src={linkedIn} width={"20px"} />
                        </Box>
                      </Link>
                    </Box>
                  </Grid>
                  <Grid item className={"teamBox"}>
                    <Box className={"teamImg"}>
                      <img alt="pravalika" src={require("../../../images/Pravallika Reddy.png")} />
                    </Box>
                    <Typography className="teamName">
                      Pravallika Reddy
                    </Typography>
                    <Typography className="teamDesig">
                      Relationship Manager
                    </Typography>
                    <Typography className="teamCon">
                    Business Development Manager, builds and nurtures strategic partnerships, driving business expansion. Skilled dancer, bringing energy and grace to client relationships.
                    </Typography>
                    <Box className={"teamMedia"}>
                    <Link to={"https://www.linkedin.com/in/pravallikareddy123"} target="_blank">
                      <Box className={"teamMediaIcon"}>
                        <img alt="linkedin" src={linkedIn} width={"20px"} />
                      </Box>
                      </Link>
                    </Box>
                  </Grid>
                  <Grid item className={"teamBox"}>
                    <Box className={"teamImg"}>
                      <img alt="pravalika" src={require("../../../images/Nikitha.png")} />
                    </Box>
                    <Typography className="teamName">
                      Nikitha
                    </Typography>
                    <Typography className="teamDesig">
                      Finance Manager
                    </Typography>
                    <Typography className="teamCon">
                    Finance Manager, maintains financial stability and drives fiscal optimization. Dedicated yogi, prioritising balance and mindful practices.
                    </Typography>
                  </Grid>
                  <Grid item className={"teamBox"}>
                    <Box className={"teamImg"}>
                      <img alt="pravalika" src={require("../../../images/Radhika Rongali.png")} />
                    </Box>
                    <Typography className="teamName">
                    Radhika Rongali
                    </Typography>
                    <Typography className="teamDesig">
                    Digital Marketing Executive
                    </Typography>
                    <Typography className="teamCon">
                    A Digital Marketing Executive, and a voracious reader, she finds solace and inspiration in the pages of novels, exploring various genres that broaden her perspective. 
                    </Typography>
                    <Box className={"teamMedia"}>
                      <Link to={"https://www.linkedin.com/in/radhika-rongali/"} target="_blank">
                        <Box className={"teamMediaIcon"}>
                          <img alt="linkedin4" src={linkedIn} width={"20px"} />
                        </Box>
                      </Link>
                    </Box>
                  </Grid>
                </Grid>
              </Box>


              <p style={styles.storydesc}>
                What sets us apart is our commitment to delivering exceptional
                value. Our team works tirelessly to forge partnerships with
                renowned brands and service providers, securing exclusive deals
                and discounts that you won't find anywhere else. By focusing on
                specific target audiences, we can negotiate deals that are truly
                tailored to your needs, giving you the opportunity to save money
                on the products and services you love.
              </p>
              <p style={styles.storydesc}>
                Our user-friendly interface provides a seamless browsing
                experience, making it easy for you to explore the latest
                discounts, discover new brands, and make informed purchasing
                decisions. You can browse by category, search for specific
                brands, and we guarantee no compromise on brand and discount.
              </p>
              <p style={styles.storydesc}>
                We take pride in our extensive network of partner brands,
                ensuring that you have access to an impressive array of
                discounts across various industries. We continuously strive to
                expand our partnerships and bring you new and exciting offers
                regularly.
              </p>
              <p style={styles.storydesc}>
                At Thrillh we prioritize your satisfaction. Our dedicated
                customer support team is always ready to assist you with any
                queries or concerns you may have. We value your feedback and are
                constantly working to enhance your experience with our app. Join
                us today and start enjoying the benefits of personalized
                discounts that cater to your unique needs. Whether you're a
                student looking to stretch your budget or a corporate
                professional seeking cost-effective solutions, we are here to
                make your shopping experience rewarding and delightful.
              </p>
              <p style={styles.storydesc}>
                Save money, explore new brands, and discover endless
                opportunities with Thrillh. It's time to unlock the power of
                personalized discounts!
              </p>
            </Box>
            <Box className={"ourStory"}>
              <img
                alt="ourstory"
                src={require("../../../images/abbg.png")}
                width={"100%"}
              />
            </Box>
          </Box>
        </Box>

        <Box
          backgroundColor={Colors.black}
          padding={"60px 80px 60px 80px"}
          height={450}
          className={"missionSec"}
        >
          <Grid container alignItems={"center"} flexWrap={"wrap"}>
            <Grid
              item
              md={6}
              xs={12}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Box
                display={"flex"}
                justifyContent={"start"}
                className={"aboutImage"}
              >
                <img
                  src={require("../../../images/about1.png")}
                  width={450}
                  alt="about1"
                  height={300}
                />
              </Box>
            </Grid>
            <Grid item md={6} xs={12}>
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                flexDirection={"row"}
                gap={1}
              >
                <h1 style={styles.missionHeading}>Our</h1>
                <h1 style={styles.missionSubHeading}>Mission</h1>
              </Box>
              <Box>
                <p style={styles.missiondesc}>
                  Our mission is to connect Students & Corporates with exclusive
                  offers, incredible discounts, and unbeatable deals through our
                  innovative coupon-based platform. We are dedicated to helping
                  students & corporates make the most of their hard-earned money
                  by providing a seamless and rewarding shopping experience. By
                  partnering with top brands and retailers, we aim to create a
                  community where savvy shoppers can access significant savings
                  on a wide range of products and services. Our commitment to
                  delivering value and convenience drives us to continually
                  enhance our platform, making it easier than ever for our users
                  to discover, redeem, and enjoy the benefits of smart shopping.
                </p>
              </Box>
              <Grid
                container
                justifyContent={"center"}
                className="missionStores"
              >
                <Grid item>
                  <h1 style={styles.stat}>1M Stores</h1>
                  <h4 style={styles.statSub}>across 15 Cities</h4>
                </Grid>
                <Grid item>
                  <h1 style={styles.stat}>6 million+</h1>
                  <h4 style={styles.statSub}>Engaged users</h4>
                </Grid>
                <Grid item>
                  <h1 style={styles.stat}>27 mins +</h1>
                  <h4 style={styles.statSub}>avg user active time/day</h4>
                </Grid>
                <Grid item>
                  <h1 style={styles.stat}>Top 10</h1>
                  <h4 style={styles.statSub}>lifestyle app</h4>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Box
          backgroundColor={Colors.orange}
          padding={10}
          mb={-28}
          className={"visionSec"}
        >
          <Grid
            container
            justifyContent={"center"}
            alignItems={"center"}
            width={"100%"}
          >
            <Grid item width={"48%"} className={"visionContent"}>
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                flexDirection={"row"}
                gap={1}
              >
                <h1 style={styles.visionHeading}>Our</h1>
                <h1 style={styles.visionSubHeading}>Vision</h1>
              </Box>
              <Box>
                <p style={styles.visionDesc}>
                  "Revolutionizing Savings in a Digital Age: Our vision is to
                  become the leading Target-based firm that redefines how people
                  save and shop in the modern world. We envision a future where
                  our platform seamlessly integrates with consumers' lifestyles,
                  effortlessly delivering personalized, relevant, and timely
                  discounts at their fingertips. By harnessing the power of
                  cutting-edge technology, data analytics, and strategic
                  partnerships, we aspire to create a global community of smart
                  shoppers who confidently make purchase decisions knowing they
                  are maximizing value.
                </p>
              </Box>
            </Grid>
            <Grid
              item
              justifyContent={"center"}
              display={"flex"}
              className={"aboutImage"}
            >
              <img
                src={require("../../../images/about2.png")}
                width={450}
                height={300}
                alt="about"
              />
            </Grid>
          </Grid>
        </Box>
      </>
    );
  };

  return (
    <>
      <MetaTags
        title={"Thrillh - About Us"}
        metaTitle={"Thrillh - About Us"}
        description={
          "Thrillh is a platform that offers customized discounts on a broad range of brands and services tailored specifically to students and corporations."
        }
      />
      <AboutUs />
      <Footer from={"about"} />
    </>
  );
};

const styles = {
  bottomLine: {
    width: "160px",
    backgroundColor: Colors.orange,
    height: "4px",
    borderRadius: "20px",
  },
  visionHeading: {
    fontFamily: "MetropolisRegular",
    fontSize: 36,
    color: Colors.textColor,
  },
  visionSubHeading: {
    fontFamily: "MetropolisBold",
    fontSize: 36,
    color: Colors.textColor,
    textAlign: "center",
    fontWeight: "500",
  },
  missionHeading: {
    fontFamily: "MetropolisRegular",
    fontSize: 36,
    color: Colors.white,
    lineHeight: "0px",
    fontWeight: "500",
  },
  missionSubHeading: {
    fontFamily: "MetropolisBold",
    fontSize: 36,
    color: Colors.white,
    lineHeight: "0px",
    textAlign: "center",
  },
  aboutImage: {
    objectFit: "cover",
  },
  aboutHeading: {
    fontFamily: "MetropolisRegular",
    fontSize: 40,
    color: Colors.white,
    textAlign: "center",
    margin: "10px 0px",
    fontWeight: "500",
  },
  aboutSubHeading: {
    fontFamily: "MetropolisBold",
    fontSize: 40,
    color: Colors.white,
    margin: "10px 0px",
  },
  desc: {
    fontFamily: "MetropolisRegular",
    fontSize: 18,
    color: Colors.white,
    textAlign: "center",
    lineHeight: "32px",
  },
  missiondesc: {
    fontFamily: "MetropolisRegular",
    fontSize: 16,
    color: Colors.white,
    textAlign: "justify",
    lineHeight: "25px",
  },
  visionDesc: {
    fontFamily: "MetropolisRegular",
    fontSize: 16,
    color: Colors.textColor,
    textAlign: "justify",
    lineHeight: "30px",
  },
  storydesc: {
    fontFamily: "MetropolisMedium",
    fontSize: 16,
    color: Colors.textColor,
    lineHeight: "28px",
    textAlign: "justify",
  },
  storyHeading: {
    fontFamily: "MetropolisRegular",
    fontSize: 36,
    color: Colors.lightBlue,
    margin: 0,
    textAlign: "center",
    fontWeight: "500",
  },
  storySubHeading: {
    fontFamily: "MetropolisBold",
    fontSize: 36,
    color: Colors.lightBlue,
    margin: 0,
  },
  stat: {
    fontFamily: "MetropolisBold",
    fontSize: 36,
    color: Colors.white,
    margin: 0,
  },
  statSub: {
    fontFamily: "MetropolisRegular",
    fontSize: 14,
    color: Colors.white,
    marginTop: 10,
    textAlign: "center",
    fontWeight: "500",
  },
};

export default About;
