import React, { useState, useEffect } from "react";
import { Grid, Box, Typography } from "@mui/material";
import Colors from "../../constants/Colors";
import CustomButton from "../../components/Button";
import Footer from "../../components/Footer";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import CustomLink from "../Link";
import BannerImg from "../../../images/bannerimg.png";
import ProfileMenu from "../../../images/menu.png";
import User from "../../../images/user.svg";
import LinkSvg from "../../../images/link.svg";
import gif from "../../../images/loaders/thirllh icon  -3.gif";
import Contact from "../../../images/contact us.svg";
// import Star from "../../../images/star.png";
import Help from "../../../images/helps.png";
import Mark from "../../../images/orders.png";
import Set from "../../../images/settings.png";
import Logout from "../../../images/Logout.png";
import { useNavigate, Link } from "react-router-dom";
import CustomDialog from "../Dialog/custom-dialog";
import EditProfile from "../../components/ProfileHoc/edit-profile";
import { ApiRequest } from "../../../services/api-request";
// import { Settings } from "@mui/icons-material";
import userSvg from "../../../images/admin/user (8).svg";
import { useAppContext } from "../../../context/AppContext";
import FileUploader from "../FileUploader/file-uploder";
import { useSnackbar } from "../../../context/useSnackbar";
import CustomBreadCrumb from "../BreadCrumb/bread-crumb";
import Drawer from "@mui/material/Drawer";
import walletSvg from "../../../images/wallet.svg";
import "./profile.css";

const ProfileHoc = (Component) => {
  const { user, handleLogoutSuccess } = useAppContext();

  const navigate = useNavigate();

  const [openProfileDialog, setOpenProfileDialog] = useState(false);

  const [userData, setUserData] = useState({});

  const [loaderForUserDetails, setLoaderForUserDetails] = useState(false);

  useEffect(() => {
    getUser();
  }, []);

  const getUser = (filter) => {
    setLoaderForUserDetails(true);
    ApiRequest({
      url: `user`,
      method: "GET",
    })
      .then((res) => {
        setUserData(res);
        setTimeout(() => {
          setLoaderForUserDetails(false);
        }, 100);
      })
      .catch((err) => {
        setLoaderForUserDetails(false);
        openSnackbar(err?.response?.data?.message, "error");
        if (err?.statusCode === 403 || err?.statusCode === 401) {
          if (navigate) {
            navigate("/login"); // Navigate to "/login" route
            handleLogoutSuccess();
          }
        }
      });
  };

  const [openSuccessModal, setOpenSuccessModal] = useState(false);

  const handleLogout = () => {
    setOpenSuccessModal(true);
  };

  const logOut = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("loginStatus", "false");
    localStorage.removeItem("selectedLocality");
    openSnackbar("Logged out Successful. Have a great day!", "success");
    navigate("/login");
  };

  const { openSnackbar } = useSnackbar();

  const onHandleUploadedFile = (file) => {
    if (file) {
      onSubmit(file);
    }
  };

  const existingUserData = localStorage.getItem("user");
  const userObj = existingUserData ? JSON.parse(existingUserData) : {};

  const onSubmit = (file) => {
    const modifiedData = {
      profilePicture: file?.logoResponseFiles[0]?.url,
    };
    ApiRequest({
      url: `user/update`,
      data: modifiedData,
      method: "PUT",
    })
      .then((res) => {
        getUser();
        if (res) {
          const newItemKey = "profilePicture";
          const newItemValue = res?.profilePicture;
          userObj[newItemKey] = newItemValue;
          Object.assign(userObj, modifiedData);
          openSnackbar("Profile Picture Updated Successfully", "success");
          localStorage.setItem("user", JSON.stringify(userObj));
          window.location.reload();
        }
      })
      .catch((err) => {
        openSnackbar(err?.response?.data?.message, "error");
        if (err?.statusCode === 403 || err?.statusCode === 401) {
          if (navigate) {
            navigate("/login"); // Navigate to "/login" route
            handleLogoutSuccess();
          }
        }
      });
  };

  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const breadcrumbs = [
    <Link to="/" className={"link"}>
      Home
    </Link>,
    <Link className={"link"} to={"/admin/products"}>
      Profile
    </Link>,
  ];

  return (
    <>
      <CustomDialog
        width={"400px"}
        open={openSuccessModal}
        title={"Logout"}
        onClose={() => setOpenSuccessModal(false)}
      >
        <Typography>Are you sure you want to Logout?</Typography>
        <Box display={"flex"} justifyContent={"end"} gap={"10px"} mt={"20px"}>
          <Box>
            <CustomButton
              border={"1px solid #000000"}
              handleClick={() => setOpenSuccessModal(false)}
              color={"#000000"}
              title={"Cancel"}
              bgColor={"#F7F7F7"}
              borderRadius={"5px"}
            />
          </Box>
          <Box>
            <CustomButton
              title={"Logout"}
              type={"submit"}
              borderRadius={"5px"}
              handleClick={logOut}
            />
          </Box>
        </Box>
      </CustomDialog>

      {loaderForUserDetails ? (
        <>
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            height={"100vh"}
          >
            <img
              src={gif}
              alt="GIF animation"
              style={{ objectFit: "contain" }}
              width={"80px"}
              height={"80px"}
            />
            <Typography
              mt={"10px"}
              fontFamily={"MetropolisSemiBold"}
              fontSize={"14px"}
              color={"#000000"}
            >
              Working to get you the best offers!
            </Typography>
          </Box>
        </>
      ) : (
        <>
          <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
            <Box width={"70%"} className={"profileCard"}>
              <Box position={"relative"}>
                <Box mt={"10px"} mb={"10px"}>
                  <CustomBreadCrumb breadcrumbsArr={breadcrumbs} />
                </Box>
                <img
                  src={BannerImg}
                  alt="banner"
                  width={"100%"}
                  height={300}
                  style={styles.aboutImage}
                />

                <Box position={"absolute"} top={80} left={0} right={0}>
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    gap={"10px"}
                  >
                    <Box
                      border={4}
                      borderColor={Colors.orange}
                      borderRadius={"50%"}
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <FileUploader
                        accept={"image/png,image/jpeg,image/jpg"}
                        displayType={"profile"}
                        setUploadedFiles={(file) => onHandleUploadedFile(file)}
                        uploadType={"logo"}
                        image={
                          userData?.profilePicture
                            ? userData?.profilePicture
                            : userSvg
                        }
                      />
                    </Box>

                    <h1 style={styles.name}>{userData?.name}</h1>
                    <Box mt={1}>
                      <Typography
                        padding={"0px 20px"}
                        textAlign={"center"}
                        fontSize={"16px"}
                        fontFamily={"MetropolisSemiBold"}
                        variant="h1"
                        sx={{ background: "#FA8842", borderRadius: "18px" }}
                        color={"#fff"}
                      >
                        {user?.role?.code === "student"
                          ? "Student"
                          : "Corporate"}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    className={"editProfileBtn"}
                    position={"absolute"}
                    top={"-35px"}
                    right={"25px"}
                    mt={1}
                    width={130}
                  >
                    <CustomButton
                      title={"Edit"}
                      bgColor={Colors.orange}
                      width={"15%"}
                      padding={1}
                      handleClick={() => {
                        setOpenProfileDialog(true);
                      }}
                    />
                  </Box>
                  {/* /// mobile drawer starts*/}
                  <Box className={"drawer"}>
                    {["left"].map((anchor) => (
                      <React.Fragment key={anchor}>
                        <Box
                          className={"menuProfileBtn"}
                          onClick={toggleDrawer(anchor, true)}
                          position={"absolute"}
                          top={"-35px"}
                          left={"25px"}
                          mt={1}
                          width={130}
                        >
                          <img src={ProfileMenu} width={"30px"} alt="menu" />
                        </Box>
                        <Drawer
                          anchor={anchor}
                          open={state[anchor]}
                          onClose={toggleDrawer(anchor, false)}
                        >
                          <Box
                            sx={{
                              width:
                                anchor === "top" || anchor === "bottom"
                                  ? "auto"
                                  : 250,
                            }}
                            role="presentation"
                            onClick={toggleDrawer(anchor, false)}
                            onKeyDown={toggleDrawer(anchor, false)}
                          >
                            <Box>
                              <Box
                                boxShadow={"0px 0px 16px #67676729"}
                                padding={"10px"}
                                borderRadius={"20px"}
                                m={"10px"}
                              >
                                <CustomLink route={"/my-account/profile"}>
                                  <Grid
                                    container
                                    justifyContent={"space-between"}
                                    alignItems={"center"}
                                    mt={1}
                                  >
                                    <Grid item>
                                      <Grid container gap={"15px"}>
                                        <Grid item>
                                          <Box
                                            backgroundColor={Colors.skyBlue}
                                            borderRadius={30}
                                            width={"45px"}
                                            height={"45px"}
                                          >
                                            <img
                                              src={User}
                                              alt="user"
                                              width={"45px"}
                                              height={"45px"}
                                            />
                                          </Box>
                                        </Grid>

                                        <Grid item>
                                          <h1 style={styles.menuText}>
                                            Overview
                                          </h1>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                    <Grid item>
                                      <KeyboardArrowRightIcon
                                        style={styles.rightIcon}
                                      />
                                    </Grid>
                                  </Grid>
                                </CustomLink>

                                <CustomLink route={"/my-account/orders"}>
                                  <Grid
                                    container
                                    justifyContent={"space-between"}
                                    alignItems={"center"}
                                    mt={1}
                                  >
                                    <Grid item>
                                      <Grid container gap={"15px"}>
                                        <Grid item>
                                          <Box
                                            backgroundColor={Colors.skyBlue}
                                            borderRadius={30}
                                            width={"45px"}
                                            height={"45px"}
                                          >
                                            <img
                                              src={Mark}
                                              width={"45px"}
                                              height={"45px"}
                                              alt="mark"
                                            />
                                          </Box>
                                        </Grid>
                                        <Grid item>
                                          <h1 style={styles.menuText}>
                                            My Order's
                                          </h1>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                    <Grid item>
                                      <KeyboardArrowRightIcon
                                        style={styles.rightIcon}
                                      />
                                    </Grid>
                                  </Grid>
                                </CustomLink>

                                <CustomLink route={"/my-account/wallet"}>
                                  <Grid
                                    container
                                    justifyContent={"space-between"}
                                    alignItems={"center"}
                                    mt={1}
                                  >
                                    <Grid item>
                                      <Grid container gap={"15px"}>
                                        <Grid item>
                                          <Box
                                            backgroundColor={Colors.skyBlue}
                                            borderRadius={30}
                                            width={"45px"}
                                            display={"flex"}
                                            alignItems={"center"}
                                            justifyContent={"center"}
                                            height={"45px"}
                                          >
                                            <img
                                              src={walletSvg}
                                              alt="wallet"
                                              width={"45px"}
                                              height={"45px"}
                                            />
                                          </Box>
                                        </Grid>
                                        <Grid item>
                                          <h1 style={styles.menuText}>
                                            Wallet
                                          </h1>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                    <Grid item>
                                      <KeyboardArrowRightIcon
                                        style={styles.rightIcon}
                                      />
                                    </Grid>
                                  </Grid>
                                </CustomLink>
                              </Box>

                              <Box
                                boxShadow={"0px 0px 16px #67676729"}
                                padding={"10px"}
                                borderRadius={"20px"}
                                m={"10px"}
                              >
                                <Grid
                                  onClick={() =>
                                    window.open(
                                      "https://play.google.com/store/apps/details?id=com.thrillh&hl=en-IN"
                                    )
                                  }
                                  sx={{ cursor: "pointer" }}
                                  container
                                  justifyContent={"space-between"}
                                  alignItems={"center"}
                                  mt={1}
                                >
                                  <Grid item>
                                    <Grid container gap={"15px"}>
                                      <Grid item>
                                        <Box
                                          backgroundColor={Colors.skyBlue}
                                          borderRadius={30}
                                          width={"45px"}
                                          height={"45px"}
                                        >
                                          <img
                                            src={User}
                                            width={"45px"}
                                            height={"45px"}
                                            alt="user"
                                          />
                                        </Box>
                                      </Grid>
                                      <Grid item>
                                        <h1 style={styles.menuText}>
                                          Rate Our Site
                                        </h1>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  <Grid item>
                                    <KeyboardArrowRightIcon
                                      style={styles.rightIcon}
                                    />
                                  </Grid>
                                </Grid>

                                <CustomLink route={"/my-account/help"}>
                                  <Grid
                                    container
                                    justifyContent={"space-between"}
                                    alignItems={"center"}
                                    mt={1}
                                  >
                                    <Grid item>
                                      <Grid container gap={"15px"}>
                                        <Grid item>
                                          <Box
                                            backgroundColor={Colors.skyBlue}
                                            borderRadius={30}
                                            width={"45px"}
                                            height={"45px"}
                                          >
                                            <img
                                              src={Help}
                                              width={"45px"}
                                              alt="help"
                                              height={"45px"}
                                            />
                                          </Box>
                                        </Grid>
                                        <Grid item>
                                          <h1 style={styles.menuText}>Help</h1>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                    <Grid item>
                                      <KeyboardArrowRightIcon
                                        style={styles.rightIcon}
                                      />
                                    </Grid>
                                  </Grid>
                                </CustomLink>
                                <CustomLink route={"/my-account/refer"}>
                                  <Grid
                                    container
                                    justifyContent={"space-between"}
                                    alignItems={"center"}
                                    mt={1}
                                  >
                                    <Grid item>
                                      <Grid container gap={"15px"}>
                                        <Grid item>
                                          <Box
                                            backgroundColor={Colors.skyBlue}
                                            borderRadius={30}
                                            width={"45px"}
                                            height={"45px"}
                                          >
                                            <img
                                              src={LinkSvg}
                                              width={"45px"}
                                              alt="link"
                                              height={"45px"}
                                            />
                                          </Box>
                                        </Grid>
                                        <Grid item>
                                          <h1 style={styles.menuText}>Refer</h1>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                    <Grid item>
                                      <KeyboardArrowRightIcon
                                        style={styles.rightIcon}
                                      />
                                    </Grid>
                                  </Grid>
                                </CustomLink>

                                <CustomLink route={"/my-account/contact"}>
                                  <Grid
                                    container
                                    justifyContent={"space-between"}
                                    alignItems={"center"}
                                    mt={1}
                                  >
                                    <Grid item>
                                      <Grid container gap={"15px"}>
                                        <Grid item>
                                          <Box
                                            backgroundColor={Colors.skyBlue}
                                            borderRadius={30}
                                            width={"45px"}
                                            height={"45px"}
                                          >
                                            <img
                                              src={Contact}
                                              width={"45px"}
                                              height={"45px"}
                                              alt="conatct"
                                            />
                                          </Box>
                                        </Grid>
                                        <Grid item>
                                          <h1 style={styles.menuText}>
                                            Contact Us
                                          </h1>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                    <Grid item>
                                      <KeyboardArrowRightIcon
                                        style={styles.rightIcon}
                                      />
                                    </Grid>
                                  </Grid>
                                </CustomLink>
                              </Box>

                              <Box
                                boxShadow={"0px 0px 16px #67676729"}
                                padding={"10px"}
                                borderRadius={"20px"}
                                m={"10px"}
                              >
                                <CustomLink route={"/my-account/settings"}>
                                  <Grid
                                    container
                                    justifyContent={"space-between"}
                                    alignItems={"center"}
                                    mt={1}
                                  >
                                    <Grid item>
                                      <Grid container gap={"15px"}>
                                        <Grid item>
                                          <Box
                                            backgroundColor={Colors.skyBlue}
                                            width={"45px"}
                                            height={"45px"}
                                            borderRadius={30}
                                          >
                                            <img
                                              src={Set}
                                              width={"45px"}
                                              height={"45px"}
                                              alt="set"
                                            />
                                          </Box>
                                        </Grid>
                                        <Grid item>
                                          <h1 style={styles.menuText}>
                                            Settings
                                          </h1>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                    <Grid item>
                                      <KeyboardArrowRightIcon
                                        style={styles.rightIcon}
                                      />
                                    </Grid>
                                  </Grid>
                                </CustomLink>

                                <Grid
                                  container
                                  justifyContent={"space-between"}
                                  alignItems={"center"}
                                  mt={1}
                                  onClick={handleLogout}
                                  sx={{ cursor: "pointer" }}
                                >
                                  <Grid item>
                                    <Grid container gap={"15px"}>
                                      <Grid item>
                                        <Box
                                          backgroundColor={Colors.skyBlue}
                                          width={"45px"}
                                          height={"45px"}
                                          borderRadius={30}
                                        >
                                          <img
                                            src={Logout}
                                            width={"45px"}
                                            height={"45px"}
                                            alt="log"
                                          />
                                        </Box>
                                      </Grid>
                                      <Grid item>
                                        <h1 style={styles.menuText}>Logout</h1>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  <Grid item>
                                    <KeyboardArrowRightIcon
                                      style={styles.rightIcon}
                                    />
                                  </Grid>
                                </Grid>
                              </Box>
                            </Box>
                          </Box>
                        </Drawer>
                      </React.Fragment>
                    ))}
                  </Box>
                  {/* /// mobile drawer ends*/}
                </Box>
              </Box>
              <Box>
                <Box display={"flex"} gap={"10px"}>
                  <Box width={"30%"} className={"mainRoutes"}>
                    <Box
                      boxShadow={"0px 0px 16px #67676729"}
                      padding={"10px"}
                      paddingLeft={4}
                      borderRadius={"20px"}
                      mt={3}
                    >
                      <CustomLink route={"/my-account/profile"}>
                        <Grid
                          container
                          justifyContent={"space-between"}
                          alignItems={"center"}
                          mt={1}
                        >
                          <Grid item>
                            <Grid container gap={"15px"}>
                              <Grid item>
                                <Box
                                  backgroundColor={Colors.skyBlue}
                                  borderRadius={30}
                                  width={"45px"}
                                  height={"45px"}
                                >
                                  <img
                                    src={User}
                                    alt="user"
                                    width={"45px"}
                                    height={"45px"}
                                  />
                                </Box>
                              </Grid>

                              <Grid item>
                                <h1 style={styles.menuText}>Overview</h1>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item>
                            <KeyboardArrowRightIcon style={styles.rightIcon} />
                          </Grid>
                        </Grid>
                      </CustomLink>

                      <CustomLink route={"/my-account/orders"}>
                        <Grid
                          container
                          justifyContent={"space-between"}
                          alignItems={"center"}
                          mt={1}
                        >
                          <Grid item>
                            <Grid container gap={"15px"}>
                              <Grid item>
                                <Box
                                  backgroundColor={Colors.skyBlue}
                                  borderRadius={30}
                                  width={"45px"}
                                  display={"flex"}
                                  alignItems={"center"}
                                  height={"45px"}
                                >
                                  <img
                                    src={Mark}
                                    alt="mark"
                                    width={"45px"}
                                    height={"45px"}
                                  />
                                </Box>
                              </Grid>
                              <Grid item>
                                <h1 style={styles.menuText}>My Order's</h1>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item>
                            <KeyboardArrowRightIcon style={styles.rightIcon} />
                          </Grid>
                        </Grid>
                      </CustomLink>

                      <CustomLink route={"/my-account/wallet"}>
                        <Grid
                          container
                          justifyContent={"space-between"}
                          alignItems={"center"}
                          mt={1}
                        >
                          <Grid item>
                            <Grid container gap={"15px"}>
                              <Grid item>
                                <Box
                                  backgroundColor={Colors.skyBlue}
                                  borderRadius={30}
                                  width={"45px"}
                                  display={"flex"}
                                  alignItems={"center"}
                                  justifyContent={"center"}
                                  height={"45px"}
                                >
                                  <img
                                    src={walletSvg}
                                    alt="wallet"
                                    width={"45px"}
                                    height={"45px"}
                                  />
                                </Box>
                              </Grid>
                              <Grid item>
                                <h1 style={styles.menuText}>Wallet</h1>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item>
                            <KeyboardArrowRightIcon style={styles.rightIcon} />
                          </Grid>
                        </Grid>
                      </CustomLink>
                    </Box>

                    <Box
                      boxShadow={"0px 0px 16px #67676729"}
                      padding={"10px"}
                      paddingLeft={4}
                      borderRadius={"20px"}
                      mt={"12px"}
                    >
                      <Grid
                        onClick={() =>
                          window.open(
                            "https://play.google.com/store/apps/details?id=com.thrillh&hl=en-IN"
                          )
                        }
                        sx={{ cursor: "pointer" }}
                        container
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        mt={1}
                      >
                        <Grid item>
                          <Grid container gap={"15px"}>
                            <Grid item>
                              <Box
                                backgroundColor={Colors.skyBlue}
                                borderRadius={30}
                                width={"45px"}
                                height={"45px"}
                              >
                                <img
                                  src={User}
                                  alt="user"
                                  width={"45px"}
                                  height={"45px"}
                                />
                              </Box>
                            </Grid>
                            <Grid item>
                              <h1 style={styles.menuText}>Rate Our Site</h1>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item>
                          <KeyboardArrowRightIcon style={styles.rightIcon} />
                        </Grid>
                      </Grid>

                      <CustomLink route={"/my-account/help"}>
                        <Grid
                          container
                          justifyContent={"space-between"}
                          alignItems={"center"}
                          mt={1}
                        >
                          <Grid item>
                            <Grid container gap={"15px"}>
                              <Grid item>
                                <Box
                                  backgroundColor={Colors.skyBlue}
                                  borderRadius={30}
                                  width={"45px"}
                                  height={"45px"}
                                >
                                  <img
                                    src={Help}
                                    alt="help"
                                    width={"45px"}
                                    height={"45px"}
                                  />
                                </Box>
                              </Grid>
                              <Grid item>
                                <h1 style={styles.menuText}>Help</h1>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item>
                            <KeyboardArrowRightIcon style={styles.rightIcon} />
                          </Grid>
                        </Grid>
                      </CustomLink>
                      <CustomLink route={"/my-account/refer"}>
                        <Grid
                          container
                          justifyContent={"space-between"}
                          alignItems={"center"}
                          mt={1}
                        >
                          <Grid item>
                            <Grid container gap={"15px"}>
                              <Grid item>
                                <Box
                                  backgroundColor={Colors.skyBlue}
                                  borderRadius={30}
                                  width={"45px"}
                                  height={"45px"}
                                >
                                  <img
                                    alt="linkSvg"
                                    src={LinkSvg}
                                    width={"45px"}
                                    height={"45px"}
                                  />
                                </Box>
                              </Grid>
                              <Grid item>
                                <h1 style={styles.menuText}>Refer</h1>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item>
                            <KeyboardArrowRightIcon style={styles.rightIcon} />
                          </Grid>
                        </Grid>
                      </CustomLink>

                      <CustomLink route={"/my-account/contact"}>
                        <Grid
                          container
                          justifyContent={"space-between"}
                          alignItems={"center"}
                          mt={1}
                        >
                          <Grid item>
                            <Grid container gap={"15px"}>
                              <Grid item>
                                <Box
                                  backgroundColor={Colors.skyBlue}
                                  borderRadius={30}
                                  width={"45px"}
                                  height={"45px"}
                                >
                                  <img
                                    src={Contact}
                                    width={"45px"}
                                    alt="contact"
                                    height={"45px"}
                                  />
                                </Box>
                              </Grid>
                              <Grid item>
                                <h1 style={styles.menuText}>Contact Us</h1>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item>
                            <KeyboardArrowRightIcon style={styles.rightIcon} />
                          </Grid>
                        </Grid>
                      </CustomLink>
                    </Box>

                    <Box
                      boxShadow={"0px 0px 16px #67676729"}
                      padding={"10px"}
                      paddingLeft={4}
                      borderRadius={"20px"}
                      mt={"12px"}
                    >
                      <CustomLink route={"/my-account/settings"}>
                        <Grid
                          container
                          justifyContent={"space-between"}
                          alignItems={"center"}
                          mt={1}
                        >
                          <Grid item>
                            <Grid container gap={"15px"}>
                              <Grid item>
                                <Box
                                  backgroundColor={Colors.skyBlue}
                                  width={"45px"}
                                  height={"45px"}
                                  borderRadius={30}
                                >
                                  <img
                                    src={Set}
                                    alt="set"
                                    width={"45px"}
                                    height={"45px"}
                                  />
                                </Box>
                              </Grid>
                              <Grid item>
                                <h1 style={styles.menuText}>Settings</h1>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item>
                            <KeyboardArrowRightIcon style={styles.rightIcon} />
                          </Grid>
                        </Grid>
                      </CustomLink>

                      <Grid
                        container
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        mt={1}
                        onClick={handleLogout}
                        sx={{ cursor: "pointer" }}
                      >
                        <Grid item>
                          <Grid container gap={"15px"}>
                            <Grid item>
                              <Box
                                backgroundColor={Colors.skyBlue}
                                width={"45px"}
                                height={"45px"}
                                borderRadius={30}
                              >
                                <img
                                  src={Logout}
                                  alt="logout"
                                  width={"45px"}
                                  height={"45px"}
                                />
                              </Box>
                            </Grid>
                            <Grid item>
                              <h1 style={styles.menuText}>Logout</h1>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item>
                          <KeyboardArrowRightIcon style={styles.rightIcon} />
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                  <Box width={"70%"} className={"routesComponent"}>
                    <Component userDetails={userData} />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box mt={"30px"}>
            <Footer from={"about"} />
          </Box>
        </>
      )}

      {openProfileDialog && (
        <CustomDialog
          // width={"520px"}
          open={openProfileDialog}
          title={"Edit Profile"}
          onClose={() => setOpenProfileDialog(false)}
        >
          <EditProfile
            onClose={() => setOpenProfileDialog(false)}
            getUser={getUser}
            userData={userData}
          />
        </CustomDialog>
      )}
    </>
  );
};

const styles = {
  profileImage: {
    borderRadius: 50,
  },
  name: {
    fontFamily: "MetropolisBold",
    fontSize: 24,
    color: Colors.white,
    margin: 0,
    marginTop: 5,
  },
  menuText: {
    fontFamily: "MetropolisSemiBold",
    fontSize: 15,
    color: Colors.textColor,
  },

  rightIcon: {
    fontSize: 25,
    color: Colors.textColor,
  },
  screenTExt: {
    fontFamily: "MetropolisRegular",
    color: Colors.textColor,
    fontSize: 15,
    marginTop: 25,
    marginBottom: 25,
  },
  aboutImage: {
    borderRadius: 30,
    objectFit: "cover",
  },
  smallIcons: {
    width: "40px",
  },
};

export default ProfileHoc;
