import { Box, Typography, Grid } from "@mui/material";
import React from "react";
import tick from "../../../images/Flat Color (1).svg";
import Colors from "../../constants/Colors";
const PaymentSuccess = (props) => {
  const { individualOffer, orderSuccess } = props;
  return (
    <>
      <Box pl={30} pr={30} pt={10} className={"summaryCard"}>
        <Box>
          <Box
            boxShadow={"0px 0px 16px #67676729"}
            p={4}
            mt={2}
            borderRadius={10}
            className={"summaryInner"}
          >
            <Box style={{ textAlign: "center" }}>
              <img src={tick} alt="tick" />
            </Box>
            <Typography
              sx={{ textAlign: "center" }}
              fontFamily={"MetropolisSemiBold"}
              fontSize={"14px"}
              color={"#FA8842"}
            >
              Transaction Successful!{" "}
              <p style={styles.subHeadingOne}>
                YOUR PAYMENT WAS SUCCESSFULLY PROCESSED{" "}
              </p>
              <p> Reach out to us for any query.</p>
            </Typography>
            <Typography
              sx={{ textAlign: "center" }}
              fontFamily={"MetropolisSemiBold"}
              fontSize={"14px"}
              color={"#000000"}
            >
              Order ID : {orderSuccess?.payment?.orderId}
            </Typography>
            <Box
              width={"100%"}
              height={1.5}
              backgroundColor={Colors.grey2}
              mt={2}
            />
            <Grid
              container
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Grid item>
                <Grid
                  container
                  justifyContent={"center"}
                  alignItems={"center"}
                  gap={1}
                >
                  <Grid item>
                    <Box
                      width={80}
                      height={80}
                      borderRadius={50}
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <img
                        src={individualOffer?.logoImage}
                        width={70}
                        height={70}
                        alt="logo"
                      />
                    </Box>
                  </Grid>
                  <Grid item gap={"10px"}>
                    <p style={styles.name}>Offer Name</p>
                    <p style={styles.companyName}>{individualOffer?.name}</p>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid
                  container
                  justifyContent={"center"}
                  alignItems={"center"}
                  gap={1}
                >
                  <Grid item gap={"10px"}>
                    <p style={styles.name}>Transaction ID : #TA919912545665</p>
                    <Box
                      mt={"6px"}
                      display={"flex"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <Typography
                        fontFamily={"MetropolisRegular"}
                        fontSize={"12px"}
                        color={"#00000"}
                        fontWeight={"600"}
                      >
                        Amount :
                      </Typography>
                      <Typography
                        fontFamily={"MetropolisRegular"}
                        fontSize={"14px"}
                        color={"#FA8842"}
                        fontWeight={"600"}
                      >
                        ₹ {orderSuccess?.payablePrice}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  );
};

const styles = {
  billHeading: {
    fontFamily: "MetropolisRegular",
    fontSize: 22,
    color: Colors.textColor,
    margin: 0,
    marginTop: 10,
  },
  billHeadingBlue: {
    fontFamily: "MetropolisRegular",
    fontSize: 22,
    color: Colors.lightBlue,
    margin: 0,
  },
  name: {
    fontFamily: "MetropolisSemiBold",
    fontSize: 12,
    color: Colors.textColor,
    margin: 0,
  },
  offerDesc: {
    fontFamily: "MetropolisRegular",
    fontSize: 12,
    color: Colors.textColor,
    margin: 0,
    marginTop: 10,
  },
  companyName: {
    fontFamily: "MetropolisSemiBold",
    fontSize: 14,
    color: Colors.black,
    margin: 0,
    marginTop: 6,
  },
  offertext: {
    fontFamily: "MetropolisBold",
    fontSize: 13,
    color: Colors.textColor,
    margin: 0,
    marginTop: 6,
  },
  screenTExt: {
    fontFamily: "MetropolisRegular",
    color: Colors.textColor,
    fontSize: 15,
  },
  amount: {
    fontFamily: "MetropolisSemiBold",
    fontSize: 19,
    color: Colors.lightBlue,
  },
  amount1: {
    fontFamily: "MetropolisSemiBold",
    fontSize: 16,
    color: Colors.black,
  },
  checkboxText: {
    fontFamily: "MetropolisRegular",
    color: Colors.textColor,
    fontSize: 13,
  },
};
export default PaymentSuccess;
