import { Box, } from "@mui/material";
import React, { useState } from "react";

const ImageLoader = ({ src, style = {} }) => {
  const [isLoading, setIsLoading] = useState(true);

  const handleImageLoad = () => {
    setIsLoading(false);
  };

  return (
    <Box sx={{ width: "800px", minHeight: "200px" }}>
      {isLoading && <div>{"Loading..."}</div>}

      <>
        <img
          src={src}
          alt="loading"
          onLoad={handleImageLoad}
          style={{ ...style }}
        />
      </>
    </Box>
  );
};

export default ImageLoader;
