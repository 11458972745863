import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "../src/fonts/Metropolis/Metropolis-Regular.ttf";
import "../src/fonts/Metropolis/Metropolis-Light.ttf";
import "../src/fonts/Metropolis/Metropolis-Medium.ttf";
import "../src/fonts/Metropolis/Metropolis-SemiBold.ttf";
import "../src/fonts/Metropolis/Metropolis-Bold.ttf";
import "../src/fonts/Metropolis/Metropolis-ExtraBold.ttf";
import App from "./app/App";
import { BrowserRouter, useLocation } from "react-router-dom";
import { SnackbarProvider } from "./context/useSnackbar";
import { GoogleOAuthProvider } from "@react-oauth/google";

const root = ReactDOM.createRoot(document.getElementById("root"));

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ScrollToTop />
      <SnackbarProvider>
        <GoogleOAuthProvider clientId="756951302166-bbfm38d5mbsfii1ridppc76fervj8q7r.apps.googleusercontent.com">
          <App />
        </GoogleOAuthProvider>
      </SnackbarProvider>
    </BrowserRouter>
  </React.StrictMode>
);
