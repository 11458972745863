import { Box, Switch, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import eyeSvg from "../../../../images/admin/Subtraction 2.svg";
import editSvg from "../../../../images/admin/edit (4).svg";
import deleteSvg from "../../../../images/admin/Subtraction 1.svg";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "../../../../context/useSnackbar";
import AddEditCareers from "./add-edit-career";
import CustomInput from "../../../components/Input";
import CustomButton from "../../../components/Button";
import CustomDialog from "../../../components/Dialog/custom-dialog";
import { ApiRequest } from "../../../../services/api-request";
import Pagination from "../../../components/Pagination";

const CareerList = (props) => {
  const navigate = useNavigate();

  const { bgcolor } = props;

  const [openAddCareerModal, setOpenAddCareerModal] = useState({
    open: false,
    data: {},
    openType: "",
  });

  const limit = 100; // Careers per page

  const [skip, setSkip] = useState(0);

  const { openSnackbar } = useSnackbar();

  const [careerList, setCareerList] = useState([]);

  const [filter, setFilter] = useState({
    search: "",
  });

  const [deleteCareererModal, setDeleteCareererModal] = useState({
    open: false,
    data: {},
  });

  const [careererStatusChangeModal, setCareererStatusChangeModal] = useState({
    open: false,
    data: "",
    item: {},
  });

  useEffect(() => {
    getAllCareererList();
  }, [filter]);

  const getAllCareererList = () => {
    ApiRequest({
      url: `career/all${filter.search ? `?search=${filter.search}` : ""}`,
      method: "GET",
    })
      .then((res) => {
        const sortedCareerList = res.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        setCareerList(sortedCareerList);
      })
      .catch((err) => {
        openSnackbar(err?.response?.data?.message, "error");
        setCareerList(false);
      });
  };

  const handlePageChange = (page) => {
    const newSkip = (page - 1) * limit;
    setSkip(newSkip);
  };

  const handleSearchChange = (e) => {
    const searchValue = e.target.value;
    const decodedSearchValue = decodeURIComponent(searchValue);
    setFilter((prevFilter) => ({
      ...prevFilter,
      search: decodedSearchValue.trim(),
    }));
  };

  const handleDelete = () => {
    ApiRequest({
      url: `career/delete/${deleteCareererModal.data?._id}`,
      method: "DELETE",
    })
      .then((res) => {
        openSnackbar("career Deleted Successfully", "success");
        getAllCareererList();
        setDeleteCareererModal({
          open: false,
          data: {},
        });
      })
      .catch((err) => {});
  };

  const updateCareererStatusChange = (item) => {
    const payload = {
      status: careererStatusChangeModal.data,
    };

    ApiRequest({
      url: `career/update/${careererStatusChangeModal?.item?._id}`,
      method: "PUT",
      data: payload,
    })
      .then((res) => {
        setCareererStatusChangeModal({
          open: false,
          data: {},
        });
        getAllCareererList();
      })
      .catch((err) => {
        openSnackbar(err?.response?.data?.message, "error");
      });
  };

  return (
    <>
      {openAddCareerModal.open && (
        <CustomDialog
          width={"500px"}
          open={openAddCareerModal.open}
          title={
            openAddCareerModal?.openType === "edit"
              ? "Edit Career"
              : "Add Career"
          }
          onClose={() => setOpenAddCareerModal({ open: false })}
        >
          <AddEditCareers
            careerList={openAddCareerModal.data ?? {}}
            onClose={() => setOpenAddCareerModal({ open: false })}
            isEdit={openAddCareerModal?.openType === "add" ? false : true}
            getAllCareererList={() => getAllCareererList()}
          />
        </CustomDialog>
      )}

      {deleteCareererModal.open && (
        <CustomDialog
          title={"Delete career?"}
          width={"400px"}
          open={deleteCareererModal.open}
          onClose={() => setDeleteCareererModal({ open: false })}
        >
          <Typography>Are you sure you want to Delete career?</Typography>
          <Box display={"flex"} justifyContent={"end"} gap={"10px"} mt={"20px"}>
            <Box width={"20%"}>
              <CustomButton
                handleClick={() => setDeleteCareererModal({ open: false })}
                border={"1px solid #000000"}
                color={"#000000"}
                title={"No"}
                bgColor={"#F7F7F7"}
                borderRadius={"5px"}
              />
            </Box>
            <Box width={"20%"}>
              <CustomButton
                handleClick={handleDelete}
                type={"submit"}
                title={"Yes"}
                borderRadius={"5px"}
              />
            </Box>
          </Box>
        </CustomDialog>
      )}

      {careererStatusChangeModal.open && (
        <CustomDialog
          title={"Status Confirmation"}
          width={"400px"}
          open={careererStatusChangeModal.open}
          onClose={() => {
            setCareererStatusChangeModal({
              open: false,
              data: {},
            });
          }}
        >
          <Typography>
            Are you sure you want to{" "}
            {careererStatusChangeModal?.data === "active"
              ? "Active"
              : "In-Active"}{" "}
            Sub-category
          </Typography>
          <Box display={"flex"} justifyContent={"end"} gap={"10px"} mt={"20px"}>
            <Box width={"20%"}>
              <CustomButton
                border={"1px solid #000000"}
                handleClick={() =>
                  setCareererStatusChangeModal({ open: false })
                }
                color={"#000000"}
                title={"No"}
                bgColor={"#F7F7F7"}
                borderRadius={"5px"}
              />
            </Box>
            <Box width={"20%"}>
              <CustomButton
                handleClick={() =>
                  updateCareererStatusChange(careererStatusChangeModal.item)
                }
                type={"submit"}
                title={"Yes"}
                borderRadius={"5px"}
              />
            </Box>
          </Box>
        </CustomDialog>
      )}

      <Box
        bgcolor={bgcolor ? bgcolor : "#FFEDE3"}
        padding={"20px"}
        height={"100%"}
        minHeight={"94vh"}
        borderRadius={"40px 0px 40px"}
      >
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography
            fontFamily={"MetropolisBold"}
            fontSize={"20px"}
            color={"#000000"}
          >
            {"Careers"}
          </Typography>
          <Box>
            <CustomButton
              title={"+ Add Career"}
              borderRadius={"5px"}
              padding={"12px 28px"}
              handleClick={() => {
                setOpenAddCareerModal({ openType: "add", open: true });
              }}
            />
          </Box>
        </Box>

        <Box bgcolor={"#fff"} padding={"10px"} borderRadius={"10px"} mt={2}>
          <Box display={"flex"} justifyContent={"space-between"} width={"100%"}>
            <Box width={"25%"}>
              <CustomInput
                placeHolder={"Search"}
                borderRadius={"5px"}
                onChange={handleSearchChange}
              />
            </Box>
          </Box>
          <Box
            mt={"12px"}
            padding={"5px 10px"}
            borderRadius={"5px"}
            bgcolor={"#FEE0CE"}
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Box width={"10%"}>
              <Typography color={"#000000"} fontFamily={"MetropolisSemiBold"}>
                Job Id
              </Typography>
            </Box>
            <Box width={"10%"}>
              <Typography color={"#000000"} fontFamily={"MetropolisSemiBold"}>
                Job Title
              </Typography>
            </Box>
            <Box width={"40%"}>
              <Typography color={"#000000"} fontFamily={"MetropolisSemiBold"}>
                Job Description
              </Typography>
            </Box>
            <Box width={"10%"}>
              <Typography color={"#000000"} fontFamily={"MetropolisSemiBold"}>
                Status
              </Typography>
            </Box>
            <Box width={"15%"}>
              <Typography color={"#000000"} fontFamily={"MetropolisSemiBold"}>
                Action
              </Typography>
            </Box>
          </Box>
          {careerList.map((item) => {
            return (
              <Box
                padding={"5px 10px"}
                borderRadius={"5px"}
                bgcolor={"#FFFFFF"}
                border={"1px solid #FEECE3"}
                mt={"5px"}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Box width={"10%"}>
                  <Typography
                    sx={{ wordBreak: "break-word" }}
                    color={"#000000"}
                    fontFamily={"MetropolisMedium"}
                  >
                    {item.jobId}
                  </Typography>
                </Box>
                <Box width={"10%"}>
                  <Typography
                    sx={{ wordBreak: "break-word" }}
                    color={"#000000"}
                    fontFamily={"MetropolisMedium"}
                  >
                    {item.jobTitle}
                  </Typography>
                </Box>

                <Box width={"40%"}>
                  <Typography color={"#000000"} fontFamily={"MetropolisMedium"}>
                    {item.jobDescription}
                  </Typography>
                </Box>
                <Box width={"10%"}>
                  <Typography color={"#000000"} fontFamily={"MetropolisMedium"}>
                    <Switch
                      color="warning"
                      checked={item?.status === "active" ? true : false}
                      onChange={(e) => {
                        setCareererStatusChangeModal({
                          open: true,
                          data:
                            item?.status === "active" ? "in-active" : "active",
                          item: item,
                        });
                      }}
                    />
                  </Typography>
                </Box>

                <Box
                  sx={{ cursor: "pointer" }}
                  display={"flex"}
                  gap={"10px"}
                  alignItems={"center"}
                  width={"15%"}
                >
                  <Box width={"10%"}>
                    <img
                      src={eyeSvg}
                      alt=""
                      width={"20px"}
                      onClick={() =>
                        navigate(`/admin/career/${item?._id}/view`)
                      }
                    />
                  </Box>
                  <Box width={"10%"}>
                    <img
                      src={editSvg}
                      alt=""
                      width={"20px"}
                      onClick={() =>
                        setOpenAddCareerModal({
                          openType: "edit",
                          data: item,
                          open: true,
                        })
                      }
                    />
                  </Box>
                  <Box width={"10%"}>
                    <img
                      src={deleteSvg}
                      alt=""
                      width={"20px"}
                      onClick={() => {
                        setDeleteCareererModal({ open: true, data: item });
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            );
          })}

          <Box mt={2} display={"flex"} justifyContent={"right"}>
            <Pagination
              totalCount={Number(careerList?.totalCount)}
              skip={skip}
              limit={limit}
              onPageChange={handlePageChange}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default CareerList;
