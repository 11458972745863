import { Box, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import logo from "../../../images/final logo.png";
import InactiveDashboardSvg from "../../../images/sidebar/inactive-dashboard.svg";
import InactiveOfferSvg from "../../../images/sidebar/inactive-offes.svg";
import InactiveUserSvg from "../../../images/sidebar/inactive-users.svg";
import activeDashboardSvg from "../../../images/sidebar/active-dashboard.svg";
import activeUsers from "../../../images/sidebar/active-users.svg";
import activeOffersSvg from "../../../images/sidebar/active-offes.svg";
import LogoutSvg from "../../../images/sidebar/log-out-Regular.svg";
import inActiveMasterSvg from "../../../images/sidebar/inactive-master.svg";
import activeMasterSvg from "../../../images/sidebar/active-master.svg";
import InactiveBannerSvg from "../../../images/sidebar/active-banner.svg";
import activeBannerSvg from "../../../images/sidebar/inactive-banner.svg";
import InactiveCareerSvg from "../../../images/sidebar/active-carrer.svg";
import activeCareerSvg from "../../../images/sidebar/inactive-carrer.svg";
import InactiveSettingsSvg from "../../../images/sidebar/inactive-settings.svg";
import activeSettingsSvg from "../../../images/sidebar/active-settings (2).svg";
import "./sideMenuStyles.css";
import UserSvg from "../../../images/admin/user.svg";
import ActiveOrdersSvg from "../../../images/sidebar/active-order.svg";
import InActiveOrdersSvg from "../../../images/sidebar/inactive-order.svg";
import CustomDialog from "../Dialog/custom-dialog";
import CustomButton from "../Button";

function hasChildren(node) {
  return (
    typeof node === "object" &&
    typeof node.children !== "undefined" &&
    node.children.length > 0
  );
}

const MenuItem = (props) => {
  const { item } = props;
  const Component = hasChildren(item) ? MultiLevel : SingleLevel;
  return <Component item={item} />;
};

const SingleLevel = (props) => {
  const { pathname } = useLocation();
  const { item } = props;
  let itemProps = {};
  if (item.to) {
    itemProps = {
      component: NavLink,
      to: item.to,
    };
  }

  const offerPrefix = "/vendor/offer";
  const offerIndex = pathname.indexOf(offerPrefix);

  const prefixToOffer =
    offerIndex !== -1
      ? pathname.substring(0, offerIndex + offerPrefix.length)
      : "";

  const isActive = prefixToOffer === item.activeTo;

  return (
    <Box
      {...itemProps}
      style={{ textDecoration: "none" }}
      display={"flex"}
      padding={"10px"}
      gap={"10px"}
      borderRadius={"6px"}
      alignItems={"center"}
      bgcolor={
        item.to
          ? `${pathname.includes(item.to) || isActive ? "#FFEDE3" : "#fff"}`
          : `${item.inactiveIcon ? "#f7f7f7" : null}`
      }
    >
      {item.inactiveIcon && (
        <Box
          component="img"
          width={"22px"}
          height={"22px"}
          alt="Logo"
          src={
            pathname.includes(item.to)
              ? item.icon
              : isActive
              ? item.icon
              : item.inactiveIcon
          }
        />
      )}
      <Typography
        style={{ textDecoration: "none" }}
        fontFamily={"MetropolisRegular"}
        fontSize={"15px"}
        color={
          item.to
            ? `${pathname.includes(item.to) || isActive ? "#FA8842" : "black"}`
            : `${item.inactiveIcon ? "#f7f7f7" : null}`
        }
      >
        {" "}
        {item.title}
      </Typography>
    </Box>
  );
};

const MultiLevel = (props) => {
  const { pathname } = useLocation();
  const { item } = props;
  const { children } = item;

  const [expanded, setExpanded] = useState(false);

  const allPaths = item?.children?.map((item) => item?.to);

  useEffect(() => {
    if (allPaths.some((path) => pathname.startsWith(path))) {
      setExpanded(true);
    } else {
      setExpanded(false);
    }
  }, [pathname]);

  let itemProps = {};
  if (item.to) {
    itemProps = {
      component: NavLink,
      to: item.to,
    };
  }

  return (
    <React.Fragment>
      <Box
        button
        onClick={() => setExpanded(!expanded)}
        {...itemProps}
        style={{ textDecoration: "none" }}
        sx={{ cursor: "pointer" }}
        justifyContent={"space-between"}
        display={"flex"}
        gap={"10px"}
        padding={"10px"}
        borderRadius={"6px"}
        alignItems={"center"}
      >
        <Box display={"flex"} gap={"10px"}>
          {item.inactiveIcon && (
            <Box
              component="img"
              alt="Logo"
              src={pathname.includes(item.to) ? item.icon : item.inactiveIcon}
            />
          )}
          <Typography fontSize={"15px"} fontFamily={"MetropolisRegular"}>
            {item.title}
          </Typography>
        </Box>
        {expanded ? <ExpandLess /> : <ExpandMore />}
      </Box>
      <Box ml={"32px"}>
        {expanded && (
          <>
            {children.map((child, key) => (
              <MenuItem key={key} item={child} />
            ))}
          </>
        )}
      </Box>
    </React.Fragment>
  );
};

const SideMenu = (props) => {
  const [loginUser, setLoginUser] = useState(null);

  const sideMenuList = [
    {
      title: "Dashboard",
      icon: activeDashboardSvg,
      inactiveIcon: InactiveDashboardSvg,
      to: "/admin/dashboard",
    },
    {
      title: "Offers",
      icon: activeOffersSvg,
      inactiveIcon: InactiveOfferSvg,
      to: "/admin/offers",
      activeTo: "/admin/offer-view",
    },
    {
      title: "Orders",
      icon: ActiveOrdersSvg,
      inactiveIcon: InActiveOrdersSvg,
      to: `admin/orders-list`,
    },
    {
      title: "Users",
      icon: activeUsers,
      inactiveIcon: InactiveUserSvg,
      to: "/admin/users",
    },
    {
      title: "Banners",
      icon: activeBannerSvg,
      inactiveIcon: InactiveBannerSvg,
      to: "/admin/banners",
    },
    {
      title: "Careers",
      icon: activeCareerSvg,
      inactiveIcon: InactiveCareerSvg,
      to: "/admin/careers",
    },
    {
      title: "Notification",
      icon: activeCareerSvg,
      inactiveIcon: InactiveCareerSvg,
      to: "/admin/notifications",
    },
    {
      title: "Reports",
      icon: activeMasterSvg,
      inactiveIcon: inActiveMasterSvg,
      children: [
        {
          title: "Offer Reports",
          to: "/admin/offer-reports",
        },
        {
          title: "User Reports",
          to: "/admin/user-reports",
        },
        {
          title: "Transaction Reports",
          to: "/admin/transaction-reports",
        },
        {
          title: "Order Reports",
          to: "/admin/order-reports",
        },
      ],
    },
    {
      title: "Masters",
      icon: activeMasterSvg,
      inactiveIcon: inActiveMasterSvg,
      children: [
        {
          title: "Categories",
          to: "/admin/categories",
        },
        {
          title: "Sub-Categories",
          to: "/admin/sub-categories",
        },
        {
          title: "Offer Tax",
          to: "/admin/offer-tax",
        },
      ],
    },
    {
      title: "Settings",
      icon: activeSettingsSvg,
      inactiveIcon: InactiveSettingsSvg,
      to: "/admin/settings",
    },
  ];

  const vendorMenuList = [
    {
      title: "Dashboard",
      icon: activeDashboardSvg,
      inactiveIcon: InactiveDashboardSvg,
      to: "/vendor/dashboard",
    },

    {
      title: "Offers View",
      icon: activeBannerSvg,
      inactiveIcon: InactiveBannerSvg,
      to: `/vendor/offer/${loginUser?.name}/${loginUser?.offerId}`,
      activeTo: `/vendor/offer`,
    },

    {
      title: "Orders list",
      icon: ActiveOrdersSvg,
      inactiveIcon: InActiveOrdersSvg,
      to: `vendor/orders-list`,
    },

    {
      title: "Contact Us",
      icon: activeCareerSvg,
      inactiveIcon: InactiveCareerSvg,
      to: "/vendor/contact-us",
    },

    {
      title: "Settings",
      icon: activeSettingsSvg,
      inactiveIcon: InactiveSettingsSvg,
      to: `/vendor/settings`,
    },
  ];

  const navigate = useNavigate();

  const [openLogoutModal, setOpenLogoutModal] = useState({
    open: false,
  });

  const [filteredMenuList, setFilteredMenuList] = useState([]);

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("user") || "{}");
    setLoginUser(userData);
  }, []);

  useEffect(() => {
    if (loginUser?.role?.code === "vendor") {
      setFilteredMenuList(vendorMenuList);
    } else if (loginUser?.role?.code === "admin") {
      setFilteredMenuList(sideMenuList);
    }
  }, [loginUser]);

  const logOut = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("loginStatus", "false");
    props.handleLogoutSuccess();
    navigate("/login");
  };
  return (
    <>
      <Box
        bgcolor={"#fff"}
        height={"100vh"}
        padding={"15px 15px 0px 15px"}
        position={"relative"}
      >
        <Box textAlign={"center"}>
          <img src={logo} alt="logo" width={"150px"} />
        </Box>
        <Box mt={3} borderRadius={"50%"} textAlign={"center"}>
          <img
            src={UserSvg}
            alt="user"
            style={{ borderRadius: "50%" }}
            width={"100px"}
          />
        </Box>
        <Box mt={1} textAlign={"center"}>
          <Typography
            fontFamily={"MetropolisSemiBold"}
            fontSize={"16px"}
            color={"#000000"}
          >
            {loginUser?.role?.code === "admin"
              ? "Thrillh Admin"
              : "Thrill Vendor"}
          </Typography>
        </Box>
        <Box
          mt={1}
          className="sideMenu"
          sx={{
            scrollbarWidth: "thin",
            scrollbarColor: "transparent transparent",
            "&::-webkit-scrollbar": {
              width: "0.5em",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "transparent",
            },
          }}
        >
          {filteredMenuList.map((item, key) => (
            <MenuItem key={key} item={item} />
          ))}
        </Box>
        <Box position={"absolute"} bottom={"30px"} width={"100%"}>
          <Box
            display={"flex"}
            gap={"10px"}
            justifyContent={"flex-start"}
            alignItems={"center"}
          >
            <Box
              width={"70%"}
              display={"flex"}
              gap={"10px"}
              justifyContent={"center"}
              alignItems={"center"}
              bgcolor={"#FA8842"}
              padding={"10px"}
              borderRadius={"20px"}
              onClick={() => setOpenLogoutModal({ open: true })}
              sx={{ cursor: "pointer" }}
            >
              <img src={LogoutSvg} alt="logout" />
              <Typography
                fontFamily={"MetropolisRegular"}
                color={"#fff"}
                fontSize={"15px"}
              >
                Logout
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      <CustomDialog
        width={"400px"}
        open={openLogoutModal.open}
        title={"Logout"}
        onClose={() => setOpenLogoutModal({ open: false })}
      >
        <Typography>Are you sure you want to Logout?</Typography>
        <Box display={"flex"} justifyContent={"end"} gap={"10px"} mt={"20px"}>
          <Box>
            <CustomButton
              border={"1px solid #000000"}
              handleClick={() => setOpenLogoutModal({ open: false })}
              color={"#000000"}
              title={"Cancel"}
              bgColor={"#F7F7F7"}
              borderRadius={"5px"}
            />
          </Box>
          <Box>
            <CustomButton
              title={"Logout"}
              type={"submit"}
              borderRadius={"5px"}
              handleClick={logOut}
            />
          </Box>
        </Box>
      </CustomDialog>
    </>
  );
};

export default SideMenu;
