// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lists {
  font-family: "MetropolisMedium";
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  font-weight: 400;
}
.containerForPrivacy {
  padding: 60px;
  width: 1280px;
}
.para {
  font-size: 20px !important;
  font-weight: 700 !important;
  padding-top: 16px !important;
  font-family: "MetropolisMedium" !important;
  text-align: justify !important;
}
@media (min-width: 320px) and (max-width: 990px) {
  .loginAndRegisterPrivacy {
    display: none;
  }
  .containerForPrivacy {
    padding: 30px;
    width: 1280px;
  }
  .para {
    font-size: 20px !important;
    font-weight: 700 !important;
    padding-top: 10px !important;
    font-family: "MetropolisMedium" !important;
    text-align: left !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/app/screens/PrivacyPolicy/privacy-policy.css"],"names":[],"mappings":"AAAA;EACE,+BAA+B;EAC/B,eAAe;EACf,gBAAgB;EAChB,yBAAyB;EACzB,gBAAgB;AAClB;AACA;EACE,aAAa;EACb,aAAa;AACf;AACA;EACE,0BAA0B;EAC1B,2BAA2B;EAC3B,4BAA4B;EAC5B,0CAA0C;EAC1C,8BAA8B;AAChC;AACA;EACE;IACE,aAAa;EACf;EACA;IACE,aAAa;IACb,aAAa;EACf;EACA;IACE,0BAA0B;IAC1B,2BAA2B;IAC3B,4BAA4B;IAC5B,0CAA0C;IAC1C,2BAA2B;EAC7B;AACF","sourcesContent":[".lists {\n  font-family: \"MetropolisMedium\";\n  font-size: 1rem;\n  line-height: 1.5;\n  letter-spacing: 0.00938em;\n  font-weight: 400;\n}\n.containerForPrivacy {\n  padding: 60px;\n  width: 1280px;\n}\n.para {\n  font-size: 20px !important;\n  font-weight: 700 !important;\n  padding-top: 16px !important;\n  font-family: \"MetropolisMedium\" !important;\n  text-align: justify !important;\n}\n@media (min-width: 320px) and (max-width: 990px) {\n  .loginAndRegisterPrivacy {\n    display: none;\n  }\n  .containerForPrivacy {\n    padding: 30px;\n    width: 1280px;\n  }\n  .para {\n    font-size: 20px !important;\n    font-weight: 700 !important;\n    padding-top: 10px !important;\n    font-family: \"MetropolisMedium\" !important;\n    text-align: left !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
