import React, { useState } from "react";
import { Grid, Box } from "@mui/material";
import Colors from "../../constants/Colors";
import CustomInput from "../../components/Input";
import CustomButton from "../../components/Button";
import { useNavigate } from "react-router-dom";
import loginbg from "../../../images/bubbles.png";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import "./login.css";
import logo from "../../../images/final logo.png";
import { ApiRequest } from "../../../services/api-request";
import Otp from "../Otp";
import { useSnackbar } from "../../../context/useSnackbar";
import CustomDialog from "../../components/Dialog/custom-dialog";
import ModelInfo from "../../components/modelInformation";

const LoginWithOtp = () => {
  const [verifiedMobileNumber, setVerifiedMobileNumber] = useState({});

  const [openUserStatusModal, setOpenUserStatusModal] = useState({
    open: false,
    data: {},
  });
  const [userDeniedModal, setUserDeniedModal] = useState({
    data: {},
    open: false,
  });
  const navigate = useNavigate();

  const { openSnackbar } = useSnackbar();

  const schema = yup
    .object({
      mobile: yup
        .string()
        .required("Mobile Number is required")
        .test(
          "len",
          "Mobile Number must be exactly 10 digits",
          (val) => val && val.length === 10
        ),
    })
    .required();

  const { handleSubmit, control } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { mobile: "" },
  });

  const onSubmit = (data) => {
    loginWithOtp(data);
  };

  const loginWithOtp = (data) => {
    const loginWithOtpPayload = {
      ...data,
      ctx: "LOGIN",
    };
    ApiRequest({
      url: `user/send-otp`,
      data: loginWithOtpPayload,
      method: "POST",
    })
      .then((res) => {
        if (res?.success === true) {
          openSnackbar(
            "Success! An OTP has been sent to your registered mobile number",
            "success"
          );
        } else if (res?.data?.status === "pending") {
          setOpenUserStatusModal({ open: true, data: res?.data });
        } else if (res?.data?.status === "denied") {
          setUserDeniedModal({ open: true, data: res?.data });
        } else {
          openSnackbar(res?.message, "error");
        }
        setVerifiedMobileNumber({ ...res, mobile: data?.mobile });
      })
      .catch((err) => {
        openSnackbar(err, "error");
      });
  };

  const redirectTo = () => {
    if (openUserStatusModal.data?.role?.code === "student") {
      navigate(`/register/student/${openUserStatusModal?.data?.id}/form`);
    } else {
      navigate(`/register/corporate/${openUserStatusModal?.data?.id}/form`);
    }
  };

  const redirectToDetailsPage = () => {
    if (userDeniedModal?.data?.role?.code === "student") {
      navigate(`/register/student/${userDeniedModal?.data?._id}/form`);
    } else {
      navigate(`/register/corporate/${userDeniedModal?.data?._id}/form`);
    }
  };
  return (
    <>
      <CustomDialog
        width={"400px"}
        open={openUserStatusModal.open}
        title={""}
        onClose={() => setOpenUserStatusModal({ open: false })}
      >
        <ModelInfo
          type={"login"}
          title={"Continue"}
          color={"#CB0000"}
          message={"Error"}
          handleClick={redirectTo}
        />
      </CustomDialog>

      <CustomDialog
        width={"400px"}
        open={userDeniedModal.open}
        title={""}
        onClose={() => setUserDeniedModal({ open: false })}
      >
        <ModelInfo
          type={"denied"}
          title={"Continue"}
          color={"#CB0000"}
          handleClick={redirectToDetailsPage}
        />
      </CustomDialog>
      {verifiedMobileNumber?.otp_token ? (
        <Otp
          loginWithOtp={"loginWithOtp"}
          verifiedMobileNumber={verifiedMobileNumber}
          details={verifiedMobileNumber}
          requestOTP={loginWithOtp}
        />
      ) : (
        <Box height={"100vh"} bgcolor={Colors.white}>
          <Grid container>
            <Grid item md={6} width={"100%"}>
              <Box m={5}>
                <Box className={"thrillhLogo"}>
                  <img src={logo} alt={"Logo Image"} width={"150px"} />
                </Box>
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Box width={500} marginTop={"40px"} className={"loginBox"}>
                    <h2 className="heading">Login With Mobile </h2>
                    <p className="subHeading">Thrillh offers exclusively.</p>

                    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                      <Box mt={3}>
                        <Controller
                          name="mobile"
                          type="mobile"
                          defaultValue={""}
                          control={control}
                          render={({ field, fieldState: { error } }) => (
                            <CustomInput
                              label={"Mobile Number"}
                              type="text"
                              {...field}
                              placeHolder={"Enter Mobile Number"}
                              error={!!error}
                              helperText={error ? error.message : ""}
                            />
                          )}
                        />
                      </Box>
                      <Box mt={2}>
                        <CustomButton
                          title={"Login"}
                          borderButton={false}
                          type={"submit"}
                        />
                      </Box>
                    </form>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item md={6} className="registerRightSec">
              <Box position={"fixed"} width={"100%"}>
                <Box
                  position={"relative"}
                  borderRadius={"20px 0px 0px 20px"}
                  style={{
                    width: "50%",
                    height: "100vh",
                    backgroundColor: "#c5c5c5",
                  }}
                >
                  <Box
                    style={{
                      backgroundImage: `url(${loginbg})`,
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      width: "100%",
                      height: "100vh",
                      backgroundPosition: "center",
                    }}
                    position={"absolute"}
                    top={"0"}
                    zIndex={"1"}
                  ></Box>
                  <Box position={"absolute"} zIndex={"0"} top={"0"} right={"0"}>
                    <img
                      src={require("../../../images/topShape.png")}
                      alt={"Auth Bg"}
                      width={"300px"}
                    />
                  </Box>
                  <Box
                    position={"absolute"}
                    zIndex={"0"}
                    bottom={"0"}
                    left={"0"}
                  >
                    <img
                      src={require("../../../images/bottomShape.png")}
                      alt={"Auth Bg"}
                      width={"300px"}
                    />
                  </Box>
                  <Box
                    position={"absolute"}
                    top={"0"}
                    width={"100%"}
                    height={"100%"}
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <img
                      src={require("../../../images/userAuthenication/login.png")}
                      alt={"Auth Bg"}
                      width={"640px"}
                    />
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};

export default LoginWithOtp;
