import React from "react";
import { Grid, Box } from "@mui/material";
import Colors from "../../constants/Colors";
import CustomButton from "../../components/Button";
import CustomInput from "../../components/Input";
import ProfileHoc from "../../components/ProfileHoc";
import CustomSelect from "../../components/Select/CustomSelect";
import { Contact_Us_ISSUES } from "../../utilities/constants";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ApiRequest } from "../../../services/api-request";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "../../../context/useSnackbar";
import { useAppContext } from "../../../context/AppContext";
import "./index.css";

const Rate = () => {
  const ContactUsScreen = (props) => {
    const navigate = useNavigate();

    const { handleLogoutSuccess } = useAppContext();

    const validateSchema = yup
      .object({
        description: yup.string().required("Description is required"),
        type: yup.string().required("Issue Type is required"),
        name: yup.string().required("Name is required"),
        email: yup.string().required("Email  is required"),
        mobile: yup
          .string()
          .required("Mobile Number is required")
          .test(
            "len",
            "Mobile Number must be exactly 10 digits",
            (val) => val && val.length === 10
          ),
      })
      .required();

    const { handleSubmit, control, reset } = useForm({
      resolver: yupResolver(validateSchema),
      defaultValues: { mobile: "" },
    });

    const { openSnackbar } = useSnackbar();

    const onSubmit = (data) => {
      const payload = {
        ...data,
      };
      ApiRequest({
        url: `user/issue/submit`,
        data: payload,
        method: "POST",
      })
        .then((res) => {
          if (res?.success === true) {
            openSnackbar(res?.message, "success");
            reset();
          }
        })
        .catch((err) => {
          if (err) {
            openSnackbar(err?.response?.data?.message, "error");
          }
          if (err?.statusCode === 403 || err?.statusCode === 401) {
            if (navigate) {
              navigate("/login"); // Navigate to "/login" route
              handleLogoutSuccess();
            }
          }
        });
    };

    return (
      <Box
        padding={3}
        boxShadow={"0px 0px 16px #67676729"}
        borderRadius={"20px"}
        width={600}
        height={"85%"}
        mt={3}
        className={"contactCard"}
      >
        <h1 style={styles.header}>Contact Us</h1>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid
            container
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Grid item width={"100%"}>
              <Box mt={3} width={500} className={"formBox"}>
                <Box mt={2} width={500} className={"formBox"}>
                  <Controller
                    name="name"
                    control={control}
                    defaultValue={""}
                    render={({ field, fieldState: { error } }) => (
                      <CustomInput
                        {...field}
                        backgroundColor={Colors.white}
                        type={"text"}
                        inputLabel={"Enter Name"}
                        error={!!error}
                        helperText={error ? error.message : ""}
                        borderRadius={"5px"}
                      />
                    )}
                  />
                </Box>
                <Box mt={2} width={500} className={"formBox"}>
                  <Controller
                    name="mobile"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <CustomInput
                        {...field}
                        type={"text"}
                        inputLabel={"Enter Mobile"}
                        error={!!error}
                        helperText={error ? error.message : ""}
                        borderRadius={"5px"}
                      />
                    )}
                  />
                </Box>
                <Box mt={2} width={500} className={"formBox"}>
                  <Controller
                    name="email"
                    control={control}
                    defaultValue={""}
                    render={({ field, fieldState: { error } }) => (
                      <CustomInput
                        {...field}
                        type={"text"}
                        inputLabel={"Enter Email"}
                        error={!!error}
                        helperText={error ? error.message : ""}
                        borderRadius={"5px"}
                      />
                    )}
                  />
                </Box>
                <Box>
                  <Controller
                    name="type"
                    control={control}
                    defaultValue=""
                    render={({
                      field: { ...field },
                      fieldState: { error },
                    }) => (
                      <CustomSelect
                        {...field}
                        borderRadius={"5px"}
                        error={!!error}
                        helperText={error ? error.message : ""}
                        inputLabel={"Select Issue Type"}
                        options={Contact_Us_ISSUES}
                      />
                    )}
                  />
                </Box>
                <Box mt={2} width={500} className={"formBox"}>
                  <Controller
                    name="description"
                    control={control}
                    defaultValue={""}
                    render={({ field, fieldState: { error } }) => (
                      <CustomInput
                        {...field}
                        type={"text"}
                        inputLabel={"Enter Description"}
                        error={!!error}
                        helperText={error ? error.message : ""}
                        multiline={true}
                        borderRadius={"5px"}
                      />
                    )}
                  />
                </Box>
              </Box>
              <Box display={"flex"} justifyContent={"end"} mt={3}>
                <Box width={130}>
                  <CustomButton
                    title={"Submit"}
                    borderButton={false}
                    type={"submit"}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Box>
    );
  };
  return ProfileHoc(ContactUsScreen);
};

export default Rate;

const styles = {
  header: {
    fontFamily: "MetropolisMedium",
    fontSize: 18,
    color: Colors.textColor,
    margin: 0,
    fontWeight: "500",
  },
  name: {
    fontFamily: "MetropolisRegular",
    color: Colors.textColor,
    fontSize: 13,
    margin: 0,
  },
  nameValue: {
    fontFamily: "MetropolisRegular",
    color: Colors.lightBlue,
    fontSize: 17,
    margin: 0,
    marginTop: 5,
  },
  profileImg: {
    objectFit: "contain",
  },
};
