import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
const CustomSpinner = (props) => {
  return (
    <>
      <Box>
        <CircularProgress color="inherit" size={props.size} />
      </Box>
    </>
  );
};

export default CustomSpinner;
