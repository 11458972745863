// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.drawer {
  display: none;
}
.routesComponent {
  margin: 0px;
}
.menuProfileBtn {
  display: none !important;
}
@media (min-width: 320px) and (max-width: 990px) {
  .mainRoutes {
    display: none;
  }
  .drawer {
    display: inline;
  }
  .profileCard {
    width: 98% !important;
  }
  .editProfileBtn {
    top: -40px !important;
  }
  .menuProfileBtn {
    display: block !important;
  }
  .routesComponent {
    width: 100% !important;
  }
  .MuiDialogContentText-root {
    width: 100% !important;
  }
  .editSingleField {
    width: 100% !important;
  }
  .editFields {
    flex-wrap: wrap !important;
  }
  .editFieldBox {
    width: 100% !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/app/components/ProfileHoc/profile.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;AACA;EACE,WAAW;AACb;AACA;EACE,wBAAwB;AAC1B;AACA;EACE;IACE,aAAa;EACf;EACA;IACE,eAAe;EACjB;EACA;IACE,qBAAqB;EACvB;EACA;IACE,qBAAqB;EACvB;EACA;IACE,yBAAyB;EAC3B;EACA;IACE,sBAAsB;EACxB;EACA;IACE,sBAAsB;EACxB;EACA;IACE,sBAAsB;EACxB;EACA;IACE,0BAA0B;EAC5B;EACA;IACE,sBAAsB;EACxB;AACF","sourcesContent":[".drawer {\n  display: none;\n}\n.routesComponent {\n  margin: 0px;\n}\n.menuProfileBtn {\n  display: none !important;\n}\n@media (min-width: 320px) and (max-width: 990px) {\n  .mainRoutes {\n    display: none;\n  }\n  .drawer {\n    display: inline;\n  }\n  .profileCard {\n    width: 98% !important;\n  }\n  .editProfileBtn {\n    top: -40px !important;\n  }\n  .menuProfileBtn {\n    display: block !important;\n  }\n  .routesComponent {\n    width: 100% !important;\n  }\n  .MuiDialogContentText-root {\n    width: 100% !important;\n  }\n  .editSingleField {\n    width: 100% !important;\n  }\n  .editFields {\n    flex-wrap: wrap !important;\n  }\n  .editFieldBox {\n    width: 100% !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
