import React, { useState } from "react";
import { Grid, Box } from "@mui/material";
import Colors from "../../constants/Colors";
import CustomButton from "../../components/Button";
import CustomInput from "../../components/Input";
import ProfileHoc from "../../components/ProfileHoc";
import StarRatings from "react-star-ratings";

const Rate = () => {
  const [rating] = useState(1);

  const changeRating = () => {};

  const RateScreen = () => {
    return (
      <Box
        padding={3}
        boxShadow={"0px 0px 16px #67676729"}
        borderRadius={"20px"}
        width={600}
        height={"85%"}
        mt={3}>
        <p style={styles.header}>Rate Our Site</p>
        <Grid
          container
          gap={40}
          mt={4}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}>
          <Grid item>
            <StarRatings
              rating={rating}
              starRatedColor={Colors.orange}
              changeRating={changeRating}
              numberOfStars={6}
              name="rating"
              fontSize={13}
              starDimension={"30px"}
            />
            <Grid item>
              <Box mt={3} width={500}>
                <CustomInput
                  placeHolder={"Your Feedback value us. . ."}
                  label={"Your Feedback"}
                  backgroundColor={Colors.white}
                  multiline={true}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Box marginRight={7} marginTop={7}>
          <Grid
            container
            gap={1}
            justifyContent={"flex-end"}
            alignItems={"flex-end"}
            mt={3}>
            <Grid item width={130}>
              <CustomButton
                title={"Cancel"}
                borderButton={true}
                handleClick={() => {
                  console.log("hii");
                }}
              />
            </Grid>
            <Grid item width={130}>
              <CustomButton
                title={"Send"}
                borderButton={false}
                handleClick={() => {
                  console.log("hii");
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    );
  };
  return ProfileHoc(RateScreen);
};

export default Rate;

const styles = {
  header: {
    fontFamily: "MetropolisMedium",
    fontSize: 18,
    color: Colors.textColor,
    margin: 0,
  },
  name: {
    fontFamily: "MetropolisRegular",
    color: Colors.textColor,
    fontSize: 13,
    margin: 0,
  },
  nameValue: {
    fontFamily: "MetropolisRegular",
    color: Colors.lightBlue,
    fontSize: 17,
    margin: 0,
    marginTop: 5,
  },
  profileImg: {
    objectFit: "contain",
  },
};
