// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sideMenu {
  height: 60vh;
  min-height: 52vh;
  max-height: 45vh;
  overflow-y: scroll;
}

@media screen and (max-width: 1280px) {
  .sideMenu {
    height: 50vh;
    min-height: 70vh;
    max-height: 40vh;
    overflow-y: scroll;
  }
}

@media (min-width: 320px) and (max-width: 990px) {
}
`, "",{"version":3,"sources":["webpack://./src/app/components/layouts/sideMenuStyles.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,gBAAgB;EAChB,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE;IACE,YAAY;IACZ,gBAAgB;IAChB,gBAAgB;IAChB,kBAAkB;EACpB;AACF;;AAEA;AACA","sourcesContent":[".sideMenu {\n  height: 60vh;\n  min-height: 52vh;\n  max-height: 45vh;\n  overflow-y: scroll;\n}\n\n@media screen and (max-width: 1280px) {\n  .sideMenu {\n    height: 50vh;\n    min-height: 70vh;\n    max-height: 40vh;\n    overflow-y: scroll;\n  }\n}\n\n@media (min-width: 320px) and (max-width: 990px) {\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
