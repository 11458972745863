// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.settings_heading__vdX6K {
  font-size: 14px !important;
  color: #000000 !important;
  font-family: "MetropolisRegular";
  font-weight: 600 !important;
}
`, "",{"version":3,"sources":["webpack://./src/app/screens/Vendors/Settings/settings.module.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;EAC1B,yBAAyB;EACzB,gCAAgC;EAChC,2BAA2B;AAC7B","sourcesContent":[".heading {\n  font-size: 14px !important;\n  color: #000000 !important;\n  font-family: \"MetropolisRegular\";\n  font-weight: 600 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"heading": `settings_heading__vdX6K`
};
export default ___CSS_LOADER_EXPORT___;
