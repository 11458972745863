import React from "react";
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import Colors from "../../constants/Colors";
import Footer from "../../components/Footer";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./index.css";
import { Helmet } from "react-helmet";
const HomeFaq = () => {
  return (
    <>
      {/* <MetaTags
        title={"FAQ’s - Thrillh"}
        metaTitle={"FAQ’s - Thrillh"}
        description={
          "Thrillh: Your One-Stop Solution for All Your Questions. Discover Answers on Thrillh, User Accounts, Verification, Offers, and More."
        }
      /> */}
      <Helmet>
        <title>"FAQ’s - Thrillh"</title>
        <meta name="title" content={"FAQ’s - Thrillh"} />
        <meta
          name="description"
          content={
            "Thrillh: Your One-Stop Solution for All Your Questions. Discover Answers on Thrillh, User Accounts, Verification, Offers, and More."
          }
        />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "FAQPage",
            mainEntity: [
              {
                "@type": "Question",
                name: "What Does Thrillh Do?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "Thrillh.com (Siveals Digital Private Limited) is India’s online platform that enables students, corporates, and local merchants to discover and engage with each other. The brands that provide student and corporate discounts will be connected to students and corporates through our platform.",
                },
              },
              {
                "@type": "Question",
                name: "What Are the Benefits of Thrillh?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "Before you shop online, you should visit Thrillh to get these benefits:\n\n1. Exclusive offers made for you\n2. A One-Stop Solution\n3. Easy to access & redeem\n4. India's first company to provide exclusive corporate and student offers",
                },
              },
              {
                "@type": "Question",
                name: "How Do I View My Invoices?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "Invoices can be viewed under My Account => Orders & also they can be seen in your registered email inbox.",
                },
              },
              {
                "@type": "Question",
                name: "How Do I Change My Password?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "You can change your password in your account settings or by clicking 'Reset password'.",
                },
              },
              {
                "@type": "Question",
                name: "How Do I Delete My Account?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "Please visit settings under Settings⇒ Delete Account Option will be shown",
                },
              },
              {
                "@type": "Question",
                name: "How Do I Change My Name?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "You can change your name in your account settings once logged in.",
                },
              },
              {
                "@type": "Question",
                name: "How Long Does Verification Of User Take?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "Registering with an Official email ID will take less than 30 secs to sign up, whereas uploading an ID Card will take up to 24 hours to verify the user.",
                },
              },
              {
                "@type": "Question",
                name: "My Institution or Company Is Not Listed During Registration. What Should I Do?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "You can enter your institute or company name manually in the registration field.",
                },
              },
              {
                "@type": "Question",
                name: "My Institution's Email Address Is Not Recognized. What Do I Do?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "Yes, email addresses such as @yahoo.com and @gmail.com are not valid institutional email addresses. Your verification can be completed using your Student ID card if you do not have a personal institution email address.",
                },
              },
              {
                "@type": "Question",
                name: "How Do I Redeem a Code?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "To redeem a code please log in to your Thrillh account. All retailers/services will accept payments from Thrillh, if they do not accept kindly contact our support team, and they will help you with this. Please note some retailers require you to register with them before you can enter the code.",
                },
              },
              {
                "@type": "Question",
                name: "My Code Isn't Applying Any Discounts, What Do I Do?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "Have you tried re-issuing yourself a new code? This might fix the issue you're experiencing. If there is no option to get a new code, then kindly contact the support team.",
                },
              },
              // Add more FAQ items here
            ],
          })}
        </script>
      </Helmet>
      <Box>
        <Box position={"relative"} borderRadius={"30px"}>
          <img
            src={require("../../../images/contact-bg.png")}
            width={"100%"}
            height={200}
            style={styles.aboutImage}
          />
          <Box position={"absolute"} top={70} left={0} right={0}>
            <Box
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
              gap={2}
            >
              <h1 style={styles.contactHeading}>FAQ's</h1>
              <Box width={120} height={2} backgroundColor={Colors.white} />
            </Box>
          </Box>
        </Box>
        <Box display={"flex"} justifyContent={"center"}>
          <Box
            padding={2}
            boxShadow={"0px 0px 16px #67676729"}
            borderRadius={"20px"}
            width={700}
            className={"faqSec"}
            mt={3}
          >
            <h1 style={styles.header}>Help</h1>
            {/* <Box mt={3}>
              <Typography
                fontSize={"15px"}
                fontWeight={"600"}
                fontFamily={"MetropolisMedium"}
                color={"#000000"}
                variant="h2"
              >
                About Thrillh:
              </Typography>

              <Accordion
                sx={{
                  padding: "0px 12px",
                  boxShadow: "none",
                  position: "relative !important ",
                  borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                  "::before": {
                    position: "relative",
                    left: 0,
                    top: -1,
                    right: 0,
                    height: 1,
                    content: "''",
                    opacity: 1,
                    backgroundColor: "rgba(0, 0, 0, 0.12)",
                  },
                }}
              >
                <AccordionSummary
                  className="accordion"
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h3 style={styles.question}>
                    {" "}
                    <b style={{ color: "#fa8842", marginRight: "5px" }}>
                      Q
                    </b>{" "}
                    What does Thrillh do?
                  </h3>
                </AccordionSummary>
                <AccordionDetails>
                  <p style={styles.answer}>
                    <b style={{ color: "#fa8842", marginRight: "5px" }}>A</b>
                    Thrillh.com (Siveals Digital Private Limited) is India’s
                    online platform that enables students, corporates, and local
                    merchants to discover and engage with each other. The brands
                    that provide student and corporate discounts will be
                    connected to students and corporates through our platform.
                  </p>
                </AccordionDetails>
              </Accordion>
              <Accordion
                sx={{
                  padding: "0px 12px",
                  boxShadow: "none",
                  position: "relative !important ",
                  borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                  "::before": {
                    position: "relative",
                    left: 0,
                    top: -1,
                    right: 0,
                    height: 1,
                    content: "''",
                    opacity: 1,
                    backgroundColor: "rgba(0, 0, 0, 0.12)",
                  },
                }}
              >
                <AccordionSummary
                  className="accordion"
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h3 style={styles.question}>
                    <b style={{ color: "#fa8842", marginRight: "5px" }}>Q</b>
                    What are the Benefits of Thrillh?
                  </h3>
                </AccordionSummary>
                <AccordionDetails>
                  <p style={styles.answer}>
                    <b style={{ color: "#fa8842", marginRight: "5px" }}>A</b>
                    Before you shop online, you should visit Thrillh to get
                    these benefits:
                  </p>
                  <ol className="mainList">
                    <li className="list"> Exclusive offers made for you </li>
                    <li className="list"> A One-Stop Solution </li>
                    <li className="list">Easy to access & redeem</li>
                    <li className="list">
                      India's first company to provide exclusive corporate and
                      student offers{" "}
                    </li>
                  </ol>
                </AccordionDetails>
              </Accordion>
            </Box> */}

            <Box mt={3}>
              <Typography
                fontSize={"15px"}
                fontWeight={"600"}
                fontFamily={"MetropolisMedium"}
                color={"#000000"}
                variant="h2"
              >
                Verify User:
              </Typography>
              <Accordion
                sx={{
                  padding: "0px 12px",
                  boxShadow: "none",
                  position: "relative !important ",
                  borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                  "::before": {
                    position: "relative",
                    left: 0,
                    top: -1,
                    right: 0,
                    height: 1,
                    content: "''",
                    opacity: 1,
                    backgroundColor: "rgba(0, 0, 0, 0.12)",
                  },
                }}
              >
                <AccordionSummary
                  className="accordion"
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h3 style={styles.question}>
                    <b style={{ color: "#fa8842", marginRight: "5px" }}>Q</b>
                    How long does verification of the user take?
                  </h3>
                </AccordionSummary>
                <AccordionDetails>
                  <p style={styles.answer}>
                    <b style={{ color: "#fa8842", marginRight: "5px" }}>A</b>
                    Registering with an Official email ID will take less than 30 secs to Sign Up, whereas uploading an ID Card will take up to 24 hrs to verify the user.
                  </p>
                </AccordionDetails>
              </Accordion>
              <Accordion
                sx={{
                  padding: "0px 12px",
                  boxShadow: "none",
                  position: "relative !important ",
                  borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                  "::before": {
                    position: "relative",
                    left: 0,
                    top: -1,
                    right: 0,
                    height: 1,
                    content: "''",
                    opacity: 1,
                    backgroundColor: "rgba(0, 0, 0, 0.12)",
                  },
                }}
              >
                <AccordionSummary
                  className="accordion"
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h3 style={styles.question}>
                    <b style={{ color: "#fa8842", marginRight: "5px" }}>Q</b>
                    My institution or company is not listed during registration. What should I do?
                  </h3>
                </AccordionSummary>
                <AccordionDetails>
                  <p style={styles.answer}>
                    <b style={{ color: "#fa8842", marginRight: "5px" }}>A</b>
                    You can enter your institute name or company name manually in the registration field to avail the  <a href="https://thrillh.com/student/offers" target="_blank">student offers </a>  and <a href="https://thrillh.com/corporate/offers" target="_blank">corporate offers</a>.
                  </p>
                </AccordionDetails>
              </Accordion>
              <Accordion
                sx={{
                  padding: "0px 12px",
                  boxShadow: "none",
                  position: "relative !important ",
                  borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                  "::before": {
                    position: "relative",
                    left: 0,
                    top: -1,
                    right: 0,
                    height: 1,
                    content: "''",
                    opacity: 1,
                    backgroundColor: "rgba(0, 0, 0, 0.12)",
                  },
                }}
              >
                <AccordionSummary
                  className="accordion"
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h3 style={styles.question}>
                    <b style={{ color: "#fa8842", marginRight: "5px" }}>Q</b>I don't have an institution email address. What should I do?
                  </h3>
                </AccordionSummary>
                <AccordionDetails>
                  <p style={styles.answer}>
                    <b style={{ color: "#fa8842", marginRight: "5px" }}>A</b>
                    You can complete your verification using your Student ID card if you don't have a personal institution email address.
                  </p>
                </AccordionDetails>
              </Accordion>
              <Accordion
                sx={{
                  padding: "0px 12px",
                  boxShadow: "none",
                  position: "relative !important ",
                  borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                  "::before": {
                    position: "relative",
                    left: 0,
                    top: -1,
                    right: 0,
                    height: 1,
                    content: "''",
                    opacity: 1,
                    backgroundColor: "rgba(0, 0, 0, 0.12)",
                  },
                }}
              >
                <AccordionSummary
                  className="accordion"
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h3 style={styles.question}>
                    <b style={{ color: "#fa8842", marginRight: "5px" }}>Q</b>My institution's email address is not recognized. What should I do?
                  </h3>
                </AccordionSummary>
                <AccordionDetails>
                  <p style={styles.answer}>
                    <b style={{ color: "#fa8842", marginRight: "5px" }}>A</b>
                    Yes, email addresses such as @yahoo.com and @gmail.com are not valid institutional email addresses. Your verification can be completed using your Student ID card if you do not have a personal institution email address.
                  </p>
                </AccordionDetails>
              </Accordion>
              <Accordion
                sx={{
                  padding: "0px 12px",
                  boxShadow: "none",
                  position: "relative !important ",
                  borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                  "::before": {
                    position: "relative",
                    left: 0,
                    top: -1,
                    right: 0,
                    height: 1,
                    content: "''",
                    opacity: 1,
                    backgroundColor: "rgba(0, 0, 0, 0.12)",
                  },
                }}
              >
                <AccordionSummary
                  className="accordion"
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h3 style={styles.question}>
                    <b style={{ color: "#fa8842", marginRight: "5px" }}>Q</b>
                    I am a student and require manual verification. What documents are accepted?
                  </h3>
                </AccordionSummary>
                <AccordionDetails>
                  <p style={styles.answer}>
                    <b style={{ color: "#fa8842", marginRight: "5px" }}>A</b>
                    To verify your eligibility for availing <a href="https://thrillh.com/student/offers" target="_blank" rel="noreferrer">student offers</a>, we need to see documentation confirming your academic status
                  </p>
                  <p className="subPara">
                    You must provide the following information in your document/s:
                  </p>
                  <ol className="mainList">
                    <li className="list"> Your name</li>
                    <li className="list">
                      The name of the university or college you attend
                    </li>
                    <li className="list">Be valid for the current year</li>
                  </ol>
                  <p className="subPara">
                    We accept the following types of documents:
                  </p>
                  <ol className="mainList">
                    <li className="list"> Student ID card</li>
                    <li className="list">Admission letter</li>
                    <li className="list">Or an enrollment letter</li>
                  </ol>
                  <p className="subPara">We do not accept:</p>
                  <ol className="mainList">
                    <li className="list"> Residential documentation</li>
                    <li className="list">
                      Government IDs such as driver's licenses, passports, & PAN cards.
                    </li>
                  </ol>
                </AccordionDetails>
              </Accordion>
              <Accordion
                sx={{
                  padding: "0px 12px",
                  boxShadow: "none",
                  position: "relative !important ",
                  borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                  "::before": {
                    position: "relative",
                    left: 0,
                    top: -1,
                    right: 0,
                    height: 1,
                    content: "''",
                    opacity: 1,
                    backgroundColor: "rgba(0, 0, 0, 0.12)",
                  },
                }}
              >
                <AccordionSummary
                  className="accordion"
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h3 style={styles.question}>
                    <b style={{ color: "#fa8842", marginRight: "5px" }}>Q</b>
                    Do you support apprenticeships?
                  </h3>
                </AccordionSummary>
                <AccordionDetails>
                  <p style={styles.answer}>
                    <b style={{ color: "#fa8842", marginRight: "5px" }}>A</b>
                    Here at Thrillh, we are committed to supporting all students. We also support students on Apprenticeships, Traineeships, and <a href="https://thrillh.com/careers" target="_blank">Professional Placements</a> in addition to Schools, Colleges, and Universities.
                  </p>
                </AccordionDetails>
              </Accordion>
              <Accordion
                sx={{
                  padding: "0px 12px",
                  boxShadow: "none",
                  position: "relative !important ",
                  borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                  "::before": {
                    position: "relative",
                    left: 0,
                    top: -1,
                    right: 0,
                    height: 1,
                    content: "''",
                    opacity: 1,
                    backgroundColor: "rgba(0, 0, 0, 0.12)",
                  },
                }}
              >
                <AccordionSummary
                  className="accordion"
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h3 style={styles.question}>
                    <b style={{ color: "#fa8842", marginRight: "5px" }}>Q</b>
                    Have a different problem?
                  </h3>
                </AccordionSummary>
                <AccordionDetails>
                  <p style={styles.answer}>
                    <b style={{ color: "#fa8842", marginRight: "5px" }}>A</b>
                    Do you have a different problem? Feel free to <a href="https://thrillh.com/contact-us" target="_blank">contact our support team</a> if you need assistance
                  </p>
                </AccordionDetails>
              </Accordion>
            </Box>
            <Box mt={3}>
              <Typography
                fontSize={"15px"}
                fontWeight={"600"}
                fontFamily={"MetropolisMedium"}
                color={"#000000"}
                variant="h2"
              >
                My account:
              </Typography>
              <Accordion
                sx={{
                  padding: "0px 12px",
                  boxShadow: "none",
                  position: "relative !important ",
                  borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                  "::before": {
                    position: "relative",
                    left: 0,
                    top: -1,
                    right: 0,
                    height: 1,
                    content: "''",
                    opacity: 1,
                    backgroundColor: "rgba(0, 0, 0, 0.12)",
                  },
                }}
              >
                <AccordionSummary
                  className="accordion"
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h3 style={styles.question}>
                    <b style={{ color: "#fa8842", marginRight: "5px" }}>Q</b>
                    How do I view my invoices?{" "}
                  </h3>
                </AccordionSummary>
                <AccordionDetails>
                  <p style={styles.answer}>
                    <b style={{ color: "#fa8842", marginRight: "5px" }}>A</b>
                    Invoices can be viewed under My Account ={">"} Orders & also
                    they can be seen in your registered email inbox
                  </p>
                </AccordionDetails>
              </Accordion>
              <Accordion
                sx={{
                  padding: "0px 12px",
                  boxShadow: "none",
                  position: "relative !important ",
                  borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                  "::before": {
                    position: "relative",
                    left: 0,
                    top: -1,
                    right: 0,
                    height: 1,
                    content: "''",
                    opacity: 1,
                    backgroundColor: "rgba(0, 0, 0, 0.12)",
                  },
                }}
              >
                <AccordionSummary
                  className="accordion"
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h3 style={styles.question}>
                    <b style={{ color: "#fa8842", marginRight: "5px" }}>Q</b>
                    How do I change my password?
                  </h3>
                </AccordionSummary>
                <AccordionDetails>
                  <p style={styles.answer}>
                    <b style={{ color: "#fa8842", marginRight: "5px" }}>A</b>
                    You can change your password in your account settings or by clicking <a href="https://thrillh.com/forgot-password" target="_blank">Reset password</a>.
                  </p>
                </AccordionDetails>
              </Accordion>
              <Accordion
                sx={{
                  padding: "0px 12px",
                  boxShadow: "none",
                  position: "relative !important ",
                  borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                  "::before": {
                    position: "relative",
                    left: 0,
                    top: -1,
                    right: 0,
                    height: 1,
                    content: "''",
                    opacity: 1,
                    backgroundColor: "rgba(0, 0, 0, 0.12)",
                  },
                }}
              >
                <AccordionSummary
                  className="accordion"
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h3 style={styles.question}>
                    <b style={{ color: "#fa8842", marginRight: "5px" }}>Q</b>
                    How do I delete my account?
                  </h3>
                </AccordionSummary>
                <AccordionDetails>
                  <p style={styles.answer}>
                    <b style={{ color: "#fa8842", marginRight: "5px" }}>A</b>
                    Please visit settings under Settings⇒ Delete Account Option
                    will be shown
                  </p>
                </AccordionDetails>
              </Accordion>
              <Accordion
                sx={{
                  padding: "0px 12px",
                  boxShadow: "none",
                  position: "relative !important ",
                  borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                  "::before": {
                    position: "relative",
                    left: 0,
                    top: -1,
                    right: 0,
                    height: 1,
                    content: "''",
                    opacity: 1,
                    backgroundColor: "rgba(0, 0, 0, 0.12)",
                  },
                }}
              >
                <AccordionSummary
                  className="accordion"
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h3 style={styles.question}>
                    <b style={{ color: "#fa8842", marginRight: "5px" }}>Q</b>
                    How do I change my name?
                  </h3>
                </AccordionSummary>
                <AccordionDetails>
                  <p style={styles.answer}>
                    <b style={{ color: "#fa8842", marginRight: "5px" }}>A</b>
                    You can change your name in your account settings once
                    logged in.
                  </p>
                </AccordionDetails>
              </Accordion>
              <Accordion
                sx={{
                  padding: "0px 12px",
                  boxShadow: "none",
                  position: "relative !important ",
                  borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                  "::before": {
                    position: "relative",
                    left: 0,
                    top: -1,
                    right: 0,
                    height: 1,
                    content: "''",
                    opacity: 1,
                    backgroundColor: "rgba(0, 0, 0, 0.12)",
                  },
                }}
              >
                <AccordionSummary
                  className="accordion"
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h3 style={styles.question}>
                    <b style={{ color: "#fa8842", marginRight: "5px" }}>Q</b>
                    My mobile number was lost. How should I sign in now?
                  </h3>
                </AccordionSummary>
                <AccordionDetails>
                  <p style={styles.answer}>
                    <b style={{ color: "#fa8842", marginRight: "5px" }}>A</b>
                    Don't worry, you can sign in with your mobile number & existing password. If not, by any means kindly <a href="https://thrillh.com/contact-us" target="_blank">contact the support team</a>.
                  </p>
                </AccordionDetails>
              </Accordion>
            </Box>

            <Box mt={3}>
              <Typography
                fontSize={"15px"}
                fontWeight={"600"}
                fontFamily={"MetropolisMedium"}
                color={"#000000"}
                variant="h2"
              >
                Discount Codes & Offers:
              </Typography>
              <Accordion
                sx={{
                  padding: "0px 12px",
                  boxShadow: "none",
                  position: "relative !important ",
                  borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                  "::before": {
                    position: "relative",
                    left: 0,
                    top: -1,
                    right: 0,
                    height: 1,
                    content: "''",
                    opacity: 1,
                    backgroundColor: "rgba(0, 0, 0, 0.12)",
                  },
                }}
              >
                <AccordionSummary
                  className="accordion"
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h3 style={styles.question}>
                    <b style={{ color: "#fa8842", marginRight: "5px" }}>Q</b>
                    How do I redeem a code on Thrillh?
                  </h3>
                </AccordionSummary>
                <AccordionDetails>
                  <p style={styles.answer}>
                    <b style={{ color: "#fa8842", marginRight: "5px" }}>A</b>
                    To redeem a code please <a href="https://thrillh.com/login" target="_blank">log in to your Thrillh account</a>. All retailers/services will accept payments from Thrillh, if they do not accept, kindly contact our support team, and the Thrillh team will help you with this. Please note some retailers require you to register with them before you can enter the code.
                  </p>
                </AccordionDetails>
              </Accordion>
              <Accordion
                sx={{
                  padding: "0px 12px",
                  boxShadow: "none",
                  position: "relative !important ",
                  borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                  "::before": {
                    position: "relative",
                    left: 0,
                    top: -1,
                    right: 0,
                    height: 1,
                    content: "''",
                    opacity: 1,
                    backgroundColor: "rgba(0, 0, 0, 0.12)",
                  },
                }}
              >
                <AccordionSummary
                  className="accordion"
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h3 style={styles.question}>
                    <b style={{ color: "#fa8842", marginRight: "5px" }}>Q</b>
                    My code isn't applying any discount, what do I do?
                  </h3>
                </AccordionSummary>
                <AccordionDetails>
                  <p style={styles.answer}>
                    <b style={{ color: "#fa8842", marginRight: "5px" }}>A</b>
                    Have you tried re-issuing yourself a new code? This might fix the issue you're experiencing. If there is no option to get a new code, then kindly contact the <a href="https://thrillh.com/contact-us" target="_blank">Thrillh support team</a>.
                  </p>
                </AccordionDetails>
              </Accordion>
              <Accordion
                sx={{
                  padding: "0px 12px",
                  boxShadow: "none",
                  position: "relative !important ",
                  borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                  "::before": {
                    position: "relative",
                    left: 0,
                    top: -1,
                    right: 0,
                    height: 1,
                    content: "''",
                    opacity: 1,
                    backgroundColor: "rgba(0, 0, 0, 0.12)",
                  },
                }}
              >
                <AccordionSummary
                  className="accordion"
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h3 style={styles.question}>
                    {" "}
                    <b style={{ color: "#fa8842", marginRight: "5px" }}>Q</b>I was refused an in-store discount. What can I do?
                  </h3>
                </AccordionSummary>
                <AccordionDetails>
                  <p style={styles.answer}>
                    <b style={{ color: "#fa8842", marginRight: "5px" }}>A</b>
                    The relevant retailer’s head office has approved all of our perks/discounts. While every effort is made to ensure that this is communicated to each participating store, errors can be made from time to time. If you contact us we can let the relevant <a href="https://thrillh.com/in-store/offers" target="_blank">in-store brand</a> retailer know so that they can address staff training.
                  </p>
                </AccordionDetails>
              </Accordion>
              <Accordion
                sx={{
                  padding: "0px 12px",
                  boxShadow: "none",
                  position: "relative !important ",
                  borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                  "::before": {
                    position: "relative",
                    left: 0,
                    top: -1,
                    right: 0,
                    height: 1,
                    content: "''",
                    opacity: 1,
                    backgroundColor: "rgba(0, 0, 0, 0.12)",
                  },
                }}
              >
                <AccordionSummary
                  className="accordion"
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h3 style={styles.question}>
                    <b style={{ color: "#fa8842", marginRight: "5px" }}>Q</b>
                    My payment was stuck in between in-store payments, what should I do?
                  </h3>
                </AccordionSummary>
                <AccordionDetails>
                  <p style={styles.answer}>
                    <b style={{ color: "#fa8842", marginRight: "5px" }}>A</b>
                    No need to worry at all! Your in-store payment will be safe. For assistance, please <a href="https://thrillh.com/contact-us">contact us</a> and we will ensure your stuck payment is refunded.
                  </p>
                </AccordionDetails>
              </Accordion>
            </Box>

            <Box mt={3}>
              <Typography
                fontSize={"15px"}
                fontWeight={"600"}
                fontFamily={"MetropolisMedium"}
                color={"#000000"}
                variant="h2"
              >
                Help/support:
              </Typography>
              <Accordion
                sx={{
                  padding: "0px 12px",
                  boxShadow: "none",
                  position: "relative !important ",
                  borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                  "::before": {
                    position: "relative",
                    left: 0,
                    top: -1,
                    right: 0,
                    height: 1,
                    content: "''",
                    opacity: 1,
                    backgroundColor: "rgba(0, 0, 0, 0.12)",
                  },
                }}
              >
                <AccordionSummary
                  className="accordion"
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h3 style={styles.question}>
                    <b style={{ color: "#fa8842", marginRight: "5px" }}>Q</b>
                    How do I contact customer support?
                  </h3>
                </AccordionSummary>
                <AccordionDetails>
                  <p style={styles.answer}>
                    <b style={{ color: "#fa8842", marginRight: "5px" }}>A</b>
                    You can email us at Info@Thrillh.com or call us at <a href="tel:+917989331056">+91 7989331056</a> (09:00 AM to 06:00 PM IST) or WhatsApp to <a href="tel:+919966664878">+91 9966664878</a> (24hrs)
                  </p>
                </AccordionDetails>
              </Accordion>
              <Accordion
                sx={{
                  padding: "0px 12px",
                  boxShadow: "none",
                  position: "relative !important ",
                  borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                  "::before": {
                    position: "relative",
                    left: 0,
                    top: -1,
                    right: 0,
                    height: 1,
                    content: "''",
                    opacity: 1,
                    backgroundColor: "rgba(0, 0, 0, 0.12)",
                  },
                }}
              >
                <AccordionSummary
                  className="accordion"
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h3 style={styles.question}>
                    <b style={{ color: "#fa8842", marginRight: "5px" }}>Q</b>I have feedback or an idea to share. How do I share it?
                  </h3>
                </AccordionSummary>
                <AccordionDetails>
                  <p style={styles.answer}>
                    <b style={{ color: "#fa8842", marginRight: "5px" }}>A</b>
                    We LOVE feedback & ideas. Please feel free to share all your thoughts at <a href="mailto:Info@Thrillh.com">Info@Thrillh.com</a>.
                  </p>
                </AccordionDetails>
              </Accordion>
              <Accordion
                sx={{
                  padding: "0px 12px",
                  boxShadow: "none",
                  position: "relative !important ",
                  borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                  "::before": {
                    position: "relative",
                    left: 0,
                    top: -1,
                    right: 0,
                    height: 1,
                    content: "''",
                    opacity: 1,
                    backgroundColor: "rgba(0, 0, 0, 0.12)",
                  },
                }}
              >
                <AccordionSummary
                  className="accordion"
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h3 style={styles.question}>
                    {" "}
                    <b style={{ color: "#fa8842", marginRight: "5px" }}>Q</b>
                    How do I list my business on Thrillh?
                  </h3>
                </AccordionSummary>
                <AccordionDetails>
                  <p style={styles.answer}>
                    <b style={{ color: "#fa8842", marginRight: "5px" }}>A</b>
                    Kindly send a mail to <a href="mailto:Info@Thrillh.com">Info@Thrillh.com</a>
                  </p>
                </AccordionDetails>
              </Accordion>
            </Box>

            <Box mt={3}>
              <Typography
                fontSize={"15px"}
                fontWeight={"600"}
                fontFamily={"MetropolisMedium"}
                color={"#000000"}
                variant="h2"
              >
                App Support:
              </Typography>
              <Accordion
                sx={{
                  padding: "0px 12px",
                  boxShadow: "none",
                  position: "relative !important ",
                  borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                  "::before": {
                    position: "relative",
                    left: 0,
                    top: -1,
                    right: 0,
                    height: 1,
                    content: "''",
                    opacity: 1,
                    backgroundColor: "rgba(0, 0, 0, 0.12)",
                  },
                }}
              >
                <AccordionSummary
                  className="accordion"
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h3 style={styles.question}>
                    <b style={{ color: "#fa8842", marginRight: "5px" }}>Q</b>I have a Windows phone, but I can't download the Thrillh app, why?
                  </h3>
                </AccordionSummary>
                <AccordionDetails>
                  <p style={styles.answer}>
                    <b style={{ color: "#fa8842", marginRight: "5px" }}>A</b>
                    The Thrillh app isn't currently operational on Windows devices. However, you can still get access to Thrillh via
                    <a href="https://thrillh.com/">www.thrillh.com</a> on the web.
                  </p>
                </AccordionDetails>
              </Accordion>
              <Accordion
                sx={{
                  padding: "0px 12px",
                  boxShadow: "none",
                  position: "relative !important ",
                  borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                  "::before": {
                    position: "relative",
                    left: 0,
                    top: -1,
                    right: 0,
                    height: 1,
                    content: "''",
                    opacity: 1,
                    backgroundColor: "rgba(0, 0, 0, 0.12)",
                  },
                }}
              >
                <AccordionSummary
                  className="accordion"
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h3 style={styles.question}>
                    <b style={{ color: "#fa8842", marginRight: "5px" }}>Q</b>
                    The Thrillh app isn't working, what can I do?
                  </h3>
                </AccordionSummary>
                <AccordionDetails>
                  <p style={styles.answer}>
                    <b style={{ color: "#fa8842", marginRight: "5px" }}>A</b>
                    If you're having issues with the Thrillh app, please try uninstalling and reinstalling the Thrillh app as we may have released an update. Are you still having trouble with the new installation? <a href="https://thrillh.com/contact-us">contact us</a> and let us know.
                  </p>
                </AccordionDetails>
              </Accordion>
              <Accordion
                sx={{
                  padding: "0px 12px",
                  boxShadow: "none",
                  position: "relative !important ",
                  borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                  "::before": {
                    position: "relative",
                    left: 0,
                    top: -1,
                    right: 0,
                    height: 1,
                    content: "''",
                    opacity: 1,
                    backgroundColor: "rgba(0, 0, 0, 0.12)",
                  },
                }}
              >
                <AccordionSummary
                  className="accordion"
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h3 style={styles.question}>
                    <b style={{ color: "#fa8842", marginRight: "5px" }}>Q</b>
                    Have a different problem?
                  </h3>
                </AccordionSummary>
                <AccordionDetails>
                  <p style={styles.answer}>
                    <b style={{ color: "#fa8842", marginRight: "5px" }}>A</b>
                    If none of our <a href="https://thrillh.com/faqs">FAQs</a> seem to solve your problem, you can submit your query at <a href="mailto:info@thrillh.com">info@thrillh.com</a>.
                  </p>
                </AccordionDetails>
              </Accordion>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box mt={"30px"}>
        <Footer from={"about"} />
      </Box>
    </>
  );
};

const styles = {
  aboutImage: {
    objectFit: "cover",
    objectPosition: "center",
  },
  contactHeading: {
    fontFamily: "MetropolisBold ",
    fontSize: 35,
    color: Colors.white,
    margin: 0,
  },
  header: {
    fontFamily: "MetropolisMedium",
    fontSize: 18,
    color: Colors.textColor,
    margin: 0,
    fontWeight: "550",
  },
  name: {
    fontFamily: "MetropolisRegular",
    color: Colors.textColor,
    fontSize: 13,
    margin: 0,
  },
  nameValue: {
    fontFamily: "MetropolisRegular",
    color: Colors.lightBlue,
    fontSize: 17,
    margin: 0,
    marginTop: 5,
  },
  profileImg: {
    objectFit: "contain",
  },
  question: {
    fontFamily: "MetropolisRegular",
    fontSize: 15,
    lineHeight: "22px",
    color: Colors.textColor,
    fontWeight: 550,
  },
  answer: {
    fontFamily: "MetropolisRegular",
    fontSize: 15,
    color: Colors.textColor,
    width: "100%",
    lineHeight: 1.5,
    margin: 0,
  },
};

export default HomeFaq;
