import React, { useState, useEffect } from "react";
import Footer from "../../components/Footer";
import { Box, Grid, RadioGroup, Typography } from "@mui/material";
import BgBaground from "../../../images/bgForCareerer.svg";
import careerSvg from "../../../images/careerer page.svg";
import styles from "./webcareers.module.css";
import CustomInput from "../../components/Input";
import SearchIcon from "@mui/icons-material/Search";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import growthSvg from "../../../images/growth.svg";
import CustomButton from "../../components/Button";
import { ApiRequest } from "../../../services/api-request";
import { useNavigate } from "react-router-dom";
import CustomDialog from "../../components/Dialog/custom-dialog";
import ApplyJobForm from "./apply-job-form";
import jobSvg from "../../../images/noJobsFound.jpg";
import CustomSpinner from "../../components/Spinner";
import MetaTags from "../../components/ReactMetaTags/meta-tags";
const WebCareers = () => {
  const navigate = useNavigate();

  const [careerList, setCareerList] = useState({
    data: [],
    loader: true,
  });

  const [openJobModal, setOpenJobModal] = useState({
    open: false,
    data: {},
  });

  const [filters, setFilters] = useState({
    search: "",
    AtoZ: "az",
    ZtoA: "",
    newest: "",
    oldest: "",
  });

  useEffect(() => {
    getAllCareererList(filters);
  }, [filters]);

  const getAllCareererList = (filters) => {
    let sortingParam = "";

    if (filters.AtoZ) {
      sortingParam = "az";
    } else if (filters.ZtoA) {
      sortingParam = "za";
    } else if (filters.newest) {
      sortingParam = "newest";
    } else if (filters.oldest) {
      sortingParam = "oldest";
    }

    ApiRequest({
      url: `career/all?status=active${
        filters.search ? `&search=${filters.search}` : ""
      }${sortingParam ? `&sort=${sortingParam}` : ""}`,
      method: "GET",
    })
      .then((res) => {
        setCareerList({ data: res, loader: false });
      })
      .catch((err) => {
        setCareerList({ loader: false });
      });
  };

  const handleSearchChange = (e) => {
    const searchString = e.target.value;
    setFilters({ search: searchString });
  };

  const handleChangeAtoZ = (e) => {
    const selectedValue = e.target.value;
    setFilters({ AtoZ: selectedValue });
  };

  const handleChangeZtoA = (e) => {
    const selectedValue = e.target.value;
    setFilters({ ZtoA: selectedValue });
  };

  const handleNewest = (e) => {
    const selectedValue = e.target.value;
    setFilters({ newest: selectedValue });
  };
  const handleChangeOldest = (e) => {
    const selectedValue = e.target.value;
    setFilters({ oldest: selectedValue });
  };

  return (
    <>
      <MetaTags
        title={"Thrillh - Careers"}
        metaTitle={"Thrillh - Careers"}
        content={
          "Thrillh: Where Passion Meets Purpose. Discover Rewarding Career Opportunities and Unleash Your Potential"
        }
      />
      <Box position={"relative"}>
        <Box
          style={{
            backgroundImage: `url(${BgBaground})`,
            backgroundSize: "cover",
            backgroundPosition: "bottom",
          }}
          padding={"40px 20px 40px 20px"}
        >
          <Box className={styles.overlay}>
  
          </Box>
          <Grid container className={styles.careerContainer}>
            <Grid item md={7}>
              <Typography
                className={styles.heading}
                fontFamily={"MetropolisRegular"}
                variant="h1"
              >
                Thrillh Careers
              </Typography>
              <Typography
                variant="h2"
                className={styles.subHeading}
                fontFamily={"MetropolisRegular"}
              >
                Where Passion Meets Opportunity
              </Typography>
              <Typography
                className={styles.description}
                fontFamily={"MetropolisRegular"}
              >
                Thrillh is a leading provider of experiences and activities,
                connecting people with their passions and helping them discover
                new and exciting things to do. We're a team of passionate and
                creative individuals who are driven by a shared mission: to make
                the world a more thrilling place.
              </Typography>
              <Typography
                mt={"15px"}
                className={styles.description}
                fontFamily={"MetropolisRegular"}
              >
                If you're looking for a career where you can make a real impact,
                be part of a dynamic team, and experience the thrill of helping
                people discover new passions, we encourage you to explore our
                career opportunities.
              </Typography>
            </Grid>
            <Grid item md={3}>
              <img src={careerSvg} alt="" width={"250px"} />
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
        <Box width={"1100px"}>
          <Grid container spacing={2} justifyContent={"center"}>
            <Grid item md={3} mt={"75px"}>
              <Box>
                <Box
                  boxShadow={"0px 3px 16px #67676729"}
                  borderRadius={"16px"}
                  bgcolor={"#fff"}
                  padding={"10px "}
                >
                  <Typography
                    className={styles.filterHeader}
                    fontFamily={"MetropolisBold"}
                  >
                    Filter
                  </Typography>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="az"
                    className={styles.radioContainer}
                    name="radio-buttons-group"
                  >
                    <Box display={"flex"} alignItems={"center"}>
                      <FormControlLabel
                        sx={{ ml: "0px", mr: "0px" }}
                        value="az"
                        onChange={handleChangeAtoZ}
                        control={<Radio size="small" />}
                      />
                      <Typography
                        className={styles.allFilters}
                        sx={{ fontFamily: "MetropolisSemiBold" }}
                      >
                        A to Z
                      </Typography>
                    </Box>
                    <Box display={"flex"} alignItems={"center"}>
                      <FormControlLabel
                        sx={{ ml: "0px", mr: "0px" }}
                        value="za"
                        onChange={handleChangeZtoA}
                        control={<Radio size="small" />}
                      />
                      <Typography
                        className={styles.allFilters}
                        sx={{ fontFamily: "MetropolisSemiBold" }}
                      >
                        Z to A
                      </Typography>
                    </Box>
                    <Box display={"flex"} alignItems={"center"}>
                      <FormControlLabel
                        sx={{ ml: "0px", mr: "0px" }}
                        value="newest"
                        onChange={handleNewest}
                        control={<Radio size="small" />}
                      />
                      <Typography
                        className={styles.allFilters}
                        sx={{ fontFamily: "MetropolisSemiBold" }}
                      >
                        Newest
                      </Typography>
                    </Box>
                    <Box display={"flex"} alignItems={"center"}>
                      <FormControlLabel
                        sx={{ ml: "0px", mr: "0px" }}
                        value="oldest"
                        onChange={handleChangeOldest}
                        control={<Radio size="small" />}
                      />
                      <Typography
                        className={styles.allFilters}
                        sx={{ fontFamily: "MetropolisSemiBold" }}
                      >
                        Oldest
                      </Typography>
                    </Box>
                  </RadioGroup>
                </Box>
              </Box>
            </Grid>
            <Grid item md={8}>
              <Box mt={2}>
                <CustomInput
                  placeHolder={"Search"}
                  startAdornment={<SearchIcon />}
                  padding={"17px"}
                  onChange={handleSearchChange}
                />
              </Box>
              {careerList.loader ? (
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  height={"300px"}
                >
                  <CustomSpinner />
                </Box>
              ) : (
                <>
                  {careerList.data.length ? (
                    <>
                      {careerList.data?.map((item) => {
                        return (
                          <Box
                            key={item?._id}
                            mt={2}
                            boxShadow={"0px 0px 16px #67676729"}
                            padding={"15px"}
                            bgcolor={"#fff"}
                            borderRadius={"16px"}
                          >
                            <Typography
                              className="jobCard"
                              variant="h6"
                              sx={{
                                fontFamily: "MetropolisSemiBold",
                                fontSize: "16px",
                              }}
                            >
                              {item.jobTitle}
                            </Typography>
                            <Box
                              display={"flex"}
                              justifyContent={"space-between"}
                              alignItems={"center"}
                              gap={"12px"}
                            >
                              <Box
                                display={"flex"}
                                gap={"5px"}
                                alignItems={"center"}
                              >
                                <img src={growthSvg} alt="" />
                                <Typography
                                  className="jobDes"
                                  variant="h4"
                                  sx={{
                                    fontFamily: "MetropolisRegular",
                                    fontSize: "15px",
                                  }}
                                >
                                  Job ID : {item.jobId}
                                </Typography>
                              </Box>
                              <Box
                                display={"flex"}
                                gap={"10px"}
                                alignItems={"center"}
                              >
                                <Box item md={4}>
                                  <CustomButton
                                    title={"Learn More"}
                                    padding={"10px 20px"}
                                    bgColor={"#000000"}
                                    handleClick={() =>
                                      navigate(`/careerer/${item?._id}/details`)
                                    }
                                  />
                                </Box>
                                <Box item md={4}>
                                  <CustomButton
                                    title={"Apply"}
                                    padding={"10px 30px"}
                                    handleClick={() =>
                                      setOpenJobModal({
                                        open: true,
                                        data: item,
                                      })
                                    }
                                  />
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        );
                      })}
                    </>
                  ) : (
                    <Box
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <img
                        src={jobSvg}
                        alt=""
                        style={{ width: "400px", height: "400px" }}
                      />
                    </Box>
                  )}
                </>
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>
      {openJobModal.open && (
        <CustomDialog
          width={"500px"}
          open={openJobModal.open}
          title={"Apply Job"}
          onClose={() => setOpenJobModal({ open: false })}
        >
          <ApplyJobForm
            onClose={() => setOpenJobModal({ open: false, data: {} })}
            getAllCareererList={() => getAllCareererList()}
            jobID={openJobModal.data}
          />
        </CustomDialog>
      )}
      <Box mt={"30px"}>
        <Footer from={"career"} />
      </Box>
      <Box />
    </>
  );
};

export default WebCareers;
