// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vendor-login_heading__26\\+2I {
  font-size: 18px !important;
  color: #000000 !important;
  font-family: "MetropolisBold" !important;
  font-weight: 600 !important;
  text-align: center !important;
}
.vendor-login_subHeading__KBC1U {
  font-size: 12px !important;
  color: #000000 !important;
  font-family: "MetropolisRegular" !important;
  font-weight: 600 !important;
  text-align: center;
}
.vendor-login_forgotPassword__xeMsT {
  font-size: 12px !important;
  color: #fa8842 !important;
  font-family: "MetropolisRegular" !important;
  font-weight: 600 !important;
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/app/screens/Vendors/Login/vendor-login.module.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;EAC1B,yBAAyB;EACzB,wCAAwC;EACxC,2BAA2B;EAC3B,6BAA6B;AAC/B;AACA;EACE,0BAA0B;EAC1B,yBAAyB;EACzB,2CAA2C;EAC3C,2BAA2B;EAC3B,kBAAkB;AACpB;AACA;EACE,0BAA0B;EAC1B,yBAAyB;EACzB,2CAA2C;EAC3C,2BAA2B;EAC3B,kBAAkB;AACpB","sourcesContent":[".heading {\n  font-size: 18px !important;\n  color: #000000 !important;\n  font-family: \"MetropolisBold\" !important;\n  font-weight: 600 !important;\n  text-align: center !important;\n}\n.subHeading {\n  font-size: 12px !important;\n  color: #000000 !important;\n  font-family: \"MetropolisRegular\" !important;\n  font-weight: 600 !important;\n  text-align: center;\n}\n.forgotPassword {\n  font-size: 12px !important;\n  color: #fa8842 !important;\n  font-family: \"MetropolisRegular\" !important;\n  font-weight: 600 !important;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"heading": `vendor-login_heading__26+2I`,
	"subHeading": `vendor-login_subHeading__KBC1U`,
	"forgotPassword": `vendor-login_forgotPassword__xeMsT`
};
export default ___CSS_LOADER_EXPORT___;
