import React from "react";
import { Grid, Box, Typography } from "@mui/material";
import Colors from "../../constants/Colors";
import BgBaground from "../../../images/bg-banner.png";
import Footer from "../../components/Footer";
import "./terms.css";
import MetaTags from "../../components/ReactMetaTags/meta-tags";

const TermsOfServices = () => {
  return (
    <>
      <MetaTags
        title={"Thrillh - Terms and Services"}
        metaTitle={"Thrillh - Terms and Services"}
        description={
          "Thrillh's commitment to transparency, security, and rewards empowers users to save with confidence and reap the benefits of a truly rewarding savings platform."
        }
      />
      <Box position={"relative"}>
        <Box
          style={{
            backgroundImage: `url(${BgBaground})`,
            backgroundSize: "cover",
          }}
          padding={"40px 20px 60px 20px"}
        >
          <Box>
            <Typography
              color={"#fff"}
              fontSize={"40px"}
              textAlign={"center"}
              p={5}
              variant="h1"
            >
              Terms of Services
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box display={"flex"} justifyContent={"center"}>
        <Grid container className="container">
          <Box>
            <Typography
              pt={"30px"}
              className="boldText"
              textAlign={"justify"}
              style={styles.para}
              variant="h6"
            >
              Last updated on: May 10, 2023
            </Typography>
            <Typography className="title" variant="h1">
              ACCEPTANCE OF TERMS AND CONDITIONS
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              General: By using www.Thrillh.com (the “Website” or “Thrillh.com”
              or “Thrillh” in this document) or Thrillh mobile application (the
              “mobile application” ), you, the Customer (“you”, “you're”, or the
              “User” in this document) unconditionally agree to the terms and
              conditions that we, Siveals digital private limited with our
              Registered Office at 4th Floor, CoWork Valley, Madhapur, Hitec
              City,500033 and having CIN: U72900TG2021PTC155286 (“Thrillh.com”,
              “Thrillh”, “we” or “us” or “our” in this document) have provided
              herein for use of Thrillh’s Website or mobile application owned
              and maintained by us. This Website or mobile application and any
              individual websites or merchant-specific, city-specific, or
              state-specific sites now or hereinafter contained within or
              otherwise available through external hyperlinks ( “Minisites” )
              with our Website or mobile application are private property. All
              interactions on this Website or mobile application must comply
              with these Terms of Use. If you do not wish to agree to the
              outlined terms and conditions (the “Terms of Use” in this
              document) please refrain from using this Website or mobile
              application. The term Website shall include any or all Minisites,
              Algorithms, Logs, and Data wherever mentioned.
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              For these Terms of Use, wherever the context so requires "You" or
              "User" shall mean any natural or legal person who has agreed to
              become a buyer on the Website or mobile application by providing
              Registration Data while registering on the Website or mobile
              application as Registered User using the computer systems or
              mobile device. Thrillh allows the User to surf the Website or make
              purchases without registering on the Website. The term "We", "Us",
              and "Our" shall mean Siveals digital private limited or Thrillh.
              The terms Thrillh and Thrillh.com have been used interchangeably
              in the document.
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              CAUTION: PLEASE READ THIS AGREEMENT CAREFULLY. BY BROWSING,
              ACCESSING, OR USING THIS WEBSITE OR MOBILE APPLICATION OR BY USING
              ANY FACILITIES OR SERVICES MADE AVAILABLE THROUGH OR ON IT, YOU
              ARE AGREEING TO THE TERMS OF USE THAT APPEAR BELOW (ALL OF WHICH
              ARE CALLED THE “AGREEMENT”). THIS AGREEMENT IS MADE BETWEEN YOU
              AND US. USE OF THE SERVICES AND THE WEBSITE OR MOBILE APPLICATION
              AND ANY PURCHASE MADE THERE TO ARE EACH SUBJECT TO THE TERMS OF
              USE SET OUT IN THIS AGREEMENT.
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              Accessing Our Site Access to our Site is permitted temporarily,
              and we reserve the right to withdraw or amend the service we
              provide on our Site without notice (see below). We will not be
              liable if for any reason our Site is unavailable at any time or
              for any period.
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              From time to time, we may restrict access to some parts of our
              Site, or our entire site, to users who have registered with us.
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              If you choose, or you are provided with, a user identification
              code, password, or any other piece of information as part of our
              security procedures, you must treat such information as
              confidential, and you must not disclose it to any third party. We
              have the right to disable any user identification code or
              password, whether chosen by you or allocated by us, at any time,
              if in our opinion you have failed to comply with any of the
              provisions of these Terms.
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              You are responsible for making all arrangements necessary for you
              to have access to our Site. You are also responsible for ensuring
              that all persons who access our Site through your Internet
              connection are aware of these terms and that they comply with
              them.
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              By obtaining a promotional code or accessing an offer or discount
              through our Site, you acknowledge that such offer or discount is
              subject to third-party terms and conditions and it is your
              responsibility to review such terms and conditions before entering
              into any transaction with that third party.
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              The Thrillh service is not intended for individuals under the age
              of 16 years old. If you are under 16 years of age, you are not
              permitted to use the Thrillh service. By using the service, you
              confirm that you are at least 16 years of age. If you are 16 to 17
              years of age, you may only sign up for the Thrillh service with
              the consent of a parent or legal guardian. By using the service
              you confirm that you have such consent and that you shall abide by
              and comply with these Terms. If you are 18 years of age or over
              you confirm that you are fully able and competent to enter into
              the terms, conditions, obligations, confirmations,
              representations, and warranties outlined in these Terms, and to
              abide by and comply with these Terms.
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              Amendments and Changes: We at all times reserve the right to amend
              these terms and conditions at any time. All amendments and changes
              to these terms and conditions will be available online. Such
              changes may include, among other things, the addition of certain
              fees or charges. We suggest, therefore, that you re-read this
              important notice containing our Terms of Use and Privacy Policy
              from time to time so that you remain informed as to any such
              changes. If we make changes to our Terms of Use and Privacy Policy
              and you continue to use our Website or mobile application, you are
              deemed to have implicitly agreed to the amended Terms of Use and
              Privacy Policy. Unless specified otherwise, any such deletions or
              modifications shall be effective immediately upon Thrillh’s
              posting thereof. Continued use of any of the services provided by
              Thrillh via the Website or mobile application (or via other
              electronic or other communication from Thrillh) including the
              information services, content, and transaction capabilities on the
              Website or mobile application, including the ability to make a
              purchase (all of which are called the “Services” in this document)
              will be deemed to constitute acceptance of the new terms and
              conditions. Thrillh may immediately terminate these Terms or any
              Services concerning you, or generally cease offering or deny
              access to the Services or any portion thereof, at any time for any
              reason.
            </Typography>
            <Typography className="title" variant="h1">
              GENERAL
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              User Accounts: To use most aspects of the Services, you must
              register for and maintain an active personal user Services account
              (“Account”). You must be at least 18 years of age, or the age of
              legal majority in your jurisdiction (if different than 18), to
              obtain an Account. Account registration requires you to submit to
              Thrillh certain personal information, such as your name, address,
              and mobile phone number, as well as at least one valid payment
              method (either a credit card/ debit card or any other acceptable
              payment partner) at the time of making payment. You agree to
              maintain bona fide accurate, complete, and up-to-date information
              in your Account. Your failure to maintain accurate, complete, and
              up-to-date Account information, including having an invalid or
              expired payment method on file, may result in your inability to
              access and use the Services or Thrillh 's termination of these
              Terms with you. However, you hereby acknowledge that you shall
              have to use the Account only under the supervision of your parents
              in case you are aged below 18 years. Thrillh shall in no manner be
              responsible for any liabilities in case it is used by you
              otherwise than mentioned under these terms. You are responsible
              for all or any activities that occur under your Account, and you
              agree to maintain the security and secrecy of your Account
              username and password at all times. Unless otherwise permitted by
              Thrillh in writing, you may only possess one Account. You shall
              not use the Thrillh Website or Mobile Application for any illegal
              or fraudulent purposes barred by law or in general including the
              creation of multiple accounts. Any such illegal or fraudulent use
              may render you liable under civil and/ or criminal activity along
              with quasi-judicial inquiry, if any, including but not limited to
              equitable remedies, injunctions, etc., and all other remedies
              available at the recourse of Thrillh Website or Mobile
              Application. Thrillh also reserves the right to reverse
              illegitimate and/ or fraudulent purchases by you at any time even
              after the purchase of Offers/ Deals by you in addition to the
              right to further disable your use or cancel your account.
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              Territory: The Website or mobile application and the Services and
              any purchase are directed solely at those who access the Website
              from the Republic of India. Thrillh makes no representation that
              Service (or any goods or services) are available or otherwise
              suitable for use outside the Republic of India. If you choose to
              access the Website or mobile application (or use the Service or
              make a purchase) from locations outside the Republic of India, you
              do so on your initiative and are responsible for the consequences
              thereof.
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              Territory: The Website or mobile application and the Services and
              any purchase are directed solely at those who access the Website
              from the Republic of India. Thrillh makes no representation that
              Service (or any goods or services) are available or otherwise
              suitable for use outside the Republic of India. If you choose to
              access the Website or mobile application (or use the Service or
              make a purchase) from locations outside the Republic of India, you
              do so on your initiative and are responsible for the consequences
              thereof.
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              Website and/or Mobile Application: Thrillh provides an interactive
              online service owned and operated by Thrillh through the Website
              on the World Wide Web of the Internet (the “Web” or “Internet” )
              and its mobile application, consisting of information services,
              content and, transaction capabilities provided by Thrillh, its
              Holding Company, and its associates, if any, with whom it has
              business relationships ( “Business Associates” ) including but not
              limited to third parties that provide services to creation,
              production or distribution of content for the Website or mobile
              application ( “Third Party Content Providers” ), third parties
              that provide advertising services to Thrillh ( “Third Party
              Advertisers” ) and third parties that perform function on behalf
              of Thrillh like sending out and distributing our administrative
              and promotional emails and text messages ( “Third Party Service
              Providers” ).
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              Right to Transfer: The right to use this Website or mobile
              application is personal to the User and is not transferable to any
              other person or entity. The User shall be responsible for
              protecting the confidentiality of the User’s password(s), if any.
              The User understands and acknowledges that, although the Internet
              is often a secure environment, sometimes there are interruptions
              in service or events that are beyond the control of Thrillh, and
              Thrillh shall not be held responsible for any data lost or misused
              by a third party while transmitting information on the Internet in
              any manner whatsoever.
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              Not on Uninterrupted Basis: While it is Thrillh’s objective to
              make the Website or mobile application accessible at all times,
              the Website or mobile application may be unavailable from time to
              time for any reason including, without limitation, routine
              maintenance. You understand and acknowledge that due to
              circumstances both within and outside of the control of Thrillh,
              access to the Website or mobile application may be interrupted,
              suspended, or terminated from time to time. Thrillh reserves the
              right, in its sole discretion, to terminate the access to any or
              all Thrillh website(s) and the related services or any portion
              thereof at any time, without notice. Thrillh shall also have the
              right at any time to change or discontinue any aspect or feature
              of the Website or mobile application, including, but not limited
              to, content, graphics, Offers, settings, hours of availability,
              and equipment needed for access or use. Further, Thrillh may
              discontinue disseminating any portion of information or category
              of information, may change or eliminate any transmission method,
              and may change transmission speeds or other signal
              characteristics.
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              User’s Responsibility: The Services and use of the website or
              mobile application do not include the provision of a computer or
              other necessary equipment to access the Site or the Services. To
              use the Site or Services, you will require Internet connectivity
              and appropriate telecommunication links. The User shall be
              responsible for obtaining and maintaining all telephone, computer
              hardware, software, and other equipment needed for access to and
              use of this Website or mobile application and all charges related
              thereto. Thrillh shall not be liable for any damages to the User’s
              equipment resulting from the use of this Website or mobile
              application.
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              Restrictions: The User undertakes without limitation, not to use
              or permit anyone else to use the Service Website or mobile
              application:
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              1. To upload, send or receive any information for which you have
              not obtained all necessary licenses and/ or approvals (from us or
              third parties); or which constitutes or encourages conduct that
              would be considered a criminal offense, gives rise to civil
              liability or otherwise be contrary to the law of or infringes the
              rights of any third party in any country in the world;
              <Typography pt={2} textAlign={"justify"} style={styles.para}>
                2. To upload, send or receive any material which is technically
                harmful (including computer viruses, logic bombs, Trojan horses,
                worms, harmful components, corrupted data, malicious software,
                harmful data, or anything else which may interrupt, interfere
                with, corrupt or otherwise cause loss, damage, destruction or
                limitation to the functionality of any software or computer
                equipment);
              </Typography>
              <Typography pt={2} textAlign={"justify"} style={styles.para}>
                3. To intercept or attempt to intercept any communications
                transmitted by way of a telecommunication system; For a purpose
                other than for which we have designed them or intended them to
                be used; For any fraudulent purposes; In any way which is
                calculated to incite hatred against any ethnic, religious, or
                any other minority or is otherwise calculated to adversely
                affect any individual, group, or entity.
              </Typography>
              <Typography pt={2} textAlign={"justify"} style={styles.para}>
                4. To upload, send or receive any material, which is not civil
                or tasteful.
              </Typography>
              <Typography pt={2} textAlign={"justify"} style={styles.para}>
                5. To upload, send or receive any material, including User
                Content, which is unlawful, harmful, tortious, threatening,
                abusive, harassing, hateful, racist, homophobic, infringing,
                pornographic, violent, misleading, grossly offensive, of an
                indecent, obscene or menacing character, blasphemous or
                defamatory or of a libelous nature of any person or entity, in
                contempt of Court or breach of confidence, or which infringes
                the rights of another person or entity, including copyrights,
                trademarks, trade secrets, patents, rights of personality,
                publicity or privacy or any other third party rights;
              </Typography>
              <Typography pt={2} textAlign={"justify"} style={styles.para}>
                6. cause annoyance, inconvenience, or needless anxiety.
              </Typography>
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              Forbidden Uses: The following uses of the Site and Services are
              expressly prohibited and you undertake not to do (or to permit
              anyone else to do) any of the following:
            </Typography>
            <Typography>
              <Typography pt={2} textAlign={"justify"} style={styles.para}>
                1. Resell the Services or Site;
              </Typography>
              <Typography pt={2} textAlign={"justify"} style={styles.para}>
                2. Furnish false data including false names, addresses, and
                contact details and fraudulent use of credit/ debit card numbers
                or net banking or any other mode of payment;
              </Typography>
              <Typography pt={2} textAlign={"justify"} style={styles.para}>
                3. Attempt to circumvent our security or network including but
                not limited to accessing data not intended for you, logging into
                a server or Account you are not expressly authorized to access,
                or probing the security of other networks (such as running a
                port scan);
              </Typography>
              <Typography pt={2} textAlign={"justify"} style={styles.para}>
                4. Access the Services (or Site) in such a way as to, or commit
                any act that would or does, impose an unreasonable or
                disproportionately large burden on our infrastructure or that
                otherwise interrupts or interferes with its functionality,
                efficiency or operation;
              </Typography>
              <Typography pt={2} textAlign={"justify"} style={styles.para}>
                5. Execute any form of network monitoring that will intercept
                data not intended for you;
              </Typography>
              <Typography pt={2} textAlign={"justify"} style={styles.para}>
                6. Send unsolicited mail messages, including the sending of
                "junk mail" or other advertising material to individuals who did
                not specifically request such material. You are explicitly
                prohibited from sending unsolicited bulk mail messages. This
                includes bulk mailing of commercial advertising, promotional, or
                informational announcements, and political or religious tracts.
                Such material may only be sent to those who have explicitly
                requested it. If a recipient asks to stop receiving emails of
                this nature, you may not send that person any further emails;
              </Typography>
              <Typography pt={2} textAlign={"justify"} style={styles.para}>
                7. Create or forward "chain letters" or other "pyramid schemes"
                of any type, whether or not the recipient wishes to receive such
                mailings;
              </Typography>
              <Typography pt={2} textAlign={"justify"} style={styles.para}>
                8. Send malicious emails, including flooding a user or site with
                very large or numerous emails;
              </Typography>
              <Typography pt={2} textAlign={"justify"} style={styles.para}>
                9. Enter into fraudulent interactions or transactions with us, a
                Seller, or a Merchant (which shall include entering into
                interactions or transactions purportedly on behalf of a third
                party where you have no authority to bind that third party or
                you are pretending to be a third party);
              </Typography>
              <Typography pt={2} textAlign={"justify"} style={styles.para}>
                10. Use the Services or Site (or any relevant functionality of
                either of them) in breach of this Agreement;
              </Typography>
              <Typography pt={2} textAlign={"justify"} style={styles.para}>
                11. Use in an unauthorized manner, or forge, mail header
                information
              </Typography>
              <Typography pt={2} textAlign={"justify"} style={styles.para}>
                12. Engage in any unlawful or criminal activity in connection
                with the use of the Services and/ or the Site or any Voucher
                coupon Code;
              </Typography>
              <Typography pt={2} textAlign={"justify"} style={styles.para}>
                13. Copy or use any User Content for any commercial purposes;
              </Typography>
              <Typography pt={2} textAlign={"justify"} style={styles.para}>
                14. Engage in any frivolous act that hampers the goodwill and
                brand reputation of Thrillh or its Website or mobile application
                in any manner whatsoever.
              </Typography>
              <Typography pt={2} textAlign={"justify"} style={styles.para}>
                Any conduct by a User that in Thrillh’s exclusive discretion is
                in breach of the Terms of Use or which restricts or inhibits any
                other User from using or enjoying this Website or mobile
                application is strictly prohibited. The User shall not use this
                Website or mobile application to advertise or perform any
                commercial, religious, political, or non-commercial
                solicitation, including, but not limited to, the solicitation of
                users of this Website or mobile application to become users of
                other online or offline services directly or indirectly
                competitive or potentially competitive with Thrillh.
              </Typography>
              <Typography pt={2} textAlign={"justify"} style={styles.para}>
                Thrillh reserves the right to prevent you from using the Website
                (or mobile application) and the Service (or any part of thereof)
                and to prevent you from making any purchase if your conduct is
                found to be in question or in contravention of such Terms as
                mentioned in this Agreement.
              </Typography>
            </Typography>
            <Typography className="title" variant="h1">
              PURCHASE AND SALE OF PRODUCTS AND/ OR SERVICES
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              Thrillh takes no responsibility for the services and/ or products
              for which Thrillh Voucher coupon Codes may be redeemed. Further,
              Thrillh makes no warranty to the Users for the quality, safety,
              usability, or another aspect of the product or service for which
              the Thrillh Voucher Coupon Code may be redeemed.
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              Thrillh disclaims any warranty or liability with the Services/
              Products for which the Offers/ Voucher coupon Codes are purchased/
              redeemed, for instance, quality, merchantability, imperishable in
              nature, fit for human consumption, etc.
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              Some services for which Thrillh Voucher Coupon Code(s) can be
              redeemed are activities that involve potential bodily harm (such
              as different forms of adventure sports and other associated
              activities), and for those activities, Thrillh takes no
              responsibility in any manner whatsoever for the service or
              activity being displayed, and the User takes responsibility for
              his or her acts in utilizing the services for which Thrillh
              Voucher Coupon Code may be redeemed.
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              For more information see Thrillh’s Terms of Sale.
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              The user shall solely be responsible for any product purchased and
              Thrillh is merely a platform where User can use to avail benefits
              and purchase products from different brands that are solely
              beneficial for the students.
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              However, Thrillh provides a guarantee that the seller of a product
              through Thrillh is genuine and the User has all the rights to
              claim warranties, repairs, and claims on the products’ Companies
              but not Thrillh.
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              User hereby indemnifies Thrillh from all the claims by any party
              regarding the User purchases or the products listed under Thrillh.
            </Typography>
            <Typography className="title" variant="h1">
              SECURITY
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              The User agrees and understands that all payments shall only be
              made to the bank accounts of Thrillh. Thrillh or its agents,
              representatives, or employees shall never ask a customer to
              transfer money to any private account or to an account not held in
              the name of Thrillh. The User agrees that if that User transfers
              any amount against any booking or transaction to any bank account
              that is not legitimately held by Thrillh or to any personal
              account of any person, Thrillh shall not be held liable for the
              same. User shall not hold any right to recover from Thrillh any
              amount that is transferred by the User to any third party.
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              The User will not share his personal sensitive information like
              credit/ debit card number, CVV, OTP, card expiry date, user IDs,
              passwords, etc., with any person including the agents, employees,
              or representatives of Thrillh. The User shall immediately inform
              Thrillh if such details are demanded by any of its agents'
              employees or representatives. Thrillh shall not be liable for any
              loss that the User incurs for sharing the aforesaid details.
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              Thrillh or its Affiliates or any of its representatives will never
              ask for sensitive information like User ID, PIN, internet banking
              passwords, CVV Numbers, OTP, etc., through phone/ SMS/ email/ any
              other modes of communication. We request you not share or respond/
              provide any such details to anyone.
            </Typography>
            <Typography className="title" variant="h1">
              CONVENIENCE FEE
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              In addition to the cost of booking an offer/ deal available on the
              Thrillh Platform, we reserve the right to charge certain fees like
              convenience fees or service fees. We further reserve the right to
              alter any fees from time to time. Any such additional fees,
              including fees towards any modifications thereof, will be
              displayed to the User before confirming the booking or collecting
              the payment from such User through a Payment Gateway.
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              In cases of short charging of the booking amount, taxes, statutory
              fee, convenience fee, etc., owing to any technical error or other
              reason, we shall reserve the right to deduct, charge or claim the
              balance amount from the User and the User shall pay such balance
              amount to us. In cases where the short charge is claimed before
              the utilization of the booking, we will be at liberty to cancel
              such bookings if the amount is not paid before the utilization
              date.
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              Any increase in the price charged by us on account of a change in
              the rate of taxes or imposition of new taxes, levied by the
              Government shall have to be borne by the User. Such imposition of
              taxes, levies, etc., may be without prior notice and could also be
              retrospective but will always be as per applicable law.
            </Typography>
            <Typography className="title" variant="h1">
              REFUNDS
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              In the rare circumstance of a booking not getting confirmed for an
              Offer/ Deal for any reason whatsoever, we will process the refund
              of the booking amount paid by the User and intimate the User about
              the same. We are not under any obligation to provide an alternate
              booking instead of or to compensate or replace the unconfirmed
              booking. All subsequent bookings will be treated as new
              transactions. Any applicable refund will be processed as per the
              defined policies of Thrillh.
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              The User shall be completely responsible for all charges, fees,
              duties, taxes, and assessments arising out of the use of the
              service, as per the applicable laws.
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              It is clarified that in cases of cancellation of the event by the
              Host/ Merchant or any third party, Thrillh shall not be held
              liable or responsible in any manner whatsoever for any losses,
              claims, liabilities, damages, etc., to anyone.
            </Typography>
            <Typography className="title" variant="h1">
              CANCELLATION POLICY AND CANCELLATION FEES
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              Except with the prior registration with Thrillh as B2B agents,
              priority partners, or a merchant, and explicit permission of
              Thrillh to use the Website, all merchants/ agents or aggregators
              are barred from using this Website for any commercial or resale
              purpose. If any such bookings are detected, Thrillh reserves the
              right, including without limitation, to cancel all such bookings
              immediately without any notice to such merchants/ agents and also
              to withhold payments or any refunds thereto. Thrillh shall not be
              held liable for any incidental loss or damage that may arise from
              the bookings made by any person through such merchants. The
              liability in case of such cancellations shall be solely borne by
              such merchants.
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              All discounts and offers mentioned on the Website or the mobile
              application apply only to the User(s) of Thrillh for legitimate
              bookings for the respective offers/ deals.
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              Cancellation Fees shall mean and refer to the fees that shall be
              charged by Thrillh from the User on a percentage basis to be
              calculated at the sole discretion of Thrillh on the total MRP
              value excluding taxes which shall be applicable only in cases the
              User cancels the transaction/ Voucher Coupon Code for the services
              purchased from the Merchant on the Thrillh Platform which shall be
              deducted invariably from the User’s NB Wallet/ User’s NB Credits,
              if any, or the amount paid by the User for the transaction, as the
              case may be, and the same shall be solely retained by Thrillh.
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              Refunds, if any, on canceled bookings will always be processed to
              the respective account or the banking instrument (credit card,
              debit card, wallet, etc.) from which payment was made for that
              booking.
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              Booking(s) made by the User through Thrillh are subject to the
              applicable cancellation policy given in the Terms of Use
              hereinbelow.
            </Typography>
            <Typography className="title" variant="h1">
              PROMOTIONAL CODES
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              Thrillh may, in its sole discretion, create promotional codes that
              may be redeemed for Account credit, or other features or benefits
              related to the Services and/ or a Third Party Provider’s services,
              subject to any additional terms that Thrillh establishes on a per
              promotional code basis (“Promo Codes”). You agree that Promo
              Codes: (i) must be used for the intended audience and purpose, and
              in a lawful manner; (ii) may not be duplicated, sold, or
              transferred in any manner, or made available to the general public
              (whether made available to a public form or otherwise), unless
              expressly permitted by Thrillh; (iii) may be disabled by Thrillh
              at any time for any reason without liability to Thrillh; (iv) may
              only be used under the specific terms that Thrillh establishes for
              such Promo Code; (v) are not valid for cash; (vi) may expire
              before your use; and (vii) unless otherwise specified cannot be
              used more than once. Thrillh reserves the right to withhold or
              deduct credits or other features or benefits obtained through the
              use of Promo Codes by you or any other user if Thrillh determines
              or believes that the use or redemption of the Promo Code was in
              error, fraudulent, illegal, or in violation of the applicable
              Promo Code terms or these Terms.
            </Typography>
            <Typography className="title" variant="h1">
              INTELLECTUAL PROPERTY RIGHTS
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              Thrillh grants you a limited, personal, non-transferable,
              non-exclusive, revocable license to access and use the Site under
              this Agreement and any additional terms and policies set forth by
              Thrillh. All Intellectual Property Rights (including all
              copyright, patents, trademarks, service marks, trade names, and
              designs (including the "look and feel" and other visual or
              non-literal elements) whether registered or unregistered) in the
              Website or mobile application and Service, (subject to the Title &
              Ownership Rights as mentioned below) information contained on the
              Website or mobile application or accessed as part of the Service,
              any database operated by us, and all the Website design, text and
              graphics, software, photos, videos, music, sound, and their
              selection and arrangement, and all software compilations,
              underlying source code and software (including applets and
              scripts) shall remain our property (or that of our licensors). You
              shall not, and shall not attempt to, obtain any title to any such
              intellectual property. All rights are reserved.
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              None of the material listed in this clause may be reproduced or
              redistributed or copied, distributed, republished, downloaded,
              displayed, posted, or transmitted in any form or by any means,
              sold, rented or sub-licensed, used to create derivative works, or
              in any way exploited without our prior express written permission.
              You may, however, retrieve and display the content of the Website
              on a computer screen, store such content in electronic form on
              disk (but not on any server or other storage device connected to a
              network), or print/save one copy of such content for your own
              personal, non-commercial use, provided you keep intact all and any
              copyright and proprietary notices. You may not otherwise
              reproduce, modify, copy or distribute or use for commercial
              purposes any of the materials or content on the Website or mobile
              application without our permission.
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              None of the material listed in this clause may be reproduced or
              redistributed or copied, distributed, republished, downloaded,
              displayed, posted, or transmitted in any form or by any means,
              sold, rented or sub-licensed, used to create derivative works, or
              in any way exploited without our prior express written permission.
              You may, however, retrieve and display the content of the Website
              on a computer screen, store such content in electronic form on
              disk (but not on any server or other storage device connected to a
              network), or print/save one copy of such content for your own
              personal, non-commercial use, provided you keep intact all and any
              copyright and proprietary notices. You may not otherwise
              reproduce, modify, copy or distribute or use for commercial
              purposes any of the materials or content on the Website or mobile
              application without our permission.
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              “Thrillh.com” , the Thrillh Logos and variations thereof found on
              the website or mobile application are trademarks owned by Thrillh
              and all use of these marks inures to the benefit of Thrillh. All
              rights (including goodwill and, where relevant, trademarks) in
              Thrillh’s name are owned by us (or our licensors). Other product
              and company names mentioned on the Website or mobile application
              are the trademarks or registered trademarks of their respective
              owners.
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              The title, Ownership Rights, and Intellectual Property Rights in
              and to the content accessed using the Service are the property of
              the applicable content owner or Merchant and may be protected by
              applicable copyright or other law. The Agreement gives you no
              rights to such content.
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              Subject to the Clause below, any material you transmit or post or
              submit to the Website or mobile application (or otherwise to us)
              shall be considered (and we may treat it as) non-confidential and
              non-proprietary, subject to our obligations under data protection
              legislation or such legislation that provides for the protection
              of electronic data under Indian Laws. If for some reason, any part
              of that statement does not work as a matter of law, then for
              anything which you supply to us from whatever source (i.e. via
              email, the Website, or otherwise) you grant us a royalty-free,
              perpetual, irrevocable, non-exclusive right to use, copy, modify,
              adapt, translate, publish and distribute world-wide any such
              material.
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              All comments, suggestions, ideas, notes, drawings, concepts, or
              other information: (i) disclosed or offered to us by you; or (ii)
              in response to solicitations by us regarding the Service or the
              Website or mobile application; (in each foregoing case, these are
              called "Ideas") shall be deemed to be and shall remain our
              property and you hereby assign by way of present and future
              assignment all intellectual property rights in Ideas, to us. You
              understand and acknowledge that we have both internal resources
              and other external resources that may have developed or may in the
              future develop ideas identical to or similar to Ideas and that we
              are only willing to consider Ideas on these terms. In any event,
              any Ideas are not submitted in confidence and we assume no
              obligation, express or implied by considering them. Without
              limitation, we shall exclusively own all now-known or hereafter
              existing rights to the Ideas of every kind and nature throughout
              the world and shall be entitled to unrestricted use of the Ideas
              for any purpose whatsoever, commercial or otherwise without
              compensation to the provider of the Ideas.
            </Typography>
            <Typography className="title">
              DISCLAIMER OF WARRANTY; LIMITATION OF LIABILITY
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              The User expressly agrees that use of the Website or mobile
              application is at the User’s sole risk. Neither Thrillh, its
              Holding or Subsidiaries, Business Associates nor any of their
              respective employees, agents, and third-party content providers
              warrant that use of the Website or mobile application will be
              uninterrupted or error-free; nor do they make any warranty as to
              (i) the results that may be obtained from the use of this Website
              or mobile application, or (ii) the accuracy, reliability or
              content of any information, service or merchandise provided
              through the Website or mobile application.
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              The Website or mobile application is made accessible on an “as is”
              basis without warranties of any kind, either express or implied,
              including, but not limited to, warranties of title or implied
              warranties of merchantability or fitness for a particular purpose,
              other than those warranties which are implied by and incapable of
              exclusion, restriction or modification under the laws applicable
              to this agreement.
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              We do not give any warranty that the Services or the Website or
              mobile application are free from viruses or anything else which
              may hurt any technology.
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              We reserve the right to change, modify substitute, suspend, or
              remove without notice any information Voucher coupon Code or
              service from the Website or mobile application forming part of the
              Service from time to time. Your access to the Website or mobile
              application and/ or the Services may also be occasionally
              restricted to allow for repairs, maintenance, or the introduction
              of new facilities or services. We will attempt to restore such
              access as soon as we reasonably can. We assume no responsibility
              for the functionality, which is dependent on your browser or other
              third-party software to operate. There might be certain
              circumstances wherein Thrillh may within the validity period of a
              particular Voucher coupon Code withdraw any information or the
              Voucher coupon Code from the Website or Mobile Application or
              services at any time.
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              We reserve the right to block access to and/ or to edit or remove
              any material, which in our reasonable opinion may give rise to a
              breach of this Agreement.
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              This disclaimer of liability applies to any damages or injury
              caused by any failure of performance, error, omission,
              interruption, deletion, defect, delay in operation or
              transmission, computer virus, communication line failure, theft or
              destruction or unauthorized access to, alteration of, or use of
              record, whether for breach of contract, tortuous behavior,
              negligence, or under any other cause of action. The User
              specifically acknowledges that Thrillh is not liable for the
              defamatory, offensive, or illegal conduct of other users or third
              parties and that the risk of injury from the foregoing rests
              entirely with the User.
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              In no event shall Thrillh, or any Business Associates/ Employees,
              Third Party Content Providers, Third Party Advertisers, or Third
              Party Service Providers, producing or distributing the Website or
              the contents hereof, mobile application, and any software, be
              liable for any damages, including, without limitation, direct,
              indirect, incidental, special, consequential or punitive damages
              arising out of the use of or inability to use the Website or
              mobile application. The User hereby acknowledges that the
              provisions of this Clause shall apply to all contents on the
              Website and mobile application.
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              In addition to the terms set forth above, neither Thrillh, nor its
              Subsidiaries and Business Associates, Third Party Service
              Providers, or Third Party Content Providers shall be liable
              regardless of the cause or duration, for any errors, inaccuracies,
              omissions, or other defects in, or untimeliness or inauthenticity
              of, the information contained within the Website, or for any delay
              or interruption in the transmission thereof to the user, or any
              claims or losses arising therefrom or occasioned thereby. None of
              the foregoing parties shall be liable for any third-party claims
              or losses of any nature, including without limitation lost
              profits, punitive or consequential damages.
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              Thrillh will not be held liable for indirect, special, or
              consequential damages (or any loss of revenue, profits, or data)
              arising in connection with this Agreement, even if we have been
              advised of the possibility of such damages. Further, our aggregate
              liability arising concerning this Agreement will not exceed the
              total Offer price paid or payable to you under this Agreement.
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              Thrillh shall be under no liability whatsoever in respect of any
              loss or damage arising directly or indirectly out of the decline
              of authorization for any Transaction, on Account of the Cardholder
              having exceeded the preset limit mutually agreed by us with our
              associated banking partners from time to time.
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              Thrillh’s liability is limited to providing the User with a
              confirmed booking as selected by the User for any product/ service
              provided by the Merchant and further listed on Thrillh.
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              Any issues or concerns faced by the User at the time of availing
              of any such services/ products shall be the sole responsibility of
              the Merchant. Thrillh will have no liability concerning the acts,
              omissions, errors, representations, warranties, breaches, or
              negligence on the part of any Merchant.
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              Unless explicitly committed by Thrillh as a part of any product or
              service:
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              1. Thrillh assumes no liability for the standard of services as
              provided by the respective Merchants.
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              2. Thrillh provides no guarantee about their quality or fitness as
              represented by the Merchants.
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              3. Thrillh doesn't guarantee the availability of any services as
              listed by a Merchant.
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              By making a booking, User understands Thrillh merely provides a
              technology platform for booking services and products and the
              ultimate liability rests on the respective Merchant and not
              Thrillh. Thus the ultimate contract of service is between User and
              Merchant.
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              Our liability
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              The material displayed on our Site is provided without any
              guarantees, conditions, or warranties as to its accuracy. To the
              extent permitted by law, we, and any third parties connected to us
              hereby expressly exclude:
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              All conditions, warranties, and other terms which might otherwise
              be implied by statute, common law, or the law of equity.
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              Any liability for any direct, indirect, or consequential loss or
              damage incurred by any user in connection with our Site or
              connection with the use, inability to use, or results of the use
              of our Site, any websites linked to it, and any materials posted
              on it, including, without limitation any liability for:
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              loss of income or revenue; loss of business; loss of profits or
              contracts; loss of anticipated savings; loss of data; loss of
              goodwill;
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              wasted management or office time; and for any other loss or damage
              of any kind, however arising and whether caused by tort (including
              negligence), breach of contract, or otherwise, even if
              foreseeable.
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              This does not affect our liability for death or personal injury
              arising from our negligence, our liability for fraudulent
              misrepresentation or misrepresentation as to a fundamental matter,
              nor any other liability which cannot be excluded or limited under
              applicable law.
            </Typography>
            <Typography className="title">RESERVATION POLICY</Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              Any transactions relating to the booking of tables or reservations
              of products or services not directly offered by Thrillh are to be
              settled inter-se between the merchant and the User i.e. Parties to
              such transaction, and all warranties express or implied of any
              kind, regarding any matter pertaining thereto, including without
              limitation the implied warranties of merchantability, fitness for
              a particular purpose, and non-infringement are disclaimed by
              Thrillh. Thrillh merely endeavors to provide a platform where you
              and the merchant may interact, exchange information or carry out a
              reservation on such basis that is mutually agreed between you and
              the merchant. Thrillh shall not be liable for any loss that you
              may incur while making reservations or bookings by the User or for
              the third party.
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              Under no circumstances shall Thrillh be held responsible or
              liable, in any way, for any content which in legal opinion is
              derogatory, threatening, defamatory, obscene, or offensive or
              offends public sensibilities or morals and shall also not assume
              liability for any errors or omissions in any content, or any loss
              or damage of any kind incurred as a result of the use of any
              content posted or uploaded on the Website or mobile application,
              or any infringement of another's rights, including intellectual
              property rights. You specifically agree that Thrillh is not
              responsible for any content sent using and/or included in
              Thrillh’s Website or mobile application or service by any third
              party.
            </Typography>
            <Typography className="title">MONITORING</Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              Thrillh shall have the right, but not the obligation, to monitor
              the content of the Website or mobile application at all times,
              including any chat rooms and forums that may hereinafter be
              included as part of the Website or mobile application, to
              determine compliance with this Agreement and any operating rules
              established by Thrillh, as well as to satisfy any applicable law,
              regulation or authorized government request. Without limiting the
              foregoing, Thrillh shall have the right to remove any material
              that Thrillh, in its sole discretion, finds to violate the
              provisions hereof or otherwise objectionable.
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              PRIVACY
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              The User acknowledges that all discussions for ratings, comments,
              bulletin board service, chat rooms, and/ or other message or
              communication facilities (collectively “Communities” ) are public
              and not private communications, and that, therefore, others may
              read the User’s communications without the User’s knowledge.
              Thrillh does not control or endorse the content, messages, or
              information found in any Communities. Therefore, Thrillh
              specifically disclaims any liability concerning the Communities
              and any actions resulting from the User’s participation in any
              Communities, including any objectionable content. Generally, any
              communication that the User posts on the Website (whether in chat
              rooms, discussion groups, message boards, or otherwise) is
              considered to be non-confidential. If particular web pages permit
              the submission of communications that will be treated by Thrillh
              as confidential, that fact will be stated on those pages. By
              posting comments, messages, or other information on the Website or
              mobile application, the User grants Thrillh the right to use such
              comments, messages, or information for promotions, advertising,
              market research, or any other lawful purpose. For more information
              see Thrillh’s Privacy Policy.
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              By posting communications on or through this Website or mobile
              application, the User shall be deemed to have granted Thrillh, a
              royalty-free, perpetual, irrevocable & non-exclusive license to
              use, reproduce, modify, publish, edit, translate, distribute,
              perform, and display the communication alone or as part of other
              works in any form, media, or technology whether now known or
              hereafter developed and to sublicense such rights through multiple
              tiers of sublicenses.
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              The User agrees to defend, indemnify and hold harmless Thrillh,
              its Subsidiaries and Business Associates, and their respective
              Directors, officers, employees, and agents from and against all
              claims and expenses, including attorneys’ fees, arising out of (i)
              the use of this Website and/ or the mobile application by the
              User; (ii) the use of the Voucher coupon Code; (iii) the use of
              the Service or Website or mobile application through your
              password; or (iv) any breach of this Agreement by you.
            </Typography>
            <Typography className="title">THIRD-PARTY CONTENT</Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              Thrillh, similar to an Internet Service Provider, is a distributor
              (and not a publisher) of content supplied by third parties and
              Users. Accordingly, Thrillh does not have editorial control over
              such content. Any opinions, advice, statements, services, offers,
              or other information or content expressed or made available by
              third parties, including Third Party Content Providers, or any
              other Users are those of the respective author(s) or distributors
              and not of Thrillh.
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              Neither Thrillh nor any third-party provider of information
              guarantees the accuracy, completeness, or usefulness of any
              content, nor its merchantability or fitness for any particular
              purpose (refer to the Clause for ‘Disclaimer of Warranty,
              Limitation of Liability’ above for the complete provisions
              governing limitation of liabilities and disclaimers of warranty).
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              In many instances, the content available through this Website or
              mobile application represents the opinions and judgments of the
              respective information provider, User, or other users not under
              contract with Thrillh. Thrillh neither endorses nor is responsible
              for the accuracy or reliability of any opinion, advice, or
              statement made on the Website by anyone other than authorized
              Thrillh employees/ spokespersons while acting in official
              capacities.
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              Under no circumstances will Thrillh be liable for any loss or
              damage caused by User’s reliance on information obtained through
              the Website. It is the responsibility of the User to evaluate the
              accuracy, completeness, or usefulness of any information, opinion,
              advice, etc., or other content available through the Website.
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              The Website contains links to third-party websites maintained by
              other content providers. These links are provided solely as a
              convenience to you and not as an endorsement by Thrillh of the
              contents on such third-party websites and Thrillh hereby expressly
              disclaims any representations regarding the content or accuracy of
              materials on such third-party websites. If the User decides to
              access linked third-party websites, the User does so at his/ her
              own risk.
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              Unless you have executed a written agreement with Thrillh
              expressly permitting you to do so, you may not provide a hyperlink
              to the Website from any other website. Thrillh reserves the right
              to revoke its consent to any link at any time in its sole
              discretion.
            </Typography>
            <Typography className="title">ADVERTISEMENT</Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              Thrillh may place advertisements in different locations on the
              Website and its Mobile Application and at different points during
              the use of the Service. These locations and points may change from
              time to time at the sole discretion of Thrillh.
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              You are free to select or click on advertised goods and services
              or not as you see fit.
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              Any advertisements may be delivered on our behalf by a Third Party
              Advertiser.
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              No personal data (for example your name, address, email address,
              or telephone number) will be used during serving our advertising,
              but, on our behalf, our Third Party Advertiser or affiliate may
              place or recognize a unique "cookie" on your browser (see our
              Privacy Policy about this). This cookie will not collect personal
              data about you nor is it linked to any personal data about you. If
              you would like more information about this practice and to know
              your choices about not having this information used by any
              Company, see our Privacy Policy for more information.
            </Typography>
            <Typography className="title">FORCE MAJEURE</Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              Without prejudice to any other provision herein, Thrillh shall not
              be liable for any loss, damage, or penalty as a result of any
              delay in or failure to deliver or otherwise perform hereunder due
              to any cause beyond Thrillh’s control, including, without
              limitation, acts of the User, embargo or other Governmental act,
              regulation or request affecting the conduct of Thrillh’s business,
              fire, explosion, accident, theft, vandalism, riot, acts of war,
              strikes or other labor difficulties, lightning, flood, windstorm
              or other acts of God.
            </Typography>
            <Typography style={styles.para}>MISCELLANEOUS</Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              This Agreement, our Terms of Sale, and our Privacy Policy
              (including our Cookie Policy) constitute the entire agreement
              between the parties concerning the subject matter hereof. No
              representation, undertaking, or promise shall be taken to have
              been given or be implied from anything said or written in
              negotiations between the parties before this Agreement except as
              expressly stated in this Agreement. Neither party shall have any
              remedy in respect of any untrue statement made by the other upon
              which that party relied when entering into this Agreement (unless
              such untrue statement was made fraudulently or was as to a matter
              fundamental to a party’s ability to perform this Agreement) and
              that party’s only remedies shall be for breach of contract as
              provided in this Agreement. However, the Service is provided to
              you under our operating rules, policies, and procedures as
              published from time to time on the Website.
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              No waiver by either party of any breach or default hereunder is a
              waiver of any preceding or subsequent breach or default.
            </Typography>
            <Typography style={styles.para}>
              The section headings used herein are for convenience only and
              shall be of no legal force or effect.
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              If any provision of this Agreement is held invalid by a Court of
              competent jurisdiction, such invalidity shall not affect the
              enforceability of any other provisions contained in this
              Agreement, and the remaining portions of this Agreement shall
              continue in full force and effect. The failure of either party to
              exercise any of its rights under this Agreement shall not be
              deemed a waiver or forfeiture of such rights or any other rights
              provided hereunder.
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              All provisions of this Agreement apply equally to and are for the
              benefit of Thrillh, its Subsidiaries, any Holding Companies of
              Thrillh, its (for their) affiliated and/ or their Third Party
              Content Providers and Licensors and each shall have the right to
              assert and enforce such provisions directly or on its behalf (save
              that this Agreement may be varied or rescinded without the consent
              of those parties).
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              The registered office of Siveals digital private limited is
              situated in Delhi, India. Legal issues arising out of, but not
              exclusive to the use of, this Website or mobile application
              (unless otherwise specifically stated) are governed by and
              following the laws of India (exclusive of its rules regarding
              conflicts of laws) and the parties shall submit to the exclusive
              jurisdiction of the Courts in Delhi.
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              Any Disputes arising out of this Agreement or policy shall be
              settled mutually by way of Arbitration under the Indian
              Arbitration and Conciliation Act and the place of Arbitration
              shall be in Delhi and the proceedings shall be conducted in
              English or Hindi.
            </Typography>
            <Typography className="title">
              Reliance on information posted
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              Commentaries, advertisements, and other materials posted on our
              Site are not intended to amount to advice on which reliance should
              be placed. We, therefore, disclaim all liability and
              responsibility arising from any reliance placed on such materials
              by any visitor to
            </Typography>
            <Typography className="title">
              Our Site changes regularly
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              We aim to update our Site regularly and may change the content at
              any time. If the need arises, we may suspend access to our Site,
              or close it indefinitely. Any of the material on our Site may be
              out of date at any given time, and we are under no obligation to
              update such material
            </Typography>
            <Typography className="title">
              Viruses, hacking, and other offenses
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              You must not misuse our Site by knowingly introducing viruses,
              trojans, worms, logic bombs, or other material which is malicious
              or technologically harmful. You must not attempt to gain
              unauthorized access to our Site, the server on which our Site is
              stored or any server, computer, or database connected to our Site.
              You must not attack our Site via a denial-of-service attack or a
              distributed denial-of-service attack.
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              By breaching this provision, you would commit a criminal offense
              under the Information Technology Act, 2000, as amended. We will
              report any such breach to the relevant law enforcement authorities
              and we will co-operate with those authorities by disclosing your
              identity to them. In the event of such a breach, your right to use
              our Site will cease immediately.
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              We will not be liable for any loss or damage caused by a
              distributed denial-of-service attack, viruses, or other
              technologically harmful material that may infect your computer
              equipment, computer programs, data, or other proprietary material
              due to your use of our Site or to your downloading of any material
              posted on it, or any website linked to it.
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              Linking to our Site
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              You may link to our home page, provided you do so in a way that is
              fair and legal and does not damage our reputation or take
              advantage of it, but you must not establish a link in such a way
              as to suggest any form of association, approval or endorsement on
              our part where none exists.
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              You must not establish a link from any website that is not owned
              by you.
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              Your Site must not be framed on any other site, nor may you create
              a link to any part of our Site other than the home page. We
              reserve the right to withdraw linking permission without notice.
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              If you wish to make any use of material on our Site other than
              that set out above, please address your request to
              contactus@Thrillh.com
            </Typography>
            <Typography className="title">Links from our Site</Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              Where our Site contains links to other sites and resources
              provided by third parties, these links are provided for your
              information only. We have no control over the contents of those
              sites or resources and accept no responsibility for them or for
              any loss or damage that may arise from your use of them
            </Typography>
            <Typography className="title">Your concerns</Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              If you have any concerns about material that appears on our Site,
              please contact contactus@Thrillh.com
            </Typography>
            <Typography className="title">
              PRODUCTS/ SERVICES TAKEDOWN POLICY: REPORTING VIOLATION OF
              INFRINGEMENT
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              We want to list the products and services of genuine sellers on
              our website or mobile application to ensure that infringing
              products are removed from the site, as they affect your and the
              genuine Seller’s valuable trust.
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              We do not and cannot verify if the Sellers have the right or
              ability to sell or distribute their listed products or services.
              However, we are committed to removing any infringing or unlicensed
              product or service once an authorized representative of the rights
              owner properly reports them to us. We sincerely want to ensure
              that item listings do not infringe upon the copyright, trademark,
              or other intellectual property rights of third parties. We can
              identify and request the removal of allegedly infringing products
              and materials. Any person or company who holds intellectual
              property rights (such as a copyright, trademark, or patent) that
              may be infringed upon by products listed on Thrillh is encouraged
              to report the same to our Legal Team.
            </Typography>
            <Typography className="title">TERMINATION</Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              Thrillh may terminate this Agreement at any time. Without limiting
              the foregoing, Thrillh shall have the right to immediately
              terminate any passwords or accounts of a User in the event of any
              conduct by the User that Thrillh, in its sole discretion,
              considers unacceptable, or in the event of any breach by the User
              of this Agreement.
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              One User can have only one Account with a unique e-mail ID and
              unique phone number. If Thrillh has any suspicion or knowledge
              that any of its Users have created multiple Accounts with
              different e-mail addresses or phone numbers (including but not
              limited to account creation by using false names or providing
              misleading data for creating those Accounts or using disposable
              email addresses or disposable phone numbers) to generate
              additional cashback credits or misuse or take undue advantage of
              the promotional benefits being provided on the Thrillh Platform,
              then Thrillh may while reserving its rights to initiate civil and/
              or criminal proceedings against such User may also at its sole
              discretion terminate, suspend, block, restrict, cancel the Account
              of such User and/ or disqualify that User and any related Users
              from availing the services ordered or undue benefits availed
              through these accounts.
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              All such provisions wherein the context so requires, including
              Clauses on Intellectual Property Rights, Disclaimer of Warranty,
              Limitation of Liability, License Grant, Indemnification, and
              Termination above will survive termination of this Agreement.
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              Our right to terminate this Agreement shall not prejudice any
              other right or remedy we may have in respect of any breach or any
              rights, obligations, or liabilities accrued before the
              termination.
            </Typography>
          </Box>
        </Grid>
      </Box>
      <Grid>
        <Box mt={"30px"}>
          <Footer from={"terms"} />
        </Box>
      </Grid>
    </>
  );
};

export default TermsOfServices;

const styles = {
  footerCardImg: {
    borderRadius: 30,
  },
  footerCardSubText: {
    fontFamily: "MetropolisRegular",
    fontSize: 15,
    color: Colors.textColor,
    textAlign: "center",
  },
  registerBrand: {
    fontFamily: "MetropolisRegular",
    fontSize: 15,
    color: Colors.textColor,
    marginRight: 5,
  },
  copyright: {
    fontFamily: "MetropolisRegular",
    color: Colors.white,
    fontSize: 15,
  },
  footerCardText: {
    fontFamily: "MetropolisMedium",
    color: Colors.lightBlue,
    fontSize: 23,
    textAlign: "center",
  },
  para: {
    fontFamily: "MetropolisMedium",
    fontSize: "16px",
  },
};
