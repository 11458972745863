// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (min-width: 320px) and (max-width: 990px) {
  .referCard {
    width: 90% !important;
    padding: 14px !important;
    margin: 24px 6px 6px 6px !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/app/screens/Refer/index.css"],"names":[],"mappings":"AAAA;EACE;IACE,qBAAqB;IACrB,wBAAwB;IACxB,mCAAmC;EACrC;AACF","sourcesContent":["@media (min-width: 320px) and (max-width: 990px) {\n  .referCard {\n    width: 90% !important;\n    padding: 14px !important;\n    margin: 24px 6px 6px 6px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
