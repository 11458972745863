import React, { useState, useEffect } from "react";
import Footer from "../../components/Footer";
import { Grid, Box, Typography } from "@mui/material";
import Colors from "../../constants/Colors";
import CustomInput from "../../components/Input";
import CustomButton from "../../components/Button";
import { useNavigate, useParams, Link } from "react-router-dom";
import { ApiRequest } from "../../../services/api-request";
import { useSnackbar } from "../../../context/useSnackbar";
import CustomBreadCrumb from "../../components/BreadCrumb/bread-crumb";
import PaymentSuccess from "./payment-success";
import { useAppContext } from "../../../context/AppContext";
import Checkbox from "@mui/material/Checkbox";
import "./index.css";
import MetaTags from "../../components/ReactMetaTags/meta-tags";

const Summary = () => {
  const { openSnackbar } = useSnackbar();

  const navigate = useNavigate();

  const { handleLogoutSuccess } = useAppContext();

  const { offerId } = useParams();

  const [individualOffer, setIndividualOffer] = useState({});

  const [actualAmount, setActualAmount] = useState("");

  const [coupon, setCoupon] = useState({
    code: "",
    couponValue: "",
    isCouponApplied: false,
  });

  const [orderDetails, setOrderDetails] = useState({});

  const [orderSuccess, setOrderSuccess] = useState({});

  const [loaderForOrderDetails, setLoaderForOrderDetails] = useState(false);

  const [userDetails, setUserDetails] = useState({});

  const handleChangeCoupon = (e) => {
    setCoupon({ code: e.target.value });
  };

  const applyCoupon = () => {
    if (coupon.code === individualOffer?.coupon?.code) {
      setCoupon({
        ...coupon, // Merge existing properties of coupon
        couponValue: individualOffer?.coupon?.value,
        code: individualOffer?.coupon?.code,
        isCouponApplied: true, // Add the isCouponApplied property
      });
    } else {
      openSnackbar("Wrong Coupon", "error");
    }
  };

  const removeCoupon = () => {
    setCoupon({ code: "" });
  };

  const handleChangeAmount = (e) => {
    const value = Number(e.target.value);
    setActualAmount(value);
  };

  useEffect(() => {
    getOfferById();
    getOrders();
    getUserById();
  }, []);

  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const [walletPoints, setWalletPoints] = useState(0);
  const [totalWalletPoints, setTotalWalletPoints] = useState("");
  const [useWalletPoints, setUseWalletPoints] = useState(false);

  const { user } = useAppContext();

  useEffect(() => {
    getUserWalletPoints();
  }, []);
  const getUserWalletPoints = () => {
    ApiRequest({
      url: `wallet/user/${user?.id}`,
      method: "GET",
    })
      .then((res) => {
        setTotalWalletPoints(res?.walletPoints);
        const percentageToShow = 25;
        const calculatedPoints = (res?.walletPoints * percentageToShow) / 100;
        if (Number(res?.walletPoints)) {
          setWalletPoints(calculatedPoints);
        } else if (Number(res?.walletPoints) < 0) {
          setWalletPoints(0);
        }
      })
      .catch((err) => {});
  };

  const getUserById = () => {
    ApiRequest({
      url: `user`,
      method: "GET",
    })
      .then((res) => {
        setUserDetails(res);
      })
      .catch((err) => {
        openSnackbar(err?.response?.data?.message, "error");
        if (err?.statusCode === 403 || err?.statusCode === 401) {
          if (navigate) {
            navigate("/login"); // Navigate to "/login" route
            handleLogoutSuccess();
          }
        }
      });
  };

  console.log(offerId);
  const getOfferById = () => {
    ApiRequest({
      url: `offer/find/${offerId}`,
      method: "GET",
    })
      .then((res) => {
        setIndividualOffer(res);
      })
      .catch((err) => {
        openSnackbar(err?.response?.data?.message, "error");
        if (err?.statusCode === 403 || err?.statusCode === 401) {
          if (navigate) {
            navigate("/login"); // Navigate to "/login" route
            handleLogoutSuccess();
          }
        }
      });
  };

  const getOrders = () => {
    ApiRequest({
      url: `order/list`,
      data: { skip: 0, limit: 100 },
      method: "GET",
    })
      .then((res) => {})
      .catch((err) => {
        openSnackbar(err?.response?.data?.message, "error");
      });
  };

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  async function displayRazorpay() {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      // openSnackbar("Razorpay SDK failed to load. Are you online?", '');
      return;
    }

    const { payablePrice, currency = "INR", payment } = orderDetails;

    console.log(
      payablePrice,
      currency,
      payment,
      orderDetails,
      "RRRRRRRRRRRRRRRRRRRRRRR"
    );

    const options = {
      key: process.env.REACT_APP_RAZOR_PAY_KEY, // Enter the Key ID generated from the Dashboard
      amount: payablePrice * 100,
      currency: currency,
      name: "Thrillh",
      description: "Test Transaction",
      logo: { logo: require("../../../images/final logo.png") },
      order_id: payment?.orderId,

      handler: async function (paymentResponse) {
        console.log(paymentResponse, "PPPPPPPAAAAAAAAAYYYYYYYYY");
        const data = {
          paymentGatewayOrderId: orderDetails?.payment?.orderId,
          paymentId: paymentResponse.razorpay_payment_id,
          ...(useWalletPoints === true && {
            applyWallet: useWalletPoints,
          }),
        };

        ApiRequest({
          url: `order/${orderDetails?._id}/update-status`,
          data: { ...data },
          method: "PUT",
        })
          .then((res) => {
            console.log(res, "PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP");
            if (res?.status === "success") {
              openSnackbar("your payment is success", "success");
              setOrderSuccess(res);
              setTimeout(() => {
                navigate("/my-account/orders");
              }, 8000);
            } else {
              openSnackbar("Payment failed", "error");
            }
          })
          .catch((err) => {
            openSnackbar(err?.response?.data?.message, "error");
          });
      },
      prefill: {
        name: userDetails?.name,
        email: userDetails?.personalEmail ?? "",
        contact: userDetails?.mobile,
      },
      notes: {
        address: "Example Corporate Office",
      },
      theme: {
        color: "#61dafb",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }

  const handleProceedPayment = () => {
    setLoaderForOrderDetails(true);
    const apiPayload = {
      offer: individualOffer?._id,
      actualPrice: Number(actualAmount),
      ...(coupon?.code && {
        coupon: coupon?.code,
      }),
      ...(useWalletPoints === true && {
        applyWallet: useWalletPoints,
      }),
      userId: userDetails?._id,
    };
    if (Number(actualAmount) === 0) {
      return alert("hi");
    }
    ApiRequest({
      url: `order/proceed-payment`,
      data: apiPayload,
      method: "POST",
    })
      .then((res) => {
        console.log(res, "PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP");
        setOrderDetails(res);
        setLoaderForOrderDetails(false);
      })
      .catch((err) => {
        if (
          err?.statusCode === 403 ||
          err?.statusCode === 401 ||
          err.response?.data?.statusCode === 403 ||
          err.response?.data?.statusCode === 401
        ) {
          if (navigate) {
            navigate("/login"); // Navigate to "/login" route
            handleLogoutSuccess();
          }
        }
        setLoaderForOrderDetails(false);
        openSnackbar(err?.response?.data?.error?.description, "error");
      });
  };

  const handleChange = (e) => {
    if (e.target.checked === true) {
      setUseWalletPoints(true);
    } else {
      setUseWalletPoints(false);
    }
  };

  const breadcrumbs = [
    <Link to="/" className={"link"}>
      Home
    </Link>,
    <Link className={"link"} to={"/admin/products"}>
      Paying To
    </Link>,
  ];
  return (
    <>
      <MetaTags
        title={individualOffer?.metaTitle ? individualOffer?.metaTitle : ""}
        metaTitle={individualOffer?.metaTitle ? individualOffer?.metaTitle : ""}
        description={
          individualOffer?.metaDescription
            ? individualOffer?.metaDescription
            : ""
        }
      />
      {orderSuccess?.status === "success" ? (
        <PaymentSuccess
          individualOffer={individualOffer}
          orderSuccess={orderSuccess}
        />
      ) : (
        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          pl={30}
          pr={30}
          pt={10}
          className={"summaryCard"}
        >
          <CustomBreadCrumb breadcrumbsArr={breadcrumbs} />
          <Box
            boxShadow={5}
            p={4}
            mt={2}
            borderRadius={10}
            className={"summaryInner"}
          >
            <Grid
              container
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Grid item>
                <Grid
                  container
                  justifyContent={"center"}
                  alignItems={"center"}
                  gap={1}
                >
                  <Grid item>
                    <Box
                      width={80}
                      height={80}
                      borderRadius={50}
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <img
                        src={individualOffer?.logoImage}
                        width={70}
                        height={70}
                        alt="logo"
                        style={{ borderRadius: "50%" }}
                      />
                    </Box>
                  </Grid>
                  <Grid item gap={"10px"}>
                    <p style={styles.name}>Offer Name</p>
                    <p style={styles.companyName}>{individualOffer?.name}</p>
                  </Grid>
                </Grid>
              </Grid>
              {orderDetails?.payment?.orderId ? null : (
                <>
                  <Grid mt={2}>
                    <Grid item width={250}>
                      <Box>
                        <p style={styles.companyName}>Enter Amount</p>
                      </Box>
                      <CustomInput
                        placeHolder={"Enter Amount"}
                        label={""}
                        type={"number"}
                        onChange={handleChangeAmount}
                      />
                    </Grid>
                  </Grid>

                  {individualOffer?.coupon?.name?.length && (
                    <>
                      <Grid container justifyContent={"space-between"} mt={2}>
                        <Grid item>
                          <Box
                            display={"flex"}
                            flexDirection={"row"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            gap={1}
                          >
                            <Box
                              backgroundColor={Colors.skyBlue}
                              width={40}
                              height={40}
                              borderRadius={25}
                              display={"flex"}
                              justifyContent={"center"}
                              alignItems={"center"}
                            >
                              <img
                                src={require("../../../images/discount.png")}
                                width={20}
                                height={20}
                                alt="logo"
                              />
                            </Box>
                            <Box>
                              <p style={styles.companyName}>Thrillh Coupon</p>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>

                      <Grid
                        container
                        justifyContent={"space-between"}
                        alignItems={"center"}
                      >
                        <Grid item>
                          <Box
                            display={"flex"}
                            flexDirection={"row"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            gap={1}
                          >
                            <Box width={250}>
                              <CustomInput
                                placeHolder={"Enter Coupon code"}
                                label={""}
                                value={coupon.code}
                                onChange={handleChangeCoupon}
                              />
                            </Box>
                            {coupon.isCouponApplied ? (
                              <Box width={150} mt={1}>
                                <CustomButton
                                  title={"Remove"}
                                  handleClick={removeCoupon}
                                  padding={1.5}
                                />
                              </Box>
                            ) : (
                              <Box width={150} mt={1} className={"removeBtn"}>
                                <CustomButton
                                  title={"Apply"}
                                  handleClick={applyCoupon}
                                  padding={1.5}
                                  disabled={coupon?.code ? false : true}
                                />
                              </Box>
                            )}
                          </Box>
                        </Grid>
                        {coupon?.isCouponApplied && (
                          <Grid>
                            <Typography
                              fontSize={"14px"}
                              fontWeight={"MetropolisSemiBold"}
                              color={"#FA8842"}
                            >
                              Coupon Applied Successfully
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    </>
                  )}
                </>
              )}
            </Grid>
            <Box
              display={"flex"}
              gap={"10px"}
              alignItems={"center"}
              justifyContent={"space-between"}
              mt={2}
              ml={"10px"}
            >
              <Box display={"flex"} gap={"10px"} alignItems={"center"}>
                <Checkbox
                  sx={{ padding: "0px" }}
                  {...label}
                  size="small"
                  disabled={
                    orderDetails?._id || Number(totalWalletPoints) === 0
                  }
                  onChange={handleChange}
                />
                <Box>
                  <Typography
                    fontFamily={"MetropolisRegular"}
                    fontSize={"13px"}
                    color={"#000000"}
                  >
                    My Wallet Points
                  </Typography>
                </Box>
              </Box>

              <Box>
                <Typography
                  fontFamily={"MetropolisBold"}
                  fontSize={"13px"}
                  color={"green"}
                >
                  + {walletPoints}
                </Typography>
              </Box>
            </Box>
            <Box ml={"10px"} mt={"10px"}>
              <Typography
                fontFamily={"MetropolisRegular"}
                fontSize={"13px"}
                color={"#000000"}
              >
                <b> Available Points</b> : {totalWalletPoints}
              </Typography>
            </Box>

            <>
              {orderDetails?.payment?.orderId && (
                <>
                  <Box
                    display={"flex"}
                    gap={"10px"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    mt={2}
                    ml={"10px"}
                  >
                    <Box display={"flex"} gap={"10px"} alignItems={"center"}>
                      <img
                        src={require("../../../images/discount.png")}
                        width={20}
                        height={20}
                        alt="logo"
                      />
                      <Box>
                        <Typography
                          fontFamily={"MetropolisRegular"}
                          fontSize={"13px"}
                          color={"#000000"}
                        >
                          Bill Amount
                        </Typography>
                      </Box>
                    </Box>
                    <Box>
                      <Typography
                        fontFamily={"MetropolisBold"}
                        fontSize={"13px"}
                        color={"#000000"}
                      >
                        ₹{orderDetails?.actualPrice}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    display={"flex"}
                    gap={"10px"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    mt={2}
                    ml={"10px"}
                  >
                    <Box display={"flex"} gap={"10px"} alignItems={"center"}>
                      <img
                        src={require("../../../images/discount.png")}
                        width={20}
                        height={20}
                        alt="logo"
                      />
                      <Box>
                        <Typography
                          fontFamily={"MetropolisRegular"}
                          fontSize={"13px"}
                          color={"#000000"}
                        >
                          Discount Price
                        </Typography>
                      </Box>
                    </Box>
                    <Box>
                      <Typography
                        fontFamily={"MetropolisBold"}
                        fontSize={"13px"}
                        color={"#FA8842"}
                      >
                        - ₹{orderDetails?.discountPrice}
                      </Typography>
                    </Box>
                  </Box>
                  {orderDetails?.couponInfo?.value && (
                    <Box
                      display={"flex"}
                      gap={"10px"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      mt={2}
                      ml={"10px"}
                    >
                      <Box display={"flex"} gap={"10px"} alignItems={"center"}>
                        <img
                          src={require("../../../images/discount.png")}
                          width={20}
                          height={20}
                          alt="logo"
                        />
                        <Box>
                          <Typography
                            fontFamily={"MetropolisRegular"}
                            fontSize={"13px"}
                            color={"#000000"}
                          >
                            Thrillh Coupon
                          </Typography>
                        </Box>
                      </Box>
                      <Box>
                        <Typography
                          fontFamily={"MetropolisBold"}
                          fontSize={"13px"}
                          color={"#56B352"}
                        >
                          - ₹{orderDetails?.couponInfo?.value}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                  <Box
                    display={"flex"}
                    gap={"10px"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    mt={2}
                    ml={"10px"}
                  >
                    <Box display={"flex"} gap={"10px"} alignItems={"center"}>
                      <img
                        src={require("../../../images/discount.png")}
                        width={20}
                        alt="logo"
                        height={20}
                      />
                      <Box>
                        <Typography
                          fontFamily={"MetropolisRegular"}
                          fontSize={"13px"}
                          color={"#000000"}
                        >
                          Convinence fee (Base fee + IGST)
                        </Typography>
                      </Box>
                    </Box>
                    <Box>
                      <Typography
                        fontFamily={"MetropolisBold"}
                        fontSize={"13px"}
                        color={"#000000"}
                      >
                        ₹
                        {(
                          Number(orderDetails?.baseFeePrice) +
                          Number(orderDetails?.gstForCustomerPrice)
                        )?.toFixed(2)}
                      </Typography>
                    </Box>
                  </Box>

                  <Box
                    display={"flex"}
                    gap={"10px"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    mt={2}
                    ml={"10px"}
                  >
                    <Box display={"flex"} gap={"10px"} alignItems={"center"}>
                      <img
                        src={require("../../../images/discount.png")}
                        width={20}
                        alt="logo"
                        height={20}
                      />
                      <Box>
                        <Typography
                          fontFamily={"MetropolisRegular"}
                          fontSize={"13px"}
                          color={"#000000"}
                        >
                          Wallet Points
                        </Typography>
                      </Box>
                    </Box>
                    <Box>
                      <Typography
                        fontFamily={"MetropolisBold"}
                        fontSize={"13px"}
                        color={"#000000"}
                      >
                        - {orderDetails?.walletPoints}
                      </Typography>
                    </Box>
                  </Box>
                </>
              )}

              <Box
                width={"100%"}
                height={1.5}
                backgroundColor={Colors.grey2}
                mt={2}
              />
              {orderDetails?.payment?.orderId && (
                <Box
                  display={"flex"}
                  gap={"10px"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  mt={2}
                  ml={"10px"}
                >
                  <Box display={"flex"} gap={"10px"} alignItems={"center"}>
                    <Box>
                      <Typography
                        fontFamily={"MetropolisSemiBold"}
                        fontSize={"22px"}
                        color={"#000000"}
                      >
                        Total Amount
                      </Typography>
                    </Box>
                  </Box>
                  <Box>
                    <Typography
                      fontFamily={"MetropolisBold"}
                      fontSize={"20px"}
                      color={"#FA8842"}
                    >
                      ₹{Number(orderDetails?.payablePrice)?.toFixed(2)}
                    </Typography>
                  </Box>
                </Box>
              )}
            </>

            <Grid
              container
              justifyContent={"space-between"}
              alignItems={"center"}
              mt={2}
            ></Grid>
            {orderDetails?.payment?.orderId ? (
              <Grid container mt={2}>
                <Grid
                  container
                  gap={2}
                  justifyContent={"flex-end"}
                  alignItems={"flex-end"}
                >
                  <Grid item width={150}>
                    <Typography
                      onClick={() => {
                        setOrderDetails({});
                        setActualAmount("");
                      }}
                      sx={{
                        backgroundColor: "#000000",
                        padding: "8px",
                        color: "#fff",
                        textAlign: "center",
                        borderRadius: "21px",
                        fontFamily: "MetropolisLight",
                        fontSize: "12px",
                        cursor: "pointer",
                      }}
                    >
                      Cancel
                    </Typography>
                  </Grid>
                  <Grid item width={150}>
                    <CustomButton
                      title={"Pay"}
                      padding={1.3}
                      handleClick={displayRazorpay}
                    />
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <Box display={"flex"} justifyContent={"end"} gap={"10px"}>
                <Box width={"150px"}>
                  <CustomButton
                    title={"Back"}
                    handleClick={() => navigate(-1)}
                    borderButton={true}
                    padding={1.3}
                    backgroundColor={"#000000"}
                    color={"#fff"}
                  />
                </Box>
                <Box width={"150px"}>
                  <CustomButton
                    title={"Confirm"}
                    handleClick={handleProceedPayment}
                    borderButton={true}
                    padding={1.3}
                    disabled={actualAmount ? false : true}
                  />
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      )}
      <Box mt={"30px"}>
        <Footer from={"about"} />
      </Box>
    </>
  );
};

export default Summary;

const styles = {
  billHeading: {
    fontFamily: "MetropolisRegular",
    fontSize: 22,
    color: Colors.textColor,
    margin: 0,
    marginTop: 10,
  },
  billHeadingBlue: {
    fontFamily: "MetropolisRegular",
    fontSize: 22,
    color: Colors.lightBlue,
    margin: 0,
  },
  name: {
    fontFamily: "MetropolisSemiBold",
    fontSize: 12,
    color: Colors.textColor,
    margin: 0,
  },
  offerDesc: {
    fontFamily: "MetropolisRegular",
    fontSize: 12,
    color: Colors.textColor,
    margin: 0,
    marginTop: 10,
  },
  companyName: {
    fontFamily: "MetropolisSemiBold",
    fontSize: 14,
    color: Colors.black,
    margin: 0,
    marginTop: 6,
  },
  offertext: {
    fontFamily: "MetropolisBold",
    fontSize: 13,
    color: Colors.textColor,
    margin: 0,
    marginTop: 6,
  },
  screenTExt: {
    fontFamily: "MetropolisRegular",
    color: Colors.textColor,
    fontSize: 15,
  },
  amount: {
    fontFamily: "MetropolisSemiBold",
    fontSize: 19,
    color: Colors.lightBlue,
  },
  amount1: {
    fontFamily: "MetropolisSemiBold",
    fontSize: 16,
    color: Colors.black,
  },
  checkboxText: {
    fontFamily: "MetropolisRegular",
    color: Colors.textColor,
    fontSize: 13,
  },
};
