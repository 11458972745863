import React from "react";
import { Grid, Box, Typography } from "@mui/material";
import BgBaground from "../../../images/bg-banner.png";
import Footer from "../../components/Footer";
import MetaTags from "../../components/ReactMetaTags/meta-tags";
import styles from "./disclaimer.module.css";

const Disclaimer = () => {
    return (
        <>
            <MetaTags
                title={"Thrillh - Disclaimer"}
                metaTitle={"Thrillh - Terms and Services"}
                description={
                    "Explore our disclaimer page to understand the boundaries and legal aspects. Read through our disclaimer for transparency and clarity on content, accuracy, and user responsibilities."
                }
            />
            <Box position={"relative"}>
                <Box
                    style={{
                        backgroundImage: `url(${BgBaground})`,
                        backgroundSize: "cover",
                    }}
                    padding={"40px 20px 60px 20px"}
                >
                    <Box>
                        <Typography
                            color={"#fff"}
                            fontSize={"40px"}
                            textAlign={"center"}
                            p={5}
                            variant="h1"
                        >
                            Disclaimer
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Box display={"flex"} justifyContent={"center"}>
                <Grid container className="container">
                    <Box className={styles.disclaimerContent}>
                        <p>Last updated: January 08, 2024</p>
                        <h3>Interpretation and Definitions</h3>
                        <h3>Interpretation</h3>
                        <p>The words of which the initial letter is capitalized have meanings defined under the following conditions.
                            The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>
                        <h3>Definitions</h3>
                        <p>For the purposes of this Disclaimer:</p>
                        <ul>
                            <li><strong>Company</strong> (referred to as either &quot;the Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in this Disclaimer) refers to Siveals digital private limited, 4th Floor, CoWork Valley, Madhapur, Hitec City,500033.</li>
                            <li><strong>Service</strong> refers to the Website.</li>
                            <li><strong>You</strong> means the individual accessing the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</li>
                            <li><strong>Website</strong> refers to Thrillh, accessible from <a href="https://thrillh.com/" rel="external nofollow noopener" target="_blank">https://thrillh.com/</a></li>
                        </ul>
                        <h3>Disclaimer</h3>
                        <h3>CONVENIENCE FEE</h3>

                        <p>In addition to the cost of booking an offer/ deal available on the Thrillh Platform, we reserve the right to charge certain fees like convenience fees or service fees. </p>


                        <p>We further reserve the right to alter any fees from time to time. Any such additional fees, including fees towards any modifications thereof, will be displayed to the User before confirming the booking or collecting the payment from such User through a Payment Gateway.</p>

                        <p>In cases of short charging of the booking amount, taxes, statutory fee, convenience fee, etc., owing to any technical error or other reason, we shall reserve the right to deduct, charge, or claim the balance amount from the User and the User shall pay such balance amount to us. </p>


                        <p>In cases where the short charge is claimed before the utilization of the booking, we will be at liberty to cancel such bookings if the amount is not paid before the utilization date.</p>

                        <p>Any increase in the price charged by us on account of a change in the rate of taxes or imposition of new taxes, levied by the Government shall have to be borne by the User. </p>


                        <p>Such imposition of taxes, levies, etc., may be without prior notice and could also be retrospective but will always be as per applicable law.</p>

                        <h3>REFUNDS</h3>
                        <p>In the rare circumstance of a booking not getting confirmed for an Offer/ Deal for any reason whatsoever, we will process the refund of the booking amount paid by the User and inform the User about the same. We are not under any obligation to provide an alternate booking instead of or to compensate or replace the unconfirmed booking. All subsequent bookings will be treated as new transactions. Any applicable refund will be processed as per the defined policies of Thrillh.</p>


                        <p>The User shall be completely responsible for all charges, fees, duties, taxes, and assessments arising out of the use of the service, as per the applicable laws.</p>


                        <p>It is clarified that in cases of cancellation of the event by the Host/ Merchant or any third party, Thrillh shall not be held liable or responsible in any manner whatsoever for any losses, claims, liabilities, damages, etc., to anyone.</p>


                        <h3>CANCELLATION POLICY AND CANCELLATION FEES</h3>

                        <p>Except with the prior registration with Thrillh as B2B agents, priority partners, or a merchant, and explicit permission of Thrillh to use the Website, all merchants/ agents or aggregators are barred from using this Website for any commercial or resale purpose. </p>


                        <p>If any such bookings are detected, Thrillh reserves the right, including without limitation, to cancel all such bookings immediately without any notice to such merchants/ agents and also to withhold payments or any refunds thereto. </p>


                        <p>Thrillh shall not be held liable for any incidental loss or damage that may arise from the bookings made by any person through such merchants. The liability in case of such cancellations shall be solely borne by such merchants.</p>


                        <p>All discounts and offers mentioned on the Website or the mobile application apply only to the User(s) of Thrillh for legitimate bookings for the respective offers/ deals.</p>


                        <p>Cancellation Fees shall mean and refer to the fees that shall be charged by Thrillh from the User on a percentage basis to be calculated at the sole discretion of Thrillh on the total MRP value excluding taxes which shall be applicable only in cases the User cancels the transaction/ Voucher Coupon Code for the services purchased from the Merchant on the Thrillh Platform which shall be deducted invariably from the User’s NB Wallet/ User’s NB Credits, if any, or the amount paid by the User for the transaction, as the case may be, and the same shall be solely retained by Thrillh.</p>


                        <p>Refunds, if any, on canceled bookings will always be processed to the respective account or the banking instrument (credit card, debit card, wallet, etc.) from which payment was made for that booking.
                            Booking(s) made by the User through Thrillh are subject to the applicable cancellation policy given in the Terms of Use hereinbelow.</p>


                        <p>Thrillh’s liability is limited to providing the User with a confirmed booking as selected by the User for any product/ service provided by the Merchant and further listed on Thrillh.</p>


                        <p>Any issues or concerns faced by the User at the time of availing of any such services/ products shall be the sole responsibility of the Merchant. Thrillh will have no liability concerning the acts, omissions, errors, representations, warranties, breaches, or negligence on the part of any Merchant.</p>


                        <p>Unless explicitly committed by Thrillh as a part of any product or service:</p>

                        <ol>
                            <li>Thrillh assumes no liability for the standard of services as provided by the respective Merchants.</li>
                            <li>Thrillh provides no guarantee about their quality or fitness as represented by the Merchants. </li>
                            <li>Thrillh doesn't guarantee the availability of any services as listed by a Merchant. By making a booking, User understands Thrillh merely provides a technology platform for booking services and products and the ultimate liability rests on the respective Merchant and not Thrillh. Thus the ultimate contract of service is between User and Merchant.</li>
                        </ol>
                        <p>
                            By making a booking, User understands Thrillh merely provides a technology platform for booking services and products and the ultimate liability rests on the respective Merchant and not Thrillh. Thus the ultimate contract of service is between User and Merchant.
                        </p>
                        <p>The information contained on the Service is for general information purposes only.</p>
                        <p>The Company assumes no responsibility for errors or omissions in the contents of the Service.</p>
                        <p>In no event shall the Company be liable for any special, direct, indirect, consequential, or incidental damages or any damages whatsoever, whether in an action of contract, negligence or other tort, arising out of or in connection with the use of the Service or the contents of the Service. The Company reserves the right to make additions, deletions, or modifications to the contents on the Service at any time without prior notice.</p>
                        <p>The Company does not warrant that the Service is free of viruses or other harmful components.</p>
                        <h3>External Links Disclaimer</h3>
                        <p>The Service may contain links to external websites that are not provided or maintained by or in any way affiliated with the Company.</p>
                        <p>Please note that the Company does not guarantee the accuracy, relevance, timeliness, or completeness of any information on these external websites.</p>
                        <h3>Errors and Omissions Disclaimer</h3>
                        <p>The information given by the Service is for general guidance on matters of interest only. Even if the Company takes every precaution to ensure that the content of the Service is both current and accurate, errors can occur. Plus, given the changing nature of laws, rules and regulations, there may be delays, omissions or inaccuracies in the information contained on the Service.</p>
                        <p>The Company is not responsible for any errors or omissions, or for the results obtained from the use of this information.</p>
                        <h3>Fair Use Disclaimer</h3>
                        <p>The Company may use copyrighted material which has not always been specifically authorized by the copyright owner. The Company is making such material available for criticism, comment, news reporting, teaching, scholarship, or research.</p>
                        <p>The Company believes this constitutes a &quot;fair use&quot; of any such copyrighted material as provided for in section 107 of the United States Copyright law.</p>
                        <p>If You wish to use copyrighted material from the Service for your own purposes that go beyond fair use, You must obtain permission from the copyright owner.</p>
                        <h3>Views Expressed Disclaimer</h3>
                        <p>The Service may contain views and opinions which are those of the authors and do not necessarily reflect the official policy or position of any other author, agency, organization, employer or company, including the Company.</p>
                        <p>Comments published by users are their sole responsibility and the users will take full responsibility, liability and blame for any libel or litigation that results from something written in or as a direct result of something written in a comment. The Company is not liable for any comment published by users and reserves the right to delete any comment for any reason whatsoever.</p>
                        <h3>No Responsibility Disclaimer</h3>
                        <p>The information on the Service is provided with the understanding that the Company is not herein engaged in rendering legal, accounting, tax, or other professional advice and services. As such, it should not be used as a substitute for consultation with professional accounting, tax, legal or other competent advisers.</p>
                        <p>In no event shall the Company or its suppliers be liable for any special, incidental, indirect, or consequential damages whatsoever arising out of or in connection with your access or use or inability to access or use the Service.</p>
                        <h3>&quot;Use at Your Own Risk&quot; Disclaimer</h3>
                        <p>All information in the Service is provided &quot;as is&quot;, with no guarantee of completeness, accuracy, timeliness or of the results obtained from the use of this information, and without warranty of any kind, express or implied, including, but not limited to warranties of performance, merchantability and fitness for a particular purpose.</p>
                        <p>The Company will not be liable to You or anyone else for any decision made or action taken in reliance on the information given by the Service or for any consequential, special or similar damages, even if advised of the possibility of such damages.</p>
                        <h3>Contact Us</h3>
                        <p>If you have any questions about this Disclaimer, You can contact Us:</p>
                        <ul>
                            <li>By visiting this page on our website: <a href="https://thrillh.com/contact-us" rel="external nofollow noopener" target="_blank">https://thrillh.com/contact-us</a></li>
                        </ul>

                    </Box>
                </Grid>
            </Box>
            <Grid>
                <Box mt={"30px"}>
                    <Footer from={"terms"} />
                </Box>
            </Grid>
        </>
    );
};

export default Disclaimer;