// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.disclaimer_disclaimerContent__w\\+caF p, .disclaimer_disclaimerContent__w\\+caF  li{
line-height: 24px !important;
}`, "",{"version":3,"sources":["webpack://./src/app/screens/Disclaimer/disclaimer.module.css"],"names":[],"mappings":"AAAA;AACA,4BAA4B;AAC5B","sourcesContent":[".disclaimerContent p, .disclaimerContent  li{\nline-height: 24px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"disclaimerContent": `disclaimer_disclaimerContent__w+caF`
};
export default ___CSS_LOADER_EXPORT___;
