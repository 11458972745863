import React from "react";
import { Box, Grid } from "@mui/material";
import CustomInput from "../../../components/Input";
import CustomButton from "../../../components/Button";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ApiRequest } from "../../../../services/api-request";
import { useSnackbar } from "../../../../context/useSnackbar";
import { useAppContext } from "../../../../context/AppContext";
import CustomSelect from "../../../components/Select/CustomSelect";

const schema = yup
  .object({
    selectBank: yup.string().required("Select Bank is required"),
    accountNumber: yup.string().required("Account Number is required"),
    accountType: yup.string().required("Account Type is required"),
    accountHolderName: yup
      .string()
      .matches(
        /^[a-zA-Z\s]+$/,
        "Account Holder Name should only contain characters"
      )
      .required("Account Holder Name  is required"),
    bankBranch: yup
      .string()
      .matches(/^[a-zA-Z\s]+$/, "Bank Branch should only contain characters")
      .required("Bank Branch is required"),
    ifscCode: yup.string().required("IFSC Code is required"),
  })
  .required();

const AddEditBankDetails = (props) => {
  const { openSnackbar } = useSnackbar();
  const { isEdit, userDetails } = props;

  const { user } = useAppContext();

  const defaultValuesOfForm = (userDetails) => {
    if (!userDetails) {
      return {
        selectBank: "",
        accountNumber: "",
        accountType: "",
        ifscCode: "",
        accountHolderName: "",
        bankBranch: "",
      };
    } else {
      return {
        selectBank: userDetails?.bankName,
        accountNumber: userDetails?.accountNumber,
        accountType: userDetails?.accountType,
        ifscCode: userDetails?.ifscCode,
        accountHolderName: userDetails?.accountHolderName,
        bankBranch: userDetails?.branch,
      };
    }
  };

  const { handleSubmit, control } = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultValuesOfForm(userDetails),
  });

  const onSubmit = (data) => {
    const modifiedFormData = {
      bankName: data.selectBank,
      accountNumber: data.accountNumber,
      ifscCode: data.ifscCode,
      branch: data.bankBranch,
      accountType: data.accountType,
      accountHolderName: data.accountHolderName,
    };
    ApiRequest({
      url: `vendor/update/${user.vendorId}`,
      data: modifiedFormData,
      method: "PUT",
    })
      .then((res) => {
        if (userDetails) {
          openSnackbar("Bank Details Edited Successfully", "success");
        } else if (!userDetails) {
          openSnackbar("Added Details Successfully", "success");
        }
        props.onClose();
        props.getVendorBankDetails();
      })
      .catch((err) => {});
  };

  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <Box>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <Controller
                name="selectBank"
                defaultValue=""
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <CustomInput
                    {...field}
                    inputLabel={"Enter Bank Name"}
                    borderRadius={"5px"}
                    error={!!error}
                    helperText={error ? error.message : ""}
                  />
                )}
              />
            </Grid>
            <Grid item md={6}>
              <Controller
                name="accountNumber"
                defaultValue=""
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <CustomInput
                    {...field}
                    inputLabel={"Account Number"}
                    borderRadius={"5px"}
                    error={!!error}
                    helperText={error ? error.message : ""}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <Controller
                name="accountType"
                defaultValue=""
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <CustomSelect
                    {...field}
                    placeHolder={"Enter Account Type"}
                    borderRadius={"5px"}
                    error={!!error}
                    helperText={error ? error.message : ""}
                    options={[
                      {
                        name: "Savings",
                        value: "savings",
                      },
                      {
                        name: "Current",
                        value: "current",
                      },
                    ]}
                  />
                )}
              />
            </Grid>
            <Grid item md={6}>
              <Controller
                name="accountHolderName"
                defaultValue=""
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <CustomInput
                    {...field}
                    inputLabel={"Enter Account Holder Name"}
                    borderRadius={"5px"}
                    error={!!error}
                    helperText={error ? error.message : ""}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <Controller
                name="ifscCode"
                defaultValue=""
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <CustomInput
                    {...field}
                    inputLabel={"Enter IFSC Code"}
                    borderRadius={"5px"}
                    error={!!error}
                    helperText={error ? error.message : ""}
                  />
                )}
              />
            </Grid>
            <Grid item md={6}>
              <Controller
                name="bankBranch"
                defaultValue=""
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <CustomInput
                    {...field}
                    inputLabel={"Enter Bank Branch"}
                    borderRadius={"5px"}
                    error={!!error}
                    helperText={error ? error.message : ""}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Box>
        <Box display={"flex"} justifyContent={"end"} gap={"10px"} mt={"20px"}>
          <Box width={"20%"}>
            <CustomButton
              border={"1px solid #000000"}
              handleClick={() => props.onClose()}
              color={"#000000"}
              title={"Cancel"}
              bgColor={"#F7F7F7"}
              borderRadius={"5px"}
            />
          </Box>
          <Box width={"20%"}>
            <CustomButton
              title={"Submit"}
              type={"submit"}
              borderRadius={"5px"}
            />
          </Box>
        </Box>
      </form>
    </Box>
  );
};

const styles = {
  cursor: {
    cursor: "pointer",
  },
};

export default AddEditBankDetails;
