import { Box, CircularProgress, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import EditSvg from "../../../../images/admin/edit (4).svg";
import CustomInput from "../../../components/Input";
import CustomButton from "../../../components/Button";
import deleteSvg from "../../../../images/admin/delete2.svg";
import Switch from "@mui/material/Switch";
import CustomDialog from "../../../components/Dialog/custom-dialog";
import AddEditCategory from "./addedit-category";
import { ApiRequest } from "../../../../services/api-request";
import { useSnackbar } from "../../../../context/useSnackbar";
import { useAppContext } from "../../../../context/AppContext";
import { useNavigate } from "react-router-dom";

const CategoriesList = (props) => {
  const { openSnackbar } = useSnackbar();
  const { bgcolor } = props;

  const { handleLogoutSuccess } = useAppContext();

  const navigate = useNavigate();

  const [showAddCategoriesModal, setShowAddCategoriesModal] = useState(false);

  const [allCategories, setAllCategories] = useState([]);

  const [currentCategoryList, setCurrentCategoryList] = useState({});

  const [activeCategory, setActiveCategory] = useState("");

  const [updateCategoryStatus, setUpdateCategoryStatus] = useState({
    open: false,
    data: {},
  });

  const [handleDeleteCategory, setHandleDeleteCategory] = useState({
    open: false,
    data: {},
  });

  const [loaderDeleteCategory, setLoaderDeleteCategory] = useState(false);

  const [filter, setFilter] = useState({
    name: "",
  });

  useEffect(() => {
    getAllCategories();
  }, []);

  function categoryUrl(filter) {
    const { name = "" } = filter || {};

    if (filter?.name?.length) {
      return `category/list?skip=0&limit=100${name ? `&name=${name}` : ""}`;
    } else {
      return `category/list?skip=0&limit=100`;
    }
  }

  const getAllCategories = (filter) => {
    ApiRequest({
      url: categoryUrl(filter),
      method: "GET",
    })
      .then((res) => {
        setAllCategories(res.result);
      })
      .catch((err) => {
        openSnackbar(err?.response?.data?.message, "error");
        if (err?.statusCode === 403 || err?.statusCode === 401) {
          if (navigate) {
            navigate("/login"); // Navigate to "/login" route
            handleLogoutSuccess();
          }
        }
      });
  };

  const updateCategory = (data) => {
    const newStatus = {
      status: data?.status,
    };
    ApiRequest({
      url: `offer/toggle-cat/${data?._id}`,
      method: "PUT",
    })
      .then((res) => {
        setUpdateCategoryStatus({
          open: false,
          data: {},
        });
        getAllCategories();
      })
      .catch((err) => {
        openSnackbar(err?.response?.data?.message, "error");
        if (err?.statusCode === 403 || err?.statusCode === 401) {
          if (navigate) {
            navigate("/login"); // Navigate to "/login" route
            handleLogoutSuccess();
          }
        }
      });
  };

  const handleSearch = (e) => {
    const searchValue = e.target.value;
    setFilter((prevFilter) => ({
      ...prevFilter,
      name: searchValue,
    }));
    getAllCategories({ ...filter, name: searchValue });
  };

  const deleteCategory = () => {
    setLoaderDeleteCategory(true);
    ApiRequest({
      url: `offer/del-category/${handleDeleteCategory?.data?._id}`,
      method: "DELETE",
    })
      .then((res) => {
        setHandleDeleteCategory({
          open: false,
          data: {},
        });
        getAllCategories();
        openSnackbar(
          res?.message === "Deleted"
            ? "Category Deleted Successfully"
            : res?.message,
          "success"
        );
        setLoaderDeleteCategory(false);
      })
      .catch((err) => {
        openSnackbar(err?.message, "error");
        setLoaderDeleteCategory(false);
        if (err?.statusCode === 403 || err?.statusCode === 401) {
          if (navigate) {
            navigate("/login"); // Navigate to "/login" route
            handleLogoutSuccess();
          }
        }
      });
  };

  return (
    <>
      <CustomDialog
        title={"Delete Category?"}
        width={"400px"}
        open={handleDeleteCategory.open}
        onClose={() => setHandleDeleteCategory({ open: false })}
      >
        <Typography>
          Are you sure you want to {handleDeleteCategory?.data?.name} Category?
        </Typography>
        <Box display={"flex"} justifyContent={"end"} gap={"10px"} mt={"20px"}>
          <Box width={"20%"}>
            <CustomButton
              handleClick={() => setHandleDeleteCategory({ open: false })}
              border={"1px solid #000000"}
              color={"#000000"}
              title={"No"}
              bgColor={"#F7F7F7"}
              borderRadius={"5px"}
            />
          </Box>
          <Box width={"20%"}>
            <CustomButton
              handleClick={deleteCategory}
              type={"submit"}
              title={"Yes"}
              borderRadius={"5px"}
            />
          </Box>
        </Box>
      </CustomDialog>

      <CustomDialog
        width={"400px"}
        open={showAddCategoriesModal}
        title={
          currentCategoryList?.openType === "add"
            ? "Add Category"
            : "Edit Category"
        }
        onClose={() => setShowAddCategoriesModal(false)}
      >
        <AddEditCategory
          onClose={() => setShowAddCategoriesModal(false)}
          allCategories={allCategories}
          isEdit={currentCategoryList?.openType === "add" ? false : true}
          list={currentCategoryList ?? {}}
          getAllCategories={getAllCategories}
        />
      </CustomDialog>

      {updateCategoryStatus.open && (
        <CustomDialog
          title={"Status Confirmation"}
          width={"400px"}
          open={updateCategoryStatus.open}
          onClose={() => {
            setUpdateCategoryStatus({
              open: false,
              data: {},
            });
            setActiveCategory((category) => ({
              ...category,
              status: activeCategory?.originalStatus,
            }));
          }}
        >
          <Typography>
            Are you sure you want to{" "}
            {activeCategory?.status == "active" ? "In-active" : "Active"}{" "}
            Category
          </Typography>
          <Box display={"flex"} justifyContent={"end"} gap={"10px"} mt={"20px"}>
            <Box width={"20%"}>
              <CustomButton
                handleClick={() => setUpdateCategoryStatus({ open: false })}
                border={"1px solid #000000"}
                color={"#000000"}
                title={"No"}
                bgColor={"#F7F7F7"}
                borderRadius={"5px"}
              />
            </Box>
            <Box width={"20%"}>
              <CustomButton
                handleClick={() => updateCategory(updateCategoryStatus.data)}
                type={"submit"}
                title={"Yes"}
                borderRadius={"5px"}
              />
            </Box>
          </Box>
        </CustomDialog>
      )}

      <Box>
        <Box
          bgcolor={bgcolor ? bgcolor : "#FFEDE3"}
          padding={"20px"}
          borderRadius={"40px 0px 0px 40px"}
        >
          <Box
            display={"flex"}
            width={"100%"}
            justifyContent={"end"}
            alignItems={"right"}
          >
            <Box>
              <CustomButton
                title={"+ Add Category"}
                borderRadius={"5px"}
                handleClick={() => {
                  setShowAddCategoriesModal(true);
                  setCurrentCategoryList({
                    openType: "add",
                  });
                }}
              />
            </Box>
          </Box>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography
              fontFamily={"MetropolisBold"}
              fontSize={"20px"}
              color={"#000000"}
            >
              Categories
            </Typography>
          </Box>
          <Box bgcolor={"#fff"} padding={"10px"} borderRadius={"10px"} mt={2}>
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              width={"100%"}
            >
              <Box width={"25%"}>
                <CustomInput
                  placeHolder={"Search"}
                  borderRadius={"5px"}
                  onChange={handleSearch}
                />
              </Box>
            </Box>

            <Box
              mt={"12px"}
              padding={"5px 10px"}
              borderRadius={"5px"}
              bgcolor={"#FFEDE3"}
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Box width={"10%"}>
                <Typography color={"#000000"} fontFamily={"MetropolisSemiBold"}>
                  Id
                </Typography>
              </Box>
              <Box width={"30%"}>
                <Typography color={"#000000"} fontFamily={"MetropolisSemiBold"}>
                  Category Title
                </Typography>
              </Box>
              <Box width={"15%"}>
                <Typography color={"#000000"} fontFamily={"MetropolisSemiBold"}>
                  Status
                </Typography>
              </Box>
              <Box width={"15%"}>
                <Typography color={"#000000"} fontFamily={"MetropolisSemiBold"}>
                  Action
                </Typography>
              </Box>
            </Box>
            {loaderDeleteCategory ? (
              <CircularProgress />
            ) : (
              <>
                {allCategories.map((item, index) => {
                  return (
                    <Box
                      key={index}
                      padding={"5px 10px"}
                      borderRadius={"5px"}
                      bgcolor={"#FFFFFF"}
                      border={"1px solid #FEECE3"}
                      mt={"5px"}
                      display={"flex"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <Box width={"10%"}>
                        <Typography
                          color={"#000000"}
                          fontFamily={"MetropolisMedium"}
                        >
                          {index + 1}
                        </Typography>
                      </Box>
                      <Box
                        width={"30%"}
                        display={"flex"}
                        gap={"10px"}
                        alignItems={"center"}
                      >
                        <img src={item.image} alt="object" width={"18px"} />
                        <Typography
                          color={"#000000"}
                          fontFamily={"MetropolisMedium"}
                        >
                          {item.name}
                        </Typography>
                      </Box>
                      <Box width={"15%"}>
                        <Typography
                          color={"#000000"}
                          fontFamily={"MetropolisMedium"}
                        >
                          <Switch
                            color="warning"
                            checked={item?.status === "active" ? true : false}
                            onChange={(e) => {
                              if (e?.target) {
                                setUpdateCategoryStatus({
                                  ...updateCategoryStatus,
                                  open: true,
                                  data: {
                                    ...item,
                                    status: e?.target?.checked
                                      ? "active"
                                      : "in-active",
                                  },
                                  isChecked: e?.target?.checked,
                                });
                                setActiveCategory((category) => ({
                                  ...category,
                                  originalStatus: item?.status,
                                  status:
                                    item?._id == category?._id
                                      ? e?.target?.checked
                                        ? "active"
                                        : "in-active"
                                      : item?.status,
                                }));
                              }
                            }}
                          />
                        </Typography>
                      </Box>
                      <Box
                        display={"flex"}
                        sx={{ cursor: "pointer" }}
                        gap={"10px"}
                        alignItems={"center"}
                        width={"15%"}
                      >
                        <img
                          src={deleteSvg}
                          alt="delete"
                          width={"20px"}
                          onClick={() =>
                            setHandleDeleteCategory({ data: item, open: true })
                          }
                        />
                        <img
                          src={EditSvg}
                          alt="edit"
                          width={"20px"}
                          onClick={() => {
                            setShowAddCategoriesModal(true);
                            setCurrentCategoryList({
                              ...item,
                              openType: "editPatient",
                            });
                          }}
                        />
                      </Box>
                    </Box>
                  );
                })}
              </>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default CategoriesList;
