import React from "react";
import { Grid, Box } from "@mui/material";
import Colors from "../../constants/Colors";
import CustomButton from "../../components/Button";
import CustomInput from "../../components/Input";
import Footer from "../../components/Footer";
import CustomSelect from "../../components/Select/CustomSelect";
import { Contact_Us_ISSUES } from "../../utilities/constants";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ApiRequest } from "../../../services/api-request";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import contactSvg from "../../../images/contact.png";
import { useSnackbar } from "../../../context/useSnackbar";
import { useAppContext } from "../../../context/AppContext";
import "./contact-us.css";
import MetaTags from "../../components/ReactMetaTags/meta-tags";

const Contact = () => {
  const navigate = useNavigate();

  const { handleLogoutSuccess } = useAppContext();

  const validateSchema = yup
    .object({
      description: yup.string().required("Description is required"),
      type: yup.string().required("Issue Type is required"),
      name: yup.string().required("Name is required"),
      email: yup.string().required("Email  is required"),
      mobile: yup
        .string()
        .required("Mobile Number is required")
        .test(
          "len",
          "Mobile Number must be exactly 10 digits",
          (val) => val && val.length === 10
        ),
    })
    .required();

  const { handleSubmit, control, reset } = useForm({
    resolver: yupResolver(validateSchema),
    defaultValues: { mobile: "" },
  });

  const { openSnackbar } = useSnackbar();

  const onSubmit = (data) => {
    const payload = {
      ...data,
    };
    ApiRequest({
      url: `user/issue/submit`,
      data: payload,
      method: "POST",
    })
      .then((res) => {
        if (res?.success === true) {
          openSnackbar(res?.message, "success");
          reset();
        }
      })
      .catch((err) => {
        if (err) {
          openSnackbar(err?.response?.data?.message, "error");
          if (err?.statusCode === 403 || err?.statusCode === 401) {
            if (navigate) {
              navigate("/login"); // Navigate to "/login" route
              handleLogoutSuccess();
            }
          }
        }
      });
  };

  return (
    <>
      <MetaTags
        metaTitle={"List Your Business – Thrillh | Contact Us"}
        title={"List Your Business – Thrillh | Contact Us"}
        description={
          "We are committed to helping businesses of all sizes achieve their goals. Contact us today and start reaping the benefits of increased revenue and growth."
        }
      />
      <Box>
        <Box position={"relative"} borderRadius={"30px"}>
          <img
            src={require("../../../images/contact-bg.png")}
            height={200}
            alt="contact"
            style={styles.aboutImage}
          />
          <Box position={"absolute"} top={70} left={0} right={0}>
            <Box
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
              gap={2}
            >
              <p style={styles.contactHeading}>Contact Us</p>
              <Box height={2} backgroundColor={Colors.white} />
            </Box>
          </Box>
        </Box>

        <Grid container justifyContent={"center"} mt={10}>
          <Grid item>
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              position={"relative"}
              boxShadow={"0px 0px 16px #67676729"}
              borderRadius={"30px"}
            >
              <Box className={"imageSection"}>
                <img src={contactSvg} alt="svg"/>
              </Box>
              <Box position={"absolute"} top={30} height={"100%"}>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Grid
                    container
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <Grid item className={"mainContainerOfContactUS"}>
                      <Box>
                        <Box mt={2}>
                          <Controller
                            name="name"
                            control={control}
                            defaultValue={""}
                            render={({ field, fieldState: { error } }) => (
                              <CustomInput
                                {...field}
                                backgroundColor={Colors.white}
                                type={"text"}
                                inputLabel={"Enter Name"}
                                error={!!error}
                                helperText={error ? error.message : ""}
                                borderRadius={"5px"}
                              />
                            )}
                          />
                        </Box>
                        <Box mt={3}>
                          <Controller
                            name="mobile"
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                              <CustomInput
                                {...field}
                                type={"text"}
                                inputLabel={"Enter Mobile"}
                                error={!!error}
                                helperText={error ? error.message : ""}
                                borderRadius={"5px"}
                              />
                            )}
                          />
                        </Box>
                        <Box mt={3}>
                          <Controller
                            name="email"
                            control={control}
                            defaultValue={""}
                            render={({ field, fieldState: { error } }) => (
                              <CustomInput
                                {...field}
                                type={"text"}
                                inputLabel={"Enter Email"}
                                error={!!error}
                                helperText={error ? error.message : ""}
                                borderRadius={"5px"}
                              />
                            )}
                          />
                        </Box>
                        <Box mt={3}>
                          <Controller
                            name="type"
                            control={control}
                            defaultValue=""
                            render={({
                              field: { ...field },
                              fieldState: { error },
                            }) => (
                              <CustomSelect
                                {...field}
                                error={!!error}
                                helperText={error ? error.message : ""}
                                inputLabel={"Select Issue Type"}
                                options={Contact_Us_ISSUES}
                                borderRadius={"5px"}
                              />
                            )}
                          />
                        </Box>
                        <Box mt={3}>
                          <Controller
                            name="description"
                            control={control}
                            defaultValue={""}
                            render={({ field, fieldState: { error } }) => (
                              <CustomInput
                                inputLabel={"Enter Description"}
                                type={"text"}
                                multiline={true}
                                borderRadius={"5px"}
                                {...field}
                                error={!!error}
                                helperText={error ? error.message : ""}
                              />
                            )}
                          />
                        </Box>
                      </Box>
                      <Box display={"flex"} justifyContent={"end"} mt={2}>
                        <Box width={130}>
                          <CustomButton
                            title={"Submit"}
                            borderButton={false}
                            type={"submit"}
                          />
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </form>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box mt={"30px"}>
        <Footer from={"about"} />
      </Box>
    </>
  );
};

const styles = {
  aboutImage: {
    objectFit: "cover",
    width: "100%",
  },
  contactHeading: {
    fontFamily: "MetropolisBold ",
    fontSize: 35,
    color: Colors.white,
    margin: 0,
  },
};

export default Contact;
