import { Box, Typography, Switch } from "@mui/material";
import React, { useState, useEffect } from "react";
import CustomButton from "../../../components/Button";
import { useParams } from "react-router-dom";
import { ApiRequest } from "../../../../services/api-request";
import CustomDialog from "../../../components/Dialog/custom-dialog";
import AddEditCareers from "./add-edit-career";

const CareererView = (props) => {
  const [individualCareerer, setIndividualCareerer] = useState({});

  const [editCareerer, setEditCareerer] = useState({
    open: false,
    data: {},
  });

  const [careererStatusChangeModal, setCareererStatusChangeModal] = useState({
    open: false,
    data: "",
    item: {},
  });

  const { careerId } = useParams();

  useEffect(() => {
    if (careerId) {
      getIndividualCareerer(careerId);
    }
  }, [careerId]);

  const getIndividualCareerer = (careerId) => {
    ApiRequest({
      url: `career/${careerId}`,
      method: "GET",
    })
      .then((res) => {
        setIndividualCareerer(res);
        setEditCareerer({ data: res });
      })
      .catch((err) => {});
  };

  const updateCareererStatusChange = (item) => {
    const payload = {
      status: careererStatusChangeModal.data,
    };

    ApiRequest({
      url: `career/update/${individualCareerer?._id}`,
      method: "PUT",
      data: payload,
    })
      .then((res) => {
        setCareererStatusChangeModal({
          open: false,
          data: {},
        });
        getIndividualCareerer(careerId);
      })
      .catch((err) => {});
  };
  return (
    <>
      {editCareerer.open && (
        <CustomDialog
          width={"500px"}
          open={editCareerer.open}
          title={"Edit Career"}
          onClose={() => setEditCareerer({ open: false })}
        >
          <AddEditCareers
            careerList={individualCareerer}
            onClose={() => setEditCareerer({ open: false })}
            getIndividualCareerer={() => getIndividualCareerer()}
          />
        </CustomDialog>
      )}

      {careererStatusChangeModal.open && (
        <CustomDialog
          title={"Status Confirmation"}
          width={"400px"}
          open={careererStatusChangeModal.open}
          onClose={() => {
            setCareererStatusChangeModal({
              open: false,
              data: {},
            });
          }}
        >
          <Typography>
            Are you sure you want to{" "}
            {careererStatusChangeModal?.data === "active"
              ? "Active"
              : "In-Active"}{" "}
            Sub-category
          </Typography>
          <Box display={"flex"} justifyContent={"end"} gap={"10px"} mt={"20px"}>
            <Box width={"20%"}>
              <CustomButton
                border={"1px solid #000000"}
                handleClick={() =>
                  setCareererStatusChangeModal({ open: false })
                }
                color={"#000000"}
                title={"No"}
                bgColor={"#F7F7F7"}
                borderRadius={"5px"}
              />
            </Box>
            <Box width={"20%"}>
              <CustomButton
                handleClick={() =>
                  updateCareererStatusChange(careererStatusChangeModal.item)
                }
                type={"submit"}
                title={"Yes"}
                borderRadius={"5px"}
              />
            </Box>
          </Box>
        </CustomDialog>
      )}
      <Box
        bgcolor={"#FFEDE3"}
        padding={"20px"}
        borderRadius={"40px 0px 0px 40px"}
      >
        <Box
          display={"flex"}
          width={"100%"}
          justifyContent={"space-between"}
          alignItems={"right"}
        >
          <Typography
            fontFamily={"MetropolisBold"}
            fontSize={"20px"}
            color={"#000000"}
          >
            Career View
          </Typography>
          <Box>
            <CustomButton
              title={"Edit Career"}
              borderRadius={"5px"}
              padding={"12px 28px"}
              handleClick={() => setEditCareerer({ open: true })}
            />
          </Box>
        </Box>
        <Box
          mt={2}
          boxShadow={"0px 0px 16px #67676729"}
          bgcolor={"#fff"}
          borderRadius={"15px"}
          padding={"15px 30px"}
        >
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            gap={"5px"}
          >
            <Box display={"flex"} alignItems={"center"} gap={"5px"}>
              <Typography
                fontFamily={"MetropolisSemiBold"}
                fontSize={"18px"}
                color={"#000000"}
              >
                Job Title :
              </Typography>
              <Typography
                fontFamily={"MetropolisRegular"}
                fontSize={"14px"}
                fontWeight={"500"}
                color={"#000000"}
              >
                {individualCareerer.jobTitle}
              </Typography>
            </Box>
            <Box display={"flex"} alignItems={"center"} gap={"5px"}>
              <Typography
                fontFamily={"MetropolisSemiBold"}
                fontSize={"16px"}
                color={"#000000"}
              >
                Status :
              </Typography>
              <Switch
                color="warning"
                checked={individualCareerer?.status === "active" ? true : false}
                onChange={(e) => {
                  setCareererStatusChangeModal({
                    open: true,
                    data:
                      individualCareerer?.status === "active"
                        ? "in-active"
                        : "active",
                    individualCareerer: individualCareerer,
                  });
                }}
              />
            </Box>
          </Box>
        </Box>
        <Box
          mt={2}
          boxShadow={"0px 0px 16px #67676729"}
          bgcolor={"#fff"}
          borderRadius={"15px"}
          padding={"15px 30px"}
        >
          <Typography
            fontFamily={"MetropolisSemiBold"}
            fontSize={"18px"}
            color={"#000000"}
          >
            Description
          </Typography>
          <Typography
            fontFamily={"MetropolisRegular"}
            fontSize={"14px"}
            mt={1}
            fontWeight={"500"}
            color={"#000000"}
          >
            {individualCareerer.jobDescription}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default CareererView;
