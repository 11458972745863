import {
  Box,
  Typography,
  FormControlLabel,
  RadioGroup,
  FormControl,
  Radio,
} from "@mui/material";
import React from "react";

import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomInput from "../Input";
import CustomButton from "../Button";

import { useState } from "react";
import { ApiRequest } from "../../../services/api-request";
import CustomDatePicker from "../../components/DatePicker";
import { CustomAutoCompleteWithIcon } from "../AutoComplete/auto-complete";
import { debounce } from "lodash";
import Colors from "../../constants/Colors";
import { getDateMonthYear } from "../../utilities/date-utility";
import { useSnackbar } from "../../../context/useSnackbar";
import { useAppContext } from "../../../context/AppContext";
import { useNavigate } from "react-router-dom";
import moment from "moment-timezone";

const EditProfile = (props) => {
  const { userData } = props;

  const { openSnackbar } = useSnackbar();

  const { user, handleLogoutSuccess } = useAppContext();

  const navigate = useNavigate();

  const styles = {
    cursor: {
      cursor: "pointer",
    },
    labelStyle: {
      fontFamily: "MetropolisSemiBold",
      fontSize: 13,
      color: Colors.textColor,
    },
  };

  const schema = yup
    .object({
      name: yup.string().required("Name is required."),
      // officialEmail: yup.string().required("Official Email is required."),
      personalEmail: yup.string().required("Personal Email is required."),
      // collegeName: yup.string().required("College Name is required."),
      dateOfBirth: yup.string().required("Date of birth is required."),
      regMobileNo: yup.string().required("Registration Number is required."),
      location: yup.string().required("Location is required."),
      gender: yup
        .string()
        .required("Either Male or Female Selection is required"),
    })
    .required();

  let userDefaultValues;

  const istDateTimeEndsAt = moment.utc(userData?.dob).tz("Asia/Kolkata");
  const dob = moment(istDateTimeEndsAt, "YYYY-MM-DDTHH:mm:ss.SSS[Z]");

  if (userData) {
    userDefaultValues = {
      name: userData?.name,
      school: {
        title: userData?.school,
      },
      company: {
        title: userData?.company,
      },
      officialEmail: userData?.email,
      regMobileNo: userData?.mobile,
      location: userData?.location,
      gender: userData?.gender,
      dateOfBirth: userData?.dob && dob,
      personalEmail: userData?.personalEmail,
    };
  } else {
    userDefaultValues = {
      name: "",
      school: "",
      officialEmail: "",
      regMobileNo: "",
      location: "",
      gender: "",
      dateOfBirth: "",
    };
  }

  const {
    handleSubmit,
    control,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { ...userDefaultValues },
  });

  const [open, setOpen] = React.useState(false);

  const [schoolList, setSchoolList] = React.useState([]);

  const [collegeList, setCollegeList] = useState([]);



  const [loading, setLoading] = useState(false);

  const fetchOptions = async (searchQuery) => {
    setLoading(true);
    if (user?.role?.code === "student") {
      try {
        const response = await ApiRequest({
          url: `college/list`,
          data: { searchText: searchQuery, limit: 30 },
          method: "POST",
        });
        const modifiedList = response?.map((name) => {
          const data = {
            title: name?.name,
            value: name?._id,
          };
          return data;
        });
        setSchoolList(modifiedList);
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const response = await ApiRequest({
          url: `companies/list`,
          data: { searchText: searchQuery, limit: 30 },
          method: "POST",
        });
        const modifiedList = response?.map((name) => {
          const data = {
            title: name?.name,
            value: name?._id,
          };
          return data;
        });
        setCollegeList(modifiedList);
      } catch (error) {
        console.log(error);
      }
    }
    setLoading(false);
  };

  const handleInputChange = (event, value) => {
    setValue("school", value); // Update the value of the 'school' field
    setValue("company", value); // Update the value of the 'school' field
    clearErrors("school"); //
    clearErrors("company"); //


    if (value === "") {
      setSchoolList([]);
      setCollegeList([]);
    }
  };

  const debouncedFetchOptions = debounce(fetchOptions, 500);

  // const onSubmit = (data) => {
  //   const endsAt = new Date(data.dateOfBirth);
  //   const modifiedData = {
  //     name: data.name,
  //     location: data.location,
  //     dob: `${getDateMonthYear(endsAt)}`,
  //     gender: data.gender,
  //     personalEmail: data.personalEmail,
  //   };

  //   if (user?.role?.code === "student") {
  //     modifiedData.school = data.school && data.school?.title;
  //   } else {
  //     modifiedData.company = data.company && data.company.title;
  //   }
  //   ApiRequest({
  //     url: `user/update`,
  //     data: modifiedData,
  //     method: "PUT",
  //   })
  //     .then((res) => {
  //       props.getUser();
  //       if (res) {
  //         props.onClose();
  //         openSnackbar("Profile Updated Successfully", "success");
  //       }
  //     })
  //     .catch((err) => {
  //       openSnackbar(err?.response?.data?.message, "error");
  //     });
  // };

  const onSubmit = (data) => {
    const endsAt = new Date(data.dateOfBirth);
    const modifiedData = {
      name: data.name,
      location: data.location,
      dob: `${getDateMonthYear(endsAt)}`,
      gender: data.gender,
      personalEmail: data.personalEmail,
    };

    if (user?.role?.code === "student") {
      modifiedData.school = data.school && data.school?.title;
    } else {
      modifiedData.company = data.company && data.company.title;
    }
    ApiRequest({
      url: `user/update`,
      data: modifiedData,
      method: "PUT",
    })
      .then((res) => {
        props.getUser();
        if (res) {
          props.onClose();
          openSnackbar(
            "Congrats! Your details are updated successfully",
            "success"
          );
        }
      })
      .catch((err) => {
        openSnackbar(err?.response?.data?.message, "error");
        if (err?.statusCode === 403 || err?.statusCode === 401) {
          if (navigate) {
            navigate("/login"); // Navigate to "/login" route
            handleLogoutSuccess();
          }
        }
      });
  };

  const tenYearsAgo = moment().subtract(10, "year");

  return (
    <>
      <Box mt={2} width={"100%"}>
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            className={"editFields"}
          >
            <Box width={"45%"} className={"editFieldBox"}>
              <Box>
                <Controller
                  name="name"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <CustomInput
                      {...field}
                      label={"Name"}
                      type={"text"}
                      placeHolder={"Enter your Name"}
                      error={!!error}
                      helperText={error ? error.message : ""}
                    />
                  )}
                />
              </Box>
              {userData?.email && (
                <Box mt={3}>
                  <Controller
                    name="officialEmail"
                    defaultValue={""}
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <CustomInput
                        label={"Official Email"}
                        type={"text"}
                        {...field}
                        placeHolder={"Enter your Official Email"}
                        error={!!error}
                        helperText={error ? error.message : ""}
                        disabled={true}
                      />
                    )}
                  />
                </Box>
              )}
              <Box mt={3}>
                <Controller
                  name="personalEmail"
                  defaultValue={""}
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <CustomInput
                      label={"Personal Email Id"}
                      type={"text"}
                      {...field}
                      placeHolder={"Enter your personal email id"}
                      error={!!error}
                      helperText={error ? error.message : ""}
                    />
                  )}
                />
              </Box>
              {user?.role?.code === "corporate" ? (
                <>
                  <Box mt={3}>
                    <Controller
                      name="company"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <CustomAutoCompleteWithIcon
                          {...field}
                          disabled={true}
                          defaultValue={userData?.company}
                          error={!!error}
                          helperText={error ? error.message : ""}
                          padding={"9.4px"}
                          open={open}
                          label={"Company/Organization"}
                          placeholder={"Search Company/organization"}
                          borderColor={"#000000"}
                          width={"100%"}
                          borderRadius={"25px"}
                          handleChange={handleInputChange}
                          onChange={(event, value) => {
                            field.onChange(value);
                            handleInputChange(value);
                          }}
                          onInputChange={(event, value) => {
                            debouncedFetchOptions(value);
                          }}
                          sx={{ width: "100%" }}
                          options={collegeList}
                          onOpen={() => {
                            setOpen(true);
                          }}
                          onClose={() => {
                            setOpen(false);
                          }}
                          loading={loading}
                        />
                      )}
                    />
                  </Box>
                </>
              ) : (
                <>
                  <Box mt={3}>
                    <Controller
                      name="school"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <CustomAutoCompleteWithIcon
                          {...field}
                          disabled={true}
                          defaultValue={userData?.school}
                          error={!!error}
                          helperText={error ? error.message : ""}
                          padding={"9.4px"}
                          open={open}
                          label={"College/University"}
                          placeholder={"Search College/University"}
                          borderColor={"#000000"}
                          width={"100%"}
                          borderRadius={"25px"}
                          handleChange={handleInputChange}
                          onChange={(event, value) => {
                            field.onChange(value);
                            handleInputChange(value);
                          }}
                          onInputChange={(event, value) => {
                            debouncedFetchOptions(value);
                          }}
                          sx={{ width: "100%" }}
                          options={schoolList}
                          onOpen={() => {
                            setOpen(true);
                          }}
                          onClose={() => {
                            setOpen(false);
                          }}
                          loading={loading}
                        />
                      )}
                    />
                  </Box>
                </>
              )}
            </Box>

            <Box width={"45%"} className={"editFieldBox"}>
              <Box className="datePick">
                <Controller
                  name="dateOfBirth"
                  control={control}
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <>
                        <CustomDatePicker
                          {...field}
                          disableFuture={true}
                          labelText={"Date of Birth"}
                          borderRadius={"25px"}
                          error={!!error}
                          helperText={error ? error.message : ""}
                          maxDate={tenYearsAgo}
                        />
                      </>
                    );
                  }}
                />
              </Box>
              <Box mt={3}>
                <Controller
                  name="regMobileNo"
                  type="text"
                  defaultValue={""}
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <CustomInput
                      label={"Regd. Mobile No."}
                      type="text"
                      {...field}
                      placeHolder={"Enter Regd. Mobile No."}
                      error={!!error}
                      helperText={error ? error.message : ""}
                      disabled={true}
                    />
                  )}
                />
              </Box>

              <Box mt={3}>
                <p style={styles.labelStyle}>Gender</p>
                <FormControl sx={{ width: "100%" }} component={"fieldset"}>
                  <Controller
                    rules={{ required: true }}
                    control={control}
                    name="gender"
                    defaultValue={""}
                    render={({ field }) => (
                      <RadioGroup
                        {...field}
                        sx={{
                          justifyContent: "space-between",
                          display: "flex",
                          padding: "0px",
                        }}
                        row
                      >
                        <Box
                          display={"flex"}
                          justifyContent={"center"}
                          alignItems={"center"}
                        >
                          <FormControlLabel
                            sx={{ margin: "0px" }}
                            value="male"
                            control={<Radio />}
                          />
                          <Box mt={"3px"}>
                            <Typography
                              fontWeight={"600"}
                              color={"#000000"}
                              fontSize={"12px"}
                              fontFamily={"MetropolisRegular"}
                            >
                              Male
                            </Typography>
                          </Box>
                        </Box>
                        <Box
                          display={"flex"}
                          justifyContent={"center"}
                          alignItems={"center"}
                        >
                          <FormControlLabel
                            sx={{ margin: "0px" }}
                            value="female"
                            control={<Radio />}
                          />
                          <Box mt={"3px"}>
                            <Typography
                              fontWeight={"600"}
                              color={"#000000"}
                              fontSize={"12px"}
                              fontFamily={"MetropolisRegular"}
                            >
                              Female
                            </Typography>
                          </Box>
                        </Box>
                      </RadioGroup>
                    )}
                  />
                  {errors.gender && (
                    <Typography fontSize={"12px"} color={"red"}>
                      {errors.gender.message}
                    </Typography>
                  )}
                </FormControl>
              </Box>
            </Box>
          </Box>
          <Box mt={3}>
            <Controller
              name="location"
              type="text"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <CustomInput
                  {...field}
                  label={"Location"}
                  type="text"
                  placeHolder={"Enter Location"}
                  error={!!error}
                  helperText={error ? error.message : ""}
                />
              )}
            />
          </Box>
          <Box display={"flex"} justifyContent={"end"} gap={"20px"}>
            <Box mt={2} item width={130}>
              <CustomButton
                title={"Cancel"}
                borderButton={true}
                padding={"11px"}
                handleClick={() => props.onClose()}
              />
            </Box>
            <Box mt={2} item width={130}>
              <CustomButton
                title={"Submit"}
                borderButton={false}
                type={"submit"}
                padding={"11px"}
              />
            </Box>
          </Box>
        </form>
      </Box>
    </>
  );
};

export default EditProfile;
