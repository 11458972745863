import { Box, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import EditSvg from "../../../../images/admin/edit (4).svg";
import CustomInput from "../../../components/Input";
import CustomButton from "../../../components/Button";
import Switch from "@mui/material/Switch";
import CustomDialog from "../../../components/Dialog/custom-dialog";
import AddEditSubCategory from "./addedit-sub-category";
import { ApiRequest } from "../../../../services/api-request";
import { useSnackbar } from "../../../../context/useSnackbar";
import Pagination from "../../../components/Pagination";
import deleteSvg from "../../../../images/delete.svg";
import { useAppContext } from "../../../../context/AppContext";
import { useNavigate } from "react-router-dom";

const SubCategoriesList = (props) => {
  const { handleLogoutSuccess } = useAppContext();
  const navigate = useNavigate();

  const { bgcolor } = props;

  const [showAddCategoriesModal, setShowAddCategoriesModal] = useState(false);

  const [subCategories, setSubCategories] = useState({});

  const [currentSubCategory, setCurrentSubCategory] = useState({});

  const [activeSubcategory, setActiveSubcategory] = useState("");

  const [updateSubCategoryStatus, setUpdateSubCategoryStatus] = useState({
    open: false,
    data: {},
  });

  const [handleDeleteSubCategory, setHandleDeleteSubCategory] = useState({
    open: false,
    data: {},
  });

  const [loaderForSubCategoryDelete, setLoaderForSubCategoryDelete] =
    useState(false);

  const { openSnackbar } = useSnackbar();

  const [filter, setFilter] = useState({
    name: "",
  });

  const limit = 100; // Users per page
  const [skip, setSkip] = useState(0);

  useEffect(() => {
    getAllSubCategories();
  }, [skip]);

  function subCategoryUrl(filter) {
    const { name = "" } = filter || {};

    if (filter?.name?.length) {
      return `sub-category/list?skip=${skip}&limit=${limit}${
        name ? `&name=${name}` : ""
      }`;
    } else {
      return `sub-category/list?skip=${skip}&limit=${limit}`;
    }
  }

  const getAllSubCategories = (filter) => {
    ApiRequest({
      url: subCategoryUrl(filter),
      method: "GET",
    })
      .then((res) => {
        setSubCategories(res);
      })
      .catch((err) => {
        openSnackbar(err?.response?.data?.message, "error");
        if (err?.statusCode === 403 || err?.statusCode === 401) {
          if (navigate) {
            navigate("/login"); // Navigate to "/login" route
            handleLogoutSuccess();
          }
        }
      });
  };

  const updateSubCategory = (data) => {
    ApiRequest({
      url: `offer/toggle-sub-cat/${data?._id}`,
      method: "PUT",
    })
      .then((res) => {
        setUpdateSubCategoryStatus({
          open: false,
          data: {},
        });
        getAllSubCategories();
      })
      .catch((err) => {
        openSnackbar(err?.response?.data?.message, "error");
        if (err?.statusCode === 403 || err?.statusCode === 401) {
          if (navigate) {
            navigate("/login"); // Navigate to "/login" route
            handleLogoutSuccess();
          }
        }
      });
  };

  const handleSearch = (e) => {
    const searchValue = e.target.value;
    setFilter((prevFilter) => ({
      ...prevFilter,
      name: searchValue,
    }));
    getAllSubCategories({ ...filter, name: searchValue });
  };

  const handlePageChange = (page) => {
    const newSkip = (page - 1) * limit;
    setSkip(newSkip);
  };

  const deleteSubCategory = (item) => {
    setLoaderForSubCategoryDelete(true);
    ApiRequest({
      url: `offer/del-sub-category/${handleDeleteSubCategory?.data?._id}`,
      method: "DELETE",
    })
      .then((res) => {
        setHandleDeleteSubCategory({
          open: false,
          data: {},
        });
        getAllSubCategories();
        openSnackbar(
          res?.message === "Deleted"
            ? "Sub-Category Deleted Successfully"
            : res?.message,
          "success"
        );
        setLoaderForSubCategoryDelete(false);
      })
      .catch((err) => {
        openSnackbar(err?.message, "error");
        setLoaderForSubCategoryDelete(false);
        if (err?.statusCode === 403 || err?.statusCode === 401) {
          if (navigate) {
            navigate("/login"); // Navigate to "/login" route
            handleLogoutSuccess();
          }
        }
      });
  };
  return (
    <>
      <CustomDialog
        width={"400px"}
        open={showAddCategoriesModal}
        title={
          currentSubCategory?.open === "add"
            ? "Add Sub Category"
            : " Edit Sub Category"
        }
        onClose={() => setShowAddCategoriesModal(false)}
      >
        <AddEditSubCategory
          onClose={() => setShowAddCategoriesModal(false)}
          currentSubCategory={currentSubCategory ?? {}}
          isEdit={currentSubCategory?.open === "add" ? false : true}
          getAllSubCategories={getAllSubCategories}
        />
      </CustomDialog>

      <CustomDialog
        title={"Delete Sub Category?"}
        width={"400px"}
        open={handleDeleteSubCategory.open}
        onClose={() => setHandleDeleteSubCategory({ open: false })}
      >
        <Typography>Are you sure you want to Delete Sub Category?</Typography>
        <Box display={"flex"} justifyContent={"end"} gap={"10px"} mt={"20px"}>
          <Box width={"20%"}>
            <CustomButton
              handleClick={() => setHandleDeleteSubCategory({ open: false })}
              border={"1px solid #000000"}
              color={"#000000"}
              title={"No"}
              bgColor={"#F7F7F7"}
              borderRadius={"5px"}
            />
          </Box>
          <Box width={"20%"}>
            <CustomButton
              handleClick={deleteSubCategory}
              type={"submit"}
              title={"Yes"}
              borderRadius={"5px"}
            />
          </Box>
        </Box>
      </CustomDialog>

      {updateSubCategoryStatus.open && (
        <CustomDialog
          title={"Status Confirmation"}
          width={"400px"}
          open={updateSubCategoryStatus.open}
          onClose={() => {
            setUpdateSubCategoryStatus({
              open: false,
              data: {},
            });
            setActiveSubcategory((category) => ({
              ...category,
              status: activeSubcategory?.originalStatus,
            }));
          }}
        >
          <Typography>
            Are you sure you want to{" "}
            {activeSubcategory?.status === "active" ? "in-active" : "Active"}{" "}
            Sub-category
          </Typography>
          <Box display={"flex"} justifyContent={"end"} gap={"10px"} mt={"20px"}>
            <Box width={"20%"}>
              <CustomButton
                border={"1px solid #000000"}
                handleClick={() => setUpdateSubCategoryStatus({ open: false })}
                color={"#000000"}
                title={"No"}
                bgColor={"#F7F7F7"}
                borderRadius={"5px"}
              />
            </Box>
            <Box width={"20%"}>
              <CustomButton
                handleClick={() =>
                  updateSubCategory(updateSubCategoryStatus.data)
                }
                type={"submit"}
                title={"Yes"}
                borderRadius={"5px"}
              />
            </Box>
          </Box>
        </CustomDialog>
      )}
      <Box>
        <Box
          bgcolor={bgcolor ? bgcolor : "#FFEDE3"}
          padding={"20px"}
          borderRadius={"40px 0px 0px 40px"}
        >
          <Box
            display={"flex"}
            width={"100%"}
            justifyContent={"end"}
            alignItems={"right"}
          >
            <Box>
              <CustomButton
                title={"+ Add Sub Category"}
                borderRadius={"5px"}
                handleClick={() => {
                  setCurrentSubCategory({ open: "add" });
                  setShowAddCategoriesModal(true);
                }}
              />
            </Box>
          </Box>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography
              fontFamily={"MetropolisBold"}
              fontSize={"20px"}
              color={"#000000"}
            >
              Sub Categories
            </Typography>
          </Box>
          <Box bgcolor={"#fff"} padding={"10px"} borderRadius={"10px"} mt={2}>
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              width={"100%"}
            >
              <Box width={"25%"}>
                <CustomInput
                  placeHolder={"Search"}
                  borderRadius={"5px"}
                  onChange={handleSearch}
                />
              </Box>
            </Box>

            <Box
              mt={"12px"}
              padding={"5px 10px"}
              borderRadius={"5px"}
              bgcolor={"#FFEDE3"}
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Box width={"10%"}>
                <Typography color={"#000000"} fontFamily={"MetropolisSemiBold"}>
                  Id
                </Typography>
              </Box>
              <Box width={"30%"}>
                <Typography color={"#000000"} fontFamily={"MetropolisSemiBold"}>
                  Sub Category
                </Typography>
              </Box>
              <Box width={"30%"}>
                <Typography color={"#000000"} fontFamily={"MetropolisSemiBold"}>
                  Categories
                </Typography>
              </Box>
              <Box width={"15%"}>
                <Typography color={"#000000"} fontFamily={"MetropolisSemiBold"}>
                  Status
                </Typography>
              </Box>
              <Box width={"15%"}>
                <Typography color={"#000000"} fontFamily={"MetropolisSemiBold"}>
                  Action
                </Typography>
              </Box>
            </Box>
            {loaderForSubCategoryDelete ? (
              <>
                <h1>loading</h1>
              </>
            ) : (
              <>
                {subCategories?.result?.map((item, index) => {
                  const adjustedIndex = index + skip + 1;
                  return (
                    <Box
                      key={index}
                      padding={"5px 10px"}
                      borderRadius={"5px"}
                      bgcolor={"#FFFFFF"}
                      border={"1px solid #FEECE3"}
                      mt={"5px"}
                      display={"flex"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <Box width={"10%"}>
                        <Typography
                          color={"#000000"}
                          fontFamily={"MetropolisMedium"}
                        >
                          {adjustedIndex}
                        </Typography>
                      </Box>
                      <Box width={"30%"}>
                        <Typography
                          color={"#000000"}
                          fontFamily={"MetropolisMedium"}
                        >
                          {item?.name}
                        </Typography>
                      </Box>
                      <Box
                        width={"30%"}
                        display={"flex"}
                        gap={"10px"}
                        alignItems={"center"}
                      >
                        {/* <img src={item.image} alt="image" width={"18px"} /> */}
                        <Typography
                          color={"#000000"}
                          fontFamily={"MetropolisMedium"}
                        >
                          {item?.category?.name}
                        </Typography>
                      </Box>

                      <Box width={"15%"}>
                        <Typography
                          color={"#000000"}
                          fontFamily={"MetropolisMedium"}
                        >
                          <Switch
                            color="warning"
                            checked={item?.status === "active" ? true : false}
                            onChange={(e) => {
                              if (e?.target) {
                                setUpdateSubCategoryStatus({
                                  ...updateSubCategoryStatus,
                                  open: true,
                                  data: {
                                    ...item,
                                    status: e?.target?.checked
                                      ? "active"
                                      : "in-active",
                                  },
                                  isChecked: e?.target?.checked,
                                });
                                setActiveSubcategory((subCategory) => ({
                                  ...subCategory,
                                  originalStatus: item?.status,
                                  status:
                                    item?._id === subCategory?._id
                                      ? e?.target?.checked
                                        ? "active"
                                        : "in-active"
                                      : item?.status,
                                }));
                              }
                            }}
                          />
                        </Typography>
                      </Box>

                      <Box
                        display={"flex"}
                        sx={{ cursor: "pointer" }}
                        gap={"10px"}
                        alignItems={"center"}
                        width={"15%"}
                      >
                        <img
                          src={deleteSvg}
                          alt="image"
                          width={"20px"}
                          onClick={() =>
                            setHandleDeleteSubCategory({
                              data: item,
                              open: true,
                            })
                          }
                        />
                        <img
                          src={EditSvg}
                          alt="image"
                          width={"20px"}
                          onClick={() => {
                            setShowAddCategoriesModal(true);
                            setCurrentSubCategory({
                              ...item,
                              open: "editCategory",
                            });
                          }}
                        />
                      </Box>
                    </Box>
                  );
                })}
              </>
            )}
            <Box mt={2} display={"flex"} justifyContent={"right"}>
              {subCategories?.totalCount > 5 && (
                <Pagination
                  totalCount={subCategories?.totalCount}
                  skip={skip}
                  limit={limit}
                  onPageChange={handlePageChange}
                />
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SubCategoriesList;
