import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import Colors from "../../constants/Colors";
import ProfileHoc from "../../components/ProfileHoc";
import "./index.css";
import { useRef } from "react";

const Refer = (props) => {
  const ReferScreen = (props) => {
    const messageRef = useRef(null);

    const [copyLink, setCopyLink] = useState(false);

    const handleCopyLink = () => {
      navigator.clipboard.writeText(messageRef.current.innerText);
      setCopyLink(true);
    };

    const { userDetails } = props;

    useEffect(()=>{
      console.log(userDetails, "UUUUUUUUUUUUUUU")
    },[])

    return (
      <>
        <div style={{ display: "none" }} ref={messageRef}>
          {/* https://thrillh.com/register?referralCode={userDetails?.referralId} */}
          "Hey!I find this app interesting which is exclusively designed only to
          students & corporates for offers."Please find the below link to use
          referralCode : https://thrillh.com/register?referralCode=
          {userDetails?.referralId}
        </div>

        <Box
          padding={"24px"}
          boxShadow={"0px 0px 16px #67676729"}
          borderRadius={"20px"}
          width={"93.5%"}
          mt={3}
          height={"86.5%"}
          className={"referCard"}
        >
          <p style={styles.header}>Refer</p>
          <Box mt={3}>
            <p style={styles.shareText}>Send link to your friends</p>
            <Box
              display={"flex"}
              alignItems={"center"}
              position={"relative"}
              mt={2}
            >
              <Box
                border={1}
                width={"100%"}
                borderColor={Colors.lightBlue}
                borderRadius={10}
                display={"flex"}
                flexDirection={"row"}
              >
                <p style={styles.link}>
                  https://thrillh.com/register?referralCode=
                  {userDetails?.referralId}
                </p>
              </Box>
              <Box
                sx={{ cursor: "pointer" }}
                onClick={handleCopyLink}
                position={"absolute"}
                right={"0px"}
              >
                <Typography
                  sx={{ textAlign: "center" }}
                  borderRadius={10}
                  fontFamily={"MetropolisMedium"}
                  fontSize={"14px"}
                  color={"#ffff"}
                  padding={"10px 20px"}
                  bgcolor={"#F47821"}
                >
                  {copyLink ? <>Linked Copied</> : <>Copy Link</>}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </>
    );
  };
  return ProfileHoc(ReferScreen);
};

export default Refer;

const styles = {
  header: {
    fontFamily: "MetropolisMedium",
    fontSize: 18,
    color: Colors.textColor,
    margin: 0,
  },
  name: {
    fontFamily: "MetropolisRegular",
    color: Colors.textColor,
    fontSize: 13,
    margin: 0,
  },
  nameValue: {
    fontFamily: "MetropolisRegular",
    color: Colors.lightBlue,
    fontSize: 17,
    margin: 0,
    marginTop: 5,
  },
  profileImg: {
    objectFit: "contain",
  },
  shareText: {
    fontFamily: "MetropolisSemiBold",
    fontSize: 15,
    color: Colors.textColor,
    margin: 0,
  },
  link: {
    fontFamily: "MetropolisRegular",
    fontSize: 14,
    color: Colors.textColor,
    textAlign: "center",
    marginRight: 100,
    marginLeft: 20,
  },
};
