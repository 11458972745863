import { Box, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import eyeSvg from "../../../../../images/orange eye.svg";
import CustomInput from "../../../../components/Input";
import CustomSelect from "../../../../components/Select/CustomSelect";
import CustomButton from "../../../../components/Button";
import { ApiRequest } from "../../../../../services/api-request";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "../../../../../context/useSnackbar";
import Pagination from "../../../../components/Pagination";
import CustomSpinner from "../../../../components/Spinner";
import { useAppContext } from "../../../../../context/AppContext";
import { downloadFile } from "../../../../components/ExportToCSV/export-to-csv";
import {
  ConvertToEpoch,
  ConvertToEpochAndAddOneDay,
  UTCformatDate,
  capitalizeFirstLetter,
} from "../../../../utilities/date-utility";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useCallback } from "react";

const OfferReports = (props) => {
  const { openSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const { handleLogoutSuccess } = useAppContext();

  const { bgcolor } = props;

  const offerType = [
    {
      name: "Online",
      value: "online",
    },
    {
      name: "In-Store",
      value: "in-store",
    },
    {
      name: "All",
      value: "all",
    },
    {
      name: "Top Offers",
      value: "topOffers",
    },
    {
      name: "Featured Offers",
      value: "featured",
    },
    {
      name: "In-store Payable",
      value: "payable",
    },
    {
      name: "In-store Non-Payable",
      value: "non-payable",
    },

    {
      name: "Thrillh-coupon",
      value: "true",
    },
    {
      name: "Without-ThrillhCoupon",
      value: "false",
    },
  ];

  const OfferStatus = [
    {
      name: "Active",
      value: "active",
    },
    {
      name: "In-Active",
      value: "in-active",
    },
  ];

  const [offersList, setOffersList] = useState([]);

  const [offersList1, setOffersList1] = useState([]);

  const [pages, setPages] = useState(1);

  const [CsvOfferList, setCsvOfferList] = useState([]);

  const [loader, setLoader] = useState(false);

  const [filter, setFilter] = useState({
    search: "",
    type: "",
    userType: "",
    withCoupon: "",
    subType: "",
    category: "",
    status: "",
    topOffers: "",
    featuredOffers: "",
  });

  const limit = 1000;

  const [skip, setSkip] = useState(0);

  useEffect(() => {
    getAllOffers();
    setPages(1);
  }, [skip, filter]);

  useEffect(() => {
    let headers = [
      "Id,Name,Offer Type,Students Viewed,Corporate Viewed,Ordered,Redeemed,Status,Offer Code, offer Value,Category,Sub-Category,User Type,Top Offer,City & Locality,Start Date,End Date,Coupon Name,Coupon Value,Vendor Name, vendor Id,Vendor Mobile,Site Url,Address",
    ];
    let offersCsv = [];
    if (Array.isArray(CsvOfferList?.result)) {
      offersCsv = CsvOfferList?.result?.reduce((acc, offer, index) => {
        const {
          name,
          type,
          studentCount,
          corporateCount,
          orderedCount,
          redeemVisitCount,
          status,
          code,
          discount,
          category,
          subCategories,
          toShow,
          enableTopOffer,
          city,
          locality,
          startsAt,
          endsAt,
          coupon,
          vendorName,
          vendorId,
          mobile,
          siteUrl,
          address,
        } = offer;
        const modifiedIndex = index + 1;
        const sub = subCategories?.map((item) => item?.name).join("-");
        const location = `${city}-${locality}`;
        const startDate = UTCformatDate(startsAt).replace(/,/g, " -");
        const endDate = UTCformatDate(endsAt).replace(/,/g, " -");
        const couponName = coupon?.name ? coupon?.name : "NA";
        const couponValue = coupon?.value ? coupon?.value : "NA";
        const discountValue = discount?.value;
        const categoryName = category?.name;
        acc.push(
          [
            modifiedIndex,
            name,
            type,
            studentCount,
            corporateCount,
            orderedCount,
            redeemVisitCount,
            status,
            code,
            discountValue,
            categoryName,
            sub,
            toShow,
            enableTopOffer,
            location,

            startDate,
            endDate,
            couponName,
            couponValue,
            vendorName,
            vendorId,
            mobile,
            siteUrl,
            address,
          ].join(",")
        );
        return acc;
      }, []);

      downloadFile({
        data: [...headers, ...offersCsv].join("\n"),
        fileName: "Offer Reports.csv",
        fileType: "text/csv",
      });
    } else {
    }
  }, [CsvOfferList]);

  const [dateRange, setDateRange] = useState([null, null]);

  const [startDate, endDate] = dateRange;

  const getAllOffers = (fromDate, toDate) => {
    const searchTextParam =
      filter.search && filter.search.trim() !== "" ? filter.search : null;
    setLoader(true);

    const withDatesPayload =
      fromDate && toDate
        ? {
            fromDate: ConvertToEpochAndAddOneDay(fromDate),
            toDate: ConvertToEpochAndAddOneDay(toDate),
          }
        : "";

    const payload = {
      ...withDatesPayload,
      expired: true,
      skip: skip,
      limit: limit,
      ...(filter.search && {
        searchText: searchTextParam,
      }),
      ...(filter.status && {
        status: filter.status,
      }),

      ...(filter.userType && {
        userType: filter.userType,
      }),
      ...(filter.subType && {
        subType: filter.subType,
      }),
      ...(filter.category && {
        categories: [filter.category],
      }),
      ...(filter.withCoupon && {
        withCoupon:
          filter.withCoupon === "true"
            ? true
            : filter.withCoupon === "false"
            ? false
            : null,
      }),
      ...(filter.topOffers && {
        topOffers:
          filter.topOffers === "topOffers"
            ? true
            : filter.topOffers === "false"
            ? false
            : null,
      }),
    };

    if (filter.type && filter.type !== "all") {
      payload.type = filter.type;
    }

    const featuredPayload = {
      ...withDatesPayload,
      skip: 0,
      limit: 32,
      status: "active",
      sort_field: "createdAt",
      sort_order: "desc",
      ...(filter.search && {
        searchText: filter.search,
      }),
      ...(filter.status && {
        status: filter.status,
      }),
      ...(filter.userType && {
        userType: filter.userType,
      }),
      ...(filter.subType && {
        subType: filter.subType,
      }),
      ...(filter.category && {
        categories: [filter.category],
      }),
      ...(filter.withCoupon && {
        withCoupon:
          filter.withCoupon === "true"
            ? true
            : filter.withCoupon === "false"
            ? false
            : null,
      }),
      ...(filter.topOffers && {
        topOffers:
          filter.topOffers === "topOffers"
            ? true
            : filter.topOffers === "false"
            ? false
            : null,
      }),
    };
    if (filter.type && filter.type !== "all") {
      featuredPayload.type = filter.type;
    }
    ApiRequest({
      url: `offer/list`,
      data: filter.featuredOffers ? featuredPayload : payload,
      method: "POST",
    })
      .then((res) => {
        console.log(res, "KKKKKKKKK")
        setOffersList1(res.result);
        var arr = res.result.slice(0, 150)
        var set = {
          result: arr,
          totalCount: res.result.length,
          err: res.err
        }
        setOffersList(set);
        setLoader(false);
      })
      .catch((err) => {
        openSnackbar(err?.response?.data?.message, "error");
        setLoader(false);
        if (err?.statusCode === 403 || err?.statusCode === 401) {
          if (navigate) {
            navigate("/login"); // Navigate to "/login" route
            handleLogoutSuccess();
          }
        }
      });
  };

  const handleSearch = (e) => {
    const searchValue = e.target.value;
    setFilter((prevFilter) => ({
      ...prevFilter,
      search: searchValue.length ? searchValue : null,
    }));
  };

  const onSelectedType = (e) => {
    const selectedType = e.target.value;
    const type = ["online", "in-store", "all"];
    const subType = ["payable", "non-payable"];
    const coupon = ["true", "false"];
    const topOffers = ["topOffers"];
    const featuredOffers = ["featured"];
    if (type.includes(selectedType)) {
      setFilter((prevFilter) => ({
        ...prevFilter,
        type: selectedType === "all" ? "all" : selectedType,
        withCoupon: "",
        subType: "",
        topOffers: "",
        featuredOffers: "",
      }));
    } else if (subType.includes(selectedType)) {
      setFilter((prevFilter) => ({
        ...prevFilter,
        subType: selectedType,
        type: "",
        withCoupon: "",
        topOffers: "",
        featuredOffers: "",
      }));
    } else if (coupon.includes(selectedType)) {
      setFilter((prevFilter) => ({
        ...prevFilter,
        withCoupon: selectedType,
        type: "",
        subType: "",
        topOffers: "",
        featuredOffers: "",
      }));
    } else if (topOffers.includes(selectedType)) {
      setFilter((prevFilter) => ({
        ...prevFilter,
        topOffers: selectedType,
        withCoupon: "",
        type: "",
        subType: "",
        featuredOffers: "",
      }));
    } else if (featuredOffers.includes(selectedType)) {
      setFilter((prevFilter) => ({
        ...prevFilter,
        topOffers: "",
        withCoupon: "",
        type: "",
        subType: "",
        featuredOffers: selectedType,
      }));
    }
  };

  const clearFilters = () => {
    setFilter({
      search: "",
      type: "",
      withCoupon: "",
      subType: "",
      userType: "",
      category: "",
      status: "",
      featuredOffers: "",
      topOffers: "",
    });
    getAllOffers({
      search: "",
      type: "",
      category: "",
      status: "",
      withCoupon: "",
      subType: "",
      userType: "",
      featuredOffers: "",
      topOffers: "",
    });
    setDateRange([]);
  };

  const handlePageChange = (page) => {
    setPages(page);
    console.log(offersList1, page, ((page-1)*150), (page*150), "QQQQQQQQQQQQQQQQ")
    var res = offersList1
    var arr = res.slice(((page-1)*150), (page*150))
    console.log(res.slice(((page-1)*150), (page*150)));
    var set = {
      result: arr,
      totalCount: offersList.totalCount,
      err: offersList.err
    }
    setOffersList(set);
    // const newSkip = (page - 1) * limit;
    // setSkip(newSkip);
  };

  const onSelectedOfferStatus = (e) => {
    const selectedStatus = e.target.value;
    const activeStatus = ["active", "in-active"];
    if (activeStatus.includes(selectedStatus)) {
      setFilter((prevFilter) => ({
        ...prevFilter,
        status: selectedStatus,
      }));
    }
  };

  const exportToCsv = useCallback(() => {
    const payload = {
      limit: 1000000000,
      skip: 0,
      expired: true,
    };

    ApiRequest({
      url: "offer/list",
      method: "POST",
      data: payload,
    })
      .then((res) => {
        setCsvOfferList(res);
      })
      .catch((err) => {
        // Handle errors if needed
      });
  }, []);
  const handleChange = (update) => {
    setDateRange(update);
    const [startDate, endDate] = update;
    const tomorrowEpoch = new Date(endDate).toISOString().slice(0, 10);
    const startDateString = new Date(startDate).toISOString().slice(0, 10);
    getAllOffers(
      ConvertToEpoch(startDateString),
      ConvertToEpochAndAddOneDay(tomorrowEpoch)
    );
  };

  return (
    <>
      <Box>
        <Box
          bgcolor={bgcolor ? bgcolor : "#FFEDE3"}
          padding={"20px"}
          borderRadius={"40px 0px 0px 40px"}
        >
          <Box
            display={"flex"}
            width={"100%"}
            justifyContent={"end"}
            alignItems={"right"}
          >
            <Box width={"10%"}>
              <CustomButton
                title={"+ Export CSV"}
                handleClick={exportToCsv}
                borderRadius={"5px"}
              />
            </Box>
          </Box>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography
              fontFamily={"MetropolisBold"}
              fontSize={"20px"}
              color={"#000000"}
            >
              Offers Reports
            </Typography>
          </Box>
          <Box bgcolor={"#fff"} padding={"10px"} borderRadius={"10px"} mt={2}>
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"end"}
              width={"100%"}
            >
              <Box width={"25%"}>
                <CustomInput
                  value={filter.search}
                  placeHolder={"Search with name"}
                  borderRadius={"5px"}
                  onChange={handleSearch}
                />
              </Box>
              <Box
                display={"flex"}
                alignItems={"baseline"}
                justifyContent={"end"}
                width={"75%"}
                gap={"10px"}
              >
                <Box>
                  <DatePicker
                    onChange={handleChange}
                    selectsRange={true}
                    startDate={startDate}
                    dateFormat="dd-MM-yyyy"
                    endDate={endDate}
                    placeholderText="Select Date Range"
                    className="datePickerReactPackage"
                  />
                </Box>
                <Box width={"20%"}>
                  <CustomSelect
                    value={`${filter.status}`}
                    placeHolder={"Offer Status"}
                    options={OfferStatus}
                    borderRadius={"5px"}
                    onChange={(e) => onSelectedOfferStatus(e)}
                  />
                </Box>

                <Box width={"20%"}>
                  <CustomSelect
                    value={`${filter.subType}${filter.withCoupon}${filter.type}${filter.featuredOffers}${filter.topOffers}`}
                    placeHolder={"Filter by Offer type"}
                    options={offerType}
                    borderRadius={"5px"}
                    onChange={(e) => onSelectedType(e)}
                  />
                </Box>

                <Box width={"10%"} display={"flex"} alignItems={"end"}>
                  <CustomButton title={"Clear"} handleClick={clearFilters} />
                </Box>
              </Box>
            </Box>

            <Box
              mt={"12px"}
              padding={"5px 10px"}
              borderRadius={"5px"}
              bgcolor={"#FFEDE3"}
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Box width={"10%"}>
                <Typography color={"#000000"} fontFamily={"MetropolisSemiBold"}>
                  Id
                </Typography>
              </Box>
              <Box width={"20%"}>
                <Typography color={"#000000"} fontFamily={"MetropolisSemiBold"}>
                  Offer Title
                </Typography>
              </Box>
              <Box width={"10%"}>
                <Typography color={"#000000"} fontFamily={"MetropolisSemiBold"}>
                  Type
                </Typography>
              </Box>
              <Box width={"15%"}>
                <Typography color={"#000000"} fontFamily={"MetropolisSemiBold"}>
                  St. Viewed
                </Typography>
              </Box>
              <Box width={"15%"}>
                <Typography color={"#000000"} fontFamily={"MetropolisSemiBold"}>
                  Cor. Viewed
                </Typography>
              </Box>
              <Box width={"10%"}>
                <Typography color={"#000000"} fontFamily={"MetropolisSemiBold"}>
                  Ordered
                </Typography>
              </Box>
              <Box width={"15%"}>
                <Typography color={"#000000"} fontFamily={"MetropolisSemiBold"}>
                  Redeemed
                </Typography>
              </Box>
              <Box width={"10%"}>
                <Typography color={"#000000"} fontFamily={"MetropolisSemiBold"}>
                  Status
                </Typography>
              </Box>
              <Box width={"5%"}>
                <Typography color={"#000000"} fontFamily={"MetropolisSemiBold"}>
                  Action
                </Typography>
              </Box>
            </Box>
            {loader ? (
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                height={"300px"}
              >
                <CustomSpinner />
              </Box>
            ) : (
              <>
                {offersList?.result?.map((item, index) => {
                  // const adjustedIndex = index + skip + 1;
                  const adjustedIndex = ((pages-1) * 150) + 1 + index;
                  return (
                    <Box
                      key={index}
                      padding={"5px 10px"}
                      borderRadius={"5px"}
                      bgcolor={"#FFFFFF"}
                      border={"1px solid #FEECE3"}
                      mt={"5px"}
                      display={"flex"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <Box width={"10%"}>
                        <Typography
                          color={"#000000"}
                          fontFamily={"MetropolisMedium"}
                        >
                          {adjustedIndex}
                        </Typography>
                      </Box>
                      <Box width={"20%"}>
                        <Typography
                          color={"#000000"}
                          fontFamily={"MetropolisMedium"}
                        >
                          {item.name}
                        </Typography>
                      </Box>
                      <Box width={"10%"}>
                        <Typography
                          color={"#000000"}
                          fontFamily={"MetropolisMedium"}
                        >
                          {item.type}
                        </Typography>
                      </Box>

                      <Box width={"15%"}>
                        <Typography
                          color={"#000000"}
                          fontFamily={"MetropolisMedium"}
                        >
                          {item?.studentCount ?? "NA"}
                        </Typography>
                      </Box>
                      <Box width={"15%"}>
                        <Box display={"flex"} gap={"10px"}>
                          <Typography
                            color={"#000000"}
                            fontFamily={"MetropolisMedium"}
                          >
                            {item?.corporateCount ?? "NA"}
                          </Typography>
                        </Box>
                      </Box>

                      <Box width={"10%"}>
                        <Typography
                          color={"#000000"}
                          fontFamily={"MetropolisMedium"}
                        >
                          {item?.orderedCount ?? "NA"}
                        </Typography>
                      </Box>
                      <Box width={"15%"}>
                        <Typography
                          color={"#000000"}
                          fontFamily={"MetropolisMedium"}
                        >
                          {item?.redeemVisitCount ?? "NA"}
                        </Typography>
                      </Box>
                      <Box width={"10%"}>
                        <Typography
                          color={"#000000"}
                          fontFamily={"MetropolisMedium"}
                        >
                          {capitalizeFirstLetter(item?.status)}
                        </Typography>
                      </Box>

                      <Box
                        display={"flex"}
                        sx={{ cursor: "pointer" }}
                        gap={"10px"}
                        alignItems={"center"}
                        width={"5%"}
                      >
                        <img
                          src={eyeSvg}
                          alt=""
                          width={"20px"}
                          onClick={() =>
                            navigate(`/admin/offer/${item?.name}/${item?._id}`)
                          }
                        />
                      </Box>
                    </Box>
                  );
                })}
                <Box mt={2} display={"flex"} justifyContent={"right"}>
                  {offersList?.totalCount > 5 && (
                    <Pagination
                      totalCount={Number(offersList?.totalCount)}
                      skip={(pages-1)*150}
                      limit={150}
                      onPageChange={handlePageChange}
                    />
                  )}
                </Box>
              </>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default OfferReports;
