import React from "react";
import { Box } from "@mui/material";
import CustomInput from "../../../components/Input";
import CustomButton from "../../../components/Button";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ApiRequest } from "../../../../services/api-request";
import { useSnackbar } from "../../../../context/useSnackbar";

const schema = yup
  .object({
    jobTitle: yup.string().required("Job Title  is required"),
    jobDescription: yup.string().required("Job Description  is required"),
  })
  .required();

const AddEditCareers = (props) => {
  const { isEdit, careerList } = props;

  const { openSnackbar } = useSnackbar();

  const { handleSubmit, control } = useForm({
    resolver: yupResolver(schema),
    defaultValues:
      !isEdit && !careerList
        ? {
            jobTitle: "",
            jobDescription: "",
          }
        : {
            jobTitle: careerList?.jobTitle,
            jobDescription: careerList?.jobDescription,
          },
  });

  const onSubmit = (data) => {
    const modifiedFormData = {
      jobTitle: data.jobTitle,
      jobDescription: data.jobDescription,
    };
    if (isEdit && careerList?._id) {
      ApiRequest({
        url: `career/update/${careerList._id}`,
        data: modifiedFormData,
        method: "PUT",
      })
        .then((res) => {
          if (res) {
            openSnackbar("career has been updated successfully", "success");
          }
          props.getAllCareererList();
          props.onClose();
        })
        .catch((err) => {});
    } else {
      ApiRequest({
        url: `career/create`,
        data: modifiedFormData,
        method: "POST",
      })
        .then((res) => {
          if (res) {
            openSnackbar("career has been created successfully", "success");
          }
          props.getAllCareererList();
          props.onClose();
        })
        .catch((err) => {});
    }
  };

  return (
    <>
      <Box>
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <Box display={"flex"} alignItems={"center"} gap={"10px"}>
            <Box mt={2} width={"100%"}>
              <Controller
                name="jobTitle"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <CustomInput
                    {...field}
                    inputLabel={"Enter Job Title"}
                    borderRadius={"5px"}
                    type={"text"}
                    error={!!error}
                    helperText={error ? error.message : ""}
                  />
                )}
              />
            </Box>
          </Box>
          <Box display={"flex"} alignItems={"center"} gap={"10px"}>
            <Box mt={2} width={"100%"}>
              <Controller
                name="jobDescription"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <CustomInput
                    inputLabel={"Enter Job Description"}
                    multiline={true}
                    type={"text"}
                    borderRadius={"5px"}
                    {...field}
                    error={!!error}
                    helperText={error ? error.message : ""}
                  />
                )}
              />
            </Box>
          </Box>
          <Box display={"flex"} justifyContent={"end"} gap={"10px"} mt={"20px"}>
            <Box width={"20%"}>
              <CustomButton
                border={"1px solid #000000"}
                color={"#000000"}
                title={"Cancel"}
                bgColor={"#F7F7F7"}
                handleClick={() => props.onClose()}
                borderRadius={"5px"}
              />
            </Box>
            <Box width={"20%"}>
              <CustomButton
                title={"Save"}
                type={"submit"}
                borderRadius={"5px"}
              />
            </Box>
          </Box>
        </form>
      </Box>
    </>
  );
};

export default AddEditCareers;
