import React, { useState, useEffect } from "react";
import CustomButton from "../../components/Button";
import { Box, Grid } from "@mui/material";
import OtpInput from "react-otp-input";
import Colors from "../../constants/Colors";
import { ApiRequest } from "../../../services/api-request";
import { useSnackbar } from "../../../context/useSnackbar";
import { useNavigate } from "react-router-dom";
import CustomSpinner from "../../components/Spinner";

const GoogleOTPForm = (props) => {
  const { googleRegisterResponseDetails, profile } = props;

  const [otp, setOtp] = useState("");

  const [timer, setTimer] = useState(30);

  const [loader, setLoader] = useState(false);

  useEffect(() => {
    const countdown = setInterval(() => {
      setTimer((prevTimer) => (prevTimer > 0 ? prevTimer - 1 : prevTimer));
    }, 1000);

    return () => {
      clearInterval(countdown);
    };
  }, []);

  const { openSnackbar } = useSnackbar();

  const navigate = useNavigate();

  const handleOtpSubmit = (data) => {
    setLoader(true);
    data.preventDefault();
    setTimeout(() => {
      ApiRequest({
        url: `user/verify-otp`,
        data: {
          otp: Number(otp),
          otp_token: googleRegisterResponseDetails?.otp_token,
        },
        method: "POST",
      })
        .then((res) => {
          setLoader(false);
          openSnackbar(
            res?.success === false
              ? "INVALID OTP"
              : "Mobile verification Success",
            `${res?.success === false ? "error" : "success"}`
          );
          if (res?.success === true) {
            const payload = {
              email: profile?.email,
              gid: profile?.id,
              mobile: googleRegisterResponseDetails?.payload?.mobile,
              userRole: googleRegisterResponseDetails?.payload?.userRole,
              name: googleRegisterResponseDetails?.payload?.profileName,
            };
            ApiRequest({
              url: `user/register-google`,
              data: payload,
              method: "POST",
            })
              .then((res) => {
                if (res?.role?.code === "student") {
                  navigate(`/register/student/${res?._id}/form`);
                } else {
                  navigate(`/register/corporate/${res?._id}/form`);
                }
              })
              .catch((err) => {
                openSnackbar(err?.response?.data?.message, "error");
              });
          }
        })
        .catch((err) => {
          setLoader(false);
          openSnackbar(err?.response?.data?.message, "error");
        });
    }, 200);
  };
  return (
    <>
      <Box>
        <h2 style={styles.heading}>Enter Otp</h2>
        <p style={styles.subHeading}>Thrillh offers exclusively Lorem ipsum</p>
        <form onSubmit={handleOtpSubmit}>
          <Grid
            container
            justifyContent={"center"}
            alignItems={"center"}
            gap={6}
          >
            <Grid item>
              <p style={styles.currentMobile}>
                {googleRegisterResponseDetails?.payload?.mobile}
              </p>
            </Grid>
          </Grid>
          <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
            <OtpInput
              value={otp}
              onChange={(e) => setOtp(e)}
              numInputs={6}
              isInputNum={true}
              shouldAutoFocus={true}
              focusStyle={{ outline: "none" }}
              separator={<Box m={"3px"} />}
              inputStyle={styles.inputStyle}
              containerStyle={styles.otpContainer}
            />
          </Box>
          {loader ? (
            <>
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                height={"100px"}
              >
                <CustomSpinner size={"1rem"} />
              </Box>
            </>
          ) : (
            <Box mt={4}>
              <CustomButton
                padding={"15.5px"}
                title={"Submit"}
                disabled={otp?.length === 6 ? false : true}
                borderButton={false}
                type={"submit"}
              />
            </Box>
          )}
          <Box mt={2}>
            <CustomButton
              padding={"15.5px"}
              title={"Request Otp"}
              borderButton={true}
              disabled={timer === 0 ? false : true}
              handleClick={(data) =>
                props.requestOTP({
                  mobile: googleRegisterResponseDetails?.payload?.mobile,
                })
              }
            />
          </Box>
          <Box mt={4}>
            <p style={styles.skipText}>Request OTP in {timer} Secs</p>
          </Box>
        </form>
      </Box>
    </>
  );
};
const styles = {
  authBg: {
    height: "100vh",
  },
  innerAuthBg: {
    width: 500,
    height: 500,
  },
  heading: {
    fontFamily: "MetropolisExtraBold",
    color: Colors.textColor,
  },
  subHeading: {
    fontFamily: "MetropolisLight",
    color: Colors.textColor,
    fontSize: 13,
  },
  inputStyle: {
    fontFamily: "MetropolisRegular",
    width: 60,
    height: 40,
    borderColor: Colors.lightBlue,
    borderRadius: 30,
    borderWidth: 1,
    fontSize: 14,
  },
  otpContainer: {
    marginTop: 30,
  },
  currentMobile: {
    fontFamily: "MetropolisSemiBold",
    fontSize: 18,
    color: "#000000",
  },
  changeText: {
    fontFamily: "MetropolisMedium",
    fontSize: 14,
    color: Colors.lightBlue,
    textDecoration: "underline",
  },
  skipText: {
    fontFamily: "MetropolisSemiBold",
    color: Colors.lightBlue,
    fontSize: 13,
    textAlign: "center",
  },
};
export default GoogleOTPForm;
