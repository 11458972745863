import { Box, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import CustomButton from "../../components/Button";
import Switch from "@mui/material/Switch";
import { useParams } from "react-router-dom";
import { ApiRequest } from "../../../services/api-request";
import CustomDialog from "../../components/Dialog/custom-dialog";
import { useNavigate } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./offer-view.css";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useAppContext } from "../../../context/AppContext";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import moment from "moment-timezone";
const OfferView = () => {
  const {  offerId } = useParams();

  const navigate = useNavigate();

  const { handleLogoutSuccess } = useAppContext();

  const [offerDetails, setOfferDetails] = useState({});

  useEffect(() => {
    getOfferDetails();
  }, []);

  const getOfferDetails = () => {
    ApiRequest({
      url: `offer/find/${offerId}`,
      method: "GET",
    })
      .then((res) => {
        setOfferDetails(res);
      })
      .catch((err) => {
        if (err?.statusCode === 403 || err?.statusCode === 401) {
          if (navigate) {
            navigate("/login"); // Navigate to "/login" route
            handleLogoutSuccess();
          }
        }
      });
  };

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  const { user } = useAppContext();

  const [active, setActive] = useState({});

  const [updateOfferDetails, setUpdateOfferDetails] = useState({
    open: false,
    data: {},
  });

  const updateOfferStatus = (data) => {
    const newStatus = {
      status: data?.status,
    };
    ApiRequest({
      url: `offer/${offerDetails?._id}/status`,
      data: newStatus,
      method: "PUT",
    })
      .then((res) => {
        setUpdateOfferDetails({
          open: false,
          data: {},
        });
        getOfferDetails();
      })
      .catch((err) => {
        // alert(err?.response?.data?.message);
        if (err?.statusCode === 403 || err?.statusCode === 401) {
          if (navigate) {
            navigate("/login"); // Navigate to "/login" route
            handleLogoutSuccess();
          }
        }
      });
  };

  const conditions = (condition) => {
    if (condition?.type === "maximum-bill") {
      const maximumBill = `${condition?.name}
      ${condition?.type} ${condition?.value}`;
      return maximumBill;
    } else if (condition?.type === "minimum-bill") {
      const minimumBill = `${condition?.name}\n 
      ${condition?.type}${condition?.value}`;
      return minimumBill;
    } else if (condition?.type === "text") {
      return condition?.name;
    } else if (condition?.type === "days") {
      const days = `${condition?.name}\n${condition?.days
        ?.map((day) => day)
        .join(", ")}`;
      return days;
    } else if (!condition?.type) {
      return `${condition?.name}`;
    }
  };

  const istDateTimeEndsAt = moment.utc(offerDetails?.endsAt).tz("Asia/Kolkata");

  const istDateEndsAt = moment(istDateTimeEndsAt, "YYYY-MM-DDTHH:mm:ss.SSS[Z]");

  const istDateTimeStartsAt = moment
    .utc(offerDetails?.startsAt)
    .tz("Asia/Kolkata");

  const istDateStartsAt = moment(
    istDateTimeStartsAt,
    "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
  );

  return (
    <>
      {updateOfferDetails.open && (
        <CustomDialog
          title={
            offerDetails?.status === "active"
              ? "Inactive Offer Status"
              : "Active Offer Status"
          }
          width={"400px"}
          open={updateOfferDetails.open}
          onClose={() => {
            setUpdateOfferDetails({
              open: false,
              data: {},
            });
            setActive((offer) => ({
              ...offer,
              status: active?.originalStatus,
            }));
          }}
        >
          <Typography>
            Are you sure you want to
            {/* {updateOfferDetails?.status === "active"
              ? "active"
              : "Inactive"}{" "} */}
              {" "}{offerDetails?.status === "active" ? "Inactive" : "Active"}{" "}
            Offer
          </Typography>
          <Box display={"flex"} justifyContent={"end"} gap={"10px"} mt={"20px"}>
            <Box width={"20%"}>
              <CustomButton
                handleClick={() => setUpdateOfferDetails({ open: false })}
                border={"1px solid #000000"}
                color={"#000000"}
                title={"No"}
                bgColor={"#F7F7F7"}
                borderRadius={"5px"}
              />
            </Box>
            <Box width={"20%"}>
              <CustomButton
                handleClick={() => updateOfferStatus(updateOfferDetails.data)}
                type={"submit"}
                title={"Yes"}
                borderRadius={"5px"}
              />
            </Box>
          </Box>
        </CustomDialog>
      )}
      <Box
        bgcolor={"#FFEDE3"}
        padding={"20px"}
        borderRadius={"40px 0px 0px 40px"}
      >
        <Box
          display={"flex"}
          width={"100%"}
          justifyContent={"space-between"}
          alignItems={"right"}
        >
          <Typography
            fontFamily={"MetropolisBold"}
            fontSize={"20px"}
            color={"#000000"}
          >
            Offers View
          </Typography>
        </Box>
        <Box
          mt={2}
          boxShadow={"0px 0px 16px #67676729"}
          bgcolor={"#fff"}
          borderRadius={"30px"}
          padding={"15px 0px"}
        >
          <Box pl={2} pr={2}>
            {offerDetails?.coverImages?.length > 0 && (
              <Carousel
                swipeable={true}
                draggable={true}
                showDots={false}
                autoPlaySpeed={3000}
                responsive={responsive}
                className={"all-ban"}
                customTransition="all 0.3s"
                autoPlay={true}
                infinite={true}
              >
                {offerDetails?.coverImages?.length > 0 &&
                  offerDetails.coverImages.map((image, index) => (
                    <img
                      className="car-img"
                      key={index} // Make sure to use a unique key for each element in the array
                      src={image}
                      alt={`Cover Image ${index}`}
                      width={"100%"}
                      style={{
                        objectFit: "contain",

                        opacity: "0.5",
                      }}
                    />
                  ))}
              </Carousel>
            )}
          </Box>
          <Box padding={"15px"}>
            <Typography
              fontFamily={"MetropolisSemiBold"}
              fontSize={"18px"}
              color={"#000000"}
            >
              {offerDetails?.name}
            </Typography>
            <Box mt={2} display={"flex"}>
              <Box width={"33.33%"}>
                <Typography fontFamily={"MetropolisMedium"} fontSize={"14px"}>
                  <b>Offer for: </b>
                  {offerDetails?.toShow}
                </Typography>
              </Box>
              <Box width={"33.33%"}>
                <Typography fontFamily={"MetropolisMedium"} fontSize={"14px"}>
                  <b>Category : </b>
                  {offerDetails?.category?.name ?? "NA"}
                </Typography>
              </Box>
              <Box width={"33.33%"}>
                <Typography fontFamily={"MetropolisMedium"} fontSize={"14px"}>
                  <b>Sub Category : </b>{" "}
                  {offerDetails?.subCategories?.map((item) => item?.name) ??
                    "NA"}
                </Typography>
              </Box>
            </Box>
            <Box mt={2} display={"flex"}>
              <Box width={"33.33%"}>
                <Typography fontFamily={"MetropolisMedium"} fontSize={"14px"}>
                  <b>Offer Type : </b>
                  {offerDetails?.type}
                </Typography>
              </Box>
              {offerDetails?.code && (
                <Box width={"33.33%"}>
                  <Typography fontFamily={"MetropolisMedium"} fontSize={"14px"}>
                    <b>Offer code : </b>
                    {offerDetails?.code}
                  </Typography>
                </Box>
              )}
              <Box width={"33.33%"}>
                <Typography fontFamily={"MetropolisMedium"} fontSize={"14px"}>
                  <b>Discount : </b>
                  {offerDetails.discount?.type === "price"
                    ? `₹${offerDetails.discount?.value}`
                    : `${offerDetails.discount?.value}%`}
                </Typography>
              </Box>
            </Box>
            <Box mt={2} display={"flex"} alignItems={"baseline"}>
              <Box width={"33.33%"}>
                <Typography fontFamily={"MetropolisMedium"} fontSize={"14px"}>
                  <b>Start Date : </b>
                  {istDateStartsAt.format("DD-MM-YYYY")}
                </Typography>
              </Box>
              <Box width={"33.33%"}>
                <Typography fontFamily={"MetropolisMedium"} fontSize={"14px"}>
                  <b>End Date : </b>
                  {istDateEndsAt.format("DD-MM-YYYY")}
                </Typography>
              </Box>
              {user?.role?.code === "admin" && (
                <Box width={"33.33%"}>
                  <Typography fontFamily={"MetropolisMedium"} fontSize={"14px"}>
                    <b>Status</b> :{" "}
                    <Switch
                      color="warning"
                      checked={offerDetails?.status === "active" ? true : false}
                      onChange={(e) => {
                        if (e?.target) {
                          setUpdateOfferDetails({
                            ...updateOfferDetails,
                            open: true,
                            data: {
                              ...offerDetails,
                              status: e?.target?.checked
                                ? "active"
                                : "in-active",
                            },
                            isChecked: e?.target?.checked,
                          });
                          setActive((offer) => ({
                            ...offer,
                            originalStatus: offerDetails?.status,
                            status:
                              offerDetails?._id === offer?._id
                                ? e?.target?.checked
                                  ? "active"
                                  : "in-active"
                                : offerDetails?.status,
                          }));
                        }
                      }}
                    />
                  </Typography>
                </Box>
              )}
            </Box>

            {offerDetails?.coupon?.value > 0 && (
              <Box display={"flex"} mt={2}>
                <Box width={"33.33%"}>
                  <Typography fontFamily={"MetropolisMedium"} fontSize={"14px"}>
                    <b>Coupon Value : </b>
                    {offerDetails?.coupon?.value}
                  </Typography>
                </Box>
              </Box>
            )}
            {offerDetails?.subType && (
              <Box width={"33.33%"} mt={2}>
                <Typography fontFamily={"MetropolisMedium"} fontSize={"14px"}>
                  <b>Sub Type : </b>
                  {offerDetails?.subType}
                </Typography>
              </Box>
            )}

            <Box display={"flex"}>
              {offerDetails?.vendorId?.length > 0 && (
                <Box width={"33.33%"} mt={2}>
                  <Typography fontFamily={"MetropolisMedium"} fontSize={"14px"}>
                    <b>Vendor Id : </b>
                    {offerDetails?.vendorId}
                  </Typography>
                </Box>
              )}
              {offerDetails?.vendorName?.length > 0 && (
                <Box width={"33.33%"} mt={2}>
                  <Typography fontFamily={"MetropolisMedium"} fontSize={"14px"}>
                    <b>Vendor Name : </b>
                    {offerDetails?.vendorName}
                  </Typography>
                </Box>
              )}
              {offerDetails?.mobile?.length > 0 && (
                <Box width={"33.33%"} mt={2}>
                  <Typography fontFamily={"MetropolisMedium"} fontSize={"14px"}>
                    <b>Vendor Mobile : </b>
                    {offerDetails?.mobile}
                  </Typography>
                </Box>
              )}
            </Box>

            <Box display={"flex"}>
              <Box width={"33.33%"} mt={2}>
                <Typography fontFamily={"MetropolisMedium"} fontSize={"14px"}>
                  <b>Corporate Visit Count : </b>
                  {offerDetails?.corporateCount ?? 0}
                </Typography>
              </Box>

              <Box width={"33.33%"} mt={2}>
                <Typography fontFamily={"MetropolisMedium"} fontSize={"14px"}>
                  <b>Student Visit Count : </b>
                  {offerDetails?.studentCount ?? 0}
                </Typography>
              </Box>
              {offerDetails?.type === "in-store" &&
                offerDetails?.subType === "payable" && (
                  <Box width={"33.33%"} mt={2}>
                    <Typography
                      fontFamily={"MetropolisMedium"}
                      fontSize={"14px"}
                    >
                      <b>Offer Ordered Count : </b>
                      {offerDetails?.orderedCount ?? 0}
                    </Typography>
                  </Box>
                )}
            </Box>
            {offerDetails?.type === "in-store" &&
              offerDetails?.subType === "non-payable" && (
                <Box width={"33.33%"} mt={2}>
                  <Typography fontFamily={"MetropolisMedium"} fontSize={"14px"}>
                    <b>Users Opened: </b>
                    {offerDetails?.redeemVisitCount ?? 0}
                  </Typography>
                </Box>
              )}

            <Box display={"flex"} mt={2}>
              {offerDetails?.address && (
                <Box width={"33.33%"}>
                  <Typography fontFamily={"MetropolisMedium"} fontSize={"14px"}>
                    <b>Address : </b>
                    {offerDetails?.address ?? "NA"}
                  </Typography>
                </Box>
              )}
              {offerDetails?.city?.length > 0 && (
                <Box width={"33.33%"}>
                  <Typography fontFamily={"MetropolisMedium"} fontSize={"14px"}>
                    <b>Cities : </b>

                    {offerDetails?.city?.map((item) => item).join(", ") ?? "NA"}
                  </Typography>
                </Box>
              )}
              {offerDetails?.locality?.length > 0 && (
                <Box width={"33.33%"}>
                  <Typography fontFamily={"MetropolisMedium"} fontSize={"14px"}>
                    <b>Localities : </b>
                    {offerDetails?.locality?.map((item) => item).join(", ") ??
                      "NA"}
                  </Typography>
                </Box>
              )}

              {offerDetails?.siteUrl && (
                <Box width={"33.33%"}>
                  <Typography
                    fontFamily={"MetropolisMedium"}
                    sx={{ wordWrap: "break-word" }}
                    fontSize={"14px"}
                  >
                    <b>Site Url : </b>
                    {offerDetails?.siteUrl}
                  </Typography>
                </Box>
              )}
              {offerDetails?.coupon?.name && (
                <Box width={"33.33%"}>
                  <Typography fontFamily={"MetropolisMedium"} fontSize={"14px"}>
                    <b>Coupon Code : </b>
                    {offerDetails?.coupon?.name}
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
        {offerDetails?.usages?.length > 0 && (
          <Box
            mt={2}
            boxShadow={"0px 0px 16px #67676729"}
            bgcolor={"#fff"}
            borderRadius={"18px"}
            padding={"15px"}
          >
            <Typography fontFamily={"MetropolisBold"} fontSize={"16px"}>
              Usages
            </Typography>
            {offerDetails?.usages?.map((usage, index) => {
              return (
                <>
                  <Box display={"flex"} gap={"5px"} alignItems={"center"}>
                    <FiberManualRecordIcon sx={{ fontSize: "10px" }} />
                    <Typography
                      fontFamily={"MetropolisMedium"}
                      fontSize={"14px"}
                      key={index}
                      className="condition"
                    >
                      {usage}
                    </Typography>
                  </Box>
                </>
              );
            })}
          </Box>
        )}
        {offerDetails?.discount?.conditions?.length > 0 && (
          <Box
            mt={2}
            boxShadow={"0px 0px 16px #67676729"}
            bgcolor={"#fff"}
            borderRadius={"18px"}
          >
            <Box padding={"15px"}>
              <Typography fontFamily={"MetropolisBold"} fontSize={"16px"}>
                Conditions
              </Typography>
              {offerDetails?.discount?.conditions?.length > 0 && (
                <Box mt={"10px"} width={"100%"}>
                  <Box mt={"15px"}>
                    {offerDetails?.discount?.conditions?.length > 0 &&
                      offerDetails?.discount?.conditions?.map((condition) => {
                        if (
                          !Object.keys(condition).length ||
                          !condition.description
                        ) {
                          return null; // Skip rendering if the condition is empty
                        }
                        return (
                          <Box
                            display={"flex"}
                            alignItems={"center"}
                            gap={"5px"}
                            mt={
                              offerDetails?.discount?.conditions?.length > 1
                                ? "10px"
                                : null
                            }
                          >
                            <FiberManualRecordIcon sx={{ fontSize: "10px" }} />
                            <Box display={"flex"} flexDirection={"column"}>
                              <p className="condition">
                                {conditions(condition)}
                              </p>
                              <Typography className="conditionDescription">
                                {condition?.description}
                              </Typography>
                            </Box>
                          </Box>
                        );
                      })}
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        )}

        {offerDetails?.description && (
          <Box
            mt={2}
            boxShadow={"0px 0px 16px #67676729"}
            bgcolor={"#fff"}
            borderRadius={"18px"}
          >
            <Box padding={"15px"}>
              <Typography fontFamily={"MetropolisBold"} fontSize={"16px"}>
                Offer Description
              </Typography>
              <Typography
                fontFamily={"MetropolisMedium"}
                fontSize={"14px"}
                style={{ margin: "5px 0px" }}
              >
                {offerDetails?.description}
              </Typography>
            </Box>
          </Box>
        )}

        {offerDetails?.partnerDescription && (
          <Box
            mt={2}
            boxShadow={"0px 0px 16px #67676729"}
            bgcolor={"#fff"}
            borderRadius={"18px"}
          >
            <Box padding={"15px"}>
              <Typography
                fontFamily={"MetropolisBold"}
                fontSize={"16px"}
                style={{ margin: "5px 0px" }}
              >
                Partner Description
              </Typography>
              <Typography fontFamily={"MetropolisMedium"} fontSize={"14px"}>
                {offerDetails?.partnerDescription}
              </Typography>
            </Box>
          </Box>
        )}

        {offerDetails?.faqs?.length > 0 && (
          <Box
            mt={2}
            boxShadow={"0px 0px 16px #67676729"}
            bgcolor={"#fff"}
            borderRadius={"18px"}
          >
            <Box padding={"15px"}>
              <Typography fontFamily={"MetropolisBold"} fontSize={"16px"}>
                FAQs
              </Typography>
              <>
                {offerDetails?.faqs?.map((item) => {
                  return (
                    // <Box>
                    //   <ul style={{ margin: "5px" }}>
                    //     <Box
                    //       display={"flex"}
                    //       justifyContent={"space-between"}
                    //       alignItems={"center"}>
                    //       <li
                    //         style={{
                    //           fontFamily: "MetropolisSemiBold",
                    //           fontSize: "14px",
                    //         }}>
                    //         {item?.name}
                    //       </li>
                    //     </Box>
                    //   </ul>
                    //   <Typography
                    //     fontFamily={"MetropolisMedium"}
                    //     fontSize={"14px"}>
                    //     {item?.description}
                    //   </Typography>
                    // </Box>
                    <Accordion
                      sx={{
                        // padding: "0px 12px",
                        boxShadow: "none",
                        position: "relative !important ",
                        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                        "::before": {
                          position: "relative",
                          left: 0,
                          top: -1,
                          right: 0,
                          height: 1,
                          content: "''",
                          opacity: 1,
                          backgroundColor: "rgba(0, 0, 0, 0.12)",
                        },
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <p
                          style={{
                            fontFamily: "MetropolisRegular",
                            fontSize: "15px",
                            fontWeight: "550",
                            color: "#000",
                            margin: "0px",
                          }}
                        >
                          <b
                            style={{
                              color: "#fa8842",
                              marginRight: "5px",
                            }}
                          >
                            Q
                          </b>
                          {item?.name}
                        </p>
                      </AccordionSummary>
                      <AccordionDetails>
                        <p
                          style={{
                            fontFamily: "MetropolisRegular",
                            fontSize: "15px",
                            fontWeight: "550",
                            color: "#000",
                            margin: "0px",
                          }}
                        >
                          <b
                            style={{
                              color: "#fa8842",
                              marginRight: "5px",
                            }}
                          >
                            A
                          </b>
                          {item?.description}
                        </p>
                      </AccordionDetails>
                    </Accordion>
                  );
                })}
              </>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

export default OfferView;
