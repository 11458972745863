import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import Colors from "../../constants/Colors";
import CustomButton from "../../components/Button";
import { CustomInputWithIcon } from "../../components/Input";
import ProfileHoc from "../../components/ProfileHoc";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomDialog from "../../components/Dialog/custom-dialog";
import DeleteAccount from "../Summary/delete-account";
import { ApiRequest } from "../../../services/api-request";
import { useSnackbar } from "../../../context/useSnackbar";
import "./index.css";

const Settings = () => {
  const schema = yup
    .object({
      currentPassword: yup.string().required("Current Password is required."),
      password: yup
        .string()
        .required("New Password Email is required.")
        .min(6, "The password must be at least six characters."),
      confirmPassword: yup
        .string()
        .required("Confirm Password Name is required."),
    })
    .required();

  const { handleSubmit, control, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      currentPassword: "",
      confirmPassword: "",
      password: "",
    },
  });

  const { openSnackbar } = useSnackbar();

  const onSubmit = (data) => {
    ApiRequest({
      url: `user/change-password`,
      method: "PUT",
      data: data,
    })
      .then((res) => {
        if (res) {
          reset();
          openSnackbar(
            "Success! Password updated. Use new password to login now.",
            "success"
          );
        }
      })
      .catch((err) => {
        reset();
        openSnackbar(err?.response?.data?.message, "error");
      });
  };
  const [openDeleteAccountModal, setOpenDeleteAccountModal] = useState(false);

  const SettingsScreen = () => {
    return (
      <>
        <CustomDialog
          width={"400px"}
          open={openDeleteAccountModal}
          onClose={() => setOpenDeleteAccountModal(false)}
        >
          <DeleteAccount handleClose={() => setOpenDeleteAccountModal(false)} />
        </CustomDialog>

        <Box
          padding={5}
          boxShadow={"0px 0px 16px #67676729"}
          borderRadius={"20px"}
          width={640}
          height={"55%"}
          mt={3}
          className={"settingsCard"}
        >
          <p style={styles.header}>Change Password</p>

          <Box mt={2} width={"100%"}>
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <Box>
                <Box width={"45%"} className={"settingSingleField"}>
                  <Box>
                    <Controller
                      name="currentPassword"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <CustomInputWithIcon
                          {...field}
                          label={"Current Password"}
                          type={"password"}
                          placeHolder={"Enter Current Password"}
                          error={!!error}
                          helperText={error ? error.message : ""}
                        />
                      )}
                    />
                  </Box>
                </Box>
              </Box>
              <Box>
                <Box
                  width={"100%"}
                  display={"flex"}
                  justifyContent={"space-between"}
                  className={"settingFieldBox"}
                >
                  <Box mt={3} width={"45%"} className={"settingSingleField"}>
                    <Controller
                      name="password"
                      defaultValue={""}
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <CustomInputWithIcon
                          label={"New Password"}
                          type="password"
                          {...field}
                          placeHolder={"Enter your New Password"}
                          error={!!error}
                          helperText={error ? error.message : ""}
                        />
                      )}
                    />
                  </Box>
                  <Box mt={3} width={"45%"} className={"settingSingleField"}>
                    <Controller
                      name="confirmPassword"
                      type="password"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <CustomInputWithIcon
                          {...field}
                          label={"Confirm  Password"}
                          type="password"
                          placeHolder={"Enter your Confirm Password"}
                          error={!!error}
                          helperText={error ? error.message : ""}
                        />
                      )}
                    />
                  </Box>
                </Box>
              </Box>

              <Box display={"flex"} justifyContent={"end"} gap={"20px"}>
                <Box
                  mt={2}
                  item
                  width={130}
                  marginLeft={"25px"}
                  marginTop={"30px"}
                >
                  <CustomButton
                    title={"Update"}
                    borderButton={false}
                    type={"send"}
                    padding={"11px"}
                    margin={"10px"}
                  />
                </Box>
              </Box>
            </form>
          </Box>
        </Box>
        <Box
          padding={"40px 40px 20px 40px"}
          boxShadow={"0px 0px 16px #67676729"}
          borderRadius={"20px"}
          width={640}
          mt={3}
          className={"settingsCard2"}
        >
          <Box>
            <p style={styles.header}>Delete Account</p>
            <Box display={"flex"} justifyContent={"space-between"} mt={"10px"}>
              <Typography
                fontFamily={"MetropolisMedium"}
                fontSize={"12px"}
                color={"#000000"}
              >
                Delete Your Account
              </Typography>
              <Box width={"20%"} className={"deleteBtn"}>
                <CustomButton
                  handleClick={() => setOpenDeleteAccountModal(true)}
                  title={"Delete Account"}
                  borderButton={false}
                  type={"send"}
                  padding={"11px"}
                  margin={"10px"}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </>
    );
  };
  return ProfileHoc(SettingsScreen);
};

export default Settings;

const styles = {
  header: {
    fontFamily: "MetropolisMedium",
    fontSize: 18,
    color: Colors.textColor,
    margin: 0,
  },
  name: {
    fontFamily: "MetropolisRegular",
    color: Colors.textColor,
    fontSize: 13,
    margin: 0,
  },
  nameValue: {
    fontFamily: "MetropolisRegular",
    color: Colors.lightBlue,
    fontSize: 17,
    margin: 0,
    marginTop: 5,
  },
  profileImg: {
    objectFit: "contain",
  },
  title: {
    fontFamily: "MetropolisRegular",
    color: Colors.lightBlue,
    fontSize: 17,
  },
};
