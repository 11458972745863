import { Box, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import eyeSvg from "../../../../../images/orange eye.svg";
import CustomInput from "../../../../components/Input";
import CustomSelect from "../../../../components/Select/CustomSelect";
import { ApiRequest } from "../../../../../services/api-request";
import { useNavigate } from "react-router-dom";
import {
  ConvertToEpoch,
  ConvertToEpochAndAddOneDay,
  UTCformatDate,
  UTCformatOnlyDate,
  capitalizeFirstLetter,
} from "../../../../utilities/date-utility";
import { useSnackbar } from "../../../../../context/useSnackbar";
import CustomButton from "../../../../components/Button";
import Pagination from "../../../../components/Pagination";
import { useAppContext } from "../../../../../context/AppContext";
import { downloadFile } from "../../../../components/ExportToCSV/export-to-csv";
import CustomDialog from "../../../../components/Dialog/custom-dialog";
import OrderTransactionDetails from "../order-transaction-details";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useCallback } from "react";

const OrdersReports = (props) => {
  const { openSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const { handleLogoutSuccess } = useAppContext();

  const [showOrderDetails, setShowOrderDetails] = useState({
    open: false,
    data: {},
  });

  const [dateRange, setDateRange] = useState([null, null]);

  const { bgcolor, openedFrom } = props;

  const status = [
    {
      name: "Active",
      value: "active",
    },
    {
      name: "In-Active",
      value: "in-active",
    },
    {
      name: "Pending",
      value: "pending",
    },
    {
      name: "Processing",
      value: "processing",
    },
    {
      name: "Success",
      value: "success",
    },
    {
      name: "Failed",
      value: "failed",
    },
  ];
  const userTypes = [
    {
      name: "Student",
      value: "student",
    },
    {
      name: "Corporate",
      value: "corporate",
    },
  ];

  const limit = 100; // Users per page
  const [skip, setSkip] = useState(0);

  const [allOrders, setAllOrders] = useState([]);

  const [CsvOrdersList, setCsvOrdersList] = useState([]);

  const [filters, setFilters] = useState({
    status: "",
    category: "",
    userType: "",
    search: "",
  });

  useEffect(() => {
    getAllOrders("", "", filters, skip);
  }, ["", "", filters, skip]);

  useEffect(() => {
    let headers = [
      "Id,Offer Name,Category,Sub Category,Date and Time,Actual Amount,Offer Amount,Payable Price,Order Id,Transaction Id,Convenience Fee,Coupon Name,Coupon Amount,Wallet Points,By Customer,Vendor Name,Vendor Mobile,Vendor Id,Status,User Type,User Mobile",
    ];
    let orderCsv;
    if (Array.isArray(CsvOrdersList?.result)) {
      orderCsv = CsvOrdersList?.result?.reduce((acc, order, index) => {
        const {
          id,
          createdAt,
          actualPrice,
          discountInfo: { value: discountValue },
          couponInfo,
          user,
          status,
          offer,
          payablePrice,
          baseFeePrice,
          gstForCustomerPrice,
          payment,
          walletPoints,
        } = order;

        const date = UTCformatOnlyDate(createdAt);
        const formatedData = date.replace(/\W/g, " ");
        const modifiedIndex = index + 1;
        const userNameToDisplay = user?.name ? user?.name : "NA";
        const userType = user?.role?.code ? user?.role?.code : "NA";
        const userMobile = user?.mobile ? user?.mobile : "NA";
        const offerName = offer?.name;
        const sub = offer?.subCategories?.map((item) => item?.name).join("-");
        const categoryName = offer?.category?.name;
        const fee = baseFeePrice + gstForCustomerPrice ?? "NA";
        const vendorName = offer?.vendorName ? offer?.vendorName : "NA";
        const vendorId = offer?.vendorId ? offer?.vendorId : "NA";
        const vendorMobile = offer?.mobile ? offer?.mobile : "NA";
        const orderId = payment?.orderId ? payment?.orderId : "NA";
        const transactionId = payment?.response?.id
          ? payment?.response?.id
          : "NA";
        const couponName = couponInfo?.code ? couponInfo?.code : "NA";
        const couponValue = couponInfo?.value ? couponInfo?.value : "NA";
        acc.push(
          [
            modifiedIndex,
            offerName,
            categoryName,
            sub,
            formatedData,
            actualPrice,
            discountValue,
            payablePrice,
            orderId,
            transactionId,
            fee,
            couponName,
            couponValue,
            walletPoints,
            userNameToDisplay,
            vendorName,
            vendorMobile,
            vendorId,
            status,
            userType,
            userMobile,
          ].join(",")
        );
        return acc;
      }, []);

      downloadFile({
        data: [...headers, ...orderCsv].join("\n"),
        fileName: "Order Reports.csv",
        fileType: "text/csv",
      });
    }
  }, [CsvOrdersList]);

  const [startDate, endDate] = dateRange;

  const getAllOrders = (fromDate, toDate) => {
    const basePayload = {
      skip: skip,
      limit: limit,
      ...(filters?.userType && {
        userType: filters?.userType,
      }),
      ...(filters?.category && {
        category: filters.category,
      }),
      ...(filters?.status && {
        status: filters.status,
      }),
      ...(filters?.search && {
        searchText: filters.search,
      }),
    };
    const payload =
      fromDate && toDate
        ? {
            ...basePayload,
            fromDate: ConvertToEpochAndAddOneDay(fromDate),
            toDate: ConvertToEpochAndAddOneDay(toDate),
          }
        : basePayload;
    ApiRequest({
      url: `order/all`,
      data: payload,
      method: "POST",
    })
      .then((res) => {
        setAllOrders(res);
      })
      .catch((err) => {
        openSnackbar(err?.response?.data?.message, "error");
        if (err?.statusCode === 403 || err?.statusCode === 401) {
          if (navigate) {
            navigate("/login"); // Navigate to "/login" route
            handleLogoutSuccess();
          }
        }
      });
  };
  const handlePageChange = (page) => {
    const newSkip = (page - 1) * limit;
    setSkip(newSkip);
  };

  const onUserStatusSelected = (e) => {
    const selectedStatus = e.target.value;
    setFilters((prevFilter) => ({
      ...prevFilter,
      status: selectedStatus,
    }));
  };

  const userTypeSelected = (e) => {
    const selectedUserType = e.target.value;
    setFilters((prevFilter) => ({
      ...prevFilter,
      userType: selectedUserType,
    }));
  };

  const handleSearchChange = (e) => {
    const searchValue = e.target.value;
    setFilters((prevFilter) => ({
      ...prevFilter,
      search: searchValue,
    }));
  };

  const clearFilters = () => {
    setFilters({
      userType: "",
      status: "",
      category: "",
      search: "",
    });
    setDateRange([]);
  };

  const exportToCsv = useCallback(() => {
    const payload = {
      limit: 1000000000,
      skip: 0,
    };

    ApiRequest({
      url: "order/all",
      data: payload,
      method: "POST",
    })
      .then((res) => {
        setCsvOrdersList(res);
      })
      .catch((err) => {
        openSnackbar(err?.response?.data?.message, "error");
      });
  }, []);

  const handleChange = (update) => {
    if (update?.length) {
      setDateRange(update);
      const [startDate, endDate] = update;
      const startDateString = new Date(startDate).toISOString().slice(0, 10);
      const tomorrowEpoch = new Date(endDate).toISOString().slice(0, 10);
      getAllOrders(
        ConvertToEpoch(startDateString),
        ConvertToEpochAndAddOneDay(tomorrowEpoch)
      );
    }
  };
  return (
    <>
      {" "}
      <CustomDialog
        bgcolor={"#FFEDE3"}
        fontWeight={"600"}
        open={showOrderDetails.open}
        width={"600px"}
        size={"lg"}
        title={"Order Reports"}
        onClose={() => setShowOrderDetails({ open: false, data: {} })}
      >
        <OrderTransactionDetails orderDetails={showOrderDetails.data} />
      </CustomDialog>
      <Box
        bgcolor={bgcolor ? bgcolor : "#FFEDE3"}
        padding={"20px"}
        borderRadius={"10px"}
      >
        <Box
          display={"flex"}
          width={"100%"}
          justifyContent={"end"}
          alignItems={"right"}
        >
          <Box width={"10%"}>
            <CustomButton
              handleClick={exportToCsv}
              title={"+ Export CSV"}
              borderRadius={"5px"}
            />
          </Box>
        </Box>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography
            fontFamily={"MetropolisBold"}
            fontSize={"20px"}
            color={"#000000"}
          >
            Order Reports
          </Typography>
        </Box>
        <Box bgcolor={"#fff"} padding={"10px"} borderRadius={"10px"} mt={2}>
          <>
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              width={"100%"}
            >
              <Box width={"25%"}>
                <CustomInput
                  placeHolder={"Search with name"}
                  borderRadius={"5px"}
                  onChange={handleSearchChange}
                  value={filters.search}
                />
              </Box>
              <Box
                display={"flex"}
                justifyContent={"end"}
                alignItems={"baseline"}
                gap={"10px"}
                width={"75%"}
              >
                <Box>
                  <DatePicker
                    onChange={handleChange}
                    selectsRange={true}
                    startDate={startDate}
                    dateFormat="dd-MM-yyyy"
                    endDate={endDate}
                    placeholderText="Select Date Range"
                    className="datePickerReactPackage"
                  />
                </Box>
                <Box width={"20%"}>
                  <CustomSelect
                    placeHolder={"Status"}
                    value={filters.status}
                    options={status}
                    borderRadius={"5px"}
                    onChange={(e) => onUserStatusSelected(e)}
                  />
                </Box>
                <Box width={"20%"}>
                  <CustomSelect
                    placeHolder={"UserType"}
                    value={filters.userType}
                    options={userTypes}
                    borderRadius={"5px"}
                    onChange={(e) => userTypeSelected(e)}
                  />
                </Box>
                <Box width={"10%"} display={"flex"} alignItems={"end"}>
                  <CustomButton title={"Clear"} handleClick={clearFilters} />
                </Box>
              </Box>
            </Box>
          </>

          <Box
            mt={"12px"}
            padding={"5px 10px"}
            borderRadius={"5px"}
            bgcolor={"#FFEDE3"}
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Box width={"10%"}>
              <Typography color={"#000000"} fontFamily={"MetropolisSemiBold"}>
                Id
              </Typography>
            </Box>
            <Box width={"20%"}>
              <Typography color={"#000000"} fontFamily={"MetropolisSemiBold"}>
                Date & Time
              </Typography>
            </Box>
            <Box width={"11%"}>
              <Typography color={"#000000"} fontFamily={"MetropolisSemiBold"}>
                Actual Amount
              </Typography>
            </Box>

            <Box width={"11%"}>
              <Typography color={"#000000"} fontFamily={"MetropolisSemiBold"}>
                Offer Amount
              </Typography>
            </Box>
            <Box width={"11%"}>
              <Typography color={"#000000"} fontFamily={"MetropolisSemiBold"}>
                Coupon Amount
              </Typography>
            </Box>
            <Box width={"11%"}>
              <Typography color={"#000000"} fontFamily={"MetropolisSemiBold"}>
                By Customer
              </Typography>
            </Box>
            <Box width={"11%"}>
              <Typography color={"#000000"} fontFamily={"MetropolisSemiBold"}>
                To Vendor
              </Typography>
            </Box>
            <Box width={"10%"}>
              <Typography color={"#000000"} fontFamily={"MetropolisSemiBold"}>
                Status
              </Typography>
            </Box>
            <Box width={"5%"}>
              <Typography color={"#000000"} fontFamily={"MetropolisSemiBold"}>
                Action
              </Typography>
            </Box>
          </Box>
          {allOrders?.result?.map((item, index) => {
            const adjustedIndex = index + skip + 1;
            return (
              <Box
                key={index}
                padding={"5px 10px"}
                borderRadius={"5px"}
                bgcolor={"#FFFFFF"}
                border={"1px solid #FEECE3"}
                mt={"5px"}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Box width={"10%"}>
                  <Typography color={"#000000"} fontFamily={"MetropolisMedium"}>
                    {adjustedIndex}
                  </Typography>
                </Box>
                <Box width={"20%"}>
                  <Typography
                    sx={{ wordBreak: "break-word" }}
                    color={"#000000"}
                    fontFamily={"MetropolisMedium"}
                  >
                    {UTCformatDate(item?.createdAt) ?? "NA"}
                  </Typography>
                </Box>
                <Box width={"11%"}>
                  <Typography color={"#000000"} fontFamily={"MetropolisMedium"}>
                    {item?.actualPrice ?? "NA"}
                  </Typography>
                </Box>

                <Box width={"11%"}>
                  <Typography color={"#000000"} fontFamily={"MetropolisMedium"}>
                    {item?.discountInfo?.value}
                  </Typography>
                </Box>
                <Box width={"11%"}>
                  <Typography color={"#000000"} fontFamily={"MetropolisMedium"}>
                    {item?.couponInfo?.value ?? "NA"}
                  </Typography>
                </Box>
                <Box width={"11%"}>
                  <Typography color={"#000000"} fontFamily={"MetropolisMedium"}>
                    {item?.user?.name}
                  </Typography>
                </Box>
                <Box width={"11%"}>
                  <Typography color={"#000000"} fontFamily={"MetropolisMedium"}>
                    {item?.offer?.vendorName ?? "NA"}
                  </Typography>
                </Box>
                <Box width={"10%"}>
                  <Typography color={"#000000"} fontFamily={"MetropolisMedium"}>
                    {capitalizeFirstLetter(item?.status)}
                  </Typography>
                </Box>

                <Box
                  sx={{ cursor: "pointer" }}
                  display={"flex"}
                  gap={"10px"}
                  alignItems={"center"}
                  width={"5%"}
                >
                  <Box width={"10%"}>
                    <img
                      src={eyeSvg}
                      alt=""
                      width={"20px"}
                      onClick={() =>
                        setShowOrderDetails({ open: true, data: item })
                      }
                    />
                  </Box>
                </Box>
              </Box>
            );
          })}
          <Box mt={2} display={"flex"} justifyContent={"right"}>
            {openedFrom != "dashboard" && allOrders?.totalCount > 5 && (
              <Pagination
                totalCount={Number(allOrders?.totalCount)}
                skip={skip}
                limit={limit}
                onPageChange={handlePageChange}
              />
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default OrdersReports;
