import React, { useState, useEffect } from "react";
import { Grid, Box } from "@mui/material";
import Colors from "../../constants/Colors";
import ProfileHoc from "../../components/ProfileHoc";
import { useAppContext } from "../../../context/AppContext";
import { ApiRequest } from "../../../services/api-request";
import { extractTimeIn24HoursFormat } from "../../utilities/date-utility";

const Wallet = () => {
  const [walletPoints, setWalletPoints] = useState("");
  const [transactionList, setTransactionList] = useState([]);

  const { user } = useAppContext();

  useEffect(() => {
    getUserWalletPoints();
    getTransactionList();
  }, []);

  const getUserWalletPoints = () => {
    ApiRequest({
      url: `wallet/user/${user?.id}`,
      method: "GET",
    })
      .then((res) => {
        setWalletPoints(res);
      })
      .catch((err) => {});
  };
  const getTransactionList = () => {
    ApiRequest({
      url: `wallet/activity/user/${user?.id}`,
      method: "GET",
    })
      .then((res) => {
        setTransactionList(res);
      })
      .catch((err) => {});
  };

  const ActivityTypeDetailsCard = (props) => {
    const { transaction } = props;
    return (
      <>
        <Grid container justifyContent={"space-between"} alignItems={"center"}>
          <Grid item>
            <Grid
              container
              gap={2}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Grid item>
                <Box
                  backgroundColor={Colors.skyBlue}
                  width={50}
                  height={50}
                  borderRadius={25}
                />
              </Grid>
              <Grid item>
                <p style={styles.walletText}>Activity Type</p>
                <p style={styles.spent}>{transaction?.activityType}</p>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <p style={styles.activityType}>+ ₹. {transaction?.walletPoints}</p>
          </Grid>
        </Grid>
      </>
    );
  };

  const OfferTransactionCard = (props) => {
    const { transaction } = props;
    return (
      <>
        <Grid
          container
          justifyContent={"space-between"}
          alignItems={"center"}
          mt={2}
        >
          <Grid item>
            <Grid
              container
              gap={2}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Grid item>
                <Box
                  backgroundColor={Colors.skyBlue}
                  width={50}
                  height={50}
                  borderRadius={25}
                />
              </Grid>
              <Grid item>
                <p style={styles.walletText}>
                  Txn : {transaction?.transactionId} |{" "}
                  {extractTimeIn24HoursFormat(transaction?.createdAt)}
                </p>
                <p style={styles.spent}>{transaction?.offerName}</p>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <p style={styles.amountRow}>- ₹. {transaction?.walletPoints}</p>
          </Grid>
        </Grid>
      </>
    );
  };

  const WalletScreen = () => {
    return (
      <Box boxShadow={"0px 0px 16px #67676729"} borderRadius={4} mt={3}>
        <Box p={3}>
          <h1 style={styles.header}>Wallet</h1>
          <Grid
            container
            mt={3}
            justifyContent={"space-between"}
            alignItems={"center"}
            width={700}
          >
            <Grid item>
              <Grid
                container
                gap={2}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Grid item>
                  <Box
                    backgroundColor={Colors.skyBlue}
                    width={50}
                    height={50}
                    borderRadius={25}
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <img
                      alt="image"
                      src={require("../../../images/discount.png")}
                      width={20}
                      height={20}
                    />
                  </Box>
                </Grid>
                <Grid item>
                  <h1 style={styles.walletText}>Wallet Points</h1>
                  <h2 style={styles.amount}>{walletPoints?.walletPoints}</h2>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Box>
          <Box p={3}>
            <h1 style={styles.header}>Transactions</h1>
          </Box>
          <Box
            height={"300px"}
            paddingBottom={"24px"}
            paddingRight={"20px"}
            sx={{
              scrollbarWidth: "thin",
              overflowY: "scroll",
              scrollbarColor: "transparent transparent",
              "&::-webkit-scrollbar": {
                width: "0.5em",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "transparent",
              },
            }}
          >
            {transactionList.length ? (
              <>
                {transactionList?.map((transaction, index) => {
                  return (
                    <Box pl={3} key={transaction?._id} mt={"10px"}>
                      {transaction?.offerName && transaction?.transactionId ? (
                        <OfferTransactionCard transaction={transaction} />
                      ) : (
                        <ActivityTypeDetailsCard transaction={transaction} />
                      )}
                    </Box>
                  );
                })}
              </>
            ) : (
              <>
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  mt={"2"}
                >
                  <h5 style={styles.noTransactionFound}>
                    No Transactions Found
                  </h5>
                </Box>
              </>
            )}
          </Box>
        </Box>
      </Box>
    );
  };
  return ProfileHoc(WalletScreen);
};

export default Wallet;

const styles = {
  header: {
    fontFamily: "MetropolisMedium",
    fontSize: 18,
    color: Colors.textColor,
    margin: 0,
    fontWeight: "500",
  },
  noTransactionFound: {
    fontFamily: "MetropolisMedium",
    fontSize: 16,
    color: Colors.textColor,
    margin: 0,
    fontWeight: "500",
  },
  name: {
    fontFamily: "MetropolisRegular",
    color: Colors.textColor,
    fontSize: 13,
    margin: 0,
  },
  nameValue: {
    fontFamily: "MetropolisRegular",
    color: Colors.lightBlue,
    fontSize: 17,
    margin: 0,
    marginTop: 5,
  },
  profileImg: {
    objectFit: "contain",
  },
  walletText: {
    fontFamily: "MetropolisRegular",
    color: Colors.textColor,
    fontSize: 14,
    margin: 0,
    fontWeight: "500",
  },
  amount: {
    fontFamily: "MetropolisMedium",
    color: Colors.lightBlue,
    fontSize: 30,
    margin: 0,
    marginTop: 12,
  },
  amountRow: {
    fontFamily: "MetropolisSemiBold",
    color: Colors.lightBlue,
    fontSize: 17,
    margin: 0,
    marginTop: 12,
  },
  greenRow: {
    fontFamily: "MetropolisSemiBold",
    color: "green",
    fontSize: 17,
    margin: 0,
    marginTop: 12,
  },
  spent: {
    fontFamily: "MetropolisRegular",
    color: Colors.lightBlue,
    fontSize: 15,
    margin: 0,
    marginTop: 12,
  },
  activityType: {
    fontFamily: "MetropolisRegular",
    color: "green",
    fontSize: 17,
    margin: 0,
    marginTop: 12,
  },
};
