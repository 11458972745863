import React from "react";
import { Box, Typography } from "@mui/material";
import CustomInput from "../../../components/Input";
import CustomButton from "../../../components/Button";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ApiRequest } from "../../../../services/api-request";
import { useSnackbar } from "../../../../context/useSnackbar";
import { useAppContext } from "../../../../context/AppContext";
import { useNavigate } from "react-router-dom";

const schema = yup
  .object({
    taxValue: yup.string().required("Tax Value is required"),
  })
  .required();

const AddEditOfferTax = (props) => {
  const navigate = useNavigate();

  const { handleLogoutSuccess } = useAppContext();

  const { openSnackbar } = useSnackbar();
  const { isEdit, currentOfferTax } = props;

  const { handleSubmit, control } = useForm({
    resolver: yupResolver(schema),
    defaultValues: !isEdit
      ? { taxValue: currentOfferTax?.percentage }
      : { TaxName: currentOfferTax?.name },
  });

  const onSubmit = (data) => {
    if (currentOfferTax) {
      const modifiedFormData = {
        taxId: currentOfferTax?._id,
        percentage: Number(data.taxValue),
      };
      ApiRequest({
        url: `order/update-tax`,
        data: modifiedFormData,
        method: "POST",
      })
        .then((res) => {
          if (res) {
            openSnackbar("OfferTax  updated successfully", "success");
          }
          props.onClose();
          props.getOfferTaxList();
        })
        .catch((err) => {
          openSnackbar(err?.response?.data?.message, "error");
          if (err?.statusCode === 403 || err?.statusCode === 401) {
            if (navigate) {
              navigate("/login"); // Navigate to "/login" route
              handleLogoutSuccess();
            }
          }
        });
    }
  };

  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <Box>
          <Typography
            fontFamily={"MetropolisSemiBold"}
            fontSize={"14px"}
            color={"#000000"}
          >
            Tax Name : <b>{currentOfferTax?.name}</b>
          </Typography>
        </Box>
        <Box>
          <Controller
            name="taxValue"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <CustomInput
                {...field}
                label={"Tax Value"}
                borderRadius={"5px"}
                type={"text"}
                placeHolder={"Enter Tax Value"}
                error={!!error}
                helperText={error ? error.message : ""}
              />
            )}
          />
        </Box>
        <Box display={"flex"} justifyContent={"end"} gap={"10px"} mt={"20px"}>
          <Box width={"20%"}>
            <CustomButton
              border={"1px solid #000000"}
              handleClick={() => props.onClose()}
              color={"#000000"}
              title={"Cancel"}
              bgColor={"#F7F7F7"}
              borderRadius={"5px"}
            />
          </Box>
          <Box width={"20%"}>
            <CustomButton
              title={"Submit"}
              type={"submit"}
              borderRadius={"5px"}
            />
          </Box>
        </Box>
      </form>
    </Box>
  );
};

const styles = {
  cursor: {
    cursor: "pointer",
  },
};

export default AddEditOfferTax;
