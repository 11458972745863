import React, { useState } from "react";
import { ApiRequest } from "../../../services/api-request";
import { Box, Typography } from "@mui/material";
import { useSnackbar } from "../../../context/useSnackbar";

const FileUploader = (props) => {
  const { displayType, uploadType } = props;

  const [selectedFile, setSelectedFile] = useState("");
  const { openSnackbar } = useSnackbar();

  // const [errorMessage, setErrorMessage] = useState('')

  const onInputClick = (event) => {
    const element = event.target;
    element.value = "";
  };

  function validFileType(fileList) {
    const allowedExtensions = ["jpeg", "jpg", "png"];
    const invalidExtension = fileList?.some(
      (file) =>
        !allowedExtensions.includes(file?.name?.split(".").pop()?.toLowerCase())
    );
    if (invalidExtension) {
      openSnackbar("Only JPEG and PNG files are allowed");
      return;
    }
    return true;
  }

  const handleChange = (event) => {
    if (!event?.target.files || event?.target?.files?.length === 0) {
      setSelectedFile(undefined);
      return;
    }
    if (event?.target?.files?.length) {
      try {
        const fileList = Array.from(event.target.files);
        if (validFileType(fileList)) {
          const maxSize = 1024 * 1024 * 50;
          const totalSize = fileList?.reduce(
            (total, file) => total + file.size,
            0
          );
          if (totalSize > maxSize) {
            openSnackbar("All File size exceeds the maximum limit (50MB)");
            event.target.value = "";
            return;
          } else {
            setSelectedFile(fileList);
            if (
              displayType === "registrationIDUpload" ||
              displayType === "square" ||
              displayType === "offerThumbNail"
            ) {
              uploadFileToS3(fileList[0]);
              if (displayType === "registrationIDUpload") {
                openSnackbar("Your information stays secure with us.");
              }
            } else {
              uploadFilesToS3(fileList);
            }
          }
        } else {
          openSnackbar("Max file size should be less than 50 MB");
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const uploadFileToS3 = (file) => {
    const formData = new FormData();
    formData.append("file", file);

    ApiRequest(
      {
        url: `upload`,
        data: formData,
        method: "POST",
      },
      {
        isFileUpload: true,
      }
    )
      .then((res) => {
        const updatedUploadIdImages = {
          ...props?.uploadIdImages,
          [`${uploadType}ResponseFiles`]: res,
        };

        // Check if frontSideImageResponseFiles is present and clear deniedUserFrontImage if true
        if (
          uploadType === "frontSide" &&
          updatedUploadIdImages?.frontSideResponseFiles?.url
        ) {
          updatedUploadIdImages.deniedUserFrontImage = "";
        }

        // Check if backSideImageResponseFiles is present and clear deniedBackUserImage if true
        if (
          uploadType === "backSide" &&
          updatedUploadIdImages?.backSideResponseFiles
        ) {
          updatedUploadIdImages.deniedUserBackSideImage = "";
        }

        props.setUploadedFiles(updatedUploadIdImages);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const uploadFilesToS3 = async (files) => {
    for (let i = 0; i < files.length; i++) {
      let formData = {};
      formData = new FormData();
      console.log(props?.uploadIdImages);
      // if (i == 0) {
      formData.append("file", files[i]);
      // } else {
      //   formData.append("files", files[i]);
      // }

      ApiRequest(
        {
          url: `upload`,
          data: formData,
          method: "POST",
        },
        {
          isFileUpload: true,
        }
      )
        .then((res) => {
          formData = {};
          props.setUploadedFiles({
            ...props?.uploadIdImages,
            [`${uploadType}ResponseFiles`]: props?.uploadIdImages
              ?.coverImagesResponseFiles?.length
              ? [...props?.uploadIdImages?.coverImagesResponseFiles, ...[res]]
              : [res],
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleClick = () => {
    document.getElementById(`${props?.uploadType}`).click();
  };

  return (
    <>
      {displayType === "registrationIDUpload" && (
        <>
          <Box
            sx={{ cursor: "pointer" }}
            onClick={handleClick}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            height={"200px"}
            width={"180px"}
          >
            <Box>{props.icon}</Box>

            <Typography
              fontFamily={"MetropolisMedium"}
              fontSize={"14px"}
              sx={{ wordBreak: "break-word" }}
            >
              {props.heading}
            </Typography>

            {props?.image ? (
              <img
                src={props.image}
                alt="circle"
                style={{
                  width: "180px",
                  height: "200px",
                  cursor: "pointer",
                  objectFit: "contain",
                }}
              />
            ) : null}
          </Box>
        </>
      )}

      {displayType === "square" && (
        <Box
          sx={{ cursor: "pointer" }}
          border={"1px dotted #FA8842"}
          padding={props.padding}
          onClick={handleClick}
        >
          <Typography
            fontFamily={"MetropolisMedium"}
            sx={{ wordBreak: "break-word" }}
            fontSize={"14px"}
          >
            {props.heading}
          </Typography>
        </Box>
      )}
      {displayType === "offerThumbNail" && (
        <Box
          sx={{ cursor: "pointer" }}
          border={"1px dotted #FA8842"}
          padding={props.padding}
          onClick={handleClick}
        >
          <Typography
            fontFamily={"MetropolisMedium"}
            sx={{ wordBreak: "break-word" }}
            fontSize={"14px"}
          >
            {props.heading}
          </Typography>
        </Box>
      )}
      {displayType === "profile" && (
        <img
          src={props.image}
          alt="profile"
          onClick={handleClick}
          style={{
            width: "110px",
            height: "110px",
            border: "none",
            borderRadius: "50%",
            cursor: "pointer",
          }}
        />
      )}

      {displayType === "rectangle" && (
        <>
          <Box
            sx={{ cursor: "pointer" }}
            border={"1px dotted #FA8842"}
            padding={"15px"}
            onClick={handleClick}
          >
            <Typography
              fontFamily={"MetropolisMedium"}
              sx={{ wordBreak: "break-word" }}
              fontSize={"14px"}
            >
              {props.heading}
            </Typography>
          </Box>
        </>
      )}
      <input
        id={`${props?.uploadType}`}
        name={props?.uploadType}
        key={`${props?.uploadType ?? ""}-uploaded-key-${selectedFile?.Key}`}
        type="file"
        onChange={(event) => handleChange(event)}
        accept={props?.accept?.[0]}
        onClick={onInputClick}
        style={{ display: "none" }}
        multiple={false} //props.multiple
        defaultValue={props.defaultValue}
      />
    </>
  );
};

export default FileUploader;
