import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import EditSvg from "../../../../images/admin/edit (4).svg";
import CustomInput from "../../../components/Input";
import CustomButton from "../../../components/Button";
import { ApiRequest } from "../../../../services/api-request";
import CustomDialog from "../../../components/Dialog/custom-dialog";
import AddEditBanner from "./add-edit-banner";
import { capitalizeFirstLetter } from "../../../utilities/date-utility";
import deleteSvg from "../../../../images/admin/delete2.svg";
import ImageLoader from "../../../components/ImageLoader";
import { useSnackbar } from "../../../../context/useSnackbar";
import Pagination from "../../../components/Pagination";
import CustomSelect from "../../../components/Select/CustomSelect";
import { Link, useNavigate } from "react-router-dom";
import { useAppContext } from "../../../../context/AppContext";
import moment from "moment-timezone";
const BannerList = () => {
  const navigate = useNavigate();
  const { handleLogoutSuccess } = useAppContext();

  const bannerOptions = [
    {
      name: "Both",
      value: "all",
    },
    {
      name: "Student",
      value: "student",
    },
    {
      name: "Corporate",
      value: "corporate",
    },
  ];

  const locationSearch = [
    {
      name: "Home",
      value: "Home",
    },
    {
      name: "Online",
      value: "Online",
    },
    {
      name: "Student",
      value: "Student",
    },
    {
      name: "Corporate",
      value: "Corporate",
    },
    {
      name: "In-store",
      value: "In-store",
    },
    {
      name: "Top-Offers",
      value: "Top-Offers",
    },
    {
      name: "Featured Offers",
      value: "Featured Offers",
    },
  ];

  useEffect(() => {
    getAllCategories();
  }, []);

  const [allCategories, setAllCategories] = useState([]);

  const getAllCategories = () => {
    ApiRequest({
      url: `category/all?skip=0&limit=100`,
      method: "GET",
    })
      .then((res) => {
        const modifiedCategories = res?.map((category) => ({
          name: `Category - ${category?.name}`,
          value: `Category-${category?.name}`,
        }));
        setAllCategories([...locationSearch, ...modifiedCategories]);
      })
      .catch((err) => {
        openSnackbar(err?.response?.data?.message, "error");
        if (err?.statusCode === 403 || err?.statusCode === 401) {
          if (navigate) {
            navigate("/login"); // Navigate to "/login" route
            handleLogoutSuccess();
          }
        }
      });
  };

  const [bannerList, setBannerList] = useState({});

  const [openAddBannerModal, setOpenAddBannerModal] = useState(false);

  const [imagePreviewModal, setImagePreviewModal] = useState({
    open: false,
    data: "",
  });

  const [handleDeleteBanner, setHandleDeleteBanner] = useState({
    open: false,
    data: {},
  });

  const { openSnackbar } = useSnackbar();

  const [currentBanner, setCurrentBanner] = useState({});

  const [filter, setFilter] = useState({
    name: "",
    toShow: "",
    location: "",
  });

  const limit = 100; // Banners per page
  const [skip, setSkip] = useState(0);

  function bannerUrl(filter = null) {
    const { name = "", toShow = null, location = null } = filter || {};

    if (filter?.name?.length) {
      return `banner/list?skip=${skip}&isAdmin=true&limit=${limit}${
        name ? `&name=${name}` : ""
      }`;
    } else if (filter?.name?.length || toShow || location) {
      return `banner/list?skip=${skip}&isAdmin=true&limit=${limit}${
        toShow ? `&toShow=${toShow}` : ""
      }${location ? `&location=${encodeURIComponent(location)}` : ""}`;
    } else if (location) {
      return `banner/list?skip=${skip}&isAdmin=true&limit=${limit}${
        location ? `&location=${encodeURIComponent(location)}` : ""
      }`;
    } else if (toShow) {
      debugger;
      return `banner/list?skip=${skip}&isAdmin=true&limit=${limit}${
        toShow ? `&toShow=${toShow}` : ""
      }`;
    } else {
      return `banner/list?skip=${skip}&limit=${limit}&isAdmin=true`;
    }
  }

  useEffect(() => {
    getAllBannersList(filter);
  }, [skip, filter]);

  let getAllBannersList = (filter) => {
    ApiRequest({
      url: bannerUrl(filter),
      method: "GET",
    })
      .then((res) => {
        setBannerList(res);
        setOpenAddBannerModal(false);
        setCurrentBanner("");
      })
      .catch((err) => {
        openSnackbar(err?.response?.data?.message, "error");
        setOpenAddBannerModal(false);
        setCurrentBanner("");
        if (err?.statusCode === 403 || err?.statusCode === 401) {
          if (navigate) {
            navigate("/login"); // Navigate to "/login" route
            handleLogoutSuccess();
          }
        }
      });
  };

  const handleBannerModalClose = () => {
    setOpenAddBannerModal(false);
    setCurrentBanner("");
  };

  const handleDelete = () => {
    ApiRequest({
      url: `banner/${handleDeleteBanner.data?._id}`,
      method: "DELETE",
    })
      .then((res) => {
        if (res) {
          openSnackbar("Banner Deleted Successfully", "success");
          getAllBannersList();
          setHandleDeleteBanner({
            open: false,
            data: {},
          });
        }
      })
      .catch((err) => {
        openSnackbar(err?.response?.data?.message, "error");
        if (err?.statusCode === 403 || err?.statusCode === 401) {
          if (navigate) {
            navigate("/login"); // Navigate to "/login" route
            handleLogoutSuccess();
          }
        }
      });
  };

  const handleSearch = (e) => {
    const searchValue = e.target.value;
    setFilter((prevFilter) => ({
      ...prevFilter,
      name: searchValue,
    }));
    getAllBannersList({ ...filter, name: searchValue });
  };

  const onSelectedType = (e) => {
    const selectedType = e.target.value;
    setFilter((prevFilter) => ({
      ...prevFilter,
      toShow: selectedType,
    }));
    getAllBannersList({ ...filter, toShow: selectedType });
  };

  const handlePageChange = (page) => {
    const newSkip = (page - 1) * limit;
    setSkip(newSkip);
  };

  const onSelectedLocation = (e) => {
    const searchValue = e.target.value;
    setFilter((prevFilter) => ({
      ...prevFilter,
      location: searchValue,
    }));
    getAllBannersList({ ...filter, location: searchValue });
  };

  const clearFilters = () => {
    getAllBannersList({
      search: "",
      toShow: "",
      userType: "",
    });
    setFilter({
      search: "",
      toShow: "",
      userType: "",
    });
  };

  return (
    <>
      <CustomDialog
        width={"500px"}
        open={openAddBannerModal}
        title={
          currentBanner?.openType === "editBanner"
            ? "Edit Banner"
            : "Add Banner"
        }
        onClose={handleBannerModalClose}
      >
        <AddEditBanner
          banner={currentBanner ?? {}}
          onClose={() => setOpenAddBannerModal(false)}
          isEdit={currentBanner?.openType === "add" ? false : true}
          getAllBannersList={getAllBannersList}
        />
      </CustomDialog>

      <CustomDialog
        maxWidth={"md"}
        open={imagePreviewModal?.open}
        title={"Preview"}
        onClose={() => setImagePreviewModal(false)}
      >
        <ImageLoader
          src={imagePreviewModal?.data?.image}
          style={{ width: "800px", height: "200px", objectFit: "contain" }}
        />
      </CustomDialog>

      <CustomDialog
        title={"Delete Banner?"}
        width={"400px"}
        open={handleDeleteBanner.open}
        onClose={() => setHandleDeleteBanner({ open: false })}
      >
        <Typography>Are you sure you want to Delete Banner?</Typography>
        <Box display={"flex"} justifyContent={"end"} gap={"10px"} mt={"20px"}>
          <Box width={"20%"}>
            <CustomButton
              handleClick={() => setHandleDeleteBanner({ open: false })}
              border={"1px solid #000000"}
              color={"#000000"}
              title={"No"}
              bgColor={"#F7F7F7"}
              borderRadius={"5px"}
            />
          </Box>
          <Box width={"20%"}>
            <CustomButton
              handleClick={handleDelete}
              type={"submit"}
              title={"Yes"}
              borderRadius={"5px"}
            />
          </Box>
        </Box>
      </CustomDialog>

      <Box>
        <Box
          bgcolor={"#FFEDE3"}
          padding={"20px"}
          borderRadius={"40px 0px 0px 40px"}
        >
          <Box
            display={"flex"}
            width={"100%"}
            justifyContent={"end"}
            alignItems={"right"}
          >
            <Box width={"13%"}>
              <CustomButton
                title={"+ Add Banner"}
                borderRadius={"5px"}
                handleClick={() => {
                  setOpenAddBannerModal(true);
                  setOpenAddBannerModal({ openType: "add" });
                }}
              />
            </Box>
          </Box>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography
              fontFamily={"MetropolisBold"}
              fontSize={"20px"}
              color={"#000000"}
            >
              Banners
            </Typography>
          </Box>
          <Box bgcolor={"#fff"} padding={"10px"} borderRadius={"10px"} mt={2}>
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              width={"100%"}
            >
              <Box width={"25%"}>
                <CustomInput
                  placeHolder={"Search with name"}
                  borderRadius={"5px"}
                  onChange={handleSearch}
                />
              </Box>
              <Box
                width={"75%"}
                display={"flex"}
                justifyContent={"end"}
                gap={"10px"}
              >
                <Box width={"30%"}>
                  <CustomSelect
                    value={filter.location}
                    placeHolder={"Filter Location"}
                    options={allCategories}
                    borderRadius={"5px"}
                    onChange={(e) => onSelectedLocation(e)}
                  />
                </Box>
                <Box width={"30%"}>
                  <CustomSelect
                    value={filter.toShow}
                    placeHolder={"Filter Visible"}
                    options={bannerOptions}
                    borderRadius={"5px"}
                    onChange={(e) => onSelectedType(e)}
                  />
                </Box>
                <Box width={"10%"} display={"flex"} alignItems={"end"}>
                  <CustomButton title={"Clear"} handleClick={clearFilters} />
                </Box>
              </Box>
            </Box>

            <Box
              mt={"12px"}
              padding={"5px 10px"}
              borderRadius={"5px"}
              bgcolor={"#FFEDE3"}
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Box width={"3%"}>
                <Typography color={"#000000"} fontFamily={"MetropolisSemiBold"}>
                  Id
                </Typography>
              </Box>
              <Box width={"15%"}>
                <Typography color={"#000000"} fontFamily={"MetropolisSemiBold"}>
                  Banner Name
                </Typography>
              </Box>
              <Box width={"10%"}>
                <Typography color={"#000000"} fontFamily={"MetropolisSemiBold"}>
                  Location
                </Typography>
              </Box>
              <Box width={"20%"}>
                <Typography color={"#000000"} fontFamily={"MetropolisSemiBold"}>
                  Url
                </Typography>
              </Box>
              <Box width={"10%"}>
                <Typography color={"#000000"} fontFamily={"MetropolisSemiBold"}>
                  Image
                </Typography>
              </Box>
              <Box width={"13%"}>
                <Typography color={"#000000"} fontFamily={"MetropolisSemiBold"}>
                  Visible
                </Typography>
              </Box>
              <Box width={"10%"}>
                <Typography color={"#000000"} fontFamily={"MetropolisSemiBold"}>
                  Till Date
                </Typography>
              </Box>
              <Box width={"10%"}>
                <Typography color={"#000000"} fontFamily={"MetropolisSemiBold"}>
                  Action
                </Typography>
              </Box>
            </Box>
            {bannerList?.result?.map((item, index) => {
              const istDateTimeEndsAt = moment
                .utc(item?.endsAt)
                .tz("Asia/Kolkata");

              const istDateEndsAt = moment(
                istDateTimeEndsAt,
                "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
              );
              const adjustedIndex = index + skip + 1;
              return (
                <Box
                  key={index}
                  padding={"5px 10px"}
                  borderRadius={"5px"}
                  bgcolor={"#FFFFFF"}
                  border={"1px solid #FEECE3"}
                  mt={"5px"}
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Box width={"3%"}>
                    <Typography
                      color={"#000000"}
                      fontFamily={"MetropolisMedium"}
                    >
                      {adjustedIndex}
                    </Typography>
                  </Box>
                  <Box width={"15%"}>
                    <Typography
                      color={"#000000"}
                      sx={{ wordWrap: "break-word" }}
                      fontFamily={"MetropolisMedium"}
                    >
                      {item.name}
                    </Typography>
                  </Box>
                  <Box width={"10%"}>
                    <Typography
                      color={"#000000"}
                      sx={{ wordWrap: "break-word" }}
                      fontFamily={"MetropolisMedium"}
                    >
                      {item.location}
                    </Typography>
                  </Box>
                  <Box width={"20%"}>
                    <Typography
                      sx={{ wordWrap: "break-word" }}
                      color={"#000000"}
                      fontFamily={"MetropolisSemiBold"}
                    >
                      <Link
                        style={{ color: "#FA8842" }}
                        to={item?.url}
                        target="_blank"
                      >
                        Go to Url
                      </Link>
                    </Typography>
                  </Box>
                  <Box
                    width={"10%"}
                    sx={{ cursor: "pointer" }}
                    onClick={() =>
                      setImagePreviewModal({
                        open: true,
                        data: item,
                      })
                    }
                  >
                    <Typography
                      color={"#FA8842"}
                      sx={{ wordWrap: "break-word" }}
                      fontFamily={"MetropolisMedium"}
                    >
                      {"Preview"}
                    </Typography>
                  </Box>
                  <Box width={"13%"}>
                    <Typography
                      color={"#000000"}
                      sx={{ wordWrap: "break-word" }}
                      fontFamily={"MetropolisMedium"}
                    >
                      {item?.toShow === "all"
                        ? "Both"
                        : capitalizeFirstLetter(item?.toShow)}
                    </Typography>
                  </Box>
                  <Box width={"10%"}>
                    <Typography
                      color={"#000000"}
                      sx={{ wordWrap: "break-word" }}
                      fontFamily={"MetropolisSemiBold"}
                    >
                      {istDateEndsAt.format("DD-MM-YYYY")}
                    </Typography>
                  </Box>
                  <Box
                    display={"flex"}
                    sx={{ cursor: "pointer" }}
                    gap={"10px"}
                    alignItems={"center"}
                    width={"10%"}
                  >
                    <img
                      src={deleteSvg}
                      alt="delete"
                      width={"20px"}
                      onClick={() => {
                        setHandleDeleteBanner({ open: true, data: item });
                      }}
                    />
                    <img
                      src={EditSvg}
                      alt="edit"
                      width={"20px"}
                      onClick={() => {
                        setOpenAddBannerModal(true);
                        setCurrentBanner({
                          ...item,
                          openType: "editBanner",
                        });
                      }}
                    />
                  </Box>
                </Box>
              );
            })}
            <Box mt={2} display={"flex"} justifyContent={"right"}>
              {bannerList?.totalCount > 5 && (
                <Pagination
                  totalCount={Number(bannerList?.totalCount)}
                  skip={skip}
                  limit={limit}
                  onPageChange={handlePageChange}
                />
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default BannerList;
