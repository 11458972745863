import React from "react";
import SideMenu from "../layouts/side-menu";
import { Box } from "@mui/material";
import { useAppContext } from "../../../context/AppContext";
import { Outlet } from "react-router-dom";
import Header from "../Header";

const AppLayout = () => {
  const {  handleLogoutSuccess, user } = useAppContext();

  return (
    <div>
      {user?.role?.code === "admin" || user?.role?.code === "vendor" ? (
        <div>
          <Box
            width={"100%"}
            bgcolor={"#fff"}
            boxShadow={"0px 0px 16px #90909029"}
          >
            <Box position={"fixed"} width={"230px"}>
              <SideMenu handleLogoutSuccess={handleLogoutSuccess} />
            </Box>
            <Box ml={"230px"}>
              <Outlet />
            </Box>
          </Box>
        </div>
      ) : (
        <>
          <Header />
          <Outlet />
        </>
      )}
    </div>
  );
};

export default AppLayout;
