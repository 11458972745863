// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (min-width: 320px) and (max-width: 990px) {
  .detailsRightSec {
    display: none;
  }
  .thrillhLogo {
    display: flex;
    justify-content: center;
  }
  .loginBox {
    width: 100% !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/app/screens/Details/index.css"],"names":[],"mappings":"AAAA;EACE;IACE,aAAa;EACf;EACA;IACE,aAAa;IACb,uBAAuB;EACzB;EACA;IACE,sBAAsB;EACxB;AACF","sourcesContent":["@media (min-width: 320px) and (max-width: 990px) {\n  .detailsRightSec {\n    display: none;\n  }\n  .thrillhLogo {\n    display: flex;\n    justify-content: center;\n  }\n  .loginBox {\n    width: 100% !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
