import React, { useState, useEffect } from "react";
import { Grid, Box, Typography } from "@mui/material";
import Colors from "../../constants/Colors";
import CustomButton from "../../components/Button";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../../context/AppContext";
import PopOver from "../PopOver/pop-over";
import userSvg from "../../../images/admin/user (8).svg";
import CircularProgress from "@mui/material/CircularProgress";
import "./header.css";
import Notifications from "../../screens/Notifications";
import SearchLocationAndOffer from "../../screens/searchLocaionAndOffer";

const Header = (props) => {
  const { fromOfferDetailsPage } = props;
  const headerMenu = [
    {
      id: 1,
      name: "Profile",
      onClick: () => {
        setAnchorEl(null);
        navigate("/my-account/profile");
      },
    },
    // {
    //   id: 2,
    //   name: "Logout",
    //   onClick: handleLogoutTLogin,
    // },
  ];

  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const { user } = useAppContext();

  const [isLogged, setIsLogged] = useState(null);

  const [openNotificationModal, setOpenNotificationModal] = useState(false);

  useEffect(() => {
    const userString = localStorage.getItem("user");

    const user = JSON.parse(userString);

    setIsLogged(!!user?.accessToken);
  }, []);

  const handleLogoutToRegister = () => {
    localStorage.removeItem("user");
    navigate("/register");
  };

  const openPopUp = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (isLogged === null) {
    return (
      <Box display={"flex"} justifyContent={"end"}>
        <CircularProgress sx={{ width: "20px" }} />
      </Box>
    );
  }

  console.log(fromOfferDetailsPage);
  return (
    <>
      {/* ///laptop */}

      <Box position={"relative"} top={"0px"} zIndex={2} className="mobileHide">
        <Box backgroundColor={Colors.white} className="headerContainer">
          <Grid
            container
            justifyContent={"space-between"}
            alignItems={"center"}
            className="logoGrid1"
          >
            <a href="/">
              <img
                src={require("../../../images/final logo.png")}
                alt={"Logo"}
                className="headerLogo"
              />
            </a>

            {fromOfferDetailsPage === undefined ? (
              <>
                <Box className={"searchBox"}>
                  <Grid
                    item
                    justifyContent="center"
                    alignItems="center"
                    display="flex"
                  >
                    <Box
                      border="1px solid #161616"
                      borderRadius="28px"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <SearchLocationAndOffer />
                    </Box>
                  </Grid>
                </Box>
              </>
            ) : null}

            {isLogged ? (
              <Grid item>
                <Grid
                  container
                  justifyContent={"center"}
                  alignItems={"center"}
                  className="isLoggedInContainer"
                >
                  <Grid item sx={{ cursor: "pointer" }}>
                    <Box>
                      <Notifications
                        openNotificationModal={openNotificationModal}
                        setOpenNotificationModal={setOpenNotificationModal}
                      />
                    </Box>
                  </Grid>
                  <Grid
                    item
                    sx={{ cursor: "pointer" }}
                    onClick={() =>
                      window.open(
                        "https://play.google.com/store/apps/details?id=com.thrillh&hl=en-IN"
                      )
                    }
                  >
                    <img
                      src={require("../../../images/playstore.jpg")}
                      alt={"Android"}
                      width={45}
                      height={45}
                    />
                  </Grid>

                  <Grid item onClick={handleClick}>
                    <Box
                      sx={{ cursor: "pointer" }}
                      border={3}
                      borderRadius={20}
                      borderColor={Colors.lightBlue}
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <img
                        src={
                          user?.profilePicture ? user?.profilePicture : userSvg
                        }
                        width={45}
                        height={45}
                        style={styles.profileImage}
                        alt="user"
                      />
                    </Box>
                  </Grid>
                  <Box mt={2}>
                    <PopOver
                      anchorEl={anchorEl}
                      open={openPopUp}
                      onClose={handleClose}
                      options={headerMenu}
                    />
                  </Box>
                </Grid>
              </Grid>
            ) : (
              <Grid item className="topSecForLaptop">
                <Grid
                  container
                  className="isLoggedInContainer"
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Grid
                    className="vendorLogin"
                    item
                    sx={{ cursor: "pointer" }}
                    onClick={() => navigate(`/vendor/login`)}
                  >
                    <Typography
                      fontFamily={"MetropolisRegular"}
                      className="vendorLogin"
                      fontSize={"15px"}
                      fontWeight={"600"}
                      color={"#000000"}
                    >
                      Vendor login
                    </Typography>
                  </Grid>
                  <Grid
                    className="vLogin"
                    item
                    sx={{ cursor: "pointer" }}
                    onClick={() => navigate(`/vendor/login`)}
                  >
                    <Typography
                      fontFamily={"MetropolisRegular"}
                      fontSize={"12px"}
                      fontWeight={"600"}
                      color={"#000000"}
                    >
                      V login
                    </Typography>
                  </Grid>
                  <Grid item sx={{ cursor: "pointer" }}>
                    <p
                      style={styles.menuText}
                      className="loginInHeader"
                      onClick={() => navigate("/login")}
                    >
                      Login
                    </p>
                  </Grid>
                  <Grid item width={100}>
                    <CustomButton
                      title={"Register"}
                      borderButton={false}
                      handleClick={handleLogoutToRegister}
                    />
                  </Grid>
                  <Grid item className="straightLine">
                    |
                  </Grid>
                  <Grid
                    className="andriodIcon"
                    item
                    sx={{ cursor: "pointer" }}
                    onClick={() =>
                      window.open(
                        "https://play.google.com/store/apps/details?id=com.thrillh&hl=en-IN"
                      )
                    }
                  >
                    <img
                      src={require("../../../images/playstore.jpg")}
                      alt={"Android"}
                      width={45}
                      height={45}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default Header;

const styles = {
  menuText: {
    fontFamily: "MetropolisRegular",
    fontSize: 16,
  },
  menuText1: {
    fontFamily: "MetropolisRegular",
    fontSize: 12,
  },
  menuLogoText: {
    fontSize: 12,
    color: Colors.textColor,
    textAlign: "center",
  },
  notifyIcon: {
    fontSize: 30,
    color: Colors.textColor,
  },
  profileImage: {
    borderRadius: 40,
  },
};
