// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.accordion .MuiAccordionSummary-content {
  margin: 0px !important;
}
.accordion .MuiAccordionSummary-root {
  padding: 0px !important;
}

.list {
  font-family: "MetropolisRegular";
  color: #000000;
}

.mainList {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.subPara {
  font-family: "MetropolisRegular";
  color: #000000;
  text-align: center;
  font-weight: 600;
  font-size: 13px;
}
`, "",{"version":3,"sources":["webpack://./src/app/screens/Help/accordian-style.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;AACxB;AACA;EACE,uBAAuB;AACzB;;AAEA;EACE,gCAAgC;EAChC,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,gCAAgC;EAChC,cAAc;EACd,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;AACjB","sourcesContent":[".accordion .MuiAccordionSummary-content {\n  margin: 0px !important;\n}\n.accordion .MuiAccordionSummary-root {\n  padding: 0px !important;\n}\n\n.list {\n  font-family: \"MetropolisRegular\";\n  color: #000000;\n}\n\n.mainList {\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n}\n\n.subPara {\n  font-family: \"MetropolisRegular\";\n  color: #000000;\n  text-align: center;\n  font-weight: 600;\n  font-size: 13px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
