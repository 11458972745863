import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import CustomButton from "../../components/Button";
import userSvg from "../../../images/admin/user (8).svg";
import { useNavigate, useParams } from "react-router-dom";
import { ApiRequest } from "../../../services/api-request";
import CustomDialog from "../../components/Dialog/custom-dialog";
import VerifyUser from "./verify-user";
import { useSnackbar } from "../../../context/useSnackbar";
import { capitalizeFirstLetter } from "../../utilities/date-utility";
import DefaultIdCardImage from "../../../images/identity-card.svg";
import { useAppContext } from "../../../context/AppContext";

const UserView = () => {
  const { openSnackbar } = useSnackbar();
  const { userId } = useParams();

  const [userDetails, setUserDetails] = useState({});

  const { handleLogoutSuccess } = useAppContext();

  const navigate = useNavigate();

  useEffect(() => {
    getUserDetails();
  }, []);

  const getUserDetails = () => {
    ApiRequest({
      url: `user/find/${userId}`,
      method: "GET",
    })
      .then((res) => {
        console.log(res, "USER............................")
        setUserDetails(res);
      })
      .catch((err) => {
        openSnackbar(err?.response?.data?.message, "error");
        if (err?.statusCode === 403 || err?.statusCode === 401) {
          if (navigate) {
            navigate("/login"); // Navigate to "/login" route
            handleLogoutSuccess();
          }
        }
      });
  };

  const [openVerifyUserModal, setOpenVerifyUserModal] = useState({
    isOpen: false,
    data: userDetails,
  });

  const [previewIdCard, setPreviewIdCard] = useState(false);

  return (
    <>
      <CustomDialog
        open={openVerifyUserModal?.isOpen}
        title={"Verification"}
        width={"500px"}
        onClose={() => setOpenVerifyUserModal({ isOpen: false })}
      >
        <VerifyUser
          openVerifyUserModal={openVerifyUserModal}
          onClose={() => setOpenVerifyUserModal({ isOpen: false })}
        />
      </CustomDialog>

      <CustomDialog
        width={"500px"}
        open={previewIdCard}
        title={"Id Card"}
        onClose={() => setPreviewIdCard(false)}
      >
        <Box display={"flex"} alignItems={"center"} mt={2} gap={"10px"}>
          <Box>
            <img
              src={
                userDetails?.frontSideImage
                  ? userDetails?.frontSideImage
                  : DefaultIdCardImage
              }
              alt="delete"
              width={"250px"}
              height={"auto"}
            />
            <Typography
              textAlign={"center"}
              fontFamily={"MetropolisBold"}
              color={"#013047"}
              fontSize={"13px"}
            >
              Front Side Image
            </Typography>
          </Box>
          <Box>
            <img
              src={
                userDetails?.backSideImage
                  ? userDetails?.backSideImage
                  : DefaultIdCardImage
              }
              alt="default"
              width={"250px"}
              height={"auto"}
            />
            <Typography
              textAlign={"center"}
              fontFamily={"MetropolisBold"}
              color={"#013047"}
              fontSize={"13px"}
            >
              Back Side Image
            </Typography>
          </Box>
        </Box>
      </CustomDialog>

      <Box
        bgcolor={"#FFEDE3"}
        padding={"20px"}
        borderRadius={"40px 0px 0px 40px"}
      >
        <Box
          display={"flex"}
          width={"100%"}
          justifyContent={"space-between"}
          alignItems={"right"}
        >
          <Typography
            fontFamily={"MetropolisBold"}
            fontSize={"20px"}
            color={"#000000"}
          >
            Users
          </Typography>
          {userDetails?.status === "in-active" && (
            <Box width={"10%"}>
              <CustomButton
                title={"Verify User"}
                borderRadius={"5px"}
                handleClick={() =>
                  setOpenVerifyUserModal({ isOpen: true, data: userDetails })
                }
              />
            </Box>
          )}
        </Box>
        <Box mt={2} bgcolor={"#FA8842"} padding={"15px"} borderRadius={"8px"}>
          <Box display={"flex"} alignItems={"center"} gap={"10px"}>
            <Box width={"9%"}>
              <img src={userSvg} alt="user" width={"100px"} />
            </Box>
            <Box width={"15%"}>
              <Typography
                color={"#000000"}
                fontFamily={"MetropolisBold"}
                fontSize={"22px"}
              >
                {userDetails?.name}
              </Typography>
              <Box>
                <Typography
                  color={"#000000"}
                  fontFamily={"MetropolisSemiBold"}
                  fontSize={"16px"}
                >
                  {userDetails?.role?.name}
                </Typography>
              </Box>
            </Box>
            <Box
              width={"76%"}
              bgcolor={"#fff"}
              padding={"10px 20px"}
              borderRadius={"20px"}
            >
              <Box mt={1} display={"flex"}>
                <Box width={"33.33%"}>
                  <Typography fontFamily={"MetropolisMedium"} fontSize={"14px"}>
                    <b>Verified With : </b>
                    {capitalizeFirstLetter(userDetails?.verifiedWith)}
                  </Typography>
                </Box>
                <Box width={"33.33%"}>
                  <Typography fontFamily={"MetropolisMedium"} fontSize={"14px"}>
                    <b>Gender : </b>
                    {capitalizeFirstLetter(userDetails?.gender)}
                  </Typography>
                </Box>
                <Box width={"33.33%"}>
                  <Typography fontFamily={"MetropolisMedium"} fontSize={"14px"}>
                    <b>DOB : </b> {new Date(userDetails?.dob).getDate()+"-"+new Date(userDetails?.dob).getMonth()+"-"+new Date(userDetails?.dob).getFullYear()}
                  </Typography>
                </Box>
              </Box>
              <Box mt={1} display={"flex"}>
                <Box width={"33.33%"}>
                  <Typography fontFamily={"MetropolisMedium"} fontSize={"14px"}>
                    <b>Mobile : </b>
                    {userDetails?.mobile}
                  </Typography>
                </Box>
                {userDetails?.email && (
                  <Box width={"33.33%"}>
                    <Typography
                      fontFamily={"MetropolisMedium"}
                      fontSize={"14px"}
                    >
                      <b>Official Email : </b>
                      {userDetails?.email ?? "NA"}
                    </Typography>
                  </Box>
                )}
                {userDetails?.email ? null : (
                  <Box width={"33.33%"} onClick={() => setPreviewIdCard(true)}>
                    <Typography
                      fontFamily={"MetropolisMedium"}
                      fontSize={"14px"}
                      color={"#FA8842"}
                      sx={{ textDecoration: "underline", cursor: "pointer" }}
                    >
                      View ID card
                    </Typography>
                  </Box>
                )}
              </Box>
              <Box mt={1} display={"flex"}>
                <Box width={"33.33%"}>
                  <Typography fontFamily={"MetropolisMedium"} fontSize={"14px"}>
                    <b>Personal Email ID : </b>
                    {userDetails?.personalEmail}
                  </Typography>
                </Box>
                <Box width={"33.33%"}>
                  <Typography fontFamily={"MetropolisMedium"} fontSize={"14px"}>
                    <b>
                      {userDetails?.role?.name === "Student"
                        ? "College/University"
                        : "Company/Organization"}{" "}
                      :{" "}
                    </b>
                    {userDetails?.role?.name === "Student"
                      ? userDetails?.school
                      : userDetails?.company}
                  </Typography>
                </Box>
              </Box>
              <Box mt={1} display={"flex"}>
                <Box width={"33.33%"}>
                  <Typography fontFamily={"MetropolisMedium"} fontSize={"14px"}>
                    <b>OrdersCount : </b>
                    {userDetails?.ordersCount ?? "NA"}
                  </Typography>
                </Box>
                <Box width={"33.33%"}>
                  <Typography fontFamily={"MetropolisMedium"} fontSize={"14px"}>
                    <b>RedeemedCount : </b>
                    {userDetails?.redeemedCount ?? "NA"}
                  </Typography>
                </Box>
                <Box width={"33.33%"}>
                  <Typography fontFamily={"MetropolisMedium"} fontSize={"14px"}>
                    <b>ViewCount : </b>
                    {userDetails?.viewCount ?? "NA"}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default UserView;
