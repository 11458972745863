import React from 'react';
import { Link } from 'react-router-dom';
import Colors from '../../constants/Colors';

const CustomLink = (props) => {
    const { route } = props;
    return(
        <Link to={route} style={styles.link}>
            {props.children}
        </Link>
    )
}

const styles = {
    link : {
        textDecoration : 'none',
        color : Colors.textColor,
    }
}

export default CustomLink;