
import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import CustomInput from "../../../components/Input";
import { Box } from "@mui/material";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomSelect from "../../../components/Select/CustomSelect";
import { ApiRequest } from "../../../../services/api-request";
import CustomButton from "../../../components/Button";
import { useSnackbar } from "../../../../context/useSnackbar";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../../../context/AppContext";



function AddEditSubCategory(props) {
  const { currentSubCategory, isEdit } = props;

  const navigate = useNavigate();

  const { handleLogoutSuccess } = useAppContext();

  const [categoryList, setCategoryList] = useState([]);
  const { openSnackbar } = useSnackbar();

  useEffect(() => {
    getAllCategories();
  }, []);

  const schema = yup
    .object({
      categoryName: yup.string().required("Category Name is required"),
      subCategory: yup.string().required("subCategory Name is required"),
    })
    .required();

  const { control, handleSubmit } = useForm({
    resolver: yupResolver(schema),
    defaultValues: !isEdit
      ? { categoryName: "", subCategory: "" }
      : {
          categoryName: currentSubCategory?.category?._id,
          subCategory: currentSubCategory?.name,
        },
  });

  const getAllCategories = () => {
    ApiRequest({
      url: `category/all`,
      method: "GET",
    })
      .then((res) => {
        const modifiedCategoryList = res?.map((res) => {
          const list = {
            name: res?.name,
            value: res?._id,
          };
          return list;
        });
        setCategoryList(modifiedCategoryList);
      })
      .catch((err) => {
        console.log(err);
        openSnackbar(err?.response?.data?.message);
        if (err?.statusCode === 403 || err?.statusCode === 401) {
          if (navigate) {
            navigate("/login"); // Navigate to "/login" route
            handleLogoutSuccess();
          }
        }
      });
  };

  const onSubmit = (data) => {
    if (isEdit && currentSubCategory) {
      const modifiedFormData = {
        name: data.subCategory,
      };
      ApiRequest({
        url: `sub-category/${currentSubCategory?._id}`,
        data: modifiedFormData,
        method: "PUT",
      })
        .then((res) => {
          if (res) {
            openSnackbar(
              "Sub-category has been updated successfully",
              "success"
            );
          }
          props.onClose();
          props.getAllSubCategories();
        })
        .catch((err) => {
          openSnackbar(err?.response?.data?.message, "error");
          if (err?.statusCode === 403 || err?.statusCode === 401) {
            if (navigate) {
              navigate("/login"); // Navigate to "/login" route
              handleLogoutSuccess();
            }
          }
        });
    } else {
      const modifiedFormData = {
        category: data.categoryName,
        name: data.subCategory,
      };
      ApiRequest({
        url: `sub-category`,
        data: modifiedFormData,
        method: "POST",
      })
        .then((res) => {
          if (res) {
            openSnackbar(
              "Sub-Category has been created successfully",
              "success"
            );
          }
          props.onClose();
          props.getAllSubCategories();
        })
        .catch((err) => {
          openSnackbar(err?.response?.data?.message, "error");
          if (err?.statusCode === 403 || err?.statusCode === 401) {
            if (navigate) {
              navigate("/login"); // Navigate to "/login" route
              handleLogoutSuccess();
            }
          }
        });
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box>
          <Controller
            name="categoryName"
            control={control}
            render={({ field, disabled, fieldState: { error } }) => (
              <CustomSelect
                {...field}
                options={categoryList}
                borderRadius={"5px"}
                placeHolder={"CategoryName"}
                error={!!error}
                helperText={error ? error.message : ""}
                disabled={isEdit ? true : false}
              />
            )}
          />
        </Box>
        <Box mt={2}>
          <Controller
            name={`subCategory`}
            control={control}
            render={({ field, fieldState: { error } }) => (
              <CustomInput
                {...field}
                inputLabel={"Enter Sub-category"}
                type={"text"}
                error={!!error}
                helperText={error ? error.message : ""}
                borderRadius={"5px"}
              />
            )}
          />
        </Box>
        <Box display={"flex"} justifyContent={"end"} gap={"10px"} mt={"20px"}>
          <Box width={"20%"}>
            <CustomButton
              border={"1px solid #000000"}
              color={"#000000"}
              title={"Cancel"}
              bgColor={"#F7F7F7"}
              handleClick={() => props.onClose()}
              borderRadius={"5px"}
            />
          </Box>
          <Box width={"20%"}>
            <CustomButton
              title={currentSubCategory?.open === "add" ? "Create" : "Save"}
              type={"submit"}
              borderRadius={"5px"}
            />
          </Box>
        </Box>
      </form>
    </>
  );
}

export default AddEditSubCategory;
