import { Grid, Box } from "@mui/material";
import React, { useRef, useState } from "react";
import { ApiRequest } from "../../../services/api-request";
import { useAppContext } from "../../../context/AppContext";
import locationSvg from "../../../images/location-pin.svg";
import { debounce } from "lodash";
import { useNavigate } from "react-router-dom";
import { CustomAutoCompleteWithIcon } from "../../components/AutoComplete/auto-complete";
import { removeSpacesAndGaps } from "../../utilities/common-utilities";
const SearchLocationAndOffer = () => {
  const navigate = useNavigate();
  const { handleLocationSelection, user, handleLogoutSuccess } =
    useAppContext();

  const [open, setOpen] = React.useState(false);
  const searchRef = useRef(null)
  const [openOfferSearchDropDown, setOpenOfferSearchDropDown] = useState(false);

  const [locationList, setLocationsList] = React.useState([]);

  const [loading, setLoading] = useState(false);

  const [offerSearch, setOfferSearch] = useState([]);

  const [noOptionTextForOffer, setOptionTextForOffer] = useState(false);

  const [noOptionTextForLocation, setNoOptionTextForLocation] = useState(false);

  let storedValue = null;
  const localStorageValue = window.localStorage.getItem("selectedLocality");
  if (localStorageValue) {
    storedValue = JSON.parse(localStorageValue);
  }

  const fetchLocations = async (searchQuery) => {
    setNoOptionTextForLocation(searchQuery ? true : false);
    // setNoOptionTextForLocation(searchQuery ? true : false);

    if (!searchQuery || searchQuery.trim() === "") {
      return; // Don't hit the API if searchQuery is empty
    }
    if (searchQuery?.length) {
      try {
        const response = await ApiRequest({
          url: `offer/city-locs`,
          data: {
            searchText: searchQuery.trim(),
            limit: 100,
            skip: 0,
          },
          method: "POST",
        });
        const modifiedPostalCodes = response?.data?.map((city) => {
          const code = {
            title: `${city?.cityLoc}`,
            value: city?.locality,
            city: city?.city,
          };
          return code;
        });
        setLocationsList(modifiedPostalCodes);
        // navigate(`/home/${city?.cityLoc}`);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleInputChange = (event, value) => {
    if (value?.title) {
      console.log(value,"VVVVVVVVVVVV");
      localStorage.setItem("selectedLocality", JSON.stringify(value));
      navigate(`/${value?.title}`);
      // sendDataToParent(true);
    } else {
      localStorage.setItem("selectedLocality", "");
      handleLocationSelection({});
      navigate(`/`);
    }
    if (value === "") {
      setLocationsList([]);
      handleLocationSelection({});
      navigate(`/`);
    }
    if (value) {
      handleLocationSelection({ title: value.title, locality: value.title });
    }
  };

  const debouncedFetchOptions = debounce(fetchLocations, 300);

  const fetchOfferSearch = async (searchQuery) => {

    let storedValue = null;
      const localStorageValue = window.localStorage.getItem("selectedLocality");
      if (localStorageValue) {
        storedValue = JSON.parse(localStorageValue);
      }

    setOptionTextForOffer(searchQuery ? true : false);
    setLoading(true);
    if (!searchQuery || searchQuery.trim() === "") {
      setLoading(false);
      return; // Don't hit the API if searchQuery is empty
    }
    if (searchQuery?.length) {
      try {
        var datas = {
          skip: 0,
          limit: 15,
          endsAt: {$lte: new Date()},
          startsAt: {$gte: new Date()},
          status: 'active',
          searchText: searchQuery.trim(),
          ...(user?.role?.code && {
            userType: `${
              user?.role?.code === "student"
                ? "STUDENT_ALL"
                : user?.role?.code === "corporate"
                ? "CORPORATE_ALL"
                : null
            }`,
          }),
        }

        if(storedValue && storedValue?.title)
        {
          // filteredEvents = filteredEvents.filter(item => item.city.length == 0 || item.city.includes(storedValue?.title))
          datas['cityLoc'] = [storedValue?.title]
        }

        const response = await ApiRequest({
          url: `offer/list`,
          method: "POST",
          data: datas
        });

        var filteredEvents = user && user.role && user.role.code ? response?.result?.filter(function(event){
          return event.toShow == user.role.code || event.toShow == 'all';
        }) : response?.result

        

        filteredEvents = filteredEvents.filter(function (event) {
          return event.status == 'active' && new Date(event.endsAt) >= new Date() && new Date(event.startsAt) <= new Date()
        });

        const modifiedResponse = filteredEvents.map((offer) => {
          if (offer?.type === "online") {
            return {
              title: `${offer?.name}, ${offer?.subCategories?.map(
                (item) => item?.name
              )}, ${offer?.category?.name} `,
              value: offer?._id,
            };
          } else {
            return {
              title: `${offer?.name}, ${
                offer?.locality &&
                offer?.locality?.map((item) => item).join(", ")
              }, ${offer?.city && offer?.city?.map((item) => item).join(", ")}`,
              value: offer?._id,
            };
          }
        });
        setOfferSearch(modifiedResponse);
      } catch (error) {
        console.log(error);
      }
    }
    setLoading(false);
  };

  const debouncedOptions = debounce(fetchOfferSearch, 300);

  const AddOfferVisitCount = (value) => {
    ApiRequest({
      url: `offer/visit/${value?.value}`,
      method: "PUT",
    })
      .then((res) => {})
      .catch((err) => {
        if (err?.statusCode === 403 || err?.statusCode === 401) {
          if (navigate) {
            navigate("/login"); // Navigate to "/login" route
            handleLogoutSuccess();
          }
        }
      });
  };

  const handleSearchOffersList = (e, value) => {
    const offerSearchString = value?.title?.split(",");
    handleLocationSelection({ searchString: e.target.value });
    if (value) {
      if (user?.role?.code === "student" || user?.role?.code === "corporate") {
        AddOfferVisitCount(value);
      }
      navigate(
        `/offer/${encodeURIComponent(removeSpacesAndGaps(offerSearchString[0]))}/${value?.value}`
      );
    }
  };

  const defaultValues = [
    {
      title: "Hyderabad",
      value: "hyderabad",
    },
    {
      title: "Bangalore",
      value: "bangalore",
    },
    {
      title: "Delhi",
      value: "delhi",
    },
    {
      title: "Chennai",
      value: "chennai",
    },
    {
      title: "Mumbai",
      value: "mumbai",
    },
  ];
  return (
    <>
      <Grid item justifyContent="center" alignItems="center" display="flex">
        <Box
          padding={"3px 10px"}
          borderRadius="28px"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <img src={locationSvg} width="15px" alt="location" height={"15px"} />
          <CustomAutoCompleteWithIcon
            open={openOfferSearchDropDown}
            defaultValue={
              storedValue?.title
                ? {
                    title: storedValue?.title,
                    value: storedValue?.title,
                  }
                : {}
            }
            noOptionsText={
              locationList?.length === 0 && noOptionTextForLocation
                ? "No results found. Try different keywords for better outcomes."
                : "Search Location"
            }
            handleChange={handleInputChange}
            onInputChange={(event, value) => {
              debouncedFetchOptions(value);
            }}
            sx={{ width: "100%" }}
            options={locationList?.length ? locationList : defaultValues}
            onOpen={() => {
              setOpenOfferSearchDropDown(true);
            }}
            onClose={() => {
              setOpenOfferSearchDropDown(false);
            }}
            loading={loading}
          />
          <CustomAutoCompleteWithIcon
            open={open}
            noOptionsText={
              offerSearch?.length === 0 && noOptionTextForOffer // Temporarily set to true to check the rendering
                ? "No results found. Try different keywords for better outcomes."
                : "Search Offers"
            }
            onInputChange={(event, value) => {
              // debouncedOptions(value);
              fetchOfferSearch(value);
              // window.location.reload();
            }}
            placeholder={"Search Offers"}
            handleChange={handleSearchOffersList}
            sx={{ width: "100%" }}
            options={offerSearch}
            loading={loading}
            onOpen={() => {
              setOpen(true);
            }}
            onClose={() => {
              setOpen(false);
            }}
          />
        </Box>
      </Grid>
    </>
  );
};

export default SearchLocationAndOffer;