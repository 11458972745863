// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.react-multi-carousel-item--active .car-img {
  width: 100%;
  opacity: 1 !important;
  object-fit: contain;
}

.all-ban .react-multi-carousel-item--active {
  margin-left: 10px;
  margin-right: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/app/screens/Offers/offer-view.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,qBAAqB;EACrB,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;AACpB","sourcesContent":[".react-multi-carousel-item--active .car-img {\n  width: 100%;\n  opacity: 1 !important;\n  object-fit: contain;\n}\n\n.all-ban .react-multi-carousel-item--active {\n  margin-left: 10px;\n  margin-right: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
