import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import CustomInput from "../../../components/Input";
import CustomButton from "../../../components/Button";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ApiRequest } from "../../../../services/api-request";
import FileUploader from "../../../components/FileUploader/file-uploder";
import CustomSelect from "../../../components/Select/CustomSelect";
import CustomDatePicker from "../../../components/DatePicker";
import {
  getDateMonthYear,
  getDateTimeArrayIn24Format,
} from "../../../utilities/date-utility";
import { useSnackbar } from "../../../../context/useSnackbar";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../../../context/AppContext";
import moment from "moment-timezone";

const schema = yup
  .object({
    bannerName: yup.string().required("Banner Name is required"),
    visibleFor: yup.string().required("Visible  For is required"),
    tillDate: yup.string().required("Till Date is required"),
    location: yup.string().required("location is required"),
    timeEnds: yup.string().required("TimeEnds is required"),
  })
  .required();

const AddEditBanner = (props) => {
  const navigate = useNavigate();

  const { user, handleLogoutSuccess } = useAppContext();

  const { isEdit, banner } = props;

  const [allCategories, setAllCategories] = useState([]);

  const defaultLocations = [
    {
      name: "Home",
      value: "Home",
    },
    {
      name: "Online",
      value: "Online",
    },
    {
      name: "In-store",
      value: "In-store",
    },
    {
      name: "Student",
      value: "Student",
    },
    {
      name: "Corporate",
      value: "Corporate",
    },
    {
      name: "Top-Offers",
      value: "Top-Offers",
    },
    {
      name: "Featured Offers",
      value: "Featured Offers",
    },
  ];

  useEffect(() => {
    getAllCategories();
  }, []);

  const getAllCategories = () => {
    ApiRequest({
      url: `category/all?skip=0&limit=100`,
      method: "GET",
    })
      .then((res) => {
        const modifiedCategories = res?.map((category) => ({
          name: `Category - ${category?.name}`,
          value: `Category-${category?.name}`,
        }));
        setAllCategories([...defaultLocations, ...modifiedCategories]);
      })
      .catch((err) => {
        openSnackbar(err?.response?.data?.message, "error");
        if (err?.statusCode === 403 || err?.statusCode === 401) {
          if (navigate) {
            navigate("/login"); // Navigate to "/login" route
            handleLogoutSuccess();
          }
        }
      });
  };

  const visibleFor = [
    {
      name: "Student",
      value: "student",
    },
    {
      name: "Corporate",
      value: "corporate",
    },
    {
      name: "Both",
      value: "all",
    },
  ];

  const timeOptions = getDateTimeArrayIn24Format().map((timeString) => ({
    name: timeString,
    value: timeString,
  }));

  const [uploadBanner, setUploadBanner] = useState("");

  const { openSnackbar } = useSnackbar();

  const onHandleUploadedFile = (file) => {
    if (file) {
      setUploadBanner(file?.url);
    } else {
      setUploadBanner([]);
    }
  };

  // const isoDate = banner?.endsAt;
  // const utcMoment = moment(isoDate, "YYYY-MM-DDTHH:mm:ss.SSS[Z]");

  const istDateTimeEndsAt = moment.utc(banner?.endsAt).tz("Asia/Kolkata");

  const istDateEndsAt = moment(istDateTimeEndsAt, "YYYY-MM-DDTHH:mm:ss.SSS[Z]");
  const istEndsTime = istDateTimeEndsAt.format("HH:mm");

  const timeString = banner?.endsAt;

  let time = "";

  if (timeString && typeof timeString === "string") {
    const timeParts = timeString.split("T");
    if (timeParts.length > 1) {
      time = timeParts[1].slice(0, 5);
    }
  }

  const { handleSubmit, control } = useForm({
    resolver: yupResolver(schema),
    defaultValues: !isEdit
      ? {
          bannerName: "",
          visibleFor: "",
          tillDate: "",
          location: "",
          timeEnds: "",
          bannerUrl: "",
        }
      : {
          bannerName: banner?.name,
          visibleFor: banner?.toShow,
          location: banner?.location,
          tillDate: banner?.endsAt && istDateEndsAt,
          timeEnds: banner?.endsAt && istEndsTime,
          bannerUrl: banner?.url,
        },
  });

  const onSubmit = (data) => {
    const endsAt = new Date(data.tillDate);

    const istEndsAtDate = getDateMonthYear(endsAt);
    const istEndsTime = data.timeEnds;
    const istEndsAtDateTimeString = `${istEndsAtDate} ${istEndsTime}`;

    const utcEndsATimeString = moment
      .tz(istEndsAtDateTimeString, "Asia/Kolkata")
      .utc()
      .format();

    const modifiedFormData = {
      name: data.bannerName,
      image: uploadBanner?.logoResponseFiles?.url
        ? uploadBanner?.logoResponseFiles?.url
        : banner?.image,
      location: data.location,
      toShow: data.visibleFor,
      endsAt: utcEndsATimeString,
      url: data?.bannerUrl,
    };
    if (isEdit && banner?._id) {
      ApiRequest({
        url: `banner/${banner?._id}`,
        data: modifiedFormData,
        method: "PUT",
      })
        .then((res) => {
          if (res) {
            openSnackbar("Banners has been updated successfully", "success");
          }
          props.getAllBannersList();
          props.onClose();
        })
        .catch((err) => {
          openSnackbar(
            err?.response?.data?.message === "Banner already exist"
              ? "Banner Name Already Exists"
              : err?.response?.data?.message,
            "error"
          );
          if (err?.statusCode === 403 || err?.statusCode === 401) {
            if (navigate) {
              navigate("/login"); // Navigate to "/login" route
              handleLogoutSuccess();
            }
          }
        });
    } else {
      ApiRequest({
        url: `banner`,
        data: modifiedFormData,
        method: "POST",
      })
        .then((res) => {
          if (res) {
            openSnackbar("Banners has been created successfully", "success");
          }
          props.getAllBannersList();
          props.onClose();
        })
        .catch((err) => {
          openSnackbar(
            err?.response?.data?.message === "Banner already exist"
              ? "Banner Name Already Exists"
              : err?.response?.data?.message,
            "error"
          );
          if (err?.statusCode === 403 || err?.statusCode === 401) {
            if (navigate) {
              navigate("/login"); // Navigate to "/login" route
              handleLogoutSuccess();
            }
          }
        });
    }
  };

  return (
    <>
      <Box>
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <Box>
            <FileUploader
              onSelectedFile={(file) => onHandleUploadedFile(file)}
              accept={"image/png,image/jpeg,image/jpg"}
              displayType={"square"}
              padding={"30px"}
              setUploadedFiles={setUploadBanner}
              uploadType={"logo"}
              heading={
                uploadBanner?.logoResponseFiles?.originalname
                  ? uploadBanner?.logoResponseFiles?.originalname
                  : banner?.image
                  ? banner?.image
                  : "Upload Banner"
              }
            />
          </Box>
          <Box display={"flex"} alignItems={"center"} gap={"10px"}>
            <Box mt={2} width={"50%"}>
              <Controller
                name="bannerName"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <CustomInput
                    {...field}
                    inputLabel={"Enter Banner Name"}
                    borderRadius={"5px"}
                    type={"text"}
                    error={!!error}
                    helperText={error ? error.message : ""}
                  />
                )}
              />
            </Box>
            <Box mt={2} width={"50%"}>
              <Controller
                name="location"
                control={control}
                rules={{ required: true }}
                defaultValue=""
                render={({ field: { ...field }, fieldState: { error } }) => (
                  <CustomSelect
                    {...field}
                    borderRadius={"5px"}
                    placeHolder={"Select Location"}
                    error={!!error}
                    helperText={error ? error.message : ""}
                    options={allCategories}
                  />
                )}
              />
            </Box>
          </Box>
          <Box display={"flex"} gap={"10px"} alignItems={"center"}>
            <Box width={"50%"}>
              <Controller
                name="visibleFor"
                control={control}
                rules={{ required: true }}
                defaultValue=""
                render={({ field: { ...field }, fieldState: { error } }) => (
                  <CustomSelect
                    {...field}
                    borderRadius={"5px"}
                    placeHolder={"Visible For"}
                    error={!!error}
                    helperText={error ? error.message : ""}
                    options={visibleFor}
                  />
                )}
              />
            </Box>
            <Box width={"50%"}>
              <Controller
                name="bannerUrl"
                control={control}
                rules={{ required: true }}
                defaultValue=""
                render={({ field: { ...field }, fieldState: { error } }) => (
                  <CustomInput
                    {...field}
                    inputLabel={"Enter Banner Url"}
                    borderRadius={"5px"}
                    type={"text"}
                    error={!!error}
                    helperText={error ? error.message : ""}
                  />
                )}
              />
            </Box>
          </Box>
          <Box width={"50%"} mt={"12px"}>
            <Controller
              name={"tillDate"}
              rules={{ required: true }}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <CustomDatePicker
                  {...field}
                  label={"End Date"}
                  error={!!error}
                  helperText={error ? error.message : ""}
                  disablePast={true}
                />
              )}
            />
          </Box>
          <Box width={"50%"}>
            <Controller
              name="timeEnds"
              defaultValue=""
              control={control}
              rules={{ required: true }}
              render={({ field: { ...field }, fieldState: { error } }) => (
                <CustomSelect
                  {...field}
                  borderRadius={"5px"}
                  error={!!error}
                  helperText={error ? error.message : ""}
                  placeHolder={"Time"}
                  options={timeOptions}
                />
              )}
            />
          </Box>
          <Box display={"flex"} justifyContent={"end"} gap={"10px"} mt={"20px"}>
            <Box width={"20%"}>
              <CustomButton
                border={"1px solid #000000"}
                color={"#000000"}
                title={"Cancel"}
                bgColor={"#F7F7F7"}
                handleClick={() => props.onClose()}
                borderRadius={"5px"}
              />
            </Box>
            <Box width={"20%"}>
              <CustomButton
                title={"Submit"}
                type={"submit"}
                borderRadius={"5px"}
              />
            </Box>
          </Box>
        </form>
      </Box>
    </>
  );
};

const styles = {
  cursor: {
    cursor: "pointer",
  },
};

export default AddEditBanner;
