export const ACTIONS = {
  OPEN_SOCKET: "OPEN_SOCKET",
  OPEN_SOCKET_SUCCESS: "OPEN_SOCKET_SUCCESS",
  OPEN_SOCKET_FAILURE: "OPEN_SOCKET_FAILURE",
  SOCKET_CHANNEL_SEND_PAYLOAD: "SOCKET_CHANNEL_SEND_PAYLOAD",

  GET_NOTIFICATIONS_SOCKET: "GET_NOTIFICATIONS_SOCKET",
  GET_NOTIFICATIONS_SOCKET_SUCCESS: "GET_NOTIFICATIONS_SOCKET_SUCCESS",
  GET_NOTIFICATIONS_SOCKET_FAILURE: "GET_NOTIFICATIONS_SOCKET_FAILURE",

  SET_NOTIFICATION_STATUS_SOCKET: "SET_NOTIFICATION_STATUS_SOCKET",
  SET_NOTIFICATION_STATUS_SOCKET_SUCCESS:
    "SET_NOTIFICATION_STATUS_SOCKET_SUCCESS",
  SET_NOTIFICATION_STATUS_SOCKET_FAILURE:
    "SET_NOTIFICATION_STATUS_SOCKET_FAILURE",
  ADD_NOTIFICATION_FROM_SOCKET: "ADD_NOTIFICATION_FROM_SOCKET",
  RESET_NOTIFICATION_FROM_SOCKET: "RESET_NOTIFICATION_FROM_SOCKET",
  SET_MARK_AS_READ_SOCKET_DATA: "SET_MARK_AS_READ_SOCKET_DATA",
  OPEN_SOCKET_FOR_ASSESSMENT_START_END: "OPEN_SOCKET_FOR_ASSESSMENT_START_END",
  ADD_LAB_STATE_CHANGE_FROM_SOCKET: "ADD_LAB_STATE_CHANGE_FROM_SOCKET",
};

export const ENABLE_CHANNELS_REDUX_ACTION = "ENABLE_CHANNELS_REDUX_ACTION";

export const enableChannels = () => {
  return {
    type: ENABLE_CHANNELS_REDUX_ACTION,
  };
};
