import React, { useState, useEffect } from "react";
import styles from "./notifications.module.css";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { enableChannels } from "../../redux/constants";
import notificationsSvg from "../../../images/Notification Icon.svg";
import Divider from "@mui/material/Divider";
import {  Box, Typography } from "@mui/material";
import {
  getNotificationStart,
  selectGeneralNotifications,
  setNotificationStatusSuccess,
} from "../../redux/slicers/socketSlicer";
import { ApiRequest } from "../../../services/api-request";
import { useSnackbar } from "../../../context/useSnackbar";
import { extractTimeIn24HoursFormat } from "../../utilities/date-utility";
import { useNavigate } from "react-router-dom";
import { removeSpacesAndGaps } from "../../utilities/common-utilities";

const Notifications = (props) => {
  const { openNotificationModal, setOpenNotificationModal } = props;

  const [notificationList, setNotificationList] = useState([]);

  const [skip, setSkip] = useState(0);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(enableChannels());
  }, []);

  const generalNotifications = useSelector(
    (state) => selectGeneralNotifications(state),
    shallowEqual
  );

  useEffect(() => {
    setNotificationList(generalNotifications?.notifications);
  }, [generalNotifications?.notifications, notificationList]);

  const { openSnackbar } = useSnackbar();

  const navigateToRelatedLocation = (notification) => {
    if (notification?.offer?._id) {
      window.open(
        // `/offer/${notification?.offer?.name}/${notification?.offer?._id}/details`
        `/offer/${encodeURIComponent(
          removeSpacesAndGaps(notification?.offer?.name)
        )}/${notification?.offer?._id}`
      );
    } else if (notification?.location) {
      navigate(`/${notification?.location}/offers`);
    } else {
      navigate(
        `/categories/${notification?.category?.name}-coupons-and-deals/${notification?.category?._id}/details`
      );
    }
  };

  const isNotificationRead = (notification) => {
    if (notification?.isRead === true && notification?.offer?._id) {
      window.open(
        // `/offer/${notification?.offer?.name}/${notification?.offer?._id}/details`
        `/offer/${encodeURIComponent(
          removeSpacesAndGaps(notification?.offer?.name)
        )}/${notification?.offer?._id}`
      );
    } else if (notification?.isRead === true && notification?.location) {
      navigate(`/${notification?.location}/offers`);
    } else if (notification?.isRead === true && notification?.category?._id) {
      navigate(
        `/categories/${notification?.category?.name}-coupons-and-deals/${notification?.category?._id}/details`
      );
    } else {
      notificationNotRead(notification);
    }
  };

  // const notificationNotRead = async (notification) => {
  //   try {
  //     await dispatch(
  //       setNotificationStatusSuccess({
  //         id: notification?._id,
  //         isRead: true,
  //         type: "GENERAL",
  //       })
  //     );
  //     await dispatch(
  //       getNotificationStart({ type: "GENERAL", skip: 0, limit: 10 })
  //     );
  //     await new Promise((resolve) => setTimeout(resolve, 200));

  //     const payload = {
  //       isRead: true,
  //     };
  //     const res = await ApiRequest({
  //       url: `notification/update/${notification?._id}`,
  //       method: "PUT",
  //       data: payload,
  //     });
  //     setOpenNotificationModal(false);
  //     if (res) {
  //       navigateToRelatedLocation(notification);
  //     }
  //   } catch (err) {
  //     openSnackbar(err?.response?.data?.message, "error");
  //   } finally {
  //     setOpenNotificationModal(false);
  //   }
  // };

  const notificationNotRead = (notification) => {
    dispatch(getNotificationStart({ type: "GENERAL", skip: 0, limit: 10 }));

    if (notificationList.length) {
      const payload = {
        isRead: true,
      };
      ApiRequest({
        url: `notification/update/${notification?._id}`,
        method: "PUT",
        data: payload,
      })
        .then((res) => {
          setOpenNotificationModal(false);
          if (res) {
            navigateToRelatedLocation(notification);
          }
          dispatch(
            setNotificationStatusSuccess({
              id: notification?._id,
              isRead: true,
              type: "GENERAL",
            })
          );
        })
        .catch((err) => {
          openSnackbar(err?.response?.data?.message, "error");
        });
      setOpenNotificationModal(false);
    }
  };

  const readAllNotifications = generalNotifications.notifications?.map(
    (item) => item?.isRead
  );
  const containsFalse = readAllNotifications?.includes(false);
  const result = !containsFalse;

  useEffect(() => {
    const handleClick = (e) => {
      const hasNoCloseClass = e.target.closest(".noClose");

      const hasViewMoreNoClose = e.target.closest(".viewMoreNoClose");

      if (hasViewMoreNoClose) {
        setOpenNotificationModal(true);
        return;
      }

      if (hasNoCloseClass) {
        // Toggle the state directly
        setOpenNotificationModal((prev) => !prev);
      } else {
        // Close the modal if clicked outside the "noClose" element
        setOpenNotificationModal(false);
      }
    };

    // Add the event listener when the component mounts
    document.addEventListener("click", handleClick);

    // Cleanup the event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  const handleViewMoreClick = (page) => {
    const limit = 10;
    const newSkip = (page - 1) * limit;
    setSkip(newSkip);
    dispatch(
      getNotificationStart({
        type: "GENERAL",
        skip: newSkip,
        limit: 10,
      })
    );
  };

  return (
    <>
      <Box position={"relative"}>
        <Box position={"relative"} className="noClose">
          <img
            src={notificationsSvg}
            alt=""
            width={"27px"}
            style={{ objectFit: "contain" }}
          />
          <Box
            className={styles.containerBox}
            sx={{
              backgroundColor: `${result === false ? "green" : "transparent"}`,
            }}
            position={"absolute"}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            top={"0px"}
            right={"0px"}
          ></Box>
        </Box>
        <div>
          {openNotificationModal && (
            <div>
              <Box mt={"20px"} className={styles.content}>
                <Box
                  width={"380px"}
                  boxShadow={"rgba(170, 180, 190, 0.3) 0px 4px 20px"}
                  borderRadius={"5px"}
                  padding={"15px 0px 15px 15px"}
                  height={"auto"}
                  sx={{ backgroundColor: "#fff" }}
                  classNam={styles.notificationContainer}
                >
                  <Typography
                    fontFamily={"MetropolisBold"}
                    className={styles.heading}
                  >
                    Notifications
                  </Typography>
                  <Divider textAlign="right" className={styles.divider}>
                    All
                  </Divider>
                  <Box
                    height={"auto"}
                    minHeight={"100px"}
                    maxHeight={"400px"}
                    sx={{
                      overflowY: "scroll",
                      "&::-webkit-scrollbar": {
                        width: "10px",
                      },
                      "&::-webkit-scrollbar-thumb": {
                        backgroundColor: "#888",
                        borderRadius: "10px",
                      },
                      "&::-webkit-scrollbar-track": {
                        backgroundColor: "#ddd",
                      },
                    }}
                  >
                    {notificationList.length ? (
                      notificationList.map((notification) => {
                        return (
                          <Box
                            padding={"0px 10px 5px 5px"}
                            key={notification?._id}
                            mt={"13px"}
                            onClick={() => {
                              isNotificationRead(notification);
                            }}
                          >
                            <Box
                              display={"flex"}
                              gap={"10px"}
                              alignItems={"baseline"}
                              justifyContent={"space-between"}
                            >
                              <Box
                                display={"flex"}
                                gap={"8px"}
                                alignItems={"baseline"}
                              >
                                <Box
                                  sx={{
                                    backgroundColor:
                                      notification?.isRead === false
                                        ? "green !important"
                                        : "grey !important",
                                  }}
                                  width={"8px"}
                                  height={"8px"}
                                  borderRadius={"50%"}
                                ></Box>
                                <Box>
                                  <p
                                    className={styles.notification}
                                    style={{
                                      fontFamily: "MetropolisRegular ",
                                    }}
                                  >
                                    {notification?.title}
                                  </p>
                                  <p
                                    className={styles.notificationSubHeading}
                                    style={{
                                      fontFamily: "MetropolisRegular ",
                                    }}
                                  >
                                    {notification?.message}
                                  </p>
                                </Box>
                              </Box>
                              <Box>
                                <p
                                  className={styles.notificationTime}
                                  style={{
                                    fontFamily: "MetropolisRegular ",
                                  }}
                                >
                                  {" "}
                                  {extractTimeIn24HoursFormat(
                                    notification?.createdAt
                                  )}
                                </p>
                              </Box>
                            </Box>
                          </Box>
                        );
                      })
                    ) : (
                      <Box
                        padding={"40px 0px"}
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                      >
                        <Typography
                          fontFamily={"MetropolisBold"}
                          color={"#000"}
                          fontSize={"16px"}
                        >
                          No Notifications Available
                        </Typography>
                      </Box>
                    )}
                  </Box>
                  <Box
                    display={"flex"}
                    mt={"12px"}
                    justifyContent={"center"}
                    className="viewMoreNoClose"
                    onClick={() => handleViewMoreClick(skip / 10 + 2)}
                  >
                    <Typography
                      fontFamily={"MetropolisRegular"}
                      fontSize={"16px"}
                      color={"rgb(0, 0, 0)"}
                    >
                      {"View More"}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </div>
          )}
        </div>
      </Box>
    </>
  );
};

export default Notifications;
