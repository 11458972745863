// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (min-width: 320px) and (max-width: 990px) {
  .imageSection img {
    width: 350px !important;
    height: 630px !important;
    object-fit: cover !important;
  }
  .mainContainerOfContactUS {
    width: 270px !important;
  }
}
.mainContainerOfContactUS {
  width: 500px;
}
.imageSection img {
  width: auto;
  height: 540px;
  object-fit: contain;
}
`, "",{"version":3,"sources":["webpack://./src/app/screens/Contact/contact-us.css"],"names":[],"mappings":"AAAA;EACE;IACE,uBAAuB;IACvB,wBAAwB;IACxB,4BAA4B;EAC9B;EACA;IACE,uBAAuB;EACzB;AACF;AACA;EACE,YAAY;AACd;AACA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;AACrB","sourcesContent":["@media (min-width: 320px) and (max-width: 990px) {\n  .imageSection img {\n    width: 350px !important;\n    height: 630px !important;\n    object-fit: cover !important;\n  }\n  .mainContainerOfContactUS {\n    width: 270px !important;\n  }\n}\n.mainContainerOfContactUS {\n  width: 500px;\n}\n.imageSection img {\n  width: auto;\n  height: 540px;\n  object-fit: contain;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
