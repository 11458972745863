import { Box, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import Footer from "../../components/Footer";
import styles from "./webcareers.module.css";
import growthSvg from "../../../images/growth.svg";
import CustomButton from "../../components/Button";
import { ApiRequest } from "../../../services/api-request";
import { useParams } from "react-router-dom";
import ApplyJobForm from "./apply-job-form";
import CustomDialog from "../../components/Dialog/custom-dialog";
const WebCareererView = () => {
  const { Id } = useParams();

  const [individualCareerer, setIndividualCareerer] = useState({});

  const [openJobModal, setOpenJobModal] = useState({
    open: false,
    data: {},
  });

  useEffect(() => {
    if (Id) {
      getIndividualCareerer(Id);
    }
  }, [Id]);

  const getIndividualCareerer = (Id) => {
    ApiRequest({
      url: `career/${Id}`,
      method: "GET",
    })
      .then((res) => {
        setIndividualCareerer(res);
      })
      .catch((err) => {});
  };

  const handleOpenModal = () => {
    setOpenJobModal({
      open: true,
      data: individualCareerer,
    });
  };

  return (
    <>
      {openJobModal.open && (
        <CustomDialog
          width={"500px"}
          open={openJobModal.open}
          title={"Apply Job"}
          onClose={() => setOpenJobModal({ open: false })}
        >
          <ApplyJobForm
            onClose={() => setOpenJobModal({ open: false, data: {} })}
            getIndividualCareerer={() => getIndividualCareerer(Id)}
            jobID={individualCareerer}
          />
        </CustomDialog>
      )}
      <Box>
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          mt={2}
        >
          <Box width={"900px"}>
            <Box height={"45vh"}>
              <Box
                boxShadow={"0px 0px 16px #67676729"}
                padding={"15px 20px"}
                borderRadius={"15px"}
              >
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Typography
                    className={styles.careerViewJobHeading}
                    sx={{ fontFamily: "MetropolisSemiBold" }}
                  >
                    {individualCareerer.jobTitle}
                  </Typography>
                  <Box item md={4}>
                    <CustomButton
                      title={"Apply"}
                      padding={"10px 30px"}
                      handleClick={() => handleOpenModal()}
                    />
                  </Box>
                </Box>

                <Box display={"flex"} gap={"5px"} alignItems={"center"}>
                  <img src={growthSvg} alt="" />
                  <Typography
                    className={styles.jobDes}
                    sx={{ fontFamily: "MetropolisRegular" }}
                  >
                    Job ID : {individualCareerer.jobId}
                  </Typography>
                </Box>
                <Box mt={3}>
                  <Typography
                    className={styles.WebJobCard}
                    sx={{ fontFamily: "MetropolisSemiBold" }}
                  >
                    About the job
                  </Typography>
                  <Typography
                    className={styles.WebJobDes}
                    sx={{ fontFamily: "MetropolisRegular" }}
                  >
                    {individualCareerer.jobDescription}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box mt={"30px"}>
          <Footer from={"career"} />
        </Box>
      </Box>
    </>
  );
};

export default WebCareererView;
