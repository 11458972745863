import { Grid, Typography, Box } from "@mui/material";
import React from "react";

const OrderTransactionDetails = (props) => {
  const { orderDetails } = props;
  return (
    <>
      <Box mt={"10px"}>
        <Box
          border={"1px solid #FA8842"}
          borderRadius={"15px"}
          padding={"10px 10px"}
        >
          <Grid container spacing={2}>
            <Grid item md={3}>
              <Typography
                fontFamily={"MetropolisRegular"}
                fontSize={"14px"}
                color={"#000000"}
              >
                <b>OfferTitle : </b>
                {orderDetails?.offer?.name}
              </Typography>
            </Grid>
            <Grid item md={3}>
              <Typography
                fontFamily={"MetropolisRegular"}
                fontSize={"14px"}
                color={"#000000"}
              >
                <b>Category : </b>
                {orderDetails?.offer?.category?.name}
              </Typography>
            </Grid>
            <Grid item md={3}>
              <Typography
                fontFamily={"MetropolisRegular"}
                fontSize={"14px"}
                color={"#000000"}
              >
                <b>SubCategory : </b>
                {orderDetails?.offer?.subCategories?.map((item) => item?.name)}
              </Typography>
            </Grid>
            <Grid item md={3}>
              <Typography
                fontFamily={"MetropolisRegular"}
                fontSize={"14px"}
                color={"#000000"}
              >
                <b>OfferStatus : </b>
                {orderDetails?.offer?.status}
              </Typography>
            </Grid>
          </Grid>
        </Box>

        <Box
          border={"1px solid #FA8842"}
          borderRadius={"15px"}
          padding={"10px 10px"}
          mt={"5px"}
        >
          <Grid container spacing={2}>
            <Grid item md={3}>
              <Typography
                fontFamily={"MetropolisRegular"}
                fontSize={"14px"}
                color={"#000000"}
              >
                <b>User Type : </b>
                {orderDetails?.offer?.toShow}
              </Typography>
            </Grid>
            <Grid item md={3}>
              <Typography
                fontFamily={"MetropolisRegular"}
                fontSize={"14px"}
                color={"#000000"}
              >
                <b>Offer Type : </b>
                {orderDetails?.offer?.type}
              </Typography>
            </Grid>
            <Grid item md={3}>
              <Typography
                fontFamily={"MetropolisRegular"}
                fontSize={"14px"}
                color={"#000000"}
              >
                <b>SubType : </b>
                {orderDetails?.offer?.subType}
              </Typography>
            </Grid>
            <Grid item md={3}>
              <Typography
                fontFamily={"MetropolisRegular"}
                fontSize={"14px"}
                color={"#000000"}
              >
                <b>Top Offer : </b>
                {orderDetails?.offer?.enableTopOffer === true
                  ? "True"
                  : "False"}
              </Typography>
            </Grid>
          </Grid>
        </Box>

        <Box
          border={"1px solid #FA8842"}
          borderRadius={"15px"}
          padding={"10px 10px"}
          mt={"5px"}
        >
          <Grid container spacing={2}>
            <Grid item md={3}>
              <Typography
                fontFamily={"MetropolisRegular"}
                fontSize={"14px"}
                color={"#000000"}
              >
                <b>Actual Price : </b>
                {orderDetails?.actualPrice}
              </Typography>
            </Grid>
            <Grid item md={3}>
              <Typography
                fontFamily={"MetropolisRegular"}
                fontSize={"14px"}
                color={"#000000"}
              >
                <b>Discount Value : </b>
                {orderDetails?.discountInfo?.value}
              </Typography>
            </Grid>
            <Grid item md={3}>
              <Typography
                fontFamily={"MetropolisRegular"}
                fontSize={"14px"}
                color={"#000000"}
                sx={{ wordBreak: "break-all" }}
              >
                <b>Payable Price : </b>
                {orderDetails?.payablePrice}
              </Typography>
            </Grid>
            <Grid item md={3}>
              <Typography
                fontFamily={"MetropolisRegular"}
                fontSize={"14px"}
                color={"#000000"}
                sx={{ wordBreak: "break-word" }}
              >
                <b>Convenience Fee : </b>
                {(
                  Number(orderDetails?.baseFeePrice) + Number(orderDetails?.gstForCustomerPrice)
                ).toFixed(2) ?? "NA"}
              </Typography>
            </Grid>
          </Grid>
        </Box>

        <Box
          border={"1px solid #FA8842"}
          borderRadius={"15px"}
          mt={"5px"}
          padding={"10px 10px"}
        >
          <Grid container spacing={2}>
            <Grid item md={3}>
              <Typography
                fontFamily={"MetropolisRegular"}
                fontSize={"14px"}
                color={"#000000"}
                sx={{ wordBreak: "break-all" }}
              >
                <b>Coupon Name : </b>
                {orderDetails?.couponInfo?.code ?? "NA"}
              </Typography>
            </Grid>
            <Grid item md={3}>
              <Typography
                fontFamily={"MetropolisRegular"}
                fontSize={"14px"}
                color={"#000000"}
              >
                <b>Coupon Value : </b>
                {orderDetails?.couponInfo?.value ?? "NA"}
              </Typography>
            </Grid>
            <Grid item md={6}>
              <Typography
                fontFamily={"MetropolisRegular"}
                fontSize={"14px"}
                color={"#000000"}
                sx={{ wordBreak: "break-all" }}
              >
                <b>Order ID : </b>
                {orderDetails?.payment?.orderId}
              </Typography>
            </Grid>
          </Grid>
        </Box>

        <Box
          border={"1px solid #FA8842"}
          borderRadius={"15px"}
          mt={"5px"}
          padding={"10px 10px"}
        >
          <Grid container spacing={2}>
            <Grid item md={3}>
              <Typography
                fontFamily={"MetropolisRegular"}
                fontSize={"14px"}
                sx={{ wordBreak: "break-all" }}
                color={"#000000"}
              >
                <b>Transaction Status : </b>
                {orderDetails?.status ?? "NA"}
              </Typography>
            </Grid>
            <Grid item md={3}>
              <Typography
                fontFamily={"MetropolisRegular"}
                fontSize={"14px"}
                sx={{ wordBreak: "break-word" }}
                color={"#000000"}
              >
                <b>Transaction Id : </b>
                {orderDetails?.payment?.response?.id ?? "NA"}
              </Typography>
            </Grid>
            <Grid item md={3}>
              <Typography
                fontFamily={"MetropolisRegular"}
                fontSize={"14px"}
                sx={{ wordBreak: "break-word" }}
                color={"#000000"}
              >
                <b>Transaction Type : </b>
                {orderDetails?.payment?.response?.method ?? "NA"}
              </Typography>
            </Grid>
          </Grid>
        </Box>

        <Box
          border={"1px solid #FA8842"}
          borderRadius={"15px"}
          mt={"5px"}
          padding={"10px 10px"}
        >
          <Grid container spacing={2}>
            <Grid item md={3}>
              <Typography
                fontFamily={"MetropolisRegular"}
                sx={{ wordBreak: "break-all" }}
                fontSize={"14px"}
                color={"#000000"}
              >
                <b>User Name : </b>
                {orderDetails?.user?.name ?? "NA"}
              </Typography>
            </Grid>
            <Grid item md={3}>
              <Typography
                fontFamily={"MetropolisRegular"}
                sx={{ wordBreak: "break-all" }}
                fontSize={"14px"}
                color={"#000000"}
              >
                <b>User Type : </b>
                {orderDetails?.user?.role?.code ?? "NA"}
              </Typography>
            </Grid>
            <Grid item md={3}>
              <Typography
                fontFamily={"MetropolisRegular"}
                sx={{ wordBreak: "break-all" }}
                fontSize={"14px"}
                color={"#000000"}
              >
                <b>User Mobile Number : </b>
                {orderDetails?.user?.mobile ?? "NA"}
              </Typography>
            </Grid>
            <Grid item md={3}>
              <Typography
                fontFamily={"MetropolisRegular"}
                fontSize={"14px"}
                sx={{ wordBreak: "break-all" }}
                color={"#000000"}
              >
                <b>Wallet Points Used By Offer : </b>
                {orderDetails?.walletPoints}
              </Typography>
            </Grid>
          </Grid>
        </Box>

        <Box
          border={"1px solid #FA8842"}
          borderRadius={"15px"}
          mt={"5px"}
          padding={"10px 10px"}
        >
          <Grid container spacing={2}>
            <Grid item md={3}>
              <Typography
                fontFamily={"MetropolisRegular"}
                fontSize={"14px"}
                sx={{ wordBreak: "break-all" }}
                color={"#000000"}
              >
                <b>Vendor Name : </b>
                {orderDetails?.offer?.vendorName ?? "NA"}
              </Typography>
            </Grid>
            <Grid item md={3}>
              <Typography
                fontFamily={"MetropolisRegular"}
                fontSize={"14px"}
                color={"#000000"}
                sx={{ wordBreak: "break-all" }}
              >
                <b>Vendor Mobile Number : </b>
                {orderDetails?.offer?.mobile ?? "NA"}
              </Typography>
            </Grid>
            <Grid item md={3}>
              <Typography
                fontFamily={"MetropolisRegular"}
                fontSize={"14px"}
                sx={{ wordBreak: "break-all" }}
                color={"#000000"}
              >
                <b>Vendor Id : </b>
                {orderDetails?.offer?.vendorId ?? "NA"}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default OrderTransactionDetails;
