import { all } from "redux-saga/effects";
import fetchToDoSagaWatcher from "./sagas/ToDoSaga";
import { socketSagaWatcher } from "./sagas/WebSocketSaga";

function* watchAll() {
  // Use:
  yield all([fetchToDoSagaWatcher(), socketSagaWatcher()]);
}

export default watchAll;
