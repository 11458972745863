import React from "react";
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import Colors from "../../constants/Colors";
import ProfileHoc from "../../components/ProfileHoc";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./accordian-style.css";

const Help = () => {
  const HelpScreen = () => {
    return (
      <Box display={"flex"} justifyContent={"center"}>
        <Box
          padding={2}
          boxShadow={"0px 0px 16px #67676729"}
          borderRadius={"20px"}
          width={"89%"}
          mt={3}>
          <p style={styles.header}>Help</p>
          <Box mt={3}>
            <Typography
              fontSize={"15px"}
              fontWeight={"600"}
              fontFamily={"MetropolisMedium"}
              color={"#000000"}>
              About Thrillh:
            </Typography>

            <Accordion
              sx={{
                padding: "0px 12px",
                boxShadow: "none",
                position: "relative !important ",
                borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                "::before": {
                  position: "relative",
                  left: 0,
                  top: -1,
                  right: 0,
                  height: 1,
                  content: "''",
                  opacity: 1,
                  backgroundColor: "rgba(0, 0, 0, 0.12)",
                },
              }}>
              <AccordionSummary
                className="accordion"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header">
                <p style={styles.question}>
                  {" "}
                  <b style={{ color: "#fa8842", marginRight: "5px" }}>Q</b> What
                  does Thrillh do?
                </p>
              </AccordionSummary>
              <AccordionDetails>
                <p style={styles.answer}>
                  <b style={{ color: "#fa8842", marginRight: "5px" }}>A</b>
                  Thrillh.com (Siveals Digital Private Limited) is India’s
                  online platform that enables students, corporates, and local
                  merchants to discover and engage with each other. The brands
                  that provide student and corporate discounts will be connected
                  to students and corporates through our platform.
                </p>
              </AccordionDetails>
            </Accordion>
            <Accordion
              sx={{
                padding: "0px 12px",
                boxShadow: "none",
                position: "relative !important ",
                borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                "::before": {
                  position: "relative",
                  left: 0,
                  top: -1,
                  right: 0,
                  height: 1,
                  content: "''",
                  opacity: 1,
                  backgroundColor: "rgba(0, 0, 0, 0.12)",
                },
              }}>
              <AccordionSummary
                className="accordion"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header">
                <p style={styles.question}>
                  <b style={{ color: "#fa8842", marginRight: "5px" }}>Q</b>
                  What are the Benefits of Thrillh?
                </p>
              </AccordionSummary>
              <AccordionDetails>
                <p style={styles.answer}>
                  <b style={{ color: "#fa8842", marginRight: "5px" }}>A</b>
                  Before you shop online, you should visit Thrillh to get these
                  benefits:
                </p>
                <ol className="mainList">
                  <li className="list"> Exclusive offers made for you </li>
                  <li className="list"> A One-Stop Solution </li>
                  <li className="list">Easy to access & redeem</li>
                  <li className="list">
                    India's first company to provide exclusive corporate and
                    student offers{" "}
                  </li>
                </ol>
              </AccordionDetails>
            </Accordion>
          </Box>
          <Box mt={3}>
            <Typography
              fontSize={"15px"}
              fontWeight={"600"}
              fontFamily={"MetropolisMedium"}
              color={"#000000"}>
              My account:
            </Typography>
            <Accordion
              sx={{
                padding: "0px 12px",
                boxShadow: "none",
                position: "relative !important ",
                borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                "::before": {
                  position: "relative",
                  left: 0,
                  top: -1,
                  right: 0,
                  height: 1,
                  content: "''",
                  opacity: 1,
                  backgroundColor: "rgba(0, 0, 0, 0.12)",
                },
              }}>
              <AccordionSummary
                className="accordion"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header">
                <p style={styles.question}>
                  <b style={{ color: "#fa8842", marginRight: "5px" }}>Q</b>
                  How do I view my invoices?{" "}
                </p>
              </AccordionSummary>
              <AccordionDetails>
                <p style={styles.answer}>
                  <b style={{ color: "#fa8842", marginRight: "5px" }}>A</b>
                  Invoices can be viewed under My Account ={">"} Orders & also
                  they can be seen in your registered email inbox
                </p>
              </AccordionDetails>
            </Accordion>
            <Accordion
              sx={{
                padding: "0px 12px",
                boxShadow: "none",
                position: "relative !important ",
                borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                "::before": {
                  position: "relative",
                  left: 0,
                  top: -1,
                  right: 0,
                  height: 1,
                  content: "''",
                  opacity: 1,
                  backgroundColor: "rgba(0, 0, 0, 0.12)",
                },
              }}>
              <AccordionSummary
                className="accordion"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header">
                <p style={styles.question}>
                  <b style={{ color: "#fa8842", marginRight: "5px" }}>Q</b>
                  How do I change my password?
                </p>
              </AccordionSummary>
              <AccordionDetails>
                <p style={styles.answer}>
                  <b style={{ color: "#fa8842", marginRight: "5px" }}>A</b>
                  You can change your password in your account settings or by
                  clicking 'Reset password'.
                </p>
              </AccordionDetails>
            </Accordion>
            <Accordion
              sx={{
                padding: "0px 12px",
                boxShadow: "none",
                position: "relative !important ",
                borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                "::before": {
                  position: "relative",
                  left: 0,
                  top: -1,
                  right: 0,
                  height: 1,
                  content: "''",
                  opacity: 1,
                  backgroundColor: "rgba(0, 0, 0, 0.12)",
                },
              }}>
              <AccordionSummary
                className="accordion"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header">
                <p style={styles.question}>
                  <b style={{ color: "#fa8842", marginRight: "5px" }}>Q</b>
                  How do I delete my account?
                </p>
              </AccordionSummary>
              <AccordionDetails>
                <p style={styles.answer}>
                  <b style={{ color: "#fa8842", marginRight: "5px" }}>A</b>
                  Please visit settings under Settings⇒ Delete Account Option
                  will be shown
                </p>
              </AccordionDetails>
            </Accordion>
            <Accordion
              sx={{
                padding: "0px 12px",
                boxShadow: "none",
                position: "relative !important ",
                borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                "::before": {
                  position: "relative",
                  left: 0,
                  top: -1,
                  right: 0,
                  height: 1,
                  content: "''",
                  opacity: 1,
                  backgroundColor: "rgba(0, 0, 0, 0.12)",
                },
              }}>
              <AccordionSummary
                className="accordion"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header">
                <p style={styles.question}>
                  <b style={{ color: "#fa8842", marginRight: "5px" }}>Q</b>
                  How do I change my name?
                </p>
              </AccordionSummary>
              <AccordionDetails>
                <p style={styles.answer}>
                  <b style={{ color: "#fa8842", marginRight: "5px" }}>A</b>
                  You can change your name in your account settings once logged
                  in.
                </p>
              </AccordionDetails>
            </Accordion>
            <Accordion
              sx={{
                padding: "0px 12px",
                boxShadow: "none",
                position: "relative !important ",
                borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                "::before": {
                  position: "relative",
                  left: 0,
                  top: -1,
                  right: 0,
                  height: 1,
                  content: "''",
                  opacity: 1,
                  backgroundColor: "rgba(0, 0, 0, 0.12)",
                },
              }}>
              <AccordionSummary
                className="accordion"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header">
                <p style={styles.question}>
                  <b style={{ color: "#fa8842", marginRight: "5px" }}>Q</b>
                  My mobile number was lost. How should I sign in now?
                </p>
              </AccordionSummary>
              <AccordionDetails>
                <p style={styles.answer}>
                  <b style={{ color: "#fa8842", marginRight: "5px" }}>A</b>
                  Don't worry, you can sign in with your mobile number &
                  existing password. If not, by any means kindly contact the
                  support team.
                </p>
              </AccordionDetails>
            </Accordion>
          </Box>
          <Box mt={3}>
            <Typography
              fontSize={"15px"}
              fontWeight={"600"}
              fontFamily={"MetropolisMedium"}
              color={"#000000"}>
              Verifying:
            </Typography>
            <Accordion
              sx={{
                padding: "0px 12px",
                boxShadow: "none",
                position: "relative !important ",
                borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                "::before": {
                  position: "relative",
                  left: 0,
                  top: -1,
                  right: 0,
                  height: 1,
                  content: "''",
                  opacity: 1,
                  backgroundColor: "rgba(0, 0, 0, 0.12)",
                },
              }}>
              <AccordionSummary
                className="accordion"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header">
                <p style={styles.question}>
                  <b style={{ color: "#fa8842", marginRight: "5px" }}>Q</b>
                  How long does verification of the user take?
                </p>
              </AccordionSummary>
              <AccordionDetails>
                <p style={styles.answer}>
                  <b style={{ color: "#fa8842", marginRight: "5px" }}>A</b>
                  Through Registering with an Official email id, it will take
                  less than 30 secs to Signup, whereas uploading an ID Card will
                  take up to 24 hrs to verify the user.
                </p>
              </AccordionDetails>
            </Accordion>
            <Accordion
              sx={{
                padding: "0px 12px",
                boxShadow: "none",
                position: "relative !important ",
                borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                "::before": {
                  position: "relative",
                  left: 0,
                  top: -1,
                  right: 0,
                  height: 1,
                  content: "''",
                  opacity: 1,
                  backgroundColor: "rgba(0, 0, 0, 0.12)",
                },
              }}>
              <AccordionSummary
                className="accordion"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header">
                <p style={styles.question}>
                  <b style={{ color: "#fa8842", marginRight: "5px" }}>Q</b>
                  My institution or company is not listed during registration.
                  What should I do?
                </p>
              </AccordionSummary>
              <AccordionDetails>
                <p style={styles.answer}>
                  <b style={{ color: "#fa8842", marginRight: "5px" }}>A</b>
                  You can enter your institute or company name manually in the
                  registration field
                </p>
              </AccordionDetails>
            </Accordion>
            <Accordion
              sx={{
                padding: "0px 12px",
                boxShadow: "none",
                position: "relative !important ",
                borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                "::before": {
                  position: "relative",
                  left: 0,
                  top: -1,
                  right: 0,
                  height: 1,
                  content: "''",
                  opacity: 1,
                  backgroundColor: "rgba(0, 0, 0, 0.12)",
                },
              }}>
              <AccordionSummary
                className="accordion"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header">
                <p style={styles.question}>
                  <b style={{ color: "#fa8842", marginRight: "5px" }}>Q</b>I
                  don’t have an institution's email address. What should I do?
                </p>
              </AccordionSummary>
              <AccordionDetails>
                <p style={styles.answer}>
                  <b style={{ color: "#fa8842", marginRight: "5px" }}>A</b>
                  You can complete your verification using your Student ID card
                  if you don't have a personal institution email address.
                </p>
              </AccordionDetails>
            </Accordion>
            <Accordion
              sx={{
                padding: "0px 12px",
                boxShadow: "none",
                position: "relative !important ",
                borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                "::before": {
                  position: "relative",
                  left: 0,
                  top: -1,
                  right: 0,
                  height: 1,
                  content: "''",
                  opacity: 1,
                  backgroundColor: "rgba(0, 0, 0, 0.12)",
                },
              }}>
              <AccordionSummary
                className="accordion"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header">
                <p style={styles.question}>
                  <b style={{ color: "#fa8842", marginRight: "5px" }}>Q</b> My
                  institution's email address is not recognized. What do I do?
                </p>
              </AccordionSummary>
              <AccordionDetails>
                <p style={styles.answer}>
                  <b style={{ color: "#fa8842", marginRight: "5px" }}>A</b>
                  Yes, email addresses such as @yahoo.com and @gmail.com are not
                  valid institutional email addresses.
                </p>
                <p style={styles.answer}>
                  Your verification can be completed using your Student ID card
                  if you do not have a personal institution email address.
                </p>
              </AccordionDetails>
            </Accordion>
            <Accordion
              sx={{
                padding: "0px 12px",
                boxShadow: "none",
                position: "relative !important ",
                borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                "::before": {
                  position: "relative",
                  left: 0,
                  top: -1,
                  right: 0,
                  height: 1,
                  content: "''",
                  opacity: 1,
                  backgroundColor: "rgba(0, 0, 0, 0.12)",
                },
              }}>
              <AccordionSummary
                className="accordion"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header">
                <p style={styles.question}>
                  <b style={{ color: "#fa8842", marginRight: "5px" }}>Q</b>
                  I’m a student and require manual verification. What documents
                  are accepted?
                </p>
              </AccordionSummary>
              <AccordionDetails>
                <p style={styles.answer}>
                  <b style={{ color: "#fa8842", marginRight: "5px" }}>A</b>
                  To verify your eligibility, we need to see documentation
                  confirming your academic status
                </p>
                <p className="subPara">
                  You must provide the following information in your document/s:
                </p>
                <ol className="mainList">
                  <li className="list"> Your name</li>
                  <li className="list">
                    The name of the university or college you attend
                  </li>
                  <li className="list">Be valid for the current year</li>
                </ol>
                <p className="subPara">
                  We accept the following types of documents:
                </p>
                <ol className="mainList">
                  <li className="list"> Student ID card</li>
                  <li className="list">Admission letter</li>
                  <li className="list">Or an enrollment letter</li>
                </ol>
                <p className="subPara">We do not accept:</p>
                <ol className="mainList">
                  <li className="list"> Residential documentation</li>
                  <li className="list">
                    Government IDs such as driving licenses, passports, or PAN
                    cards
                  </li>
                  <li className="list"> Bus passes</li>
                </ol>
              </AccordionDetails>
            </Accordion>
            <Accordion
              sx={{
                padding: "0px 12px",
                boxShadow: "none",
                position: "relative !important ",
                borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                "::before": {
                  position: "relative",
                  left: 0,
                  top: -1,
                  right: 0,
                  height: 1,
                  content: "''",
                  opacity: 1,
                  backgroundColor: "rgba(0, 0, 0, 0.12)",
                },
              }}>
              <AccordionSummary
                className="accordion"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header">
                <p style={styles.question}>
                  <b style={{ color: "#fa8842", marginRight: "5px" }}>Q</b>
                  Do you support apprenticeships?
                </p>
              </AccordionSummary>
              <AccordionDetails>
                <p style={styles.answer}>
                  <b style={{ color: "#fa8842", marginRight: "5px" }}>A</b>
                  Here at Thrillh, we are committed to supporting all students.
                  We also support students on Apprenticeships, Traineeships, and
                  Professional Placements in addition to Schools, Colleges, and
                  Universities.
                </p>
              </AccordionDetails>
            </Accordion>
            <Accordion
              sx={{
                padding: "0px 12px",
                boxShadow: "none",
                position: "relative !important ",
                borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                "::before": {
                  position: "relative",
                  left: 0,
                  top: -1,
                  right: 0,
                  height: 1,
                  content: "''",
                  opacity: 1,
                  backgroundColor: "rgba(0, 0, 0, 0.12)",
                },
              }}>
              <AccordionSummary
                className="accordion"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header">
                <p style={styles.question}>
                  <b style={{ color: "#fa8842", marginRight: "5px" }}>Q</b>
                  Have a different problem?
                </p>
              </AccordionSummary>
              <AccordionDetails>
                <p style={styles.answer}>
                  <b style={{ color: "#fa8842", marginRight: "5px" }}>A</b>
                  Do you have a different problem? Feel free to contact our
                  support team if you need assistance.
                </p>
              </AccordionDetails>
            </Accordion>
          </Box>
          <Box mt={3}>
            <Typography
              fontSize={"15px"}
              fontWeight={"600"}
              fontFamily={"MetropolisMedium"}
              color={"#000000"}>
              Discount Codes & Offers:
            </Typography>
            <Accordion
              sx={{
                padding: "0px 12px",
                boxShadow: "none",
                position: "relative !important ",
                borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                "::before": {
                  position: "relative",
                  left: 0,
                  top: -1,
                  right: 0,
                  height: 1,
                  content: "''",
                  opacity: 1,
                  backgroundColor: "rgba(0, 0, 0, 0.12)",
                },
              }}>
              <AccordionSummary
                className="accordion"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header">
                <p style={styles.question}>
                  <b style={{ color: "#fa8842", marginRight: "5px" }}>Q</b>
                  How do I redeem a code?
                </p>
              </AccordionSummary>
              <AccordionDetails>
                <p style={styles.answer}>
                  <b style={{ color: "#fa8842", marginRight: "5px" }}>A</b>
                  To redeem a code please log in to your Thrillh account. All
                  retailers/services will accept payments from Thrillh, if they
                  do not accept kindly contact our support team, and they will
                  help you with this. Please note some retailers require you to
                  register with them before you can enter the code.
                </p>
              </AccordionDetails>
            </Accordion>
            <Accordion
              sx={{
                padding: "0px 12px",
                boxShadow: "none",
                position: "relative !important ",
                borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                "::before": {
                  position: "relative",
                  left: 0,
                  top: -1,
                  right: 0,
                  height: 1,
                  content: "''",
                  opacity: 1,
                  backgroundColor: "rgba(0, 0, 0, 0.12)",
                },
              }}>
              <AccordionSummary
                className="accordion"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header">
                <p style={styles.question}>
                  <b style={{ color: "#fa8842", marginRight: "5px" }}>Q</b>
                  My code isn't applying any discounts, what do I do?
                </p>
              </AccordionSummary>
              <AccordionDetails>
                <p style={styles.answer}>
                  <b style={{ color: "#fa8842", marginRight: "5px" }}>A</b>
                  Have you tried re-issuing yourself a new code? This might fix
                  the issue you're experiencing. If there is no option to get a
                  new code, then kindly contact the support team.
                </p>
              </AccordionDetails>
            </Accordion>
            <Accordion
              sx={{
                padding: "0px 12px",
                boxShadow: "none",
                position: "relative !important ",
                borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                "::before": {
                  position: "relative",
                  left: 0,
                  top: -1,
                  right: 0,
                  height: 1,
                  content: "''",
                  opacity: 1,
                  backgroundColor: "rgba(0, 0, 0, 0.12)",
                },
              }}>
              <AccordionSummary
                className="accordion"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header">
                <p style={styles.question}>
                  {" "}
                  <b style={{ color: "#fa8842", marginRight: "5px" }}>Q</b>I was
                  refused an in-store discount. What can I do?
                </p>
              </AccordionSummary>
              <AccordionDetails>
                <p style={styles.answer}>
                  <b style={{ color: "#fa8842", marginRight: "5px" }}>A</b>
                  All of our perks/discounts have been approved by the relevant
                  retailer’s head office. While every effort is made to ensure
                  that this is communicated to each participating store, errors
                  can be made from time to time.
                </p>
                <p style={styles.answer}>
                  If you contact us we can let the relevant retailer know so
                  that they can address staff training.
                </p>
              </AccordionDetails>
            </Accordion>
            <Accordion
              sx={{
                padding: "0px 12px",
                boxShadow: "none",
                position: "relative !important ",
                borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                "::before": {
                  position: "relative",
                  left: 0,
                  top: -1,
                  right: 0,
                  height: 1,
                  content: "''",
                  opacity: 1,
                  backgroundColor: "rgba(0, 0, 0, 0.12)",
                },
              }}>
              <AccordionSummary
                className="accordion"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header">
                <p style={styles.question}>
                  <b style={{ color: "#fa8842", marginRight: "5px" }}>Q</b>
                  My payment was stuck in between during Instore payment, what
                  should I do?
                </p>
              </AccordionSummary>
              <AccordionDetails>
                <p style={styles.answer}>
                  <b style={{ color: "#fa8842", marginRight: "5px" }}>A</b>
                  No need to worry at all! Your payment will be safe. For
                  assistance, please contact us and we will make sure that your
                  stuck payment is refunded.
                </p>
              </AccordionDetails>
            </Accordion>
          </Box>

          <Box mt={3}>
            <Typography
              fontSize={"15px"}
              fontWeight={"600"}
              fontFamily={"MetropolisMedium"}
              color={"#000000"}>
              Help/support:
            </Typography>
            <Accordion
              sx={{
                padding: "0px 12px",
                boxShadow: "none",
                position: "relative !important ",
                borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                "::before": {
                  position: "relative",
                  left: 0,
                  top: -1,
                  right: 0,
                  height: 1,
                  content: "''",
                  opacity: 1,
                  backgroundColor: "rgba(0, 0, 0, 0.12)",
                },
              }}>
              <AccordionSummary
                className="accordion"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header">
                <p style={styles.question}>
                  <b style={{ color: "#fa8842", marginRight: "5px" }}>Q</b>
                  How do I contact Customer Support?
                </p>
              </AccordionSummary>
              <AccordionDetails>
                <p style={styles.answer}>
                  <b style={{ color: "#fa8842", marginRight: "5px" }}>A</b>
                  You can email us at Info@Thrillh.com or call us at
                  +91-9966664878 (11:00 AM to 8:00 PM)
                </p>
              </AccordionDetails>
            </Accordion>
            <Accordion
              sx={{
                padding: "0px 12px",
                boxShadow: "none",
                position: "relative !important ",
                borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                "::before": {
                  position: "relative",
                  left: 0,
                  top: -1,
                  right: 0,
                  height: 1,
                  content: "''",
                  opacity: 1,
                  backgroundColor: "rgba(0, 0, 0, 0.12)",
                },
              }}>
              <AccordionSummary
                className="accordion"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header">
                <p style={styles.question}>
                  <b style={{ color: "#fa8842", marginRight: "5px" }}>Q</b>I
                  have feedback or an idea to share. How do I share it?
                </p>
              </AccordionSummary>
              <AccordionDetails>
                <p style={styles.answer}>
                  <b style={{ color: "#fa8842", marginRight: "5px" }}>A</b>
                  We LOVE feedback & ideas. Please feel free to share all your
                  thoughts at Info@Thrillh.com.
                </p>
              </AccordionDetails>
            </Accordion>
            <Accordion
              sx={{
                padding: "0px 12px",
                boxShadow: "none",
                position: "relative !important ",
                borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                "::before": {
                  position: "relative",
                  left: 0,
                  top: -1,
                  right: 0,
                  height: 1,
                  content: "''",
                  opacity: 1,
                  backgroundColor: "rgba(0, 0, 0, 0.12)",
                },
              }}>
              <AccordionSummary
                className="accordion"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header">
                <p style={styles.question}>
                  {" "}
                  <b style={{ color: "#fa8842", marginRight: "5px" }}>Q</b>
                  How do I list my business on Thrillh?
                </p>
              </AccordionSummary>
              <AccordionDetails>
                <p style={styles.answer}>
                  <b style={{ color: "#fa8842", marginRight: "5px" }}>A</b>
                  Kindly send a mail to Info@Thrillh.com
                </p>
              </AccordionDetails>
            </Accordion>
            <Accordion
              sx={{
                padding: "0px 12px",
                boxShadow: "none",
                position: "relative !important ",
                borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                "::before": {
                  position: "relative",
                  left: 0,
                  top: -1,
                  right: 0,
                  height: 1,
                  content: "''",
                  opacity: 1,
                  backgroundColor: "rgba(0, 0, 0, 0.12)",
                },
              }}>
              <AccordionSummary
                className="accordion"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header">
                <p style={styles.question}>
                  <b style={{ color: "#fa8842", marginRight: "5px" }}>Q</b>
                  Have a different problem?
                </p>
              </AccordionSummary>
              <AccordionDetails>
                <p style={styles.answer}>
                  <b style={{ color: "#fa8842", marginRight: "5px" }}>A</b>
                  Are you experiencing a different issue? Contact our support
                  team for help.
                </p>
              </AccordionDetails>
            </Accordion>
          </Box>

          <Box mt={3}>
            <Typography
              fontSize={"15px"}
              fontWeight={"600"}
              fontFamily={"MetropolisMedium"}
              color={"#000000"}>
              App Support:
            </Typography>
            <Accordion
              sx={{
                padding: "0px 12px",
                boxShadow: "none",
                position: "relative !important ",
                borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                "::before": {
                  position: "relative",
                  left: 0,
                  top: -1,
                  right: 0,
                  height: 1,
                  content: "''",
                  opacity: 1,
                  backgroundColor: "rgba(0, 0, 0, 0.12)",
                },
              }}>
              <AccordionSummary
                className="accordion"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header">
                <p style={styles.question}>
                  <b style={{ color: "#fa8842", marginRight: "5px" }}>Q</b>I
                  have a Windows phone, but I can't download the app, why is
                  that?
                </p>
              </AccordionSummary>
              <AccordionDetails>
                <p style={styles.answer}>
                  <b style={{ color: "#fa8842", marginRight: "5px" }}>A</b>
                  Our app isn't currently operational on Windows devices.
                  However, you can still access our mobile site via
                  www.Thrillh.com.
                </p>
              </AccordionDetails>
            </Accordion>
            <Accordion
              sx={{
                padding: "0px 12px",
                boxShadow: "none",
                position: "relative !important ",
                borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                "::before": {
                  position: "relative",
                  left: 0,
                  top: -1,
                  right: 0,
                  height: 1,
                  content: "''",
                  opacity: 1,
                  backgroundColor: "rgba(0, 0, 0, 0.12)",
                },
              }}>
              <AccordionSummary
                className="accordion"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header">
                <p style={styles.question}>
                  <b style={{ color: "#fa8842", marginRight: "5px" }}>Q</b>
                  The app isn’t working, what can I do?
                </p>
              </AccordionSummary>
              <AccordionDetails>
                <p style={styles.answer}>
                  <b style={{ color: "#fa8842", marginRight: "5px" }}>A</b>
                  If you're having issues with the app, please try uninstalling
                  and reinstalling as we may have released an update. Still
                  having trouble with the new installation? Contact us and let
                  us know.
                </p>
              </AccordionDetails>
            </Accordion>
            <Accordion
              sx={{
                padding: "0px 12px",
                boxShadow: "none",
                position: "relative !important ",
                borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                "::before": {
                  position: "relative",
                  left: 0,
                  top: -1,
                  right: 0,
                  height: 1,
                  content: "''",
                  opacity: 1,
                  backgroundColor: "rgba(0, 0, 0, 0.12)",
                },
              }}>
              <AccordionSummary
                className="accordion"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header">
                <p style={styles.question}>
                  <b style={{ color: "#fa8842", marginRight: "5px" }}>Q</b>
                  Have a different problem?
                </p>
              </AccordionSummary>
              <AccordionDetails>
                <p style={styles.answer}>
                  <b style={{ color: "#fa8842", marginRight: "5px" }}>A</b>
                  If none of our FAQs seem to apply, you can submit your query.
                </p>
              </AccordionDetails>
            </Accordion>
          </Box>
        </Box>
      </Box>
    );
  };
  return ProfileHoc(HelpScreen);
};

export default Help;

const styles = {
  header: {
    fontFamily: "MetropolisMedium",
    fontSize: 18,
    color: Colors.textColor,
    margin: 0,
  },
  name: {
    fontFamily: "MetropolisRegular",
    color: Colors.textColor,
    fontSize: 13,
    margin: 0,
  },
  nameValue: {
    fontFamily: "MetropolisRegular",
    color: Colors.lightBlue,
    fontSize: 17,
    margin: 0,
    marginTop: 5,
  },
  profileImg: {
    objectFit: "contain",
  },
  question: {
    fontFamily: "MetropolisRegular",
    fontSize: 15,
    lineHeight: "22px",
    color: Colors.textColor,
    fontWeight: 550,
  },
  answer: {
    fontFamily: "MetropolisRegular",
    fontSize: 15,
    color: Colors.textColor,
    lineHeight: 1.5,
    margin: 0,
  },
};
