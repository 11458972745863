import { Box, Typography } from "@mui/material";
import React from "react";
import CustomButton from "../../components/Button";
import { ApiRequest } from "../../../services/api-request";
import { useLocation } from "react-router-dom";
import { useSnackbar } from "../../../context/useSnackbar";
import { capitalizeFirstLetter } from "../../utilities/date-utility";
import defaultIdCardImage from "../../../images/identity-card.svg";

const VerifyUser = (props) => {
  const { pathname } = useLocation();

  const { openVerifyUserModal } = props;

  const { openSnackbar } = useSnackbar();

  const verifyDeniedOrInActiveUser = (data) => {
    if (openVerifyUserModal?.data?.status === "in-review") {
      data = "denied";
    } else if (openVerifyUserModal?.data?.status === "active") {
      data = "in-active";
    } else {
    }
    const payload = {
      status: data,
      id: openVerifyUserModal?.data?._id
    };

    console.log(payload, "PPPPPPPPPPPPP")

    ApiRequest({
      url: `user/${openVerifyUserModal?.data?._id}/status`,
      data: payload,
      method: "PUT",
    })
      .then((res) => {
        if (res?.status === "in-active") {
          openSnackbar("user In-activated Successfully", "success");
        } else if (res?.status === "denied") {
          openSnackbar("User Denied Successfully", "error");
        } else {
        }
        props.onClose();
        props.getAllUsers();
        if (pathname === "/admin/dashboard") {
          props.getUserSummary();
        }
      })
      .catch((err) => {
        openSnackbar(err?.response?.data?.message, "error");
      });
  };

  const verifyActiveUser = (status) => {
    const payload = {
      status: status,
    };

    ApiRequest({
      url: `user/${openVerifyUserModal?.data?._id}/status`,
      data: payload,
      method: "PUT",
    })
      .then((res) => {
        openSnackbar("User Activated Successfully", "success");
        props.onClose();
        props.getAllUsers();
        if (pathname === "/admin/dashboard") {
          props.getUserSummary();
        }
      })
      .catch((err) => {
        openSnackbar(err?.response?.data?.message, "error");
      });
  };

  return (
    <>
      <Box>
        <Box display={"flex"} alignItems={"center"}>
          <Box width={"33.33%"}>
            <Typography
              fontFamily={"MetropolisRegular"}
              color={"#013047"}
              fontSize={"14px"}
            >
              <b>Verified With</b> :{" "}
              {capitalizeFirstLetter(
                openVerifyUserModal?.data?.verifiedWith ?? "NA"
              )}
            </Typography>
          </Box>
          <Box width={"33.33%"}>
            <Typography
              fontFamily={"MetropolisRegular"}
              color={"#013047"}
              fontSize={"14px"}
            >
              <b>Name </b> : {openVerifyUserModal?.data?.name ?? "NA"}
            </Typography>
          </Box>
          <Box width={"33.33%"}>
            <Typography
              fontFamily={"MetropolisRegular"}
              color={"#013047"}
              fontSize={"14px"}
            >
              <b>DOB </b> : {openVerifyUserModal?.data?.Dob ?? "NA"}
            </Typography>
          </Box>
        </Box>
        <Box mt={2} display={"flex"} alignItems={"center"}>
          <Box width={"33.33%"}>
            <Typography
              fontFamily={"MetropolisRegular"}
              color={"#013047"}
              fontSize={"14px"}
            >
              <b>Reg Mob.No</b> : {openVerifyUserModal?.data?.mobile ?? "NA"}
            </Typography>
          </Box>
          <Box width={"33.33%"}>
            <Typography
              fontFamily={"MetropolisRegular"}
              color={"#013047"}
              fontSize={"14px"}
            >
              <b>Personal Email Id </b> :{" "}
              {openVerifyUserModal?.data?.personalEmail ?? "NA"}
            </Typography>
          </Box>
          {openVerifyUserModal?.data?.email && (
            <Box width={"33.33%"}>
              <Typography
                fontFamily={"MetropolisRegular"}
                color={"#013047"}
                fontSize={"14px"}
              >
                <b>Official Email </b> :{" "}
                {openVerifyUserModal?.data?.email ?? "NA"}
              </Typography>
            </Box>
          )}
        </Box>
        <Box display={"flex"} alignItems={"center"} mt={2}>
          <Box width={"33.33%"}>
            <Typography
              fontFamily={"MetropolisRegular"}
              color={"#013047"}
              fontSize={"14px"}
            >
              <b>Gender</b> : {openVerifyUserModal?.data?.gender ?? "NA"}
            </Typography>
          </Box>
          <Box width={"33.33%"}>
            <Typography
              fontFamily={"MetropolisRegular"}
              color={"#013047"}
              fontSize={"14px"}
            >
              <b>Type </b> : {openVerifyUserModal?.data?.role?.name ?? "NA"}
            </Typography>
          </Box>
          <Box width={"33.33%"}>
            <Typography
              fontFamily={"MetropolisRegular"}
              color={"#013047"}
              fontSize={"14px"}
            >
              <b>
                {openVerifyUserModal?.data?.role?.name === "Student"
                  ? "College/University"
                  : "Company/Organization"}{" "}
              </b>{" "}
              :{" "}
              {openVerifyUserModal?.data?.role?.name === "Student"
                ? openVerifyUserModal?.data?.school
                : openVerifyUserModal?.data?.company}
            </Typography>
          </Box>
        </Box>
        <Box display={"flex"} alignItems={"center"} mt={2} gap={"10px"}>
          <Box>
            {openVerifyUserModal?.data?.frontSideImage ? (
              <img
                src={openVerifyUserModal?.data?.frontSideImage}
                alt="card"
                width={"250px"}
                height={"auto"}
              />
            ) : (
              <img
                src={defaultIdCardImage}
                width={"250px"}
                height={"auto"}
                alt="card"
              />
            )}
            <Typography
              textAlign={"center"}
              fontFamily={"MetropolisBold"}
              color={"#013047"}
              fontSize={"13px"}
            >
              Front Side Image
            </Typography>
          </Box>
          <Box>
            {openVerifyUserModal?.data?.backSideImage ? (
              <img
                src={openVerifyUserModal?.data?.backSideImage}
                width={"250px"}
                alt="card"
                height={"auto"}
              />
            ) : (
              <img
                src={defaultIdCardImage}
                alt="card"
                width={"250px"}
                height={"auto"}
              />
            )}
            <Typography
              textAlign={"center"}
              fontFamily={"MetropolisBold"}
              color={"#013047"}
              fontSize={"13px"}
            >
              Back Side Image
            </Typography>
          </Box>
        </Box>
        <Box
          display={"flex"}
          mt={"12px"}
          justifyContent={"end"}
          gap={"10px"}
          alignItems={"flex-end"}
        >
          <Box width={"25%"}>
            {openVerifyUserModal?.data?.status === "denied" ||
            openVerifyUserModal?.data?.status === "in-active" ? null : (
              <CustomButton
                title={
                  openVerifyUserModal?.data?.status === "active"
                    ? "In-Active"
                    : "Deny"
                }
                borderRadius={"5px"}
                bgColor={"#fff"}
                color={"#FA8842"}
                borderColor={"#FA8842"}
                handleClick={() =>
                  verifyDeniedOrInActiveUser(
                    openVerifyUserModal?.data?.status === "in-review"
                      ? "denied"
                      : "in-active"
                  )
                }
              />
            )}
          </Box>

          {openVerifyUserModal?.data?.status === "active" ? null : (
            <Box width={"25%"}>
              <CustomButton
                title={
                  openVerifyUserModal?.data?.status === "in-active"
                    ? "Active"
                    : "Approve"
                }
                borderRadius={"5px"}
                handleClick={() => verifyActiveUser("active")}
              />
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default VerifyUser;
