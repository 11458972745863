// WebSocket.js
import { eventChannel } from "redux-saga";

import {
  all,
  call,
  cancel,
  cancelled,
  fork,
  put,
  race,
  take,
  takeLatest,
} from "redux-saga/effects";

import socketIOConnect from "socket.io-client";

import {
  addNotificationFromSocket,
  closeSocket,
  getNotificationFailure,
  getNotificationStart,
  getNotificationSuccess,
} from "../slicers/socketSlicer";
import { ErrorMessage } from "../../utilities/constants";
import { ACTIONS } from "../constants";
import { getLocalStorageData } from "../../utilities/local-storage";
import { ApiRequest } from "./../../../services/api-request";

// Define an action type for receiving notifications

export const ENABLE_CHANNELS_REDUX_ACTION = "ENABLE_CHANNELS_REDUX_ACTION";

export const RECEIVE_NOTIFICATION = "RECEIVE_NOTIFICATION";

const SOCKET_URL = "wss://api.thrillh.com";

function connectSocketChannel() {
  const socketChannel = socketIOConnect(SOCKET_URL, {
    transports: ["websocket"],
    reconnection: true,
  });

  const user = getLocalStorageData("user");
  const connectRequest = {
    token: user?.accessToken || null,
  };

  socketChannel.on("connect", () => {
    console.debug("Connected to the socket server");
    try {
      if (connectRequest.token) {
        socketChannel.emit("join", connectRequest);
      }
    } catch (error) {
      throw error;
    }
  });
  return new Promise((resolve) => resolve(socketChannel));
}

function createSocketChannel(socket) {
  return eventChannel((emit) => {
    const messageHandler = (message) => {
      emit({ type: "MESSAGE_RECEIVED", message });
    };

    const closeHandler = () => {
      emit(closeSocket());
    };

    socket.on("message", messageHandler);
    socket.on("close", closeHandler);

    const unsubscribe = () => {
      socket.off("message", messageHandler);
      socket.off("close", closeHandler);
    };

    return unsubscribe;
  });
}

function* read(socket) {
  const channel = yield call(createSocketChannel, socket);
  try {
    while (true) {
      const { type, message } = yield take(channel);
      if (type === "MESSAGE_RECEIVED") {
        yield put(addNotificationFromSocket({ notification: message }));
      }
    }
  } finally {
    if (yield cancelled()) {
      channel.close();
    }
  }
}

// Saga to handle writing messages to the WebSocket
function* write(socket) {
  while (true) {
    const { type, payload } = yield take(ACTIONS.SOCKET_CHANNEL_SEND_PAYLOAD);
    socket.send(JSON.stringify({ type, payload }));
  }
}

// Saga to handle the overall WebSocket lifecycle
function* handleChannelIO(socket) {
  const { closeAction, readTask, writeTask } = yield race({
    closeAction: take(closeSocket.type),
    readTask: fork(read, socket),
    writeTask: fork(write, socket),
  });

  if (closeAction) {
    // The closeSocket action was dispatched
    // Clean up and close the WebSocket
    if (readTask) yield cancel(readTask);
    if (writeTask) yield cancel(writeTask);
    socket.close();
  }
}

// Saga to connect to the WebSocket and initiate the channel handling
function* socketChannelOpenSaga(action) {
  const socket = yield call(connectSocketChannel); // Implement this function to create a WebSocket connection
  yield fork(handleChannelIO, socket);
}

// const getPageNoLimit = (type) => (state) => {
//   console.log(state, "state");
//   let pageNo = 0,
//     limit = 50;
//   switch (type) {
//     case "GENERAL":
//       pageNo = state?.socketState?.general?.pageNo;
//       limit = state?.socketState?.general?.limit;
//       break;
//   }
//   return { pageNo, limit };
// };

function* onGetNotificationStart(action) {
  const { type, isRead, limit, skip } = action.payload;
  // const { pageNo, limit } = yield select(getPageNoLimit(type));
  const where = {};
  where.type = type?.toString();
  if (isRead) {
    where.isRead = isRead;
  }

  try {
    const response = yield ApiRequest({
      url: `notification/user/list?skip=${skip}&limit=${limit}`,
      method: "GET",
    });

    if (
      response.data &&
      response.data?.length &&
      response?.count !== undefined
    ) {
      yield put(
        getNotificationSuccess({
          notifications: response.data,
          type: "GENERAL" ?? null,
          hasMore: response.data?.length >= 10,
        })
      );
    } else {
      throw new Error("Unable to get notifications");
    }
  } catch (err) {
    yield put(getNotificationFailure(ErrorMessage(err)));
  }
}

export function* getAllTypesOfNotificationsSaga(action) {
  yield all([
    put(getNotificationStart({ type: "GENERAL", skip: 0, limit: 10 })),
  ]);
}

export function* socketSagaWatcher() {
  yield all([
    takeLatest(ENABLE_CHANNELS_REDUX_ACTION, socketChannelOpenSaga),
    takeLatest(ENABLE_CHANNELS_REDUX_ACTION, getAllTypesOfNotificationsSaga),
    takeLatest(getNotificationStart.type, onGetNotificationStart),
  ]);
}
