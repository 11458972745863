import { Box, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import vendorLogin from "../../../../images/vendorLoginbg.png";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import styles from "./vendor-login.module.css";
import CustomInput from "../../../components/Input";
import CustomButton from "../../../components/Button";
import { ApiRequest } from "../../../../services/api-request";
import { useSnackbar } from "../../../../context/useSnackbar";
import { useAppContext } from "../../../../context/AppContext";
import { useNavigate } from "react-router-dom";
import CustomSpinner from "../../../components/Spinner";
import MetaTags from "../../../components/ReactMetaTags/meta-tags";

const VendorLogin = () => {
  const [saveOtpResponse, setSaveOtpResponse] = useState({
    data: {},
  });
  const [forgot, setForgot] = useState(false);
  const VendorLoginForm = (props) => {
    const { setSaveOtpResponse } = props;
    const schema = yup
      .object({
        login: yup.string().required("Vendor Id is required"),
      })
      .required();

    const { handleSubmit, control } = useForm({
      resolver: yupResolver(schema),
    });

    const { openSnackbar } = useSnackbar();

    const [loaderForAPIResponse, setLoaderForAPIResponse] = useState(false);


    const onSubmit = (data) => {
      setLoaderForAPIResponse(true);
      var payload = {
        vendorId: data.login,
      };
      if(!forgot)
      {
        const vendorid = data.login.slice(0,8)
        payload['vendorId'] = vendorid
        ApiRequest({
          url: `vendor/send-otp`,
          method: "POST",
          data: payload,
        })
        .then((res) => {
          setLoaderForAPIResponse(false);
          setSaveOtpResponse({ data: res });
          if (res.success === false) {
            // openSnackbar(res.message, "error");
            openSnackbar("Server API Timeout, Please try again", "error");
          } else {
            // setForgot(!forgot);
            openSnackbar(res.message, "success");
          }
        })
        .catch((err) => {
          setLoaderForAPIResponse(false);
        });
      }
      else
      {
        const vendorid = data.login.slice(0,10)
        payload['vendorId'] = vendorid

        ApiRequest({
          url: `vendor/send-otp-mobile`,
          method: "POST",
          data: payload,
        })
        .then((res) => {
          setLoaderForAPIResponse(false);
          setSaveOtpResponse({ data: res });
          if (res.success === false) {
            // openSnackbar(res.message, "error");
            openSnackbar("Server API Timeout, Please try again", "error");
          } else {
            // setForgot(!forgot);
            openSnackbar(res.message, "success");
          }
        })
        .catch((err) => {
          setLoaderForAPIResponse(false);
        });
      }
    };

    return (
      <>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            display={"flex"}
            alignItems={"center"}
            gap={"10px"}
            width={"100%"}
          >
            <Box mt={2} width={"100%"}>
              <Controller
                name="login"
                control={control}
                render={({ field, fieldState: { error } }) =>
                  {
                    var fel = field
                    if(forgot)
                    {
                      if(fel && fel.value && fel.value.length>10)
                      {
                        fel.value=fel.value.slice(0,10)
                      }
                      console.log(fel)
                    }
                    else
                    {
                      if(fel && fel.value && fel.value.length>8)
                      {
                        fel.value=fel.value.slice(0,8)
                      }
                      console.log(fel)
                    }
                  return (
                  <CustomInput
                    {...fel}
                    label={forgot ? "Mobile Number" : "Vendor Id"}
                    type={"text"}
                    placeHolder={forgot ? "Enter Mobile Number" : "Enter Vendor ID"}
                    error={!!error}
                    helperText={error ? error.message : ""}
                  />
                )}}
              />
              <Box mt={3}>
                {loaderForAPIResponse ? (
                  <Box
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <CustomSpinner size={"1rem"} />
                  </Box>
                ) : (
                  <CustomButton
                    type={"submit"}
                    title={"Proceed"}
                    borderButton={false}
                  />
                )}
              </Box>
            </Box>
          </Box>
        </form>
      </>
    );
  };

  const VendorOTPForm = (props) => {
    const { saveOtpResponse } = props;

    const { openSnackbar } = useSnackbar();

    const { handleLoginSuccess } = useAppContext();

    const schema = yup
      .object({
        otp: yup.string().required("Otp is required"),
      })
      .required();

    const { handleSubmit, control, getValues, reset } = useForm({
      resolver: yupResolver(schema),
    });

    const navigate = useNavigate();

    const onSubmit = (data) => {
      setTimeout(() => {
        const role = {
          code: "vendor",
        };
        const otps = data.otp.slice(0,6)
        const payload = {
          otp: Number(otps),
          mobile: saveOtpResponse?.mobile,
          otp_token: saveOtpResponse?.otp_token,
          vendorId: !forgot ? saveOtpResponse?.vendorId : "TV0",
        };
        if(!forgot)
        {
          ApiRequest({
            url: `vendor/otp-login`,
            method: "POST",
            data: payload,
          })
          .then((res) => {
            localStorage.setItem("user", JSON.stringify({ ...res.data, role }));
            handleLoginSuccess({ ...res.data, role });
            if (res.success === false) {
              openSnackbar(res.message, "error");
            } else {
              navigate("/vendor/dashboard");
              openSnackbar(res.message, "success");
            }
          })
          .catch((err) => { });
        }
        else
        {
          ApiRequest({
            url: `vendor/otp-login-mobile`,
            method: "POST",
            data: payload,
          })
          .then((res) => {
            localStorage.setItem("user", JSON.stringify({ ...res.data, role }));
            handleLoginSuccess({ ...res.data, role });
            if (res.success === false) {
              openSnackbar(res.message, "error");
            } else {
              navigate("/vendor/dashboard");
              openSnackbar(res.message, "success");
            }
          })
          .catch((err) => { });
        }
      }, 600);
    };

    return (
      <>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            display={"flex"}
            alignItems={"center"}
            gap={"10px"}
            width={"100%"}
          >
            <Box mt={2} width={"100%"}>
              <Controller
                name="otp"
                control={control}
                render={({ field, fieldState: { error } }) => 
                {
                  var fel = field
                  // fel && fel.value ? fel.value=fel.value.slice(0,6) : null
                  if(fel && fel.value && fel.value.length>6)
                  {
                    fel.value=fel.value.slice(0,6)
                  }
                  console.log(fel)
                  return (
                  // field && field.value ? field.value=field.value.slice(0,6) : null,
                  <CustomInput
                    {...fel}
                    label={"OTP"}
                    type={"number"}
                    placeHolder={"Enter otp"}
                    error={!!error}
                    helperText={error ? error.message : ""}
                  />
                )
                }
              }
              />
              <Box mt={3}>
                <CustomButton
                  type={"submit"}
                  title={"Login"}
                  borderButton={false}
                />
              </Box>
            </Box>
          </Box>
        </form>
      </>
    );
  };

  return (
    <>
      <MetaTags
        title={"Partner Access for Exclusive Opportunities"}
        metaTitle={"Partner Access for Exclusive Opportunities"}
        description={
          "Log in to Thrillh's dedicated vendor portal for seamless access to exclusive opportunities and collaboration. Join us as a valued partner & unlock the benefits."
        }
      />

      <Box position={"relative"}>
        <Box position={"absolute"} top={"30px"} left={"25px"}>
          <a href="/">
            <img
              src={require("../../../../images/final logo.png")}
              alt={"Logo Image"}
              width={"150px"}
            />
          </a>
        </Box>
        <Box
          height={"100vh"}
          width={"100%"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          style={{
            backgroundImage: `url(${vendorLogin})`,
            backgroundSize: "cover",
            backgroundPosition: "bottom",
          }}
        >
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            height={"100%"}
            width={"100%"}
          >
            <Box
              boxShadow={"0px 0px 16px #90909029"}
              bgcolor={"#FFFFFF"}
              borderRadius={"21px"}
              padding={"40px 60px"}
              width={"220px"}
            >
              {!forgot ? <Box width={"100%"}>
                <Box>
                  <Typography className={styles.heading}>
                    Vendor Login
                  </Typography>
                </Box>
                <Box mt={1}>
                  <Typography className={styles.subHeading}>
                    {!saveOtpResponse?.data.otp_token
                      ? "   Please enter your details to login"
                      : "Verify with OTP to login"}
                  </Typography>
                </Box>
              </Box> : null}
              <Box width={"100%"}>
                {saveOtpResponse.data.otp_token? (
                  <VendorOTPForm
                    setSaveOtpResponse={setSaveOtpResponse}
                    saveOtpResponse={saveOtpResponse.data}
                  />
                ) : (
                  <VendorLoginForm
                    setSaveOtpResponse={setSaveOtpResponse}
                    saveOtpResponse={saveOtpResponse.data}
                  />
                )}
              </Box>
              <Box mt={10}>
                <Typography style={{cursor: "pointer"}} onClick={()=> {
                  setForgot(!forgot)
                  setSaveOtpResponse({data: {}})
                  }} className={styles.forgotPassword}>
                  {!forgot ? "Forgot Vendor Id ?" : "Enter Vendor Id"}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default VendorLogin;