import React from "react";
import { Grid, Box, Typography } from "@mui/material";
import Colors from "../../constants/Colors";
import BgBaground from "../../../images/bg-banner.png";
import Footer from "../../components/Footer";
import "./privacy-policy.css";
import MetaTags from "../../components/ReactMetaTags/meta-tags";

const TermsOfServices = () => {
  return (
    <>
      <MetaTags
        title={"Thrillh - Privacy Policy"}
        metaTitle={"Thrillh - Privacy Policy"}
        description={
          "We understand the importance of privacy at Thrillh, and we are committed to handling your personal information with the highest level of care."
        }
      />
      <Box position={"relative"}>
        <Box
          style={{
            backgroundImage: `url(${BgBaground})`,
            backgroundSize: "cover",
          }}
          padding={"40px 20px 60px 20px"}
        >
          <Box>
            <Typography
              color={"#fff"}
              fontSize={"40px"}
              textAlign={"center"}
              p={5}
              variant="h1"
            >
              Privacy Policy
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box display={"flex"} justifyContent={"center"}>
        <Grid container className="containerForPrivacy">
          <Box>
            <Typography
              pt={2}
              textAlign={"justify"}
              variant="h6"
              style={styles.para}
            >
              Last updated on: May 10, 2023
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              There are many different ways you can connect to us – when you
              create an account with us, subscribe to our offers/ deals, contact
              us online, view our e-mail address, browse through our website,
              etc. When you share your authentic information with us by creating
              a Thrillh account, you trust us with your information. We value
              your trust and your privacy is important to us. So we’ve developed
              a Privacy Policy that covers:
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              What information do we collect and why do we collect it? How do we
              use that information? The choices we offer, including how to
              access and update information?
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              Please take a moment to familiarize yourself with our Terms of Use
              and Privacy Policy and let us know if you have any questions.
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              Thanks
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              Last updated on: OCT 15, 2021
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              Presently updated on: MAY 10, 2023
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              Intent: We know that you care how information about you is used
              and shared and we appreciate your trust entrusted in us to do that
              carefully and sensibly.
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              This Privacy Policy explains how we, Siveals digital private
              limited (operating as “ Thrillh.com ” or “ Thrillh”), collect,
              process, and use the information of our users (hereinafter
              addressed as “you” , “your” , “yourself” ). We are the operators
              of the website www.Thrillh.com, an associated mobile application,
              and a provider of a range of services in relation thereto. We
              provide a platform where we may list offers/ deals for multi
              services and goods locally and nationally which are made available
              by us or other sellers (collectively: “Sellers” ). This Privacy
              Policy applies to information that we collect through our website,
              mobile application, electronic communications, or services or
              through other means (collectively, the “Website/ Site/
              Application” ).
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              We as a routine update this Privacy Policy that helps to improvise
              our practices and to reflect new or different privacy practices,
              such as when we add new services, functionality, or features to
              the Site. You can determine when this version of the Privacy
              Policy was adopted by referring to the “Effective Date” above.
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              By visiting this Website you agree to be bound by the terms and
              conditions of this Privacy Policy. If you do not agree, please do
              not use or access our Site
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              By mere use of the Site, you expressly consent to our use and
              disclosure of your personal information following this Privacy
              Policy. This Privacy Policy is incorporated into and subject to
              the Terms of Use.
            </Typography>
            <Typography
              pt={2}
              textAlign={"justify"}
              fontWeight={700}
              fontFamily={"MetropolisMedium"}
              fontSize={"20px"}
              variant="h1"
            >
              SENSITIVE PERSONAL DATA AND INFORMATION (SPDI)
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              Under the Information Technology Act, of 2000, and its successive
              amendments from time to time, we are required to give a disclosure
              of the SPDI collected by us and how we use it. We may collect and
              retain the following information from and about you if you
              interact with us through the Site:
            </Typography>
            <Typography pt={2} textAlign={"justify"}></Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              Non-Personal Information: Thrillh may also collect information
              other than Personal Information from You through the
              Website/Application when You visit and/or use the
              Website/Application. Such information may be stored in server
              logs. This Non-Personal Information would not assist Thrillh to
              identify You personally. This Non-Personal Information may
              include:
            </Typography>
            <div>
              <ul className="lists">
                <li className="lists">Your geographic location</li>
                <li className="lists">
                  details of Your telecom service provider or internet service
                  provider
                </li>
                <li className="lists">
                  the type of browser (Internet Explorer, Firefox, Opera, Google
                  Chrome, etc.),
                </li>
                <li>
                  the operating system of Your system, device, and your browsing
                  history including the URL of the Website/Application You last
                  visited before visiting the Website/Application including your
                  internet protocol address.
                </li>
                <li>
                  The duration of Your stay on the Website/Application is also
                  stored in the session along with the date and time of Your
                  access, Non-Personal Information is collected in various ways
                  such as through the use of cookies. Thrillh may store
                  temporary or permanent ‘cookies' on Your computer. You can
                  erase or choose to block these cookies from Your computer. You
                  can configure Your computer's browser to alert You when we
                  attempt to send You a cookie with an option to accept or
                  refuse the cookie. If You have turned cookies off, you may be
                  prevented from using certain features of the
                  Website/Application.
                </li>
                <li>
                  Ads: Thrillh may use third-party service providers to serve
                  ads on Thrillh's behalf across the internet and sometimes on
                  the Website/Application. They may collect Non-Personal
                  Information about Your visits to the Website/Application, and
                  Your interaction with our products and services on the
                  Website/Application. Please do note that Personal Information
                  and Non-Personal Information may be treated differently as per
                  this Privacy Policy.
                </li>
                <li>
                  We may in the future include other optional requests for
                  information from the User including through user surveys to
                  help us customize the Website to deliver personalized
                  information to the User and for other purposes mentioned
                  herein. Such information may also be collected in the course
                  of contests conducted by us. Any such additional Personal
                  Information will also be processed by this Privacy Policy.
                </li>
                <li>
                  Restrictions: We do not knowingly collect Personal Information
                  from children
                </li>
                <Typography style={styles.para}>
                  We get access to such information when you:
                </Typography>
                <li>
                  Register, subscribe, authorize the transfer of, or create an
                  account with us;
                </li>
                <li>Open or respond to emails</li>
                <li>
                  Provide information to enroll or participate in programs
                  provided on behalf of, or together with other Sellers,
                  merchants, co-marketers, distributors, resellers, and other
                  business partners, with your consent or as necessary to
                  provide services you have requested;
                </li>
                <li>Visit any page online that displays our ads or content;</li>
                <li>Purchase products or services on or through the Site;</li>
                <li>
                  Interact or connect with or link to the Site via integrated
                  social networking tools;
                </li>
                <li>Post comments to the Site.</li>
              </ul>
            </div>
            <Typography
              pt={2}
              textAlign={"justify"}
              style={styles.para}
              fontWeight={700}
              fontSize={"20px"}
              variant="h1"
            >
              MANAGING USER CHOICES
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              You can manage the types of personal data you provide to us and
              can limit how we communicate with you.
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              You can manage your email and notice preferences by logging into
              your account through the Site or by adjusting the settings in our
              mobile application.
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              You can also manage your subscriptions by following subscription
              management instructions contained in any commercial emails that we
              send you.
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              You may update your subscription preferences at any time. Please
              note that even if you decide not to subscribe to, or unsubscribe,
              from promotional email messages, we may still need to contact you
              with important transactional information related to your account
              and your purchases. For example, even if you have unsubscribed
              from our promotional email messages, we will still send you
              confirmations when you make purchases on the Site.
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              You hereby consent to receive communications by SMS or calls from
              Thrillh or its affiliates or its partners about the services
              provided by Thrillh or as facilitated by the Thrillh partners.
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              You may manage how your browser handles cookies by adjusting its
              privacy and security settings. Browsers are different, so refer to
              instructions related to your browser to learn about cookie-related
              and other privacy and security settings that may be available.
              Please refer to the Policy for information on Cookies for more
              information.
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              You may also manage how your mobile device and mobile browser
              share information on and about your devices with us, as well as
              how your mobile browser handles cookies by adjusting the privacy
              and security settings on your mobile device. Please refer to the
              instructions provided by your mobile service provider and the
              manufacturer of your device to learn how to adjust your settings.
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              You may also manage the sharing of certain personal data with us
              when you connect with us through social networking platforms or
              applications. Please refer to the paragraph below and also the
              privacy policy and settings of the social networking websites or
              applications to determine how you may adjust our permissions and
              manage the interactivity between us and your social networking
              account or your mobile device.
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              If you register for customized email newsletters containing offers
              for local services and goods, we will work to make information
              more relevant for you and customize newsletters based on the
              information you share with us, your location, website browsing
              preferences (for example, through cookies), purchase history or
              based on other attributes of your relationship with us. You can
              reject and delete cookies and unsubscribe from newsletters at any
              time by clicking links in each newsletter you wish to unsubscribe
              from.
            </Typography>
            <Typography
              pt={2}
              textAlign={"justify"}
              style={styles.para}
              fontWeight={700}
              fontSize={"20px"}
              variant="h1"
            >
              USE OF INFORMATION
            </Typography>
            <Typography
              pt={2}
              textAlign={"justify"}
              style={styles.para}
              fontWeight={700}
              variant="h2"
              fontSize={"18px"}
            >
              We process personal data for the following purposes:
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              Your information will enable us to provide you with access to
              specific parts of our site and to operate the Thrillh.com service
              for you. We will also use and analyze the information we collect
              so that we can administer, support, improve and develop our
              business.
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              In particular, we may use your information to send you marketing
              communications to contact you for your views on our services and
              to notify you occasionally about important changes or developments
              to the site or our services.
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              Further, we might also use your information to let you know about
              other products and services which we offer which may be of
              interest to you. If you change your mind about being contacted in
              the future, you can opt out by contacting us directly or changing
              your preferences through your account settings.
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              In case of any market R&D, with your permission, Thrillh may use
              the data provided by you for your benefit and the benefit of the
              Indian Economy. However, any information provided by you to any
              other third-party websites, when redirected to, shall not be
              falling under the purview of this policy and terms.
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              If you contact us, we may keep a record of that correspondence.
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              For content that is covered by intellectual property rights, such
              as photos and videos ("Media"), subject to your account settings,
              you grant us a non-exclusive, transferable, sub-licensable,
              royalty-free, worldwide license ("Licence") to use any Media that
              you provide in connection with your account for any of our
              commercial or non-commercial purposes including, without
              limitation, in our printed publications, presentations,
              promotional materials, advertising or on our website. This License
              ends when you delete your Media or your account unless your
              content has been shared with others, and they have not deleted it.
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              When you delete Media, it is deleted like emptying the recycle bin
              on a computer. However, you understand that removed content may
              persist in backup copies for a reasonable period (but will not be
              available to others).
            </Typography>
            <div>
              <ul className="lists">
                <li>Operate, maintain, and improve the Site;</li>
                <li>
                  Provide you with personalized direct marketing initiatives via
                  email and direct marketing offers;
                </li>
                <li>
                  Facilitate and process orders – for example, for Voucher
                  Coupon Codes and other goods and services;
                </li>
                <li>
                  Determine your eligibility for certain types of offers,
                  products, or services that may be of interest to you, and
                  analyze advertising effectiveness;
                </li>
                <li>Answer your questions and respond to your requests;</li>
                <li>
                  To establish and analyze individual and group profiles and
                  customer behavior;
                </li>
                <li>
                  Communicate and provide additional information that may be of
                  interest to you about us, the Sellers, and our business
                  partners;
                </li>
                <li>
                  Send you reminders, technical notices, updates, security
                  alerts, support and administrative messages, service
                  bulletins, marketing messages, and requested information,
                  including on behalf of business partners;
                </li>
                <li>
                  Administer rewards, surveys, contests, or other promotional
                  activities or events;
                </li>
                <li>
                  Manage our everyday business needs, such as administration of
                  the Site, analytics, fraud prevention, and enforcement of our
                  corporate reporting obligations and Terms of Use or to comply
                  with the law;
                </li>
                <li>Technical administration and customization of Website;</li>
                <li>
                  Delivery of personalized information and target advertisements
                  to the User;
                </li>
                <li>
                  Non-personally identifiable information, exclusively owned by
                  the Company may be used in an aggregated or non-personally
                  identifiable form for internal research, statistical analysis,
                  and business intelligence purposes including those to
                  determine the number of visitors and transactional details,
                  and we may sell or otherwise transfer such research,
                  statistical or intelligence data in an aggregated or
                  non-personally identifiable form to third parties and
                  affiliates;
                </li>
                <li>to conduct academic research and surveys</li>
                <li>to protect the integrity of the Website/Application;</li>
                <li>
                  to respond to legal, judicial, and quasi-judicial processes
                  and provide information to law enforcement agencies or in
                  connection with an investigation on matters related to public
                  safety, as permitted by law;
                </li>
                <li>
                  to conduct analytical studies on various aspects including
                  user behavior, user preferences, etc.;
                </li>
                <li>to implement information security practices;</li>
                <li>
                  to determine any security breaches, computer contaminants, or
                  computer viruses;
                </li>
                <li>
                  to investigate, prevent, or take action regarding illegal
                  activities and suspected fraud,
                </li>
                <li>
                  to undertake forensics of the concerned computer resource as a
                  part of an investigation or internal audit;
                </li>
                <li>
                  to trace computer resources or any person who may have
                  contravened, or is suspected of having or being likely to
                  contravene, any provision of law including the Information
                  Technology Act, 2000 that is likely to harm the services
                  provided on any Website/Application or by Thrillh;
                </li>
                <li>
                  Comply with our legal obligations, resolve disputes, and
                  enforce our agreements;
                </li>
                <li>
                  Allows you to sign up for special offers from merchants and
                  other business partners through the Site;
                </li>
                <li>
                  Enhance other information we have about you to help us better
                  understand you, determine your interests, and provide you with
                  more relevant and compelling services.
                </li>
              </ul>
            </div>
            <Typography
              style={styles.para}
              pt={2}
              textAlign={"justify"}
              fontWeight={700}
              fontSize={"20px"}
              variant="h1"
            >
              DISCLOSURE OF INFORMATION
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              We are not in the business of selling or renting personal data. We
              will not share your data, except in the following manner:
            </Typography>
            <div>
              <ul className="lists">
                <li>
                  With the Sellers, so they can sell and deliver to you and
                  provide other ancillary services such as table reservation to
                  serve you better;
                </li>
                <li>
                  To report or collect on payments owed to Sellers, merchants,
                  or other business partners;
                </li>
                <li>
                  As necessary to perform contractual obligations towards you
                  with business partners to the extent you have purchased or
                  redeemed a Thrillh Voucher coupon Code, goods or services
                  offered by a business partner or participated in an offer,
                  rewards, contest, or other activity or program sponsored or
                  offered through us or the Sellers on behalf of a business
                  partner;
                </li>
                <li>
                  In case of a merger, acquisition, or reorganization with a
                  purchaser of our company or all or substantially all of our
                  assets;
                </li>
                <li>
                  To comply with legal orders and government requests, or as
                  needed to support auditing and compliance;
                </li>
                <li>
                  To combat fraud or criminal activity, and to protect our
                  rights or those of our affiliates, business partners, and
                  users, or as part of legal proceedings affecting Thrillh;
                </li>
                <li>
                  With your consent or as otherwise required or permitted by
                  law.
                </li>
                <li>
                  To Government institutions/authorities to the extent required
                  Under the laws, rules, and regulations and/or under orders of
                  any relevant judicial or quasi-judicial authority;
                </li>
                <li>
                  To protect and defend the rights or property of the Company;
                </li>
                <li>To fight fraud and credit risk;</li>
                <li>
                  To enforce the Company's Terms of Use (to which this Privacy
                  Policy is also a part);
                </li>
                <li>
                  When the Company, in its sole discretion, deems it necessary
                  to protect its rights or the rights of others.
                </li>
                <li>
                  For R & D purposes of the Company and for providing better
                  services to the Users themselves and for the betterment of the
                  business of the corporate or business users
                </li>
                <li>
                  The Company makes all User Information accessible to its
                  employees and data processors only on a need-to-know basis.
                  All the employees and data processors, who have access to, and
                  are associated with the processing of User Information, are
                  obliged to respect its confidentiality.
                </li>
                <li>
                  If otherwise required by an order under any law for the time
                  being in force including in response to inquiries by
                  Government agencies for verification of identity, or
                  prevention, detection, investigation including cyber
                  incidents, prosecution, and punishment of offenses.
                </li>
              </ul>
            </div>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              We encourage business partners to adopt and post privacy policies.
              However, while we share personal data with business partners only
              for the above-mentioned purposes, their subsequent processing and
              use of personal data obtained through Thrillh is governed by their
              privacy policies and practices and are not subject to our control.
            </Typography>
            <Typography
              pt={2}
              style={styles.para}
              textAlign={"justify"}
              fontWeight={700}
              fontSize={"20px"}
              variant="h1"
            >
              COLLECTION OF INFORMATION
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              We collect your data:
            </Typography>
            <div>
              <ul className="lists">
                <li>
                  When you register, subscribe, create an account, make a
                  purchase, redeem Voucher Coupon Codes, or otherwise contact us
                  or communicate with us actively. For example, you provide
                  personal data to us when you contact us online for customer
                  service and other support using self-help tools, such as
                  email, text, or by posting to online communities.
                </li>
                <li>
                  When you interact with the Site via cookies and other
                  technologies as described in Clause on Cookies below.
                </li>
                <li>
                  Through other online and offline information from our business
                  partners who may report to us redemption, collection, or
                  refund-return events.
                </li>
              </ul>
            </div>
            <Typography
              pt={2}
              style={styles.para}
              textAlign={"justify"}
              fontWeight={700}
              fontSize={"20px"}
            >
              SECURITY OF PERSONAL INFORMATION
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              We use strict security measures to ensure the security, integrity,
              and privacy of your Personal Information and to protect your
              Personal Information against unauthorized access or unauthorized
              alteration, disclosure, or destruction. For this purpose, we have
              adopted internal reviews of the data collection, storage, and
              processing practices and security measures, including offering the
              use of a secure server to guard against unauthorized access to
              systems where we store your data.
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              We shall, however, not be responsible for any breach of security
              or for any actions of any third parties that receive your Personal
              Information. The Website is also linked to many other sites and we
              are not/ shall be not responsible for their privacy policies or
              practices as it is beyond our control.
            </Typography>
            <Typography
              pt={2}
              style={styles.para}
              textAlign={"justify"}
              fontWeight={700}
              fontSize={"18px"}
            >
              User’s Rights about Personal Data:
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              By keeping your data updated, you can help us to ensure that we
              can respect your preferences and offer you the goods and services
              that are most relevant to you.
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              You can access, update, rectify, and delete the information you
              provided to us in your profile by logging into your account or
              contacting us at <a href="http://thrillh.com/contact-us" target="_blank">http://thrillh.com/contact-us</a>
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              While we are ready to assist you in managing your subscriptions,
              closing your account, and removing your active profile, we cannot
              always delete records of past interactions and transactions. For
              example, we may be required to retain records relating to previous
              purchases on the Site for financial reporting and compliance
              reasons.
            </Typography>
            <Typography
              pt={2}
              textAlign={"justify"}
              style={styles.para}
              fontWeight={700}
              fontSize={"18px"}
            >
              RETENTION OF PERSONAL DATA
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              You can at any time unsubscribe from our services, newsletters,
              and e-mailers. We will, however, retain your data for as long as
              your account is active or as needed to provide you services and to
              maintain a record of your transactions for financial reporting or
              other compliance purposes. We will also retain your data as
              necessary to comply with our legal obligations, resolve disputes,
              and enforce our agreements.
            </Typography>
            <Typography
              pt={2}
              style={styles.para}
              textAlign={"justify"}
              fontWeight={700}
              fontSize={"18px"}
            >
              Accessing and updating
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              You are entitled to see the information held about you and you may
              ask us to make any necessary changes to ensure that it is accurate
              and kept up to date. If you wish to do this, please contact us. We
              are entitled by law to charge a fee of INR rupees- 400 to meet our
              costs in providing you with details of the information we hold
              about you.
            </Typography>
            <Typography
              pt={2}
              style={styles.para}
              textAlign={"justify"}
              fontWeight={700}
              fontSize={"18px"}
            >
              Changes to our Privacy Policy
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              Any changes to our privacy policy in the future will be posted to
              the site and, where appropriate, through email notification
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              SOCIAL SITE FEATURES
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              The Site may contain connections to areas where you may be able to
              publicly post information, communicate with others such as
              discussion boards or blogs, review products and merchants, and
              otherwise submit your original content. Before posting in these
              areas, please read our Terms of Use carefully. All the information
              you post may be accessible to anyone with Internet access, and any
              information you include in your posting may be read, collected,
              and used by others.
            </Typography>
            <Typography
              pt={2}
              textAlign={"justify"}
              fontWeight={700}
              style={styles.para}
              fontSize={"20px"}
            >
              Connecting through Social Networks:
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              We offer social networking users the opportunity to interact with
              friends and to share on social networks. If you are logged into
              both the Site and a social network, when you use the Site’s social
              networking connection functions, you may connect your social
              network account with your Thrillh account (this happens
              automatically if the email addresses match). If the email
              addresses do not match, we ask you if you want to link them and
              you must validate that you control the accounts. If you are
              already logged into the Site but not logged into your social
              network site, when you use the Site’s social network connection
              functions, you will be prompted to enter your social network
              website credentials or to sign up for the social network.
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              If you are not currently registered as a Thrillh user and you use
              the Site’s social network connection functions, you will first be
              asked to enter your social network credentials and then be given
              the option to register and join Thrillh. Once you register with us
              and connect with the social network, you will be able to
              automatically post recent Thrillh activity back to your social
              network. Please refer to the privacy settings in your social
              network account to manage the data that is shared through your
              account.
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              By using the Site’s social network connection function, you will
              permit us to access all of the elements of your social network
              profile information that you have made available to be shared (as
              per the settings chosen by you in your social network profile) and
              to use it following the social network’s Terms of Use and this
              Privacy Policy.
            </Typography>
            <Typography
              pt={2}
              style={styles.para}
              textAlign={"justify"}
              fontWeight={700}
              fontSize={"20px"}
            >
              PRIVACY POLICIES OF THIRD PARTIES
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              This Privacy Policy only addresses the collection, processing, and
              use (including disclosure) of information by us through your
              interaction with the Site. Other websites that may be accessible
              through links from the Site may have their privacy policies and
              personal information collection, processing, use, and disclosure
              practices. Our business partners may also have their privacy
              policies. We encourage you to familiarize yourself with the
              privacy policies provided by these other parties before providing
              them with information or taking advantage of a sponsored offer or
              promotion.
            </Typography>
            <Typography
              pt={2}
              style={styles.para}
              textAlign={"justify"}
              fontWeight={700}
              fontSize={"20px"}
              variant="h1"
            >
              COOKIES
            </Typography>
            <Typography
              pt={2}
              style={styles.para}
              textAlign={"justify"}
              fontWeight={700}
              fontSize={"18px"}
            >
              Consent:
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              By using our website or mobile app ( “Site” ), using services
              provided through the Site, or receiving our emails, you consent to
              the use of cookies following this “Cookies Policy” .
            </Typography>
            <Typography textAlign={"justify"} style={styles.para}>
              Intent:
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              This Privacy Policy sets out how we, and the third parties we
              authorize, use cookies when you interact with our Site or the
              services offered via the Site. Our Privacy Policy explains what we
              do (and don't do) with the information, including any personal
              data we may collect about you. Please read them both.
            </Typography>
            <Typography
              style={styles.para}
              textAlign={"justify"}
              fontWeight={700}
              fontSize={"20px"}
            >
              Meaning:
            </Typography>
            <Typography pt={2} style={styles.para} textAlign={"justify"}>
              Cookies are unique identifiers, usually made up of small bits of
              text or code. Cookies are usually stored on your device or in your
              web browser and they send certain information back to the party
              who served the cookie. When we say cookies we are referring to a
              broader range of technologies than just cookies, including web
              beacons, clear gifs, pixels, and other similar technologies. When
              we say first-party cookies we mean cookies that are served by
              Thrillh. When we say third-party cookies we mean cookies that are
              served by non-Thrillh parties.
            </Typography>
            <Typography
              pt={2}
              style={styles.para}
              textAlign={"justify"}
              fontWeight={700}
              fontSize={"20px"}
            >
              Use of Cookies:
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              Cookies are used to better understand how applications and
              websites work and to optimize online and mobile experiences.
              Cookies do this by allowing the party serving the cookie to
              analyze a website’s or mobile app’s performance, to identify
              certain users anonymously and remember their preferences, to
              better understand whether a computer or device (and probably its
              user) has visited a website previously and to serve personalized
              advertisements.
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              Types of Cookies:
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              Our Site primarily uses the following four types of cookies:
            </Typography>

            <div>
              <ul className="lists">
                <li>
                  Operations-based Cookies: These cookies are essential for the
                  operation of our Site. They make it possible for you to browse
                  and use our Site. If you disable these cookies, the Site will
                  not function correctly.
                </li>
                <li>
                  Performance-based Cookies: These cookies gather information
                  about how visitors use the Site and monitor Site performance.
                  For example, performance cookies tell us which pages are
                  popular, monitor traffic to our Site and compile anonymous
                  analytic information. Performance cookies can also be used to
                  identify and remedy operational problems with the Site.
                </li>
                <li>
                  Functionality-based Cookies: These cookies are used to
                  recognize and remember your preferences. For example,
                  functionality cookies may use information, such as your
                  location, to make sure that you arrive at a version of our
                  Site that is specific to your city or region.
                </li>
                <li>
                  Advertising Preferences-based Cookies: We offer you choices
                  about receiving interest-based ads from us. For this, we allow
                  third parties to deliver personalized advertising content on
                  our Site and/ or on third-party websites and applications.
                  These third parties use cookies to understand your interest in
                  the advertisements and content delivered to you, as well as to
                  serve you with more relevant advertisements based on your
                  activities on our Site. For example, third-party cookies could
                  record information about whether you have clicked on certain
                  content or advertisements on our Site or other websites or
                  placed an item on our Site in your shopping cart.
                </li>
                <li>
                  Strictly necessary cookies: these are cookies that are
                  required for the operation of our website. They include, for
                  example, cookies that enable you to log into secure areas of
                  our website, use a shopping cart, or make use of e-billing
                  services. They are also used to recognize you when you return
                  to our website. This enables us to personalize our content for
                  you, greet you by name, and remember your preferences (for
                  example, your choice of language or region).
                </li>
                <li>
                  Analytical/performance cookies: they allow us to recognize and
                  count the number of visitors and to see the pages you have
                  visited and the links you have followed. We will use this
                  information to make our website and the advertising displayed
                  on it more relevant to your interests. We may also share this
                  information with third parties for this purpose.
                </li>
                <li>
                  Please note that third parties (including, for example,
                  advertising networks and providers of external services like
                  web traffic analysis services) may also use cookies, over
                  which we have no control.
                </li>
                <li>
                  You block cookies by activating the setting on your browser
                  that allows you to refuse the setting of all or some cookies.
                  However, if you use your browser settings to block all cookies
                  (including essential cookies) you may not be able to access
                  all or parts of our site.
                </li>
                <Typography style={styles.para}>
                  We do not have control over, or access to, third-party
                  advertising cookies or the information they contain. Third
                  parties manage any information that they collect via cookies
                  they place following their privacy policies.
                </Typography>
                <Typography style={styles.para}>
                  You may opt out of third-party personalized advertising
                  cookies. You will still see ads but they will not be
                  personalized.
                </Typography>
                <li>
                  Cookies and Browser settings: You may be able to avoid certain
                  cookies by configuring your browser settings or opting out
                  directly with a party that operates and controls cookies.
                  Please check your browser settings for more information
                </li>
                <li>
                  Cookies in E-mails: Our emails contain cookies that track
                  whether you open our emails and whether you click any links
                  within them. The cookies used in our emails to monitor these
                  behaviors are unique to each email and are not stored on your
                  computer or mobile device.
                </li>
              </ul>
            </div>
            <Typography className="para" variant="h1">
              ANDROID APP PERMISSIONS ARE REQUIRED TO USE VARIOUS FEATURES IN
              OUR APP
            </Typography>
            <Typography pt={2} style={styles.para} textAlign={"justify"}>
              To have a better experience and to use all features in-app, we
              need the below permissions to be used in our Android app:
            </Typography>
            <div>
              <ul className="lists">
                <li>
                  Account Identity & Contacts: We use it to enable you to
                  connect using a college email account or a bonafide or student
                  id card. It enables you to see which of your friends have
                  liked or checked in at a certain merchant outlet.
                </li>
                <li>
                  Location: To provide location-specific functionality like
                  restaurants or outlets near you.
                </li>
                <li>
                  SMS: We need to send and receive SMS permission, to read your
                  SMS and auto-populate One Time password received on your
                  mobile number for authentication of payments while making a
                  transaction.
                </li>
                <li>
                  Call Phone: We require access to make phone calls through the
                  app. You can call us or restaurants, spa/ salon outlets, etc
                  directly through the app.
                </li>
                <li>
                  Phone State: This permission is used to detect your Android ID
                  using which we uniquely identify every user and map
                  user-related activities. Using these details we also prevent
                  any transaction-related fraud.
                </li>
                <li>
                  Storage: This permission is used for enabling local caching of
                  data on your device to make your browsing experience faster
                  and smoother. We do not store any confidential data as part of
                  this permission.
                </li>
                <li>
                  Vibrate: This permission is used to alert you of an incoming
                  notification.
                </li>
                <li>
                  Geo-Services: This permission is used to notify you that you
                  have entered a city hotspot and there are various merchant
                  outlets offering deals in this area.
                </li>
                <li>
                  Maps: This permission is used to show you the exact location
                  of merchant outlets on a Google map, it also helps you
                  navigate and assess distance from your current location.
                </li>
                <li>
                  Boot-Complete: In case your phone restarts, we use this
                  permission to detect if in the meantime you have entered a
                  location hotspot so that we can trigger a notification
                  informing you of the same along with deals in the area.
                </li>
                <li>
                  Network: To connect to the Internet and networks in the
                  proximity of the User for the App to function.
                </li>
                <li>
                  Install Shortcut: The shortcut of the Thrillh App is
                  auto-created upon installation by the User.
                </li>
                <li>
                  Wake Lock for Background Works: In case of multiple background
                  services, your Device shall require these background works
                  from time to time.
                </li>
              </ul>
            </div>
            <Typography className="para">
              IOS APP PERMISSIONS ARE REQUIRED TO USE VARIOUS FEATURES IN OUR
              APP
            </Typography>
            <Typography pt={2} textAlign={"justify"} style={styles.para}>
              To have a better experience and to use all features in the app, we
              need the below permissions to be used in our IOS app:
            </Typography>
            <div>
              <ul className="lists">
                <li>
                  Account Identity & Contacts: We use it to enable you to
                  connect using a college email account or a bonafide or student
                  id card. It enables you to see which of your friends have
                  liked or checked in at a certain merchant outlet.
                </li>
                <li>
                  Location: To provide location-specific functionality like
                  restaurants or outlets near you.
                </li>
                <li>
                  Camera and Microphone: To scan QR Codes and/ or any other
                  codes, Voucher coupon Codes, etc. on the App.
                </li>
                <li>
                  Gallery: To use, access, and upload photos or videos, or
                  selfies as rating/ feedback/ participation/testimonials, etc.
                </li>
              </ul>
            </div>
          </Box>
        </Grid>
      </Box>
      <Grid>
        <Box>
          <Footer from={"terms"} />
        </Box>
      </Grid>
    </>
  );
};

export default TermsOfServices;

const styles = {
  footerCardImg: {
    borderRadius: 30,
  },
  footerCardSubText: {
    fontFamily: "MetropolisRegular",
    fontSize: 15,
    color: Colors.textColor,
    textAlign: "center",
  },
  registerBrand: {
    fontFamily: "MetropolisRegular",
    fontSize: 15,
    color: Colors.textColor,
    marginRight: 5,
  },
  copyright: {
    fontFamily: "MetropolisRegular",
    color: Colors.white,
    fontSize: 15,
  },
  footerCardText: {
    fontFamily: "MetropolisMedium",
    color: Colors.lightBlue,
    fontSize: 23,
    textAlign: "center",
  },
  para: {
    fontFamily: "MetropolisMedium",
    fontSize: "16px",
  },
};
