import { Box, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import eyeSvg from "../../../images/orange eye.svg";
import { useLocation } from "react-router-dom";
import CustomInput from "../../components/Input";
import CustomSelect from "../../components/Select/CustomSelect";
import { ApiRequest } from "../../../services/api-request";
import CustomDialog from "../../components/Dialog/custom-dialog";
import VerifyUser from "./verify-user";
import { useNavigate } from "react-router-dom";
import { capitalizeFirstLetter } from "../../utilities/date-utility";
import { useSnackbar } from "../../../context/useSnackbar";
import CustomButton from "../../components/Button";
import Pagination from "../../components/Pagination";
import { useAppContext } from "../../../context/AppContext";

const UserList = (props) => {
  const { openSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const { handleLogoutSuccess } = useAppContext();

  const { pathname } = useLocation();

  const { listTitle, bgcolor, openedFrom } = props;

  const userStatus = [
    {
      name: "In-review",
      value: "in-review",
    },
    {
      name: "Active",
      value: "active",
    },
    {
      name: "In-Active",
      value: "in-active",
    },
  ];

  const userTypes = [
    {
      name: "Student",
      value: "student",
    },
    {
      name: "Corporate",
      value: "corporate",
    },
  ];

  const limit = 100; // Users per page
  const [skip, setSkip] = useState(0);

  const [userList, setUserList] = useState([]);

  const [openVerifyUserModal, setOpenVerifyUserModal] = useState({
    isOpen: false,
    data: {},
  });

  const [filter, setFilter] = useState({
    search: "",
    status: "",
    userType: "",
  });

  function usersURL(filter = null) {
    const { search = "", status = null, userType = null } = filter || {};
    if (filter?.search?.length || status || userType) {
      if (search?.length && !userType && !status) {
        return `user/list?skip=${skip}&limit=${limit}${
          search ? `&search=${search}` : ""
        }`;
      } else if (status && !search?.length && !userType) {
        return `user/list?skip=${skip}&limit=${limit}${
          status ? `&status=${status}` : ""
        }`;
      } else if (userType && !status && !search?.length) {
        return `user/list?skip=${skip}&limit=${limit}${
          userType ? `&userType=${userType}` : ""
        }`;
      } else if (
        (search?.length && status) ||
        (search?.length && userType) ||
        (search?.length && status && userType)
      ) {
        return `user/list?skip=${skip}&limit=${limit}${
          search ? `&search=${search}` : ""
        }${status ? `&status=${status}` : ""}${
          userType ? `&userType=${userType}` : ""
        }`;
      } else if (status && userType) {
        return `user/list?skip=${skip}&limit=${limit}${
          status ? `&status=${status}` : ""
        }${userType ? `&userType=${userType}` : ""}`;
      }
    } else {
      return `user/list?skip=${skip}&limit=${limit}`;
    }
  }

  useEffect(() => {
    getAllUsers(filter);
  }, [skip, filter]);

  const getAllUsers = (filter) => {
    ApiRequest({
      url: usersURL(filter),
      method: "GET",
    })
      .then((res) => {
        setUserList(res);
      })
      .catch((err) => {
        openSnackbar(err?.response?.data?.message, "error");
        if (err?.statusCode === 403 || err?.statusCode === 401) {
          if (navigate) {
            navigate("/login"); // Navigate to "/login" route
            handleLogoutSuccess();
          }
        }
      });
  };

  const handlePageChange = (page) => {
    const newSkip = (page - 1) * limit;
    setSkip(newSkip);
  };

  const onUserStatusSelected = (e) => {
    const selectedStatus = e.target.value;
    setFilter((prevFilter) => ({
      ...prevFilter,
      status: selectedStatus,
    }));
    getAllUsers({ ...filter, status: selectedStatus });
  };

  const userTypeSelected = (e) => {
    const selectedUserType = e.target.value;
    setFilter((prevFilter) => ({
      ...prevFilter,
      userType: selectedUserType,
    }));
    getAllUsers({ ...filter, userType: selectedUserType });
  };

  const handleSearchChange = (e) => {
    const searchValue = e.target.value;
    setFilter((prevFilter) => ({
      ...prevFilter,
      search: searchValue,
    }));
    getAllUsers({ ...filter, search: searchValue });
  };

  const clearFilters = () => {
    getAllUsers({
      search: "",
      type: "",
      userType: "",
    });
    setFilter({
      search: "",
      type: "",
      userType: "",
    });
  };
  return (
    <>
      <CustomDialog
        open={openVerifyUserModal?.isOpen}
        title={"Verification"}
        width={"500px"}
        onClose={() => setOpenVerifyUserModal({ isOpen: false })}
      >
        <VerifyUser
          openVerifyUserModal={openVerifyUserModal}
          onClose={() => setOpenVerifyUserModal({ isOpen: false })}
          getAllUsers={getAllUsers}
          getUserSummary={props.getUserSummary}
        />
      </CustomDialog>

      <Box
        height={pathname === "/admin/users" ? null : "400px"}
        sx={{ overflowY: "scroll" }}
      >
        <Box
          bgcolor={bgcolor ? bgcolor : "#FFEDE3"}
          padding={"20px"}
          borderRadius={
            pathname === "/admin/users" ? "40px 0px 0px 40px" : "10px"
          }
        >
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography
              fontFamily={"MetropolisBold"}
              fontSize={"20px"}
              color={"#000000"}
            >
              {listTitle ? listTitle : "Users"}
            </Typography>
            {props.viewMore === "viewMore" && (
              <Typography
                sx={{ cursor: "pointer" }}
                fontFamily={"MetropolisSemiBold"}
                fontSize={"14px"}
                color={"#000000"}
                onClick={props.handleNavigate}
              >
                View More
              </Typography>
            )}
          </Box>
          <Box
            bgcolor={"#fff"}
            padding={"10px"}
            borderRadius={"10px"}
            mt={pathname === "/admin/users" ? 2 : 0}
          >
            {pathname === "/admin/users" && (
              <>
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  width={"100%"}
                >
                  <Box width={"25%"}>
                    <CustomInput
                      placeHolder={"Search with name or mobile"}
                      borderRadius={"5px"}
                      onChange={handleSearchChange}
                      value={filter.search}
                    />
                  </Box>
                  <Box
                    display={"flex"}
                    justifyContent={"end"}
                    gap={"10px"}
                    width={"75%"}
                  >
                    <Box width={"30%"}>
                      <CustomSelect
                        placeHolder={"Status"}
                        value={filter.status}
                        options={userStatus}
                        borderRadius={"5px"}
                        onChange={(e) => onUserStatusSelected(e)}
                      />
                    </Box>
                    <Box width={"30%"}>
                      <CustomSelect
                        placeHolder={"UserType"}
                        value={filter.userType}
                        options={userTypes}
                        borderRadius={"5px"}
                        onChange={(e) => userTypeSelected(e)}
                      />
                    </Box>
                    <Box width={"10%"} display={"flex"} alignItems={"end"}>
                      <CustomButton
                        title={"Clear"}
                        handleClick={clearFilters}
                      />
                    </Box>
                  </Box>
                </Box>
              </>
            )}
            <Box
              mt={"12px"}
              padding={"5px 10px"}
              borderRadius={"5px"}
              bgcolor={"#FFEDE3"}
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Box width={pathname === "/admin/users" ? "10%" : "5%"}>
                <Typography color={"#000000"} fontFamily={"MetropolisSemiBold"}>
                  Id
                </Typography>
              </Box>
              <Box width={pathname === "/admin/users" ? "20%" : "25%"}>
                <Typography color={"#000000"} fontFamily={"MetropolisSemiBold"}>
                  User Name
                </Typography>
              </Box>
              <Box width={pathname === "/admin/users" ? "10%" : "25%"}>
                <Typography color={"#000000"} fontFamily={"MetropolisSemiBold"}>
                  User Type
                </Typography>
              </Box>
              {pathname === "/admin/users" && (
                <>
                  <Box width={"15%"}>
                    <Typography
                      color={"#000000"}
                      fontFamily={"MetropolisSemiBold"}
                    >
                      Regd. Mob. No
                    </Typography>
                  </Box>
                  <Box width={"20%"}>
                    <Typography
                      color={"#000000"}
                      fontFamily={"MetropolisSemiBold"}
                    >
                      Official Email
                    </Typography>
                  </Box>
                </>
              )}
              <Box width={pathname === "/admin/users" ? "10%" : "25%"}>
                <Typography color={"#000000"} fontFamily={"MetropolisSemiBold"}>
                  Status
                </Typography>
              </Box>
              <Box width={pathname === "/admin/users" ? "15%" : "25%"}>
                <Typography color={"#000000"} fontFamily={"MetropolisSemiBold"}>
                  Action
                </Typography>
              </Box>
            </Box>
            {userList?.result?.map((item, index) => {
              const adjustedIndex = index + skip + 1;
              return (
                <Box
                  key={index}
                  padding={"5px 10px"}
                  borderRadius={"5px"}
                  bgcolor={"#FFFFFF"}
                  border={"1px solid #FEECE3"}
                  mt={"5px"}
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Box width={pathname === "/admin/users" ? "10%" : "5%"}>
                    <Typography
                      color={"#000000"}
                      fontFamily={"MetropolisMedium"}
                    >
                      {adjustedIndex}
                    </Typography>
                  </Box>
                  <Box width={pathname === "/admin/users" ? "20%" : "25%"}>
                    <Typography
                      sx={{ wordBreak: "break-word" }}
                      color={"#000000"}
                      fontFamily={"MetropolisMedium"}
                    >
                      {item.name ?? "NA"}
                    </Typography>
                  </Box>
                  <Box width={pathname === "/admin/users" ? "10%" : "25%"}>
                    <Typography
                      color={"#000000"}
                      fontFamily={"MetropolisMedium"}
                    >
                      {item.role?.name ?? "NA"}
                    </Typography>
                  </Box>
                  {pathname === "/admin/users" && (
                    <>
                      <Box width={"15%"}>
                        <Typography
                          color={"#000000"}
                          fontFamily={"MetropolisMedium"}
                        >
                          {item.mobile}
                        </Typography>
                      </Box>
                      <Box width={"20%"}>
                        <Typography
                          sx={{ wordBreak: "break-word" }}
                          color={"#000000"}
                          fontFamily={"MetropolisMedium"}
                        >
                          {item.email ?? "NA"}
                        </Typography>
                      </Box>
                    </>
                  )}
                  <Box width={pathname === "/admin/users" ? "10%" : "25%"}>
                    <Typography
                      color={"#000000"}
                      fontFamily={"MetropolisMedium"}
                    >
                      {capitalizeFirstLetter(item.status) ?? "NA"}
                    </Typography>
                  </Box>

                  <Box
                    sx={{ cursor: "pointer" }}
                    display={"flex"}
                    gap={"10px"}
                    alignItems={"center"}
                    width={pathname === "/admin/users" ? "15%" : "25%"}
                  >
                    <>
                      {item?.status === "denied" ? null : (
                        <Box>
                          <Typography
                            onClick={() =>
                              setOpenVerifyUserModal({
                                isOpen: true,
                                data: item,
                              })
                            }
                            color={"#fff"}
                            bgcolor={"#FA8842"}
                            fontFamily={"MetropolisMedium"}
                            fontSize={"12px"}
                            border={"1px solid #FA8842"}
                            padding={"0px 12px"}
                            borderRadius={"15px"}
                          >
                            {item?.status === "active" ? "Verified" : "verify"}
                          </Typography>
                        </Box>
                      )}
                    </>

                    <Box width={"10%"}>
                      {(item?.status === "active" ||
                        item?.status === "in-review" ||
                        item?.status === "in-active" ||
                        item?.status === "denied") && (
                        <img
                          src={eyeSvg}
                          alt=""
                          width={"20px"}
                          onClick={() =>
                            // console.log(item, "UUUUUUUUUUUUUUUUU")
                            navigate(`/admin/users/${item?._id}/details`)
                          }
                        />
                      )}
                    </Box>
                  </Box>
                </Box>
              );
            })}
            <Box mt={2} display={"flex"} justifyContent={"right"}>
              {openedFrom != "dashboard" && userList?.totalCount > 5 && (
                <Pagination
                  totalCount={Number(userList?.totalCount)}
                  skip={skip}
                  limit={limit}
                  onPageChange={handlePageChange}
                />
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default UserList;
