import React from "react";
import Tooltip from "@mui/material/Tooltip";
import { Typography } from "@mui/material";
const CustomToolTip = (props) => {
  return (
    <>
      <Tooltip
      sx={{"cursor":'pointer'}}
        title={
          <>
            <Typography
              color={"#fff"}
              fontFamily={"MetropolisMedium"}
              fontSize={"12px"}>
              {props.data}
            </Typography>
          </>
        }>
        <Typography sx={{ color: "#FA8842", cursor:"pointer" }}>{props.title}</Typography>
      </Tooltip>
    </>
  );
};

export default CustomToolTip;
