import { Box, Typography, Grid } from "@mui/material";
import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import topOfferSvg from "../../../../images/admin/topOffer.svg";
import activeOfferSvg from "../../../../images/admin/percentage.svg";
import AdminOrdersList from "../../admin/OrdersList";
import { useAppContext } from "../../../../context/AppContext";
import { ApiRequest } from "../../../../services/api-request";
import { useNavigate } from "react-router-dom";

const VendorDashboard = () => {
  const { user, handleLogoutSuccess } = useAppContext();

  const navigate = useNavigate();

  const [summary, setSummary] = useState({});

  useEffect(() => {
    getDashboardSummary();
  }, []);

  const getDashboardSummary = () => {
    ApiRequest({
      url: `vendor/dash-board/${user?.vendorCode}`,
      method: "GET",
    })
      .then((res) => {
        setSummary(res);
      })
      .catch((err) => {
        if (err?.statusCode === 403 || err?.statusCode === 401) {
          if (navigate) {
            navigate("/login"); // Navigate to "/login" route
            handleLogoutSuccess();
          }
        }
      });
  };

  const corporateCount = summary?.offerDoc?.corporateCount ?? 0;
  const studentCount = summary?.offerDoc?.studentCount ?? 0;
  const totalCount = corporateCount + studentCount;
  
  return (
    <>
      <Box>
        <Box
          padding={"20px"}
          bgcolor={"#FFEDE3"}
          borderRadius={"40px 0px 0px 40px"}
        >
          <Grid container spacing={3}>
            <Grid item xs={5}>
              <Box
                bgcolor={"#000000"}
                padding={"24px 20px"}
                borderRadius={"10px"}
              >
                <Typography
                  color={"#FFFFFF"}
                  fontSize={"24px"}
                  fontFamily={"MetropolisMedium"}
                >
                  Users
                </Typography>
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  gap={"10px"}
                  alignItems={"center"}
                  mt={3}
                  padding={"0px 40px"}
                >
                  <Box textAlign={"center"}>
                    <Typography
                      textAlign={"center"}
                      color={"#FFFFFF"}
                      fontSize={"30px"}
                      fontFamily={"MetropolisMedium"}
                    >
                      {totalCount}
                    </Typography>
                    <Typography
                      color={"#707070"}
                      fontSize={"16px"}
                      fontFamily={"MetropolisMedium"}
                    >
                      Total
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      textAlign={"center"}
                      color={"#FFFFFF"}
                      fontSize={"30px"}
                      fontFamily={"MetropolisMedium"}
                    >
                      {summary?.offerDoc?.studentCount}
                    </Typography>
                    <Typography
                      color={"#707070"}
                      fontSize={"16px"}
                      fontFamily={"MetropolisMedium"}
                    >
                      Students
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      textAlign={"center"}
                      color={"#FFFFFF"}
                      fontSize={"30px"}
                      fontFamily={"MetropolisMedium"}
                    >
                      {summary?.offerDoc?.corporateCount}
                    </Typography>
                    <Typography
                      color={"#707070"}
                      fontSize={"16px"}
                      fontFamily={"MetropolisMedium"}
                    >
                      Corporates
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={7}>
              <Box padding={"20px"} bgcolor={"#FA8842"} borderRadius={"10px"}>
                <Grid container spacing={2}>
                  <Grid item xs={6} paddingTop={"0px !important"}>
                    <Box
                      position={"relative"}
                      mt={5}
                      bgcolor={"#FFFFFF"}
                      padding={"15px"}
                      borderRadius={"10px"}
                    >
                      <Box
                        bgcolor={"#000000"}
                        borderRadius={"10px"}
                        padding={"5px"}
                        width={"50px"}
                        textAlign={"center"}
                        position={"absolute"}
                        right={"15px"}
                        top={"-35px"}
                      >
                        <img
                          src={activeOfferSvg}
                          alt="img"
                          height={"44.28px"}
                          width={"30.39px"}
                        />
                      </Box>
                      <Box bgcolor={"#FFFFFF"}>
                        <Typography
                          fontFamily={"MetropolisSemiBold"}
                          color={"#000000"}
                          fontSize={"30px"}
                          mt={"10px"}
                          sx={{ wordBreak: "break-word" }}
                        >
                          {summary?.totalPayablePrice
                            ? parseFloat(summary?.totalPayablePrice).toFixed(2)
                            : 0}
                        </Typography>
                        <Typography
                          fontFamily={"MetropolisMedium"}
                          color={"#929292"}
                          fontSize={"18px"}
                        >
                          Credited from Thrillh Pay
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={6} paddingTop={"0px !important"}>
                    <Box
                      position={"relative"}
                      mt={5}
                      bgcolor={"#FFFFFF"}
                      padding={"15px"}
                      borderRadius={"10px"}
                    >
                      <Box
                        bgcolor={"#000000"}
                        borderRadius={"10px"}
                        padding={"5px"}
                        width={"50px"}
                        textAlign={"center"}
                        position={"absolute"}
                        right={"15px"}
                        top={"-35px"}
                      >
                        <img src={topOfferSvg} alt="img" />
                      </Box>
                      <Box bgcolor={"#FFFFFF"}>
                        <Typography
                          fontFamily={"MetropolisSemiBold"}
                          mt={"10px"}
                          sx={{ wordBreak: "break-word" }}
                          color={"#000000"}
                          fontSize={"30px"}
                        >
                          {summary?.totalOrders ?? 0}
                        </Typography>
                        <Typography
                          fontFamily={"MetropolisMedium"}
                          color={"#929292"}
                          fontSize={"18px"}
                        >
                          {"Total Orders"}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
          <Box mt={2}>
            <Typography
              color={"#000000"}
              fontSize={"20px"}
              fontFamily={"MetropolisBold"}
            >
              Recent Orders Payments
            </Typography>
          </Box>
          <Box>
            <AdminOrdersList />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default VendorDashboard;
