import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import imageSvg from "../../../../images/sidebar/active-settings (2).svg";
import { useSnackbar } from "../../../../context/useSnackbar";
import { useAppContext } from "../../../../context/AppContext";
import { Contact_Us_ISSUES } from "../../../utilities/constants";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ApiRequest } from "../../../../services/api-request";
import { useForm, Controller } from "react-hook-form";
import CustomButton from "../../../components/Button";
import CustomInput from "../../../components/Input";
import CustomSelect from "../../../components/Select/CustomSelect";
import { useNavigate } from "react-router-dom";
const VendorContactUs = () => {
  const { handleLogoutSuccess } = useAppContext();
  const navigate = useNavigate();
  const validateSchema = yup
    .object({
      description: yup.string().required("Description is required"),
      type: yup.string().required("Issue Type is required"),
    })
    .required();

  const { handleSubmit, control, reset } = useForm({
    resolver: yupResolver(validateSchema),
  });

  const { openSnackbar } = useSnackbar();

  const onSubmit = (data) => {
    const payload = {
      ...data,
    };
    ApiRequest({
      url: `user/issue/submit`,
      data: payload,
      method: "POST",
    })
      .then((res) => {
        if (res?.success === true) {
          openSnackbar(res?.message, "success");
          reset();
        } else {
          openSnackbar(res?.message, "error");
          reset();
        }
      })
      .catch((err) => {
        if (err) {
          openSnackbar(err?.response?.data?.message, "error");
          if (err?.statusCode === 403 || err?.statusCode === 401) {
            if (navigate) {
              navigate("/login"); // Navigate to "/login" route
              handleLogoutSuccess();
            }
          }
        }
      });
  };

  return (
    <>
      <Box
        bgcolor={"#FFEDE3"}
        padding={"20px"}
        borderRadius={"40px 0px 0px 40px"}
      >
        <Box
          display={"flex"}
          width={"100%"}
          justifyContent={"space-between"}
          alignItems={"right"}
        >
          <Typography
            fontFamily={"MetropolisBold"}
            fontSize={"20px"}
            color={"#000000"}
            variant="h1"
          >
            Contact Us
          </Typography>
        </Box>
        <Box
          padding={"15px 25px"}
          bgcolor={"#fff"}
          borderRadius={"10px"}
          mt={2}
        >
          <Typography fontFamily={"MetropolisSemibold"} fontSize={"13px"}>
            Cameras is a long-established fact that a reader will be distracted
            by the readable content of a page when looking at its layout. The
            point of using Lorem Ipsum is that it has a more- or-less normal
            distribution of letters, as opposed to using.
          </Typography>
          <Grid container spacing={2} mt={2}>
            <Grid item md={5} justifyContent={"center"} alignItems={"center"}>
              <Box padding={"20px 30px"} borderRight={"1px solid #000000"}>
                <Box mt={5}>
                  <Typography
                    fontFamily={"MetropolisBold"}
                    fontSize={"18px"}
                    color={"#000000"}
                  >
                    {" "}
                    Contact Details
                  </Typography>
                </Box>
                <Box display={"flex"} alignItems={"center"} gap={"10px"} mt={4}>
                  <Box
                    padding={"12px"}
                    borderRadius={"50%"}
                    bgcolor={"#FFEDE3"}
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <img src={imageSvg} alt="" />
                  </Box>
                  <Box>
                    <Typography
                      fontFamily={"MetropolisBold"}
                      fontSize={"15px"}
                      color={"#000000"}
                    >
                      Contact Number :
                    </Typography>
                    <Typography
                      mt={"5px"}
                      fontFamily={"MetropolisSemibold"}
                      fontSize={"13px"}
                      color={"#000000"}
                    >
                      +91 9966664878
                    </Typography>
                  </Box>
                </Box>
                <Box display={"flex"} alignItems={"center"} gap={"10px"} mt={4}>
                  <Box
                    padding={"12px"}
                    borderRadius={"50%"}
                    bgcolor={"#FFEDE3"}
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <img src={imageSvg} alt="" />
                  </Box>
                  <Box>
                    <Typography
                      fontFamily={"MetropolisBold"}
                      fontSize={"15px"}
                      color={"#000000"}
                    >
                      Email :
                    </Typography>
                    <Typography
                      mt={"5px"}
                      fontFamily={"MetropolisSemibold"}
                      fontSize={"13px"}
                      color={"#000000"}
                    >
                      info@thrillh.com
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid md={7} item>
              <Box padding={"20px 30px"}>
                <Box mt={5}>
                  <Typography
                    fontFamily={"MetropolisBold"}
                    fontSize={"18px"}
                    color={"#000000"}
                  >
                    {" "}
                    Contact Form
                  </Typography>
                </Box>
              </Box>

              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container justifyContent={"center"} alignItems={"center"}>
                  <Grid item className={"mainContainerOfContactUS"}>
                    <Box>
                      <Controller
                        name="type"
                        control={control}
                        defaultValue=""
                        render={({
                          field: { ...field },
                          fieldState: { error },
                        }) => (
                          <CustomSelect
                            {...field}
                            labelText={"Issue Type"}
                            borderRadius={"25px"}
                            error={!!error}
                            helperText={error ? error.message : ""}
                            placeHolder={"Select Issue Type"}
                            options={Contact_Us_ISSUES}
                          />
                        )}
                      />
                    </Box>
                    <Box mt={2}>
                      <Controller
                        name="description"
                        control={control}
                        defaultValue={""}
                        render={({ field, fieldState: { error } }) => (
                          <CustomInput
                            {...field}
                            type={"text"}
                            label={"Description"}
                            placeHolder={"Enter Description"}
                            error={!!error}
                            helperText={error ? error.message : ""}
                            multiline={true}
                            borderRadius={"5px"}
                          />
                        )}
                      />
                    </Box>

                    <Box display={"flex"} justifyContent={"end"} mt={2}>
                      <Box width={130}>
                        <CustomButton
                          title={"Submit"}
                          borderButton={false}
                          type={"submit"}
                        />
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default VendorContactUs;
