import React, { useState, useEffect } from "react";
import {
  Grid,
  Box,
  FormGroup,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  FormControl,
  Radio,
  Typography,
} from "@mui/material";
import Colors from "../../../constants/Colors";
import PhotoOutlinedIcon from "@mui/icons-material/PhotoOutlined";
import CustomInput from "../../../components/Input";
import CustomButton from "../../../components/Button";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import loginbg from "../../../../images/bubbles.png";
import logo from "../../../../images/final logo.png";
import { ApiRequest } from "../../../../services/api-request";
import FileUploader from "../../../components/FileUploader/file-uploder";
import Otp from "../../Otp";
import "../../../screens/Details/index.css";
import { useSnackbar } from "../../../../context/useSnackbar";
import { debounce } from "lodash";
import { CustomAutoCompleteWithIcon } from "../../../components/AutoComplete/auto-complete";
import { getForbiddenDomains } from "../../../utilities/constants";
import { useAppContext } from "../../../../context/AppContext";
import Flat from "../../../../images/Flat Color (1).svg";
import loginSvg from "../../../../images/userAuthenication/login.png";
import studentRegistrationSvg from "../../../../images/studentRegistration.png";

const StudentDetailsForm = (props) => {
  let { userId } = useParams();

  const navigate = useNavigate();

  const { openSnackbar } = useSnackbar();

  const [open, setOpen] = React.useState(false);

  const [loading, setLoading] = useState(false);

  const [schoolList, setSchoolList] = React.useState([]);

  const [selectedOption, setSelectedOption] = React.useState("");

  const [userDetails, setUserDetails] = useState({
    data: {},
    loader: false,
  });

  const [uploadIdImages, setUploadIdImages] = useState({
    frontSideResponseFiles: "",
    backSideResponseFiles: "",
    deniedUserFrontImage: "",
    deniedUserBackSideImage: "",
  });

  const [selectedVerifyType, setSelectedVerifyType] = useState("id-card");

  const validateSchema = yup
    .object({
      name: yup.string().required("Name is required"),
      personalEmail: yup
        .string()
        .email("Please enter valid email address")
        .required("Personal Email Id is required")
        .matches(/\.com$/, "Please enter valid email address"),
      school: yup
        .object()
        .shape({
          title: yup.string().required("College/University is required."),
        })
        .required("College/University is required."),
      checkBox: yup.boolean().oneOf([true], "Please check on T&C to proceed,"),
      ...(selectedVerifyType === "email" && {
        email: yup
          .string()
          .email("Invalid email format")
          .test(
            "restricted-domains",
            "Please enter the valid official Email ID",
            (value) => {
              const forbiddenDomains = getForbiddenDomains();
              if (value) {
                const domain = value.split("@")[1];
                return !forbiddenDomains.includes(domain);
              }
              return true;
            }
          )
          .required("Official Email is required"),
      }),
    })
    .required();

  const {
    handleSubmit,
    watch,
    control,
    clearErrors,
    setValue,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(validateSchema),
  });

  React.useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === "verifiedWith") {
        setSelectedVerifyType(value?.verifiedWith);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    if (userId) {
      getUserDetails(userId);
    }
  }, [userId]);

  const getUserDetails = (userId) => {
    setUserDetails({ data: {}, loader: true });
    ApiRequest({
      url: `user/findBy/${userId}`,
      method: "GET",
    })
      .then((res) => {
        const mobile = res?.mobile;
        if (res?.status === "pending") {
          setUserDetails({ data: { mobile }, loader: false });
        } else {
          setUserDetails({ data: res, loader: false });
          setUploadIdImages((prev) => ({
            ...prev,
            deniedUserFrontImage: res?.frontSideImage,
            deniedUserBackSideImage: res?.backSideImage,
          }));
          const name = res?.name || "";
          const personalEmail = res?.personalEmail || "";
          const schoolTitle = res?.school || "";
          const schoolValue = res?.school || "";

          reset({
            name,
            personalEmail,
            school: {
              title: schoolTitle,
              value: schoolValue,
            },
          });
        }
      })
      .catch((err) => {
        setUserDetails({ data: {}, loader: false });
      });
  };

  const fetchCollegeList = async (searchQuery) => {
    setLoading(true);
    if (searchQuery?.length) {
      try {
        const response = await ApiRequest({
          url: `college/list`,
          data: { searchText: searchQuery, limit: 100, matchStart: true },
          method: "POST",
        });
        const modifiedList = response?.map((name) => {
          const data = {
            title: name?.name,
            value: name?._id,
          };
          return data;
        });

        if (modifiedList?.length) {
          setSchoolList(modifiedList);
        } else {
          setSchoolList([]);
        }
      } catch (error) {
        console.log(error);
      }
    }

    setLoading(false);
  };

  const handleInputChange = (event, value) => {
    setValue("school", value); // Update the value of the 'school' field
    clearErrors("school"); //
    setSelectedOption(value);
  };

  const debouncedFetchOptions = debounce(fetchCollegeList, 600);

  const onSubmit = (data) => {
    props.setFormData(data);
    if (selectedVerifyType === "email") {
      const payload = {
        email_id: data.email,
        ctx: "REGISTRATION",
        user_details: {
          ...data,
          mobile: userDetails?.data?.mobile,
          verifiedWith: selectedVerifyType,
          school: data?.school?.title,
        },
        payload: {
          ...data,
          mobile: userDetails?.data?.mobile,
          verifiedWith: selectedVerifyType,
          school: data?.school?.title,
        },
      };
      delete payload?.user_details?.checkBox;
      delete payload?.payload?.checkBox;

      ApiRequest({
        url: `email/send-otp`,
        data: payload,
        method: "POST",
      })
        .then((res) => {
          props.setEncryptedMobileResponse(res);
          openSnackbar(
            res?.message === "OTP email sent successfully"
              ? "OTP has been sent to your email, please check your spam mails too."
              : res?.message === "User personal email already exists"
              ? "Official email already exists, Please try another email"
              : res?.message,
            res?.message === "User professional email already exists"
              ? "error"
              : "success"
          );
        })
        .catch((err) => {
          openSnackbar(err?.response?.data?.message, "error");
        });
    } else {
      if (
        !(
          userDetails.data?.frontSideImage ||
          userDetails.data?.backSideImage ||
          uploadIdImages?.frontSideResponseFiles?.url ||
          uploadIdImages?.backSideResponseFiles?.url
        )
      ) {
        openSnackbar(
          "Either the front side or back side image is required.",
          "error"
        );
        return;
      } else {
        const payload = {
          ...data,
          school: data?.school?.title,
          verifiedWith: selectedVerifyType,
          frontSideImage:
            uploadIdImages?.frontSideResponseFiles?.url ||
            userDetails.data?.frontSideImage,

          backSideImage:
            uploadIdImages?.backSideResponseFiles?.url ||
            userDetails.data?.backSideImage,
        };
        delete payload.checkBox;
        delete payload.email;
        ApiRequest({
          url: `user/${userId}/registration-details`,
          data: payload,
          method: "PUT",
        })
          .then((response) => {
            if (payload?.frontSideImage) {
              props.setOpenSuccessModal(true);
            }
          })
          .catch((err) => {
            openSnackbar(err?.response?.data?.message, "error");
          });
      }
    }
  };

  const handleChangeRadio = (data) => {
    if (data === "id-card") {
      setSelectedVerifyType("id-card");
    } else {
      setSelectedVerifyType("email");
    }
  };

  return (
    <>
      {props.openSuccessModal ? (
        <>
          <Box
            height={"80vh"}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Box
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"space-between"}
            >
              <Box display={"flex"} justifyContent={"center"}>
                <img src={Flat} alt="flat" width={"70px"}></img>
              </Box>
              <Typography
                sx={{
                  color: props.color ? props.color : '"#FA8842"',
                  fontSize: "20px",
                  fontWeight: "600",
                  display: "flex",
                  justifyContent: "center",
                  mt: "20px",
                  fontFamily: "MetropolisExtraBold",
                }}
              >
                Thank You
              </Typography>
              <Typography
                fontWeight={"bold"}
                fontFamily={"MetropolisBold"}
                mt={"20px"}
              >
                Congrats ! We will verify your ID card and will send you an
                email confirmation within 24 hours, on working days.
              </Typography>
              <Box display={"flex"} justifyContent={"center"} mt={2}>
                <Box>
                  <CustomButton
                    padding={"10px 30px"}
                    title={"Go to Home"}
                    handleClick={() => navigate("/")}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </>
      ) : (
        <Box>
          <h2 style={styles.heading}>Student Details</h2>
          <p style={styles.subHeading}>
            You can be assured that your details will never be shared with
            anyone else.
          </p>
          {userDetails.loader ? (
            <>
              <h6>loading</h6>
            </>
          ) : (
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box>
                <Box mt={4}>
                  <Box>
                    <Controller
                      name="name"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <CustomInput
                          label={"Name"}
                          type={"text"}
                          placeHolder={"Enter Name"}
                          {...field}
                          error={!!error}
                          helperText={error ? error.message : ""}
                        />
                      )}
                    />
                  </Box>
                </Box>
                <Box mt={3}>
                  <Controller
                    name="personalEmail"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <CustomInput
                        label={"Personal Email Id"}
                        type={"text"}
                        placeHolder={"Enter Personal Email Id"}
                        {...field}
                        error={!!error}
                        helperText={error ? error.message : ""}
                      />
                    )}
                  />
                </Box>

                <Box mt={3}>
                  <Controller
                    name="school"
                    control={control}
                    render={({ field, fieldState: { error } }) => {
                      return (
                        <CustomAutoCompleteWithIcon
                          {...field}
                          noOptionsText={"Search for College/University"}
                          error={!!error}
                          helperText={
                            (error && error?.title?.message) || error?.message
                              ? error?.title?.message || error?.message
                              : ""
                          }
                          padding={"9.4px"}
                          open={open}
                          label={"College/University"}
                          placeholder={"Enter Search College/University"}
                          borderColor={"#000000"}
                          width={"100%"}
                          borderRadius={"25px"}
                          handleChange={handleInputChange}
                          onInputChange={(event, value) => {
                            debouncedFetchOptions(value);
                          }}
                          sx={{ width: "100%" }}
                          options={schoolList}
                          onOpen={() => {
                            setOpen(true);
                          }}
                          onClose={() => {
                            setOpen(false);
                          }}
                          loading={loading}
                        />
                      );
                    }}
                  />
                </Box>

                <Box mt={2}>
                  <Box>
                    <FormControl sx={{ width: "100%" }} component={"fieldset"}>
                      <Controller
                        rules={{ required: true }}
                        control={control}
                        name="verifiedWith"
                        render={({ field }) => (
                          <RadioGroup
                            {...field}
                            sx={{
                              justifyContent: "space-between",
                              display: "flex",
                              padding: "0px",
                            }}
                            row
                          >
                            <Box
                              display={"flex"}
                              justifyContent={"center"}
                              alignItems={"center"}
                            >
                              <FormControlLabel
                                sx={{ margin: "0px" }}
                                value={
                                  "id-card" || userDetails.data?.verifiedWith
                                }
                                checked={selectedVerifyType === "id-card"}
                                control={
                                  <Radio
                                    onChange={() =>
                                      handleChangeRadio("id-card")
                                    }
                                  />
                                }
                              />
                              <Box mt={"3px"}>
                                <Typography
                                  fontWeight={"600"}
                                  color={"#000000"}
                                  fontSize={"12px"}
                                  fontFamily={"MetropolisRegular"}
                                >
                                  Upload ID
                                </Typography>
                              </Box>
                            </Box>
                            <Box
                              display={"flex"}
                              justifyContent={"center"}
                              alignItems={"center"}
                            >
                              <FormControlLabel
                                sx={{ margin: "0px" }}
                                checked={selectedVerifyType === "email"}
                                value={
                                  "email" || userDetails.data?.verifiedWith
                                }
                                control={
                                  <Radio
                                    onChange={() => handleChangeRadio("email")}
                                  />
                                }
                              />
                              <Box mt={"3px"}>
                                <Typography
                                  fontWeight={"600"}
                                  color={"#000000"}
                                  fontSize={"12px"}
                                  fontFamily={"MetropolisRegular"}
                                >
                                  School/University Email
                                </Typography>
                              </Box>
                            </Box>
                          </RadioGroup>
                        )}
                      />
                      {errors.selectUser && (
                        <Typography fontSize={"12px"} color={"red"}>
                          {errors.selectUser.message}
                        </Typography>
                      )}
                    </FormControl>
                  </Box>
                </Box>

                {selectedVerifyType === "id-card" && (
                  <>
                    <Box mt={2}>
                      <p style={styles.photoText}>Upload Student Id card</p>
                    </Box>
                    <Box
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      gap={"20px"}
                    >
                      <Box mt={2}>
                        <Box
                          border="1px dashed #F47821"
                          borderRadius={5}
                          p={2}
                          height={"200px"}
                        >
                          <FileUploader
                            key={"frontSide"}
                            uploadIdImages={uploadIdImages}
                            setUploadedFiles={setUploadIdImages}
                            accept={"image/png,image/jpeg,image/jpg"}
                            displayType={"registrationIDUpload"}
                            icon={
                              `${
                                userDetails.data?.frontSideImage ||
                                uploadIdImages?.frontSideResponseFiles?.url
                              }` ? (
                                ""
                              ) : (
                                <PhotoOutlinedIcon fontSize={"11"} />
                              )
                            }
                            heading={`${
                              userDetails.data?.frontSideImage ||
                              uploadIdImages?.frontSideResponseFiles?.url
                                ? ""
                                : "Upload Front Side Image"
                            }`}
                            image={
                              uploadIdImages?.deniedUserFrontImage ||
                              (uploadIdImages?.frontSideResponseFiles?.url
                                ? uploadIdImages?.frontSideResponseFiles?.url
                                : null)
                            }
                            uploadType={"frontSide"}
                          />
                        </Box>
                        <Typography
                          fontFamily={"MetropolisRegular"}
                          fontSize={"14px"}
                          mt={"5px"}
                          textAlign={"center"}
                          color={"#000000"}
                        >
                          Front Side Image
                        </Typography>
                      </Box>
                      <Box mt={2}>
                        <Box
                          border="1px dashed #F47821"
                          borderRadius={5}
                          p={2}
                          height={"200px"}
                        >
                          <FileUploader
                            key={"backSide"}
                            uploadIdImages={uploadIdImages}
                            setUploadedFiles={setUploadIdImages}
                            accept={"image/png,image/jpeg,image/jpg"}
                            displayType={"registrationIDUpload"}
                            icon={
                              `${
                                userDetails.data?.backSideImage ||
                                uploadIdImages?.backSideResponseFiles?.url
                              }` ? (
                                ""
                              ) : (
                                <PhotoOutlinedIcon fontSize={"11"} />
                              )
                            }
                            heading={`${
                              uploadIdImages?.deniedUserBackSideImage ||
                              uploadIdImages?.backSideResponseFiles?.url
                                ? ""
                                : "Upload Back Side Image"
                            }`}
                            image={
                              uploadIdImages?.deniedUserBackSideImage ||
                              (uploadIdImages?.backSideResponseFiles?.url
                                ? uploadIdImages?.backSideResponseFiles?.url
                                : null)
                            }
                            uploadType={"backSide"}
                          />
                        </Box>
                        <Typography
                          fontFamily={"MetropolisRegular"}
                          fontSize={"14px"}
                          mt={"5px"}
                          textAlign={"center"}
                          color={"#000000"}
                        >
                          Back Side Image
                        </Typography>
                      </Box>
                    </Box>
                  </>
                )}

                {selectedVerifyType === "email" && (
                  <>
                    <Box mt={4}>
                      <Controller
                        name="email"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <CustomInput
                            label={"Official Institution Email Id"}
                            type={"text"}
                            placeHolder={"yourname@institution.com"}
                            {...field}
                            error={!!error}
                            helperText={error ? error.message : ""}
                          />
                        )}
                      />
                    </Box>
                  </>
                )}

                <Box mt={2}>
                  <Grid container alignItems={"center"}>
                    <Grid item>
                      <FormGroup>
                        <Controller
                          rules={{ required: true }}
                          control={control}
                          name="checkBox"
                          defaultValue={false}
                          render={({ field }) => (
                            <FormControlLabel
                              {...field}
                              control={
                                <Checkbox
                                  value={"checkBox"}
                                  defaultValue={false}
                                  sx={{
                                    "& .MuiSvgIcon-root": { fontSize: 20 },
                                  }}
                                />
                              }
                              label={
                                <p style={styles.checkboxText}>
                                  I accept the Thriilh{" "}
                                  <span
                                    style={{ color: "#FA8842" }}
                                    onClick={() =>
                                      navigate("/terms-of-services")
                                    }
                                  >
                                    Terms of Service
                                  </span>
                                  ,{" "}
                                  <span
                                    style={{ color: "#FA8842" }}
                                    onClick={() => navigate("/privacy-policy")}
                                  >
                                    Privacy Policy
                                  </span>
                                </p>
                              }
                              labelPlacement="end"
                            />
                          )}
                        />
                        {errors.checkBox && (
                          <Typography fontSize={"12px"} color={"red"}>
                            {errors.checkBox.message}
                          </Typography>
                        )}
                      </FormGroup>
                    </Grid>
                  </Grid>
                </Box>
                <Box mt={2}>
                  <CustomButton
                    title={"Register"}
                    borderButton={false}
                    type={"submit"}
                  />
                </Box>
              </Box>
            </form>
          )}
        </Box>
      )}
    </>
  );
};

const StudentDetails = () => {
  const [encryptedMobileResponse, setEncryptedMobileResponse] = useState({});

  const [openSuccessModal, setOpenSuccessModal] = useState(false);

  const [formData, setFormData] = useState({});

  const { openSnackbar } = useSnackbar();

  const { mobile } = useAppContext();

  const requestOtp = () => {
    const payload = {
      email_id: formData.email,
      ctx: "REGISTRATION",
      user_details: {
        ...formData,
        mobile: mobile?.payload?.mobile,
        school: formData?.school?.title,
      },
      payload: {
        ...formData,
        mobile: mobile?.payload?.mobile,
        school: formData?.school?.title,
      },
    };
    delete payload?.user_details?.checkBox;
    delete payload?.payload?.checkBox;

    ApiRequest({
      url: `email/send-otp`,
      data: payload,
      method: "POST",
    })
      .then((res) => {
        openSnackbar(
          res?.message === "OTP email sent successfully"
            ? "OTP has been sent to your email, please check your spam mails too."
            : res?.message === "User personal email already exists"
            ? "Official email already exists, Please try another email"
            : res?.message,
          res?.message === "User professional email already exists"
            ? "error"
            : "success"
        );
      })
      .catch((err) => {
        openSnackbar(err?.response?.data?.message, "error");
      });
  };

  return (
    <>
      {encryptedMobileResponse?.success === true ? (
        <Otp
          verifyTypeViaEmail={"studentEmail"}
          encryptedMobileResponse={encryptedMobileResponse}
          requestOTP={requestOtp}
        />
      ) : (
        <Box height={"100vh"} bgcolor={Colors.white}>
          <Grid container>
            <Grid item md={6}>
              <Box m={5}>
                <Box className={"thrillhLogo"}>
                  <img src={logo} alt={"Logo Image"} width={179} />
                </Box>
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Box width={500} className={"loginBox"}>
                    <StudentDetailsForm
                      setEncryptedMobileResponse={setEncryptedMobileResponse}
                      setOpenSuccessModal={setOpenSuccessModal}
                      openSuccessModal={openSuccessModal}
                      setFormData={setFormData}
                    />
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item md={6} className="detailsRightSec">
              <Box position={"fixed"} width={"100%"}>
                <Box
                  position={"relative"}
                  borderRadius={"20px 0px 0px 20px"}
                  style={{
                    width: "50%",
                    height: "100vh",
                    backgroundColor: "#c5c5c5",
                  }}
                >
                  <Box
                    style={{
                      backgroundImage: `url(${loginbg})`,
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      width: "100%",
                      height: "100vh",
                      backgroundPosition: "center",
                    }}
                    position={"absolute"}
                    top={"0"}
                    zIndex={"1"}
                  ></Box>
                  <Box position={"absolute"} zIndex={"0"} top={"0"} right={"0"}>
                    <img
                      src={require("../../../../images/topShape.png")}
                      alt={"Auth Bg"}
                      width={"300px"}
                    />
                  </Box>
                  <Box
                    position={"absolute"}
                    zIndex={"0"}
                    bottom={"0"}
                    left={"0"}
                  >
                    <img
                      src={require("../../../../images/bottomShape.png")}
                      alt={"Auth Bg"}
                      width={"300px"}
                    />
                  </Box>
                  <Box
                    position={"absolute"}
                    top={"0"}
                    width={"100%"}
                    height={"100%"}
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <img
                      src={openSuccessModal ? loginSvg : studentRegistrationSvg}
                      alt={"Auth Bg"}
                      width={"640px"}
                    />
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};

const styles = {
  authBg: {
    height: "100vh",
  },
  innerAuthBg: {
    width: 800,
    height: 800,
  },
  heading: {
    fontFamily: "MetropolisExtraBold",
    color: Colors.textColor,
  },
  subHeading: {
    fontFamily: "MetropolisLight",
    color: Colors.textColor,
    fontSize: 13,
    fontWeight: "600",
  },
  googleText: {
    fontFamily: "MetropolisRegular",
    color: Colors.textColor,
    fontSize: 13,
  },
  checkboxText: {
    fontFamily: "MetropolisRegular",
    color: Colors.textColor,
    fontSize: 13,
  },
  skipText: {
    fontFamily: "MetropolisSemiBold",
    color: Colors.lightBlue,
    fontSize: 13,
    textAlign: "center",
  },
  radioText: {
    fontFamily: "MetropolisSemiBold",
    fontSize: 13,
    color: Colors.textColor,
  },
  photoText: {
    fontFamily: "MetropolisSemiBold",
    fontSize: 13,
    color: Colors.textColor,
  },
  photoSubDesc: {
    fontFamily: "MetropolisRegular",
    fontSize: 12,
    color: Colors.lightBlue,
  },
};

export default StudentDetails;
