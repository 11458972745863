import React from "react";
import { Helmet } from "react-helmet";
const MetaTags = (props) => {
  const { title, description, metaTitle } = props;
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        {/* <link rel="icon" href="../../../../public/512.png" /> */}
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        {/* <link rel="apple-touch-icon" href="../../../../public/512.png" /> */}
        <link rel="manifest" href="%PUBLIC_URL%/manifest.json" />
        <title>{title}</title>
        <meta name="title" content={metaTitle} />
        <meta name="description" content={description} />
      </Helmet>
    </>
  );
};

export default MetaTags;
