import React, { useState, useEffect } from "react";
import { Box, Grid, Typography } from "@mui/material";
import CustomButton from "../../../components/Button";
import userSvg from "../../../../images/admin/user (8).svg";
import { useNavigate } from "react-router-dom";
import CustomDialog from "../../../components/Dialog/custom-dialog";
import { useSnackbar } from "../../../../context/useSnackbar";
import { capitalizeFirstLetter } from "../../../utilities/date-utility";
import { useAppContext } from "../../../../context/AppContext";
import styles from "./settings.module.css";
import AddEditBankDetails from "./add-edit-bankdetials";
import { ApiRequest } from "../../../../services/api-request";

const VendorSettings = () => {
  const { user } = useAppContext();

  const { openSnackbar } = useSnackbar();

  const [userDetails, setUserDetails] = useState({
    data: {},
    openType: "",
  });

  const [openBankModal, setOpenBankModal] = useState({
    isOpen: false,
    data: {},
  });

  const { handleLogoutSuccess } = useAppContext();

  const navigate = useNavigate();

  useEffect(() => {
    if (user?.vendorId) {
      getVendorBankDetails();
    }
  }, [user?.vendorId]);

  const getVendorBankDetails = () => {
    ApiRequest({
      url: `vendor/details/${user?.vendorId}`,
      method: "GET",
    })
      .then((res) => {
        setUserDetails({ data: res });
      })
      .catch((err) => {
        openSnackbar(err?.response?.data?.message, "error");
        if (err?.statusCode === 403 || err?.statusCode === 401) {
          if (navigate) {
            navigate("/login"); // Navigate to "/login" route
            handleLogoutSuccess();
          }
        }
      });
  };

  return (
    <>
      <CustomDialog
        open={openBankModal?.isOpen}
        title={!userDetails.data ? "Add Bank Details" : "Edit Bank Details"}
        width={"600px"}
        onClose={() => setOpenBankModal({ isOpen: false })}
      >
        <AddEditBankDetails
          onClose={() => setOpenBankModal({ isOpen: false })}
          userDetails={userDetails.data}
          getVendorBankDetails={() => getVendorBankDetails()}
        />
      </CustomDialog>

      <Box
        bgcolor={"#FFEDE3"}
        padding={"20px"}
        borderRadius={"40px 0px 0px 40px"}
      >
        <Box
          display={"flex"}
          width={"100%"}
          justifyContent={"space-between"}
          alignItems={"right"}
        >
          <Typography
            fontFamily={"MetropolisBold"}
            fontSize={"20px"}
            color={"#000000"}
          >
            Vendor Details
          </Typography>
        </Box>
        <Box mt={2} bgcolor={"#FA8842"} padding={"15px"} borderRadius={"8px"}>
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            gap={"10px"}
          >
            <Box>
              <img src={userSvg} alt="" width={"80px"} />
            </Box>
            <Box>
              <Typography
                color={"#000000"}
                fontFamily={"MetropolisBold"}
                fontSize={"22px"}
              >
                {userDetails?.name}
              </Typography>
              <Box>
                <Typography
                  color={"#000000"}
                  fontFamily={"MetropolisSemiBold"}
                  fontSize={"16px"}
                >
                  {userDetails?.role?.name}
                </Typography>
              </Box>
            </Box>
            <Box width={"86%"} bgcolor={"#fff"}>
              <Box
                mt={1}
                display={"flex"}
                padding={"10px 20px"}
                borderRadius={"10px"}
              >
                <Box width={"33.33%"}>
                  <Typography fontFamily={"MetropolisMedium"} fontSize={"14px"}>
                    <b>Vendor ID : </b>
                    {capitalizeFirstLetter(user?.vendorCode) ?? "1212"}
                  </Typography>
                </Box>
                <Box width={"33.33%"}>
                  <Typography fontFamily={"MetropolisMedium"} fontSize={"14px"}>
                    <b>Name : </b>
                    {user?.name}
                  </Typography>
                </Box>
              </Box>

              <Box
                mt={1}
                display={"flex"}
                bgcolor={"#FEE0CE"}
                padding={"10px 20px"}
              >
                <Box width={"33.33%"}>
                  <Typography fontFamily={"MetropolisMedium"} fontSize={"14px"}>
                    <b>Type: </b>
                    {user?.offerType}
                  </Typography>
                </Box>
                <Box width={"33.33%"}>
                  <Typography fontFamily={"MetropolisMedium"} fontSize={"14px"}>
                    <b>Mobile No. :</b>
                    {user?.mobile}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          mt={2}
          padding={"15px 10px"}
          bgcolor={"#ffff"}
          borderRadius={"8px"}
        >
          <Box display={"flex"} justifyContent={"space-between"}>
            <Typography
              fontSize={"18px"}
              color={"#000000"}
              fontFamily={"MetropolisSemiBold"}
            >
              <b>Bank Details</b>{" "}
              <span style={{ fontSize: "12px" }}>
                (*only for Offline Vendor's)
              </span>
            </Typography>
            <Box width={"8%"}>
              <CustomButton
                title={!userDetails.data?.bankName ? "Add" : "Edit"}
                borderRadius={"5px"}
                handleClick={() => setOpenBankModal({ isOpen: true, data: {} })}
              />
            </Box>
          </Box>
          <Box
            mt={2}
            padding={"20px 40px"}
            bgcolor={"#FFEDE3"}
            borderRadius={"10px"}
          >
            <Grid container>
              <Grid item md={4}>
                <Typography className={styles.heading}>
                  <b>Bank Name :</b> {userDetails?.data?.bankName}
                </Typography>
              </Grid>
              <Grid item md={4}>
                <Typography className={styles.heading}>
                  <b>Account Number :</b>
                  {userDetails?.data?.accountNumber}
                </Typography>
              </Grid>
              <Grid item md={4}>
                <Typography className={styles.heading}>
                  <b>IFSC Code:</b> {userDetails?.data?.ifscCode}
                </Typography>
              </Grid>
            </Grid>
            <Grid container mt={3}>
              <Grid item md={4}>
                <Typography className={styles.heading}>
                  <b>Branch :</b> {userDetails?.data?.branch}
                </Typography>
              </Grid>
              <Grid item md={4}>
                <Typography className={styles.heading}>
                  <b>Type :</b> {userDetails?.data?.accountType}
                </Typography>
              </Grid>
              <Grid item md={4}>
                <Typography className={styles.heading}>
                  <b>Account Holder name :</b>{" "}
                  {userDetails?.data?.accountHolderName}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default VendorSettings;
