import { Box, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import EditSvg from "../../../../images/admin/edit (4).svg";
import CustomDialog from "../../../components/Dialog/custom-dialog";
import { ApiRequest } from "../../../../services/api-request";
import AddEditOfferTax from "./add-edit-offerTax";
import { useAppContext } from "../../../../context/AppContext";
import { useNavigate } from "react-router-dom";

const OfferTaxList = (props) => {
  const { bgcolor } = props;

  const navigate = useNavigate();

  const { handleLogoutSuccess } = useAppContext();

  const [offerTaxModal, setOfferTaxModal] = useState(false);

  const [offerTaxList, setOfferTaxList] = useState([]);

  const [currentOfferTax, setCurrentOfferTax] = useState({});

  // eslint-disable-next-line
  useEffect(() => {
    getOfferTaxList();
  }, []);

  const getOfferTaxList = () => {
    ApiRequest({
      url: `order/taxes`,
      method: "GET",
    })
      .then((res) => {
        setOfferTaxList(res);
      })
      .catch((err) => {
        alert(err?.response?.data?.message);
        if (err?.statusCode === 403 || err?.statusCode === 401) {
          if (navigate) {
            navigate("/login"); // Navigate to "/login" route
            handleLogoutSuccess();
          }
        }
      });
  };
  return (
    <>
      <CustomDialog
        width={"400px"}
        open={offerTaxModal}
        title={"Edit Offer Tax"}
        onClose={() => setOfferTaxModal(false)}
      >
        <AddEditOfferTax
          onClose={() => setOfferTaxModal(false)}
          currentOfferTax={currentOfferTax}
          getOfferTaxList={getOfferTaxList}
        />
      </CustomDialog>
      <Box>
        <Box
          bgcolor={bgcolor ? bgcolor : "#FFEDE3"}
          padding={"20px"}
          borderRadius={"40px 0px 0px 40px"}
        >
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography
              fontFamily={"MetropolisBold"}
              fontSize={"20px"}
              color={"#000000"}
            >
              Offer Taxes
            </Typography>
          </Box>
          <Box bgcolor={"#fff"} padding={"10px"} borderRadius={"10px"} mt={2}>
            <Box
              mt={"12px"}
              padding={"5px 10px"}
              borderRadius={"5px"}
              bgcolor={"#FFEDE3"}
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Box width={"30%"}>
                <Typography color={"#000000"} fontFamily={"MetropolisSemiBold"}>
                  Tax Name
                </Typography>
              </Box>
              <Box width={"15%"}>
                <Typography color={"#000000"} fontFamily={"MetropolisSemiBold"}>
                  Percentage
                </Typography>
              </Box>
              <Box width={"15%"}>
                <Typography color={"#000000"} fontFamily={"MetropolisSemiBold"}>
                  Action
                </Typography>
              </Box>
            </Box>
            {offerTaxList.map((item, index) => {
              return (
                <Box
                  key={index}
                  padding={"5px 10px"}
                  borderRadius={"5px"}
                  bgcolor={"#FFFFFF"}
                  border={"1px solid #FEECE3"}
                  mt={"5px"}
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Box
                    width={"30%"}
                    display={"flex"}
                    gap={"10px"}
                    alignItems={"center"}
                  >
                    <Typography
                      color={"#000000"}
                      fontFamily={"MetropolisMedium"}
                    >
                      {item.name}
                    </Typography>
                  </Box>
                  <Box width={"15%"}>
                    <Typography
                      color={"#000000"}
                      fontFamily={"MetropolisMedium"}
                    >
                      {item.percentage}%
                    </Typography>
                  </Box>
                  <Box
                    sx={{ cursor: "pointer" }}
                    display={"flex"}
                    gap={"10px"}
                    alignItems={"center"}
                    width={"15%"}
                  >
                    <img
                      src={EditSvg}
                      alt="edit"
                      width={"20px"}
                      onClick={() => {
                        setOfferTaxModal(true);
                        setCurrentOfferTax({
                          ...item,
                          openType: "editTax",
                        });
                      }}
                    />
                  </Box>
                </Box>
              );
            })}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default OfferTaxList;
