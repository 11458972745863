import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  Typography,
  FormControlLabel,
  RadioGroup,
  FormControl,
  Radio,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useAppContext } from "../../../context/AppContext";
import { useSnackbar } from "../../../context/useSnackbar";
import { ApiRequest } from "../../../services/api-request";
import CustomAutoComplete from "../../components/AutoComplete/auto-complete";
import CustomButton from "../../components/Button";
import CustomDatePicker from "../../components/DatePicker";
import FileUploader from "../../components/FileUploader/file-uploder";
import CustomInput from "../../components/Input";
import CustomSelect from "../../components/Select/CustomSelect";
import {
  ConditionType,
  Days,
  Discount,
  SelectOfferType,
  SelectSubType,
  UserType,
} from "../../utilities/constants";
import {
  getDateMonthYear,
  getDateTimeArrayIn24Format,
} from "../../utilities/date-utility";

const AddEditOffer = (props) => {
  const navigate = useNavigate();

  const { handleLogoutSuccess } = useAppContext();

  const { isEdit, currentOffer } = props;

  const [offerFaqDetails, setOfferFaqDetails] = useState([
    { faqTitle: "", faqDescription: "" },
  ]);

  const [usagePointsDetails, setUsagePointsDetails] = useState([
    { usagePoints: "" },
  ]);

  const [conditionsDetails, setConditionsDetails] = useState([
    {
      conditionTitle: "",
      conditionDescription: "",
      conditionType: "",
      conditionValue: "",
      days: [],
    },
  ]);

  const [offerTypeDetails, setOfferTypeDetails] = useState("");

  const [categoryList, setCategoryList] = useState([]);

  const [subCategoryList, setSubCategoryList] = useState([]);

  const [category, setCategory] = useState('');


  const [allCities, setAllCities] = useState([]);

  const [localities, setLocalities] = useState([]);

  const [uploadLogo, setUploadLogo] = useState("");

  const [offerThumbnailImage, setOfferThumbnailImage] = useState("");

  const [uploadCoverImages, setUploadCoverImages] = useState({
    coverImagesResponseFiles: [],
  });

  const [uploadBrochureImage, setUploadBrochureImage] = useState("");

  const [watchSubType, setWatchSubType] = useState("");

  const [selectOnlineType, setSelectOnlineType] = useState("");

  const { openSnackbar } = useSnackbar();

  useEffect(() => {
    console.log(currentOffer, "PPPPPPPPPPPPPPPPPPPPPPPPPPPP")
    getAllCategories();
  }, []);

  useEffect(() => {
    if (currentOffer?.subType) {
      setWatchSubType(currentOffer?.subType);
    }
  }, [currentOffer?.subType]);
  useEffect(() => {
    if (currentOffer?.type) {
      setOfferTypeDetails(currentOffer?.type);
    }
  }, [currentOffer?.type]);

  useEffect(() => {
    if (currentOffer?._id && currentOffer?.coverImages) {
      setUploadCoverImages({
        ...uploadCoverImages,
        coverImagesResponseFiles: currentOffer?.coverImages?.map(
          (imageUrl) => ({
            originalname: imageUrl?.substring(imageUrl?.lastIndexOf("-") + 1),
            url: imageUrl,
          })
        ),
      });
    }
  }, [currentOffer?.coverImages]);

  const timeOptions = getDateTimeArrayIn24Format().map((time) => ({
    name: `${time}`,
    value: `${time}`,
  }));

  const validateSchema = yup
    .object({
      offerTitle: yup.string().required("OfferTitle  is required"),
      userType: yup.string().required("Select UserType  is required"),
      categoryType: yup.string().required("Select Category Type  is required"),
      subCategoryType: yup
        .array()
        .of(
          yup.object().shape({
            value: yup.string(),
            label: yup.string(),
          })
        )
        .min(1, "At least one Sub Category Type  is required"),
      // offerDescription: yup.string().required("Offer Description  is required"),
      ...(offerTypeDetails === "in-store" &&
        watchSubType === "payable" && {
        discount: yup.string().required("Discount is Required"),
        offerValue: yup.string().required("Offer Value is Required"),
      }),
      ...(offerTypeDetails === "in-store" && {
        subType: yup.string().required("Sub Type is Required"),
      }),
      offerType: yup.string().required("Offer Type is Required"),
      // customGst: yup.string().required("GST percentage is Required"),
      // offerFaqDetails: yup
      //   .array()
      //   .of(
      //     yup.object().shape({
      //       faqTitle: yup.string().required("Faq Title is required"),
      //       faqDescription: yup
      //         .string()
      //         .required("Faq Description is required"),
      //     })
      //   )
      //   .required(),
      // conditionsDetails: yup.array().of(
      //   yup.object().shape({
      //     conditionTitle: yup.string().when(["offerType", "conditionType"], {
      //       is: (offerType, conditionType) =>
      //         (offerType === "in-store" && conditionType === "text") ||
      //         (offerType === "in-store" && conditionType === "maximum-bill") ||
      //         (offerType === "in-store" && conditionType === "minimum-bill") ||
      //         (offerType === "in-store" && conditionType === "days"),
      //       then: () => yup.string().required("Condition Title is required"),
      //     }),
      //     conditionDescription: yup
      //       .string()
      //       .when(["offerType", "conditionType"], {
      //         is: (offerType, conditionType) =>
      //           (offerType === "in-store" && conditionType === "text") ||
      //           (offerType === "in-store" &&
      //             conditionType === "maximum-bill") ||
      //           (offerType === "in-store" &&
      //             conditionType === "minimum-bill") ||
      //           (offerType === "in-store" && conditionType === "days"),
      //         then: () =>
      //           yup.string().required("Condition Description is required"),
      //       }),

      //     days: yup.array().when("conditionType", {
      //       is: "days",
      //       then: () => yup.array().min(1, "At least one day is required"),
      //     }),

      //     conditionValue: yup.string().when(["conditionType"], {
      //       is: (conditionType) =>
      //         conditionType === "maximum-bill" ||
      //         conditionType === "minimum-bill",
      //       then: () => yup.string().required("Condition Value is required"),
      //     }),
      //   })
      // ),

      // usagePointsDetails: yup
      //   .array()
      //   .of(
      //     yup.object().shape({
      //       usagePoints: yup.string().required("Usage Points is required"),
      //     })
      //   )
      //   .required(),
      // partnerDescription: yup
      //   .string()
      //   .required("Partner Description  is required"),

      // ...(offerTypeDetails === "online" && {
      //   url: yup.string().required("URL Required"),
      // }),

      fromDate: yup.string().required("From Date is Required"),
      toDate: yup.string().required("To Date is Required"),
      fromTime: yup.string().required("From Time is Required"),
      ToTime: yup.string().required("To Time is Required"),
      // commission: yup.string().required("commission is Required"),
      vendorName: yup.string().required("Vendor Name is Required"),
      vendorMobile: yup.string().required("Vendor Mobile Number is Required"),
      metaTitle: yup.string().required("Meta Name is Required"),
      metaDescription: yup.string().required("Meta Description is Required"),

      // ...(watchSubType === "non-payable" && {
      //   offerCode: yup.string().required("OfferCode  is Required"),
      // }),
      ...(offerTypeDetails === "online" && {
        isCodeRequired: yup
          .string()
          .required("Either OfferCode or Others  is required"),
      }),
      ...(offerTypeDetails === "in-store" && {
        city: yup
          .array()
          .of(
            yup.object().shape({
              value: yup.string(),
              label: yup.string(),
            })
          )
          .min(1, "At least one City  is required"),
        locality: yup
          .array()
          .of(
            yup.object().shape({
              value: yup.string(),
              label: yup.string(),
            })
          )
          .min(1, "At least one Locality  is required"),
      }),
    })
    .required();

  const generateOfferDefaultValues = (isEdit, currentOffer) => {
    if (!isEdit && !currentOffer) {
      return {
        offerTitle: "",
        userType: "",
        categoryType: "",
        subCategoryType: [],
        offerType: "",
        subType: "",
        discount: "",
        offerValue: "",
        commission: "",
        offerCode: "",
        enableTopOffer: "",
        address: "",
        offerFaqDetails: [{ faqTitle: "", faqDescription: "" }],
        usagePointsDetails: [{ usagePoints: "" }],
        conditionsDetails: [
          {
            conditionTitle: "",
            conditionDescription: "",
            conditionType: "",
            conditionValue: "",
            days: [],
          },
        ],
        partnerDescription: "",
        url: "",
        fromDate: "",
        toDate: "",
        fromTime: "",
        ToTime: "",
        offerDescription: "",
        thrillCoupon: true,
        couponName: "",
        couponValue: "",
        logoImage: "",
        city: [],
        locality: [],
        vendorMobile: "",
        vendorName: "",
        metaTitle: "",
        metaDescription: "",
        // customGst: "",
      };
    }

    const { startsAt, endsAt } = currentOffer || {};

    const istDateTimeStartsAt = moment.utc(startsAt).tz("Asia/Kolkata");

    const istDateStart = moment(
      istDateTimeStartsAt,
      "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
    );

    const istDateTimeEndsAt = moment.utc(endsAt).tz("Asia/Kolkata");

    const istDateEndsAt = moment(
      istDateTimeEndsAt,
      "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
    );

    const istStartTime = istDateTimeStartsAt.format("HH:mm");
    const istEndsTime = istDateTimeEndsAt.format("HH:mm");

    return {
      offerTitle: currentOffer?.name || "",
      userType: currentOffer?.toShow,
      categoryType: currentOffer?.category?._id
        ? currentOffer?.category?._id
        : null,
      subCategoryType: (currentOffer?.subCategories || [])?.map((item) => ({
        value: item._id,
        name: item.name,
      })),
      offerFaqDetails: (currentOffer?.faqs || []).map((faq) => ({
        faqTitle: faq?.name,
        faqDescription: faq?.description,
      })),
      usagePointsDetails: (currentOffer?.usages || []).map((item) => ({
        usagePoints: item,
      })),
      conditionsDetails: (currentOffer?.discount?.conditions || []).map(
        (item) => ({
          conditionTitle: item?.name,
          conditionDescription: item?.description,
          conditionType: item?.type,
          conditionValue: item?.value && item?.value,
          days: (item?.days || []).map((day) => ({ name: day, value: day })),
        })
      ),
      partnerDescription: currentOffer?.partnerDescription,
      city: (currentOffer?.city || [])?.map((item) => ({
        value: item,
        name: item,
      })),
      locality: (currentOffer?.locality || [])?.map((item) => ({
        value: item,
        name: item,
      })),
      address: currentOffer?.address || "",
      url: currentOffer?.siteUrl,
      fromDate: currentOffer?.startsAt && currentOffer?.endsAt && istDateStart,
      toDate: currentOffer?.endsAt && currentOffer?.endsAt && istDateEndsAt,
      fromTime: currentOffer?.startsAt && istStartTime,
      ToTime: currentOffer?.endsAt && istEndsTime,
      offerDescription: currentOffer?.description,
      offerType: currentOffer?.type,
      subType: currentOffer?.subType,
      discount: currentOffer?.discount?.type,
      offerValue: currentOffer?.discount?.value,
      couponName: currentOffer?.coupon?.name,
      couponValue: currentOffer?.coupon?.value,
      commission: currentOffer?.discount?.commission,
      offerCode: currentOffer?.code,
      enableTopOffer: currentOffer?.enableTopOffer,
      thrillCoupon: Boolean(
        currentOffer?.coupon?.name && currentOffer?.coupon?.value
      ),
      vendorMobile: currentOffer?.mobile,
      vendorName: currentOffer?.vendorName,
      metaTitle: currentOffer?.metaTitle,
      metaDescription: currentOffer?.metaDescription,
      othersDescription: currentOffer?.otherDescription,
      // customGst: currentOffer?.customGst?.toString(),
      isCodeRequired:
        currentOffer?.isCodeRequired === false ? "others" : "offerCode",
    };
  };

  const {
    handleSubmit,
    watch,
    control,
    setValue,

    formState: { errors },
    getValues,
  } = useForm({
    resolver: yupResolver(validateSchema),
    defaultValues: generateOfferDefaultValues(isEdit, currentOffer),
  });

  React.useEffect(() => {
    if (currentOffer?.category?._id) {
      selectedSubcategoryList({ categoryType: currentOffer?.category?._id });
    }
  }, [currentOffer?.category?._id]);

  useEffect(() => {
    if (currentOffer?.isCodeRequired === false) {
      setSelectOnlineType("others");
    } else if (currentOffer?.isCodeRequired === true) {
      setSelectOnlineType("offerCode");
    }
  }, [currentOffer?.isCodeRequired]);

  React.useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === "categoryType" && value?.categoryType) {
        selectedSubcategoryList(value);
      } else {
      }
      if (name === "offerType") {
        setOfferTypeDetails(value?.offerType);
      } else if (name === `conditionsDetails`) {
        watch({ nest: true });
      }
      if (name === "subType") {
        setWatchSubType(value?.subType);
      }
    });
    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    if (currentOffer?.discount?.conditions?.length) {
      const modifiedConditions = (currentOffer?.discount?.conditions || []).map(
        (item) => ({
          conditionTitle: item?.name,
          conditionDescription: item?.description,
          conditionType: item?.type,
          conditionValue: item?.value,
          days: (item?.days || []).map((day) => ({ name: day, value: day })),
        })
      );
      setConditionsDetails(modifiedConditions);
    }
  }, [currentOffer?.discount?.conditions]);

  useEffect(() => {
    if (currentOffer?.usages?.length) {
      const modifiedUsages = currentOffer?.usages?.map((usage) => ({
        usagePoints: usage,
      }));
      setUsagePointsDetails(modifiedUsages);
    }
  }, [currentOffer?.usages]);

  useEffect(() => {
    if (currentOffer?.faqs?.length) {
      const modifiedFaqs = currentOffer?.faqs?.map((faq) => ({
        faqTitle: faq?.name,
        faqDescription: faq?.description,
      }));
      setOfferFaqDetails(modifiedFaqs);
    }
  }, [currentOffer?.faqs]);

  useEffect(() => {
    if (watch("categoryType")) {
      setSubCategoryList([]);
      setValue("subCategoryType", []);
    }
  }, [watch("categoryType")]);

  useEffect(() => {
    if (watch("categoryType") === currentOffer?.category?._id) {
      const subCategoryMapping = currentOffer?.subCategories?.map((item) => ({
        value: item?._id,
        name: item?.name,
      }));
      setValue("subCategoryType", subCategoryMapping);
      setSubCategoryList(subCategoryMapping);
    } else {
      setSubCategoryList([]);
      setValue("subCategoryType", []);
    }
  }, [currentOffer?.subCategories, watch("categoryType")]);

  useEffect(() => {
    const selectedCityValues = watch("city")?.map((item) => item?.value);
    const currentOfferCityValues = currentOffer?.city?.map((item) => item);

    if (
      selectedCityValues &&
      selectedCityValues.join(",") === currentOfferCityValues?.join(",")
    ) {
      const localityOptions =
        currentOffer?.locality?.map((item) => ({
          name: item,
          value: item,
        })) || [];
      setLocalities(localityOptions);
      setValue("locality", localityOptions);
    } else {
      setLocalities([]);
      setValue("locality", []);
    }
  }, [currentOffer?.locality, watch("city")]);

  const { remove } = useFieldArray({
    control,
    name: "offerFaqDetails",
  });

  const { remove: deleteUsagePoints } = useFieldArray({
    control,
    name: "usagePointsDetails",
  });

  const { remove: deleteConditionsDetails, fields } = useFieldArray({
    control,
    name: "conditionsDetails",
  });

  const addMultipleFaq = () => {
    setOfferFaqDetails([
      ...offerFaqDetails,
      { faqTitle: "", faqDescription: "" },
    ]);
  };

  const removeMultipleFaq = (index) => {
    const modifiedOfferFaqConditions = [...offerFaqDetails];
    modifiedOfferFaqConditions.splice(index, 1);
    setOfferFaqDetails(modifiedOfferFaqConditions);
    remove(index);
  };

  const addUsagePoints = () => {
    setUsagePointsDetails([...usagePointsDetails, { usagePoints: "" }]);
  };

  const removeUsagePoints = (index) => {
    const modifiedUsagePointsDetails = [...usagePointsDetails];
    modifiedUsagePointsDetails.splice(index, 1);
    setUsagePointsDetails(modifiedUsagePointsDetails);
    deleteUsagePoints(index);
  };

  const addMultipleConditions = () => {
    setConditionsDetails([
      ...conditionsDetails,
      {
        conditionTitle: "",
        conditionDescription: "",
        conditionValue: "",
        conditionsType: "",
        days: [],
      },
    ]);
  };

  const removeConditions = (index) => {
    const modifiedConditionsDetails = [...conditionsDetails];
    modifiedConditionsDetails.splice(index, 1);
    setConditionsDetails(modifiedConditionsDetails);
    deleteConditionsDetails(index);
  };

  const onHandleUploadedFile = (file) => {
    if (file) {
      setUploadLogo(file?.url);
    } else {
      setUploadLogo([]);
    }
  };
  const onHandleUploadedThumbnail = (file) => {
    if (file) {
      setOfferThumbnailImage(file?.url);
    } else {
      setOfferThumbnailImage([]);
    }
  };

  const getAllCategories = () => {
    ApiRequest({
      url: `category/all?skip=0&limit=100`,
      method: "GET",
    })
      .then((res) => {
        const modifiedCategoryList = res?.map((res) => {
          if (currentOffer?.category?._id == res?._id) {
            setCategory(res?.name);
          }
          const list = {
            name: res?.name,
            value: res?._id,
          };
          return list;
        });
        setCategoryList(modifiedCategoryList);
      })
      .catch((err) => {
        openSnackbar(err?.response?.data?.message);
      });
  };

  const selectedSubcategoryList = (value) => {
    ApiRequest({
      url: `sub-category/list?categoryId=${value?.categoryType}`,
      method: "GET",
    })
      .then((res) => {
        const modifiedSubCategoryList = res?.result
          ?.map((subCategory) => {
            const list = {
              name: subCategory?.name,
              value: subCategory?._id,
              status: subCategory?.status, // Assuming there is a "status" property in subCategory
            };
            return list;
          })
          .filter((subCategory) => subCategory.status === "active");
        setSubCategoryList(modifiedSubCategoryList);
      })
      .catch((err) => {
        openSnackbar(err?.response?.data?.message, "error");
      });
  };

  const onSubmit = (data) => {
    console.log(data);
    
    // data.preventDefault();

    // if(data && data.fromDate && data.toDate && data.fromTime && data.ToTime) {

    const startAt = new Date(data.fromDate);
    const endsAt = new Date(data.toDate);

    ///converting IST time to UTC time for start Time
    const istDateStartsAt = getDateMonthYear(startAt);
    const istStartTime = data.fromTime;
    const istStartDateTimeString = `${istDateStartsAt} ${istStartTime}`;
    const utcStartTimeString = moment
      .tz(istStartDateTimeString, "Asia/Kolkata")
      .utc()
      .format();

    //converting IST time to UTC for endsAT
    const istEndsAtDate = getDateMonthYear(endsAt);
    const istEndsTime = data.ToTime;
    const istEndsAtDateTimeString = `${istEndsAtDate} ${istEndsTime}`;

    const utcEndsATimeString = moment
      .tz(istEndsAtDateTimeString, "Asia/Kolkata")
      .utc()
      .format();

    const usages = data?.usagePointsDetails
      ? data?.usagePointsDetails
        .filter((item) => item.usagePoints)
        .map((us) => us.usagePoints)
      : [];

    const uniqueConditions = [];

    const faqs = data.offerFaqDetails
      ? data.offerFaqDetails
        .filter((faq) => faq.faqTitle && faq.faqDescription)
        .map((faq) => ({
          name: faq.faqTitle,
          description: faq.faqDescription,
        }))
      : [];

      console.log(data?.conditionsDetails)

    if (data && data?.conditionsDetails && data?.conditionsDetails?.length > 0) {
      data.conditionsDetails.forEach((condition) => {
        let conditionValue = condition.conditionValue;
        let days = condition.days || [];

        // If conditionType is "text" or "days", make conditionValue empty
        if (
          condition.conditionType === "text" ||
          condition.conditionType === "days"
        ) {
          conditionValue = "";
        }

        // If conditionType is "maximum-bill" or "minimum-bill", make days empty
        if (
          condition.conditionType === "maximum-bill" ||
          condition.conditionType === "minimum-bill"
        ) {
          days = [];
        }

        if (condition.conditionType === "text") {
          days = [];
          conditionValue = "";
        }

        if (condition.conditionType === "days") {
          // If conditionType is "days", format days array
          days = days.map((day) => day.value);
        }

        const conditionObject = {
          ...(condition?.conditionTitle && { name: condition.conditionTitle }),
          ...(condition?.conditionDescription && {
            description: condition.conditionDescription,
          }),
          ...(condition?.conditionType && { type: condition.conditionType }),
          ...(conditionValue && { value: conditionValue }), // Only include if conditionValue is not empty
          ...(days.length > 0 && { days }), // Only include if days is not empty
        };

        // // Check if this condition is unique before adding
        // const isConditionUnique = !uniqueConditions.some(
        //   (existingCondition) => {
        //     return (
        //       JSON.stringify(existingCondition) ===
        //       JSON.stringify(conditionObject)
        //     );
        //   }
        // );

        if (Object.keys(conditionObject).length > 0) {
          uniqueConditions.push(conditionObject);
        }
      });
    }

    const modifiedData = {
      name: data.offerTitle.trim(),
      vendorName: data.vendorName,
      mobile: data.vendorMobile,
      metaTitle: data.metaTitle,
      metaDescription: data.metaDescription,
      toShow: data.userType,
      category: data.categoryType,
      subCategories: data.subCategoryType?.map((value) => value.value),
      description: data.offerDescription,
      partnerDescription: data.partnerDescription,

      discount: {
        ...(data.discount && {
          type: data.discount,
          value: Number(data?.offerValue),
        }),
        ...(data.commission && {
          commission: Number(data?.commission),
        }),
        ...(uniqueConditions?.length && {
          conditions: uniqueConditions,
        }),
      },

      type: data.offerType,
      ...(data.offerType === "in-store" &&
        data.subType && {
        subType: data.subType,
      }),

      ...(selectOnlineType === "offerCode" && {
        code: data?.offerCode,
        isCodeRequired: true,
        ...(isEdit &&
          currentOffer && {
          otherDescription: "",
        }),
      }),
      ...(selectOnlineType === "others" && {
        otherDescription: data?.othersDescription,
        isCodeRequired: false,
        ...(isEdit &&
          currentOffer && {
          code: "",
        }),
      }),

      // customGst: Number(data.customGst),

      ...(usages.length > 0 && { usages }),

      ...(data?.url && {
        siteUrl: data.url,
      }),
      coupon:
        data?.thrillCoupon && data?.couponName?.length
          ? {
            name: data.couponName,
            value: Number(data.couponValue),
            code: data.couponName,
          }
          : null,

      startsAt: utcStartTimeString,
      endsAt: utcEndsATimeString,

      ...(faqs.length > 0 && { faqs }),

      logoImage: uploadLogo?.logoResponseFiles?.url
        ? uploadLogo?.logoResponseFiles?.url
        : currentOffer?.logoImage,

      thumbnailImage: offerThumbnailImage?.offerThumbNailResponseFiles?.url
        ? offerThumbnailImage?.offerThumbNailResponseFiles?.url
        : currentOffer?.thumbnailImage,

      coverImages:
        uploadCoverImages?.coverImagesResponseFiles?.length > 0
          ? uploadCoverImages.coverImagesResponseFiles
            .filter((cover) => cover?.url !== null && cover?.url !== "")
            .map((cover) => cover.url)
          : currentOffer?.coverImages
            ?.filter((cover) => cover !== null && cover !== "")
            .map((cover) => cover),

      ...(uploadBrochureImage?.brochureImageResponseFiles?.url && {
        brochureImage: uploadBrochureImage?.brochureImageResponseFiles?.url
          ? uploadBrochureImage?.brochureImageResponseFiles?.url
          : currentOffer?.brochureImage,
      }),
      address: data?.address,
      enableTopOffer: data.enableTopOffer,
      city: data?.city?.map((item) => item?.value),
      locality: data?.locality?.map((item) => item?.value),
    };

    const hasDaysWithError = uniqueConditions?.some((item) => {
      return (
        item?.type === "days" &&
        (!item?.days || item?.days.length === 0 || !item?.name)
      );
    });

    if (hasDaysWithError) {
      openSnackbar("Days and Title  is required", "error");
      return;
    }

    const maximumBillTitleAndDescription = uniqueConditions?.some((item) => {
      return item?.type === "maximum-bill" && !item?.name;
    });

    if (maximumBillTitleAndDescription) {
      openSnackbar("For maximum-bill, Name  is required", "error");
      return;
    }

    const textTitleAndDescription = uniqueConditions?.some((item) => {
      return item?.type === "text" && !item?.type;
    });

    if (textTitleAndDescription) {
      openSnackbar("For condition type Text,  Name is required", "error");
      return;
    }

    const minimumBillTitleAndDescriptionError = uniqueConditions?.some(
      (item) => {
        return item?.type === "minimum-bill" && !item?.name;
      }
    );
    if (minimumBillTitleAndDescriptionError) {
      openSnackbar("For minimum-bill,  name  is required", "error");
      return;
    }

    if (isEdit && currentOffer) {
      ApiRequest({
        url: `offer/${currentOffer?._id}`,
        method: "PUT",
        data: modifiedData,
      })
        .then((res) => {
          if (res) {
            openSnackbar("Offer has been updated successfully", "success");
            props.onClose();
            props.onGetAllOffers();
          }
        })
        .catch((err) => {
          openSnackbar(err?.response?.data?.message, "error");
          if (err?.statusCode === 403 || err?.statusCode === 401) {
            if (navigate) {
              navigate("/login"); // Navigate to "/login" route
              handleLogoutSuccess();
            }
          }
        });
    } else {
      ApiRequest({
        url: `offer`,
        method: "POST",
        data: modifiedData,
      })
        .then((res) => {
          if (res) {
            openSnackbar("Offer has been Created successfully", "success");
            props.onClose();
            props.onGetAllOffers();
          }
        })
        .catch((err) => {
          openSnackbar(err?.response?.data?.message, "error");
          if (err?.statusCode === 403 || err?.statusCode === 401) {
            if (navigate) {
              navigate("/login"); // Navigate to "/login" route
              handleLogoutSuccess();
            }
          }
        });
    }

  // }
  };

  const deleteCoverImages = (index) => {
    const updatedUploadCoverImages = {
      ...uploadCoverImages,
      coverImagesResponseFiles: [
        ...uploadCoverImages?.coverImagesResponseFiles,
      ],
    };
    updatedUploadCoverImages.coverImagesResponseFiles.splice(index, 1);
    setUploadCoverImages(updatedUploadCoverImages);
  };

  const fetchCities = (searchQuery) => {
    if (searchQuery && searchQuery.length > 0) {
      const payload = {
        skip: 0,
        limit: 20,
        searchText: searchQuery,
      };

      ApiRequest({
        url: `offer/cities`,
        method: "POST",
        data: payload,
      }).then((res) => {
        const modifiedData = res?.data?.map((item, index) => ({
          name: item?.city,
          value: item?._id,
        }));
        setAllCities(modifiedData);
      });
    } else {
      setAllCities([]);
    }
  };

  const handleSearchCities = (e) => {
    const searchQuery = e.target.value;
    fetchCities(searchQuery);
  };

  const fetchLocalities = async (searchQuery) => {
    const payload = {
      skip: 0,
      limit: 20,
      searchText: searchQuery,
      city: getValues("city")
        .map((item) => item?.value)
        .toString(),
    };
    if (searchQuery && searchQuery.length > 0) {
      try {
        const response = await ApiRequest({
          url: `offer/localities`,
          method: "POST",
          data: payload,
        });
        const modifiedList = response?.data?.map((locality) => {
          const modifiedData = {
            name: locality?.Locality?.map((item) => item).toString(),
            value: locality?.Locality?.map((item) => item).toString(),
            city: locality?.City?.map((item) => item).toString(),
          };
          return modifiedData;
        });
        setLocalities(modifiedList);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleSearchLocalities = (e) => {
    const searchQuery = e.target.value;
    fetchLocalities(searchQuery);
  };

  const logoImage = currentOffer?.logoImage;
  const imageName = logoImage?.substring(logoImage?.lastIndexOf("-") + 1);
  const offerThumbnail = currentOffer?.thumbnailImage?.substring(
    currentOffer?.thumbnailImage?.lastIndexOf("-") + 1
  );

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      e.preventDefault();
      e.returnValue = ""; // Required for Chrome
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const handleRadioChange = (type) => {
    if (type === "offerCode") {
      setSelectOnlineType("offerCode");
    } else if (type === "others") {
      setSelectOnlineType("others");
    }
  };
  

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <Box mt={3}>
              <Box>
                <Controller
                  name="offerTitle"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <CustomInput
                      {...field}
                      inputLabel={"OfferTitle"}
                      type={"text"}
                      borderRadius={"5px"}
                      error={!!error}
                      helperText={error ? error.message : ""}
                    />
                  )}
                />
              </Box>
              <Box
                display={"flex"}
                alignItems={"center"}
                gap={"10px"}
                width={"100%"}
              >
                <Box width={"50%"}>
                  <Controller
                    name="userType"
                    control={control}
                    render={({
                      field: { ...field },
                      fieldState: { error },
                    }) => (
                      <CustomSelect
                        {...field}
                        value={field.value || ""}
                        borderRadius={"5px"}
                        placeHolder={"Select User Type"}
                        error={!!error}
                        helperText={error ? error.message : ""}
                        options={UserType}
                      />
                    )}
                  />
                </Box>
                <Box width={"50%"}>
                  <Controller
                    name="categoryType"
                    control={control}
                    render={({
                      field: { ...field },
                      fieldState: { error },
                    }) => (
                      <CustomSelect
                        {...field}
                        borderRadius={"5px"}
                        placeHolder={"Select Category"}
                        error={!!error}
                        helperText={error ? error.message : ""}
                        options={categoryList}
                        onChange={(e) => {
                          console.log(e, "EEEEEEEEEEEEEEEEEEEE");
                          field.onChange(e);
                          // setValue("subCategoryType", []); // Clear the selected sub-categories
                        }}
                      />
                    )}
                  />
                </Box>
              </Box>

              <Box mt={"10px"}>
                <Controller
                  name="subCategoryType"
                  control={control}
                  render={({
                    field: { ref, onChange, value },
                    fieldState: { error },
                  }) => (
                    <CustomAutoComplete
                      value={value}
                      noOptionsText={
                        "Select Category to display Sub-Categories"
                      }
                      error={!!error}
                      helperText={error ? error.message : ""}
                      ref={ref}
                      multiple
                      onChange={(event, value) => onChange(value)}
                      options={subCategoryList}
                      label="Enter SubCategory Type"
                    />
                  )}
                />
              </Box>

              <Box>
                <Controller
                  name="offerDescription"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <CustomInput
                      inputLabel={"Enter Offer Description"}
                      type={"text"}
                      multiline={true}
                      borderRadius={"5px"}
                      {...field}
                      error={!!error}
                      helperText={error ? error.message : ""}
                      minRows={5}
                      maxRows={10}
                    />
                  )}
                />
              </Box>
              <Box mt={"10px"}>
                <Typography
                  component={"span"}
                  color={"#000000"}
                  fontSize={"13px"}
                  fontFamily={"MetropolisSemiBold"}
                >
                  Offer Value Details
                </Typography>
              </Box>
              <Box
                display={"flex"}
                alignItems={"baseline"}
                gap={"10px"}
                width={"100%"}
              >
                <Box width={"50%"}>
                  <Controller
                    name="offerType"
                    control={control}
                    defaultValue=""
                    render={({
                      field: { ...field },
                      fieldState: { error },
                    }) => (
                      <CustomSelect
                        {...field}
                        borderRadius={"5px"}
                        error={!!error}
                        helperText={error ? error.message : ""}
                        placeHolder={"Select Offer Type"}
                        options={SelectOfferType}
                        disabled={isEdit ? true : false}
                      />
                    )}
                  />
                </Box>
                {getValues(`offerType`) === "in-store" ? (
                  <Box width={"50%"}>
                    <Controller
                      name="subType"
                      control={control}
                      defaultValue=""
                      render={({
                        field: { ...field },
                        fieldState: { error },
                      }) => (
                        <CustomSelect
                          {...field}
                          borderRadius={"5px"}
                          error={!!error}
                          helperText={error ? error.message : ""}
                          placeHolder={"Select Sub Type"}
                          options={SelectSubType}
                        />
                      )}
                    />
                  </Box>
                ) : null}
                {getValues(`offerType`) === "online" ? (
                  <Box
                    display={"flex"}
                    justifyContent={"space-evenly"}
                    width={"50%"}
                  >
                    <FormControl sx={{ width: "100%" }} component={"fieldset"}>
                      <Controller
                        // rules={{ required: true }}
                        control={control}
                        name="isCodeRequired"
                        defaultValue={""}
                        render={({ field }) => (
                          <RadioGroup
                            {...field}
                            sx={{
                              justifyContent: "space-between",
                              display: "flex",
                              padding: "0px",
                            }}
                            row
                          >
                            <Box
                              display={"flex"}
                              justifyContent={"center"}
                              alignItems={"center"}
                            >
                              <FormControlLabel
                                sx={{ margin: "0px" }}
                                value="offerCode"
                                control={
                                  <Radio
                                    onChange={() =>
                                      handleRadioChange("offerCode")
                                    }
                                  />
                                }
                              />
                              <Box mt={"3px"}>
                                <Typography
                                  fontWeight={"600"}
                                  color={"#000000"}
                                  fontSize={"12px"}
                                  fontFamily={"MetropolisRegular"}
                                >
                                  Offer Code
                                </Typography>
                              </Box>
                            </Box>
                            <Box
                              display={"flex"}
                              justifyContent={"center"}
                              alignItems={"center"}
                            >
                              <FormControlLabel
                                sx={{ margin: "0px" }}
                                value="others"
                                control={
                                  <Radio
                                    onChange={() => handleRadioChange("others")}
                                  />
                                }
                              />
                              <Box mt={"3px"}>
                                <Typography
                                  fontWeight={"600"}
                                  color={"#000000"}
                                  fontSize={"12px"}
                                  fontFamily={"MetropolisRegular"}
                                >
                                  Others
                                </Typography>
                              </Box>
                            </Box>
                          </RadioGroup>
                        )}
                      />
                      {errors.isCodeRequired && (
                        <Typography fontSize={"12px"} color={"red"}>
                          {errors.isCodeRequired.message}
                        </Typography>
                      )}
                    </FormControl>
                  </Box>
                ) : null}
              </Box>
              <Box
                display={"flex"}
                alignItems={"center"}
                gap={"10px"}
                width={"100%"}
              >
                <Box width={"50%"}>
                  <Controller
                    name="discount"
                    control={control}
                    render={({
                      field: { ...field },
                      fieldState: { error },
                    }) => (
                      <CustomSelect
                        {...field}
                        borderRadius={"5px"}
                        error={!!error}
                        helperText={error ? error.message : ""}
                        placeHolder={"In Discount (%)"}
                        options={Discount}
                      />
                    )}
                  />
                </Box>
                <Box width={"50%"}>
                  <Controller
                    name="offerValue"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <CustomInput
                        inputLabel={"Enter offer Value"}
                        type={"number"}
                        borderRadius={"5px"}
                        {...field}
                        error={!!error}
                        helperText={error ? error.message : ""}
                      />
                    )}
                  />
                </Box>
              </Box>
              {selectOnlineType === "offerCode" && (
                <Box>
                  <Controller
                    name="offerCode"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <CustomInput
                        inputLabel={"Enter Offer Code"}
                        type={"text"}
                        borderRadius={"5px"}
                        {...field}
                        error={!!error}
                        helperText={error ? error.message : ""}
                      />
                    )}
                  />
                </Box>
              )}
              {selectOnlineType === "others" && (
                <Box>
                  <Controller
                    name="othersDescription"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <CustomInput
                        inputLabel={"Enter Others Description"}
                        type={"text"}
                        borderRadius={"5px"}
                        {...field}
                        error={!!error}
                        helperText={error ? error.message : ""}
                      />
                    )}
                  />
                </Box>
              )}

              <>
                <Box mt={"10px"}>
                  <Typography
                    component={"span"}
                    color={"#000000"}
                    fontSize={"13px"}
                    fontFamily={"MetropolisSemiBold"}
                  >
                    Conditions
                  </Typography>
                </Box>
                {conditionsDetails.map((item, index) => {
                  return (
                    <Box>
                      {getValues(`offerType`) === "in-store" && (
                        <>
                          <Box display={"flex"} gap={"10px"}>
                            <Box width={"50%"} mt={"0px"}>
                              <Controller
                                name={`conditionsDetails.${index}.conditionType`}
                                control={control}
                                render={({
                                  field: { ...field },
                                  fieldState: { error },
                                }) => (
                                  <CustomSelect
                                    {...field}
                                    borderRadius={"5px"}
                                    error={!!error}
                                    helperText={error ? error.message : ""}
                                    placeHolder={"Select Conditions Type"}
                                    options={ConditionType}
                                  />
                                )}
                              />
                            </Box>
                            {getValues(
                              `conditionsDetails[${index}].conditionType`
                            ) === "days" && (
                                <Box width={"50%"}>
                                  <Box mt={"14px"}>
                                    <Controller
                                      name={`conditionsDetails.[${index}].days`}
                                      control={control}
                                      render={({
                                        field: { ref, onChange, value },
                                        fieldState: { error },
                                      }) => (
                                        <CustomAutoComplete
                                          value={value}
                                          error={!!error}
                                          helperText={error ? error.message : ""}
                                          ref={ref}
                                          multiple
                                          onChange={(event, value) =>
                                            onChange(value)
                                          }
                                          options={Days}
                                          label="Enter Days"
                                        />
                                      )}
                                    />
                                  </Box>
                                </Box>
                              )}

                            {(getValues(
                              `conditionsDetails[${index}].conditionType`
                            ) === "minimum-bill" ||
                              getValues(
                                `conditionsDetails[${index}].conditionType`
                              ) === "maximum-bill") && (
                                <Box width={"50%"}>
                                  <Controller
                                    name={`conditionsDetails.${index}.conditionValue`}
                                    control={control}
                                    render={({
                                      field: { ...field },
                                      fieldState: { error },
                                    }) => (
                                      <CustomInput
                                        {...field}
                                        value={field.value || ""}
                                        inputLabel={"Condition Value"}
                                        type={"number"}
                                        defaultValue={item.conditionValue}
                                        error={!!error}
                                        helperText={error ? error.message : ""}
                                        borderRadius={"5px"}
                                      />
                                    )}
                                  />
                                </Box>
                              )}
                          </Box>
                        </>
                      )}
                      <Box
                        width={"100%"}
                        display={"flex"}
                        gap={"10px"}
                        alignItems={"top"}
                        key={index}
                      >
                        <Box width={"100%"}>
                          <Controller
                            render={({ field, fieldState: { error } }) => (
                              <CustomInput
                                {...field}
                                inputLabel={"Enter Condition Title"}
                                type={"text"}
                                error={!!error}
                                helperText={error ? error.message : ""}
                                borderRadius={"5px"}
                              />
                            )}
                            name={`conditionsDetails.${index}.conditionTitle`}
                            control={control}
                          />
                          <Controller
                            render={({ field, fieldState: { error } }) => (
                              <CustomInput
                                {...field}
                                inputLabel={"Enter Condition Description"}
                                type={"text"}
                                error={!!error}
                                helperText={error ? error.message : ""}
                                borderRadius={"5px"}
                              />
                            )}
                            name={`conditionsDetails.${index}.conditionDescription`}
                            control={control}
                          />
                        </Box>
                        {index < 1 ? (
                          <Button
                            sx={{
                              border: "1px solid #FB8842",
                              marginTop: "14px",
                              fontSize: "12px",
                              color: "#FA8842",
                            }}
                            onClick={() => addMultipleConditions()}
                          >
                            ADD
                          </Button>
                        ) : null}
                        {index > 0 && (
                          <Button
                            onClick={() => removeConditions(index)}
                            sx={{
                              border: "1px solid #FB8842",
                              marginTop: "14px",
                              fontSize: "12px",
                              color: "#FA8842",
                            }}
                          >
                            Delete
                          </Button>
                        )}
                      </Box>
                    </Box>
                  );
                })}
              </>

              <Box mt={"10px"}>
                <Typography
                  component={"span"}
                  color={"#000000"}
                  fontSize={"13px"}
                  fontFamily={"MetropolisSemiBold"}
                >
                  Offer Commission Details
                </Typography>

                <Box>
                  <Controller
                    name="commission"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <CustomInput
                        inputLabel={"Enter Commission"}
                        type={"number"}
                        borderRadius={"5px"}
                        {...field}
                        error={!!error}
                        helperText={error ? error.message : ""}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                    )}
                  />
                </Box>
              </Box>
              <Box mt={"10px"}>
                <Typography
                  component={"span"}
                  color={"#000000"}
                  fontSize={"13px"}
                  fontFamily={"MetropolisSemiBold"}
                >
                  Offers Faq
                </Typography>
              </Box>
              {offerFaqDetails.map((item, index) => (
                <Box
                  display={"flex"}
                  gap={"10px"}
                  alignItems={"top"}
                  key={index}
                >
                  <Box width={"100%"}>
                    <Controller
                      render={({ field, fieldState: { error } }) => (
                        <CustomInput
                          {...field}
                          inputLabel={"Enter Faq Question"}
                          type={"text"}
                          defaultValue={item.faqTitle}
                          error={!!error}
                          helperText={error ? error.message : ""}
                          borderRadius={"5px"}
                        />
                      )}
                      name={`offerFaqDetails.${index}.faqTitle`}
                      control={control}
                    />
                    <Controller
                      render={({ field, fieldState: { error } }) => (
                        <CustomInput
                          {...field}
                          inputLabel={"Enter Faq Answer"}
                          type={"text"}
                          defaultValue={item.faqDescription}
                          error={!!error}
                          helperText={error ? error.message : ""}
                          borderRadius={"5px"}
                        />
                      )}
                      name={`offerFaqDetails.${index}.faqDescription`}
                      control={control}
                    />
                  </Box>
                  {index < 1 ? (
                    <Button
                      sx={{
                        border: "1px solid #FB8842",
                        marginTop: "14px",
                        fontSize: "12px",
                        color: "#FA8842",
                      }}
                      onClick={() => addMultipleFaq()}
                    >
                      ADD
                    </Button>
                  ) : null}
                  {index > 0 && (
                    <Button
                      onClick={() => removeMultipleFaq(index)}
                      sx={{
                        border: "1px solid #FB8842",
                        marginTop: "14px",
                        fontSize: "12px",
                        color: "#FA8842",
                      }}
                    >
                      Delete
                    </Button>
                  )}
                </Box>
              ))}
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box mt={"5px"}>
              <Controller
                // rules={{ required: true }}
                control={control}
                name="enableTopOffer"
                render={({ field }) => (
                  <FormControlLabel
                    {...field}
                    control={
                      <Checkbox
                        {...field}
                        checked={field.value}
                        onChange={(e) => field.onChange(e.target.checked)}
                        sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                      />
                    }
                    label={<p>Enable Top Offer</p>}
                    labelPlacement="end"
                  />
                )}
              />
            </Box>

            <Grid container spacing={4}>
              <Grid item xs={4}>
                <FileUploader
                  onSelectedFile={(file) => onHandleUploadedFile(file)}
                  accept={"image/*"}
                  displayType={"square"}
                  padding={"30px"}
                  setUploadedFiles={setUploadLogo}
                  uploadType={"logo"}
                  heading={
                    uploadLogo?.logoResponseFiles?.originalname
                      ? uploadLogo?.logoResponseFiles?.originalname
                      : imageName
                        ? imageName
                        : "Upload Partner Logo"
                  }
                />
              </Grid>
              <Grid item xs={8}>
                <FileUploader
                  displayType={"rectangle"}
                  accept={"image/png,image/jpeg,image/jpg"}
                  uploadType={"coverImages"}
                  heading={" Upload Offer cover images"}
                  setUploadedFiles={(res) => {
                    setUploadCoverImages({
                      ...uploadCoverImages,
                      coverImagesResponseFiles: [
                        ...uploadCoverImages?.coverImagesResponseFiles,
                        ...res.coverImagesResponseFiles,
                      ],
                    });
                  }}
                  multiple
                />
                {Boolean(uploadCoverImages?.coverImagesResponseFiles?.length) &&
                  uploadCoverImages?.coverImagesResponseFiles?.length && (
                    <Box display={"flex"} gap={"10px "} flexWrap={"wrap"}>
                      {uploadCoverImages?.coverImagesResponseFiles?.map(
                        (cover, index) => {
                          return (
                            <Box
                              display={"flex"}
                              justifyContent={"space-between"}
                              alignItems={"center"}
                              gap={"30px"}
                              bgcolor={"#D5D5D5"}
                              padding={"5px 10px"}
                              borderRadius={"5px"}
                              fontFamily={"MetropolisMedium"}
                              fontSize={"10px"}
                              mt={2}
                            >
                              {index + 1} {cover?.originalname}
                              <Typography
                                component={"span"}
                                sx={{ cursor: "pointer" }}
                                onClick={() => deleteCoverImages(index)}
                                fontSize={"12px"}
                              >
                                X
                              </Typography>
                            </Box>
                          );
                        }
                      )}
                    </Box>
                  )}
              </Grid>
            </Grid>

            <Grid xs={12} mt={2}>
              <FileUploader
                onSelectedFile={(file) => onHandleUploadedThumbnail(file)}
                accept={"image/png,image/jpeg,image/jpg"}
                displayType={"offerThumbNail"}
                padding={"30px"}
                setUploadedFiles={setOfferThumbnailImage}
                uploadType={"offerThumbNail"}
                heading={
                  offerThumbnailImage?.offerThumbNailResponseFiles?.originalname
                    ? offerThumbnailImage?.offerThumbNailResponseFiles
                      ?.originalname
                    : offerThumbnail
                      ? offerThumbnail
                      : "Offer Thumbnail"
                }
              />
            </Grid>

            <Box mt={"20px"}>
              <Controller
                name="partnerDescription"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <CustomInput
                    inputLabel={"Enter Partner Description"}
                    multiline={true}
                    minRows={5}
                    maxRows={10}
                    type={"text"}
                    borderRadius={"5px"}
                    {...field}
                    error={!!error}
                    helperText={error ? error.message : ""}
                  />
                )}
              />
            </Box>

            <Box mt={"10px"}>
              <Typography
                component={"span"}
                color={"#000000"}
                fontSize={"13px"}
                fontFamily={"MetropolisSemiBold"}
              >
                Usage Details
              </Typography>
            </Box>

            {usagePointsDetails.map((item, index) => {
              return (
                <>
                  <Box
                    display={"flex"}
                    gap={"10px"}
                    alignItems={"top"}
                    justifyContent={"center"}
                  >
                    <Box width={"100%"}>
                      <Controller
                        render={({ field, fieldState: { error } }) => (
                          <CustomInput
                            {...field}
                            inputLabel={"Enter Usage Points"}
                            type={"text"}
                            error={!!error}
                            helperText={error ? error.message : ""}
                            borderRadius={"5px"}
                          />
                        )}
                        name={`usagePointsDetails.${index}.usagePoints`}
                        control={control}
                      />
                    </Box>
                    <Box>
                      {index < 1 && (
                        <Button
                          sx={{
                            border: "1px solid #FB8842",
                            fontSize: "12px",
                            marginTop: "14px",
                            color: "#FA8842",
                          }}
                          onClick={() => addUsagePoints()}
                        >
                          Add
                        </Button>
                      )}
                      {index > 0 && (
                        <Button
                          sx={{
                            border: "1px solid #FB8842",
                            color: "#FA8842",
                            marginTop: "14px",
                            fontSize: "12px",
                          }}
                          onClick={() => removeUsagePoints(index)}
                        >
                          Delete
                        </Button>
                      )}
                    </Box>
                  </Box>
                </>
              );
            })}

            <>
              <Box mt={"10px"}>
                <Typography
                  component={"span"}
                  color={"#000000"}
                  fontSize={"13px"}
                  fontFamily={"MetropolisSemiBold"}
                >
                  Cities
                </Typography>
              </Box>
              <Box mt={"12px"}>
                <Controller
                  name="city"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <CustomAutoComplete
                      value={value}
                      onChange={(e, value) => onChange(value)}
                      noOptionsText={"Select City"}
                      error={!!error}
                      helperText={error ? error.message : ""}
                      multiple
                      onInputChange={(e, value) => handleSearchCities(e, value)}
                      options={allCities ? allCities : []}
                      label="Select City"
                    />
                  )}
                />
              </Box>
              <Box mt={"12px"}>
                <Controller
                  name="locality"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <CustomAutoComplete
                      value={value}
                      onChange={(e, value) => onChange(value)}
                      noOptionsText={"Select Locality"}
                      error={!!error}
                      helperText={error ? error.message : ""}
                      multiple
                      onInputChange={(e, value) =>
                        handleSearchLocalities(e, value)
                      }
                      options={localities ? localities : []}
                      label="Select Locality"
                    />
                  )}
                />
              </Box>
            </>

            {getValues(`offerType`) === "in-store" && (
              <Box>
                <Controller
                  name="address"
                  control={control}
                  render={({ field: { ...field }, fieldState: { error } }) => (
                    <CustomInput
                      inputLabel={"Address"}
                      type={"text"}
                      borderRadius={"5px"}
                      {...field}
                      error={!!error}
                      helperText={error ? error.message : ""}
                    />
                  )}
                />
              </Box>
            )}
            {getValues(`offerType`) === "online" && (
              <Box>
                <Controller
                  name="url"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <CustomInput
                      inputLabel={"Enter / paste your link or URL(Optional)"}
                      type={"text"}
                      borderRadius={"5px"}
                      {...field}
                      error={!!error}
                      helperText={error ? error.message : ""}
                    />
                  )}
                />
              </Box>
            )}
            {getValues(`subType`) === "non-payable" && (
              <Box mt={"10px"}>
                <FileUploader
                  accept={"image/png,image/jpeg,image/jpg"}
                  displayType={"square"}
                  setUploadedFiles={setUploadBrochureImage}
                  uploadType={"brochureImage"}
                  heading={
                    uploadBrochureImage?.brochureImageResponseFiles
                      ?.originalname
                      ? uploadBrochureImage?.brochureImageResponseFiles
                        ?.originalname
                      : currentOffer?.brochureImage
                        ? currentOffer?.brochureImage
                        : "Non-Payable Image"
                  }
                  padding={"8px"}
                />
              </Box>
            )}

            <Box mt={"10px"}>
              <Typography
                component={"span"}
                color={"#000000"}
                fontSize={"13px"}
                fontFamily={"MetropolisSemiBold"}
              >
                Valid till Period
              </Typography>
            </Box>

            <Box display={"flex"} gap={"10px"} width={"100%"}>
              <Box width={"50%"}>
                <Controller
                  name={"fromDate"}
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <CustomDatePicker
                      {...field}
                      label={"From Date"}
                      error={!!error}
                      helperText={error ? error.message : ""}
                      disablePast={true}
                    />
                  )}
                />
              </Box>

              <Box width={"50%"}>
                <Controller
                  name="fromTime"
                  control={control}
                  render={({ field: { ...field }, fieldState: { error } }) => (
                    <CustomSelect
                      borderRadius={"5px"}
                      {...field}
                      error={!!error}
                      helperText={error ? error.message : ""}
                      placeHolder={"From Time"}
                      options={timeOptions}
                    />
                  )}
                />
              </Box>
              <Box width={"50%"}>
                <Controller
                  name="toDate"
                  control={control}
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <CustomDatePicker
                        {...field}
                        label={"To Date"}
                        error={!!error}
                        helperText={error ? error.message : ""}
                        disablePast={true}
                      />
                    );
                  }}
                />
              </Box>
              <Box width={"50%"}>
                <Controller
                  name="ToTime"
                  control={control}
                  render={({ field: { ...field }, fieldState: { error } }) => (
                    <CustomSelect
                      {...field}
                      borderRadius={"5px"}
                      error={!!error}
                      helperText={error ? error.message : ""}
                      placeHolder={"End Time"}
                      options={timeOptions}
                    />
                  )}
                />
              </Box>
            </Box>
            {getValues(`offerType`) === "in-store" &&
              getValues(`subType`) === "payable" && (
                <>
                  <Box display={"flex"} alignItems={"center"}>
                    <Controller
                      // rules={{ required: true }}
                      control={control}
                      name="thrillCoupon"
                      render={({ field }) => (
                        <FormControlLabel
                          {...field}
                          defaultValue={false}
                          control={
                            <Checkbox
                              {...field}
                              checked={field.value}
                              onChange={(e) => field.onChange(e.target.checked)}
                              sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                            />
                          }
                          label={<p>Thrillh coupon</p>}
                          labelPlacement="end"
                        />
                      )}
                    />
                  </Box>
                  {getValues(`thrillCoupon`) === true && (
                    <Box display={"flex"} gap={"10px"}>
                      <Box width={"50%"}>
                        <Controller
                          name="couponName"
                          control={control}
                          render={({ field, fieldState: { error } }) => (
                            <CustomInput
                              inputLabel={"Coupon Name"}
                              type={"text"}
                              borderRadius={"5px"}
                              {...field}
                              error={!!error}
                              helperText={error ? error.message : ""}
                            />
                          )}
                        />
                      </Box>
                      <Box width={"50%"}>
                        <Controller
                          name="couponValue"
                          control={control}
                          render={({ field, fieldState: { error } }) => (
                            <CustomInput
                              inputLabel={"Coupon Value"}
                              type={"number"}
                              borderRadius={"5px"}
                              {...field}
                              error={!!error}
                              helperText={error ? error.message : ""}
                            />
                          )}
                        />
                      </Box>
                    </Box>
                  )}
                </>
              )}

            <Box mt={"20px"}>
              <Box mt={"10px"}>
                <Controller
                  name="vendorName"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <CustomInput
                      inputLabel={"Enter Vendor Name"}
                      type={"text"}
                      borderRadius={"5px"}
                      {...field}
                      error={!!error}
                      helperText={error ? error.message : ""}
                    />
                  )}
                />
              </Box>
              <Box mt={"10px"}>
                <Controller
                  name="vendorMobile"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <CustomInput
                      inputLabel={"Enter Vendor Mobile Number"}
                      type={"number"}
                      borderRadius={"5px"}
                      {...field}
                      error={!!error}
                      helperText={error ? error.message : ""}
                    />
                  )}
                />
              </Box>
            </Box>
            <Box mt={"20px"}>
              <Box mt={"10px"}>
                <Controller
                  name="metaTitle"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <CustomInput
                      inputLabel={"Enter Meta Name"}
                      type={"text"}
                      borderRadius={"5px"}
                      {...field}
                      error={!!error}
                      helperText={error ? error.message : ""}
                    />
                  )}
                />
              </Box>
              <Box mt={"10px"}>
                <Controller
                  name="metaDescription"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <CustomInput
                      inputLabel={"Enter Meta Description"}
                      type={"text"}
                      borderRadius={"5px"}
                      {...field}
                      error={!!error}
                      helperText={error ? error.message : ""}
                    />
                  )}
                />
              </Box>
              {/* <Box mt={"10px"}>
                <Controller
                  name="customGst"
                  control={control}
                  render={({ field: { ...field }, fieldState: { error } }) => (
                    <CustomSelect
                      {...field}
                      borderRadius={"5px"}
                      error={!!error}
                      helperText={error ? error.message : ""}
                      placeHolder={"Select Gst"}
                      options={GST_TYPES}
                    />
                  )}
                />
              </Box> */}
            </Box>
            <Box
              display={"flex"}
              justifyContent={"end"}
              gap={"10px"}
              mt={"20px"}
            >
              <Box width={"20%"}>
                <CustomButton
                  handleClick={() => props.onClose()}
                  border={"1px solid #000000"}
                  color={"#000000"}
                  title={"Cancel"}
                  bgColor={"#F7F7F7"}
                  borderRadius={"5px"}
                />
              </Box>
              <Box width={"20%"}>
                <CustomButton
                  title="Submit"
                  type="submit"
                  borderRadius={"5px"}
                  handleClick={handleSubmit(onSubmit)}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default AddEditOffer;
