// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (min-width: 320px) and (max-width: 990px) {
  .mainDialogueContainer .MuiDialog-paper {
    width: 100% !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/app/components/Dialog/dialog.css"],"names":[],"mappings":"AAAA;EACE;IACE,sBAAsB;EACxB;AACF","sourcesContent":["@media (min-width: 320px) and (max-width: 990px) {\n  .mainDialogueContainer .MuiDialog-paper {\n    width: 100% !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
