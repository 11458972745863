import React from "react";
import errorPageSvg from "../../../images/error page.svg";
import { Box } from "@mui/material";

const Error = () => {
  return (
    <>
      <Box
        mt={"50px"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <img src={errorPageSvg} alt="error" />
      </Box>
    </>
  );
};

export default Error;
