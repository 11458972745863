import { Box, Typography } from "@mui/material";
import React from "react";
import Flat from "../../../images/Flat Color (1).svg";
import CustomButton from "../Button";
import deleteSvg from "../../../images/caution.svg";

const ModelInfo = (props) => {
  const messages = [
    {
      id: 1,
      image: Flat,
      type: "registration",
      description:
        "Thanks, we’ll now check your ID card and will send you an email to let you know if your verification has been successful. We’ll aim to respond to you in 24 hours , although during our busy periods, this may take a little longer.",
    },
    {
      id: 2,
      image: deleteSvg,
      type: "login",
      description:
        "You have not completed your onboarding process. Please complete your onboarding process.",
    },
    {
      id: 3,
      image: Flat,
      type: "denied",
      description: "Your Account is Denied.Please reverify your Details",
    },
    {},
  ];

  const filteredMessages = messages.filter((item) => item.type === props.type);
  return (
    <>
      {filteredMessages?.map((item) => {
        return (
          <Box key={item.id}>
            <Box
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"space-between"}
              height={"100%"}
              width={"100%"}
            >
              <Box display={"flex"} justifyContent={"center"}>
                <img src={item.image} width={"70px"} alt="logo"></img>
              </Box>
              <Typography
                sx={{
                  color: props.color ? props.color : '"#FA8842"',
                  fontSize: "20px",
                  fontWeight: "600",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {props.message}
              </Typography>
              <Typography fontWeight={"bold"}>{item.description}</Typography>
              <Box display={"flex"} justifyContent={"center"} mt={2}>
                <Box>
                  <CustomButton
                    padding={"10px 30px"}
                    title={props.title}
                    handleClick={props.handleClick}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        );
      })}
    </>
  );
};
export default ModelInfo;
