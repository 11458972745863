import { Box, Typography } from "@mui/material";
import React from "react";
import settingUserSvg from "../../../../images/admin/settinguser.svg";
import CustomButton from "../../../components/Button";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { CustomInputWithIcon } from "../../../components/Input";
import { ApiRequest } from "../../../../services/api-request";
import { useSnackbar } from "../../../../context/useSnackbar";

const AdminSettings = () => {
  const schema = yup
    .object({
      currentPassword: yup.string().required("Email is required"),
      password: yup
        .string()
        .required("Password is required")
        .min(6, "The password must be at least six characters."),
      confirmPassword: yup
        .string()
        .required("Confirm Password is required")
        .oneOf([yup.ref("password"), null], "Passwords must match"),
    })
    .required();

  const { handleSubmit, control, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { currentPassword: "", password: "" },
  });
  const { openSnackbar } = useSnackbar();

  const onSubmit = (data) => {
    ApiRequest({
      url: `user/change-password`,
      method: "PUT",
      data: data,
    })
      .then((res) => {
        if (res) {
          openSnackbar("Password changed successfully", "success");
          reset();
        }
      })
      .catch((err) => {
        openSnackbar(err?.response?.data?.message, "error");
        reset();
      });
  };
  return (
    <>
      <Box
        bgcolor={"#FFEDE3"}
        padding={"20px"}
        borderRadius={"40px 0px 0px 40px"}
        height={"94vh"}
      >
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography
            fontFamily={"MetropolisBold"}
            fontSize={"20px"}
            color={"#000000"}
          >
            Settings
          </Typography>
        </Box>
        <Box
          padding={"15px"}
          bgcolor={"#FFFFFF"}
          borderRadius={"10px"}
          mt={"10px"}
        >
          <Box display={"flex"} gap={"20px"} alignItems={"center"}>
            <Box>
              <img src={settingUserSvg} alt="" width={"80px"} />
            </Box>
            <Box>
              <Typography
                fontFamily={"MetropolisBold"}
                fontSize={"16px"}
                color={"#000000"}
              >
                Thrillh Admin
              </Typography>
              <Typography
                fontFamily={"MetropolisBold"}
                fontSize={"16px"}
                color={"#000000"}
              >
                adminthrills@yopmail.com
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box
          bgcolor={"#fff"}
          padding={"20px"}
          borderRadius={"10px"}
          mt={"10px"}
        >
          <Typography
            fontFamily={"MetropolisBold"}
            fontSize={"16px"}
            color={"#000000"}
          >
            Change Password
          </Typography>
          <Box mt={2} width={"30%"}>
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <Box>
                <Controller
                  name="currentPassword"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <CustomInputWithIcon
                      {...field}
                      label={"Current Password"}
                      type={"text"}
                      placeHolder={"Enter Current Password"}
                      error={!!error}
                      helperText={error ? error.message : ""}
                    />
                  )}
                />
              </Box>
              <Box mt={3}>
                <Controller
                  name="password"
                  type="password"
                  defaultValue={""}
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <CustomInputWithIcon
                      label={"New Password"}
                      type="password"
                      {...field}
                      placeHolder={"Enter New Password"}
                      error={!!error}
                      helperText={error ? error.message : ""}
                    />
                  )}
                />
              </Box>
              <Box mt={3}>
                <Controller
                  name="confirmPassword"
                  type="password"
                  defaultValue={""}
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <CustomInputWithIcon
                      label={"Confirm Password"}
                      type="password"
                      {...field}
                      placeHolder={"Enter Confirm Password"}
                      error={!!error}
                      helperText={error ? error.message : ""}
                    />
                  )}
                />
              </Box>

              <Box mt={2}>
                <CustomButton
                  title={"Submit"}
                  borderButton={false}
                  type={"submit"}
                />
              </Box>
            </form>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default AdminSettings;
