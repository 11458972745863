import React, { Suspense, lazy, useEffect } from "react";
import { Routes, Route, useLocation, Navigate, Outlet } from "react-router-dom";
import Login from "./screens/Login";
import { AppContextProvider, useAppContext } from "../context/AppContext";
import AppLayout from "./components/AppLayout";
import UserList from "./screens/Users/user-list";
import OfferList from "./screens/Offers/offer-list";

import SubCategoriesList from "./screens/admin/Masters/subcategory-list";
import CategoriesList from "./screens/admin/Masters/categories-list";
import Register from "./screens/Register";
import ForgotPassword from "./screens/forgot-password";
import Otp from "./screens/Otp";
import StudentDetails from "./screens/Details/StudentDetails";
import CorporateDetails from "./screens/Details/CorporateDetails";
import LoginWithOtp from "./screens/Login/login-with-otp";
import OfferView from "./screens/Offers/offer-view";
import Wallet from "./screens/Wallet";
import Rate from "./screens/Rate";
import Summary from "./screens/Summary";
import BannerList from "./screens/admin/banners/banner-list";
import UserView from "./screens/Users/user-view";
import OfferTaxList from "./screens/admin/Masters/offerTax-list";
import AdminSettings from "./screens/admin/settings/admin-settings";
import Settings from "./screens/Settings";
import Help from "./screens/Help";
import Refer from "./screens/Refer";
import ContactUS from "./screens/ContactUs";
import Contact from "./screens/Contact";
import PrivacyPolicy from "./screens/PrivacyPolicy";
import TermsOfServices from "./screens/TermsOfServices";
import HavingTroubleLogin from "./screens/HavingTroubleLogin";

import About from "./screens/About";
import HomeFaq from "./screens/Faq/faq";
import AdminOrdersList from "./screens/admin/OrdersList";
import Error from "./components/ErrorPage/error-page";
import ScrollToTopOnBack from "./components/ScrollToTop/scroll";
import WebCareers from "./screens/Webcareers";
import CareererView from "./screens/admin/careers/career-view";
import CareerList from "./screens/admin/careers/career-list";
import WebCareererView from "./screens/Webcareers/web-careerer-view";
import VendorLogin from "./screens/Vendors/Login/vendor-login";
import VendorDashboard from "./screens/Vendors/Dashboard";
import VendorSettings from "./screens/Vendors/Settings";
import VendorContactUs from "./screens/Vendors/ContactUs";
import OfferReports from "./screens/admin/Reports/OfferReports/offerReports";
import UserReports from "./screens/admin/Reports/UserReports/userReports";
import TransactionReports from "./screens/admin/Reports/TransactionReports";
import OrdersReports from "./screens/admin/Reports/OrdersReports";
import store from "./redux/store";
import { Provider } from "react-redux";
import Disclaimer from "./screens/Disclaimer";

// Use lazy-loading to load the Comment component only when needed
const LazyHomeScreen = lazy(() => import("./screens/Home"));

const LazyPartnerWithUs = lazy(() => import("./screens/Partner"));
const LazyAdminDashboard = lazy(() => import("./screens/admin/Dashboard"));
const LazyProfile = lazy(() => import("./screens/Profile"));
const LazyCategoryRelatedOffers = lazy(() =>
  import("./screens/CategoryRelatedOffers")
);
const LazyOfferDetails = lazy(() => import("./screens/IndividualOfferDetails"));
const LazyAllOffers = lazy(() => import("./screens/Home/all-offers"));
const LazyMyOrders = lazy(() => import("./screens/MyOrders"));
const LazyUserLandingPage = lazy(() =>
  import("./screens/UserLandingPage/user-landing-page")
);
const LazyVendorLandingPage = lazy(() =>
  import("./screens/VendorLandingPage/vendor-landing-page")
);
const LazyAdminNotifications = lazy(() =>
  import("./screens/admin/admin-notifications")
);

const App = () => {
  function RequireAuth() {
    const { isLoggedIn, user, handleLogoutSuccess } = useAppContext();

    let allowed = true;
    let location = useLocation();
    if (isLoggedIn && user?.role?.code) {
      if (
        location?.pathname?.startsWith("/admin") &&
        (user?.role?.code === "student" ||
          user?.role?.code === "corporate" ||
          user?.role?.code === "vendor")
      ) {
        allowed = false;
      }
      if (
        user?.role?.code === "admin" &&
        !location?.pathname?.startsWith("/admin") &&
        location?.pathname?.startsWith("/")
      ) {
        allowed = false;
      }
      if (
        user?.role?.code === "admin" &&
        !location?.pathname?.startsWith("/admin")
      ) {
        allowed = false;
      }
      if (!allowed) {
        localStorage.removeItem("user");
        localStorage.removeItem("loginStatus", "false");
        localStorage.removeItem("selectedLocality");
        handleLogoutSuccess();
        return <Navigate to="/login" state={{ from: location }} />;
      }
    } else {
      if (location?.pathname?.startsWith("/admin")) {
        localStorage.removeItem("user");
        localStorage.removeItem("loginStatus", "false");
        localStorage.removeItem("selectedLocality");
        handleLogoutSuccess();
        return <Navigate to="/login" state={{ from: location }} />;
      }
    }

    return <Outlet />;
  }

  function NotFound() {
    return <Error />;
  }

  return (
    <>
      <AppContextProvider>
        <Provider store={store}>
          <ScrollToTopOnBack />
          <Suspense>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path={`/register`} element={<Register />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/otp" element={<Otp />} />
              <Route path="/otp" element={<Otp />} />
              <Route path="/login-with-otp" element={<LoginWithOtp />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/trouble-shoot" element={<HavingTroubleLogin />} />
              <Route
                path="/register/student/:userId/form"
                element={<StudentDetails />}
              />
              <Route
                path="/register/corporate/:userId/form"
                element={<CorporateDetails />}
              />
              <Route path="/vendor/login" element={<VendorLogin />} />

              <Route element={<RequireAuth />}>
                <Route
                  path="/offer/:offerName/:offerId"
                  element={<LazyOfferDetails />}
                  currentRole={["student", "corporate"]}
                />
              </Route>
              <Route element={<AppLayout />}>
                <Route path="/" element={<Navigate to="/" />} />
                <Route
                  index
                  path="/"
                  element={<LazyHomeScreen />}
                  currentRole={["student", "corporate"]}
                />
                <Route path="/:location" element={<LazyHomeScreen />} />
                <Route path="/join-with-us" element={<LazyPartnerWithUs />} />
                <Route path="/about-us" element={<About />} />
                <Route path="/faqs" element={<HomeFaq />} />
                <Route path="/contact-us" element={<Contact />} />
                <Route path="/careers" element={<WebCareers />} />

                {/* Admin Routes Starts here*/}
                <Route element={<RequireAuth />}>
                  <Route path="/" element={<Navigate to="/" />} />
                  <Route
                    path="/admin/dashboard"
                    element={<LazyAdminDashboard />}
                    currentRole={["admin"]}
                  />
                  <Route
                    path="/admin/users"
                    element={<UserList />}
                    currentRole={["admin"]}
                  />
                  <Route
                    path="/admin/users/:userId/details"
                    element={<UserView />}
                    currentRole={["admin"]}
                  />
                  <Route
                    path="/admin/offers"
                    element={<OfferList />}
                    currentRole={["admin"]}
                  />
                  <Route
                    path="/admin/categories"
                    element={<CategoriesList />}
                    currentRole={["admin"]}
                  />
                  <Route
                    path="/admin/sub-categories"
                    element={<SubCategoriesList />}
                    currentRole={["admin"]}
                  />
                  <Route
                    path="/admin/offer/:offerName/:offerId"
                    element={<OfferView />}
                    currentRole={["admin"]}
                  />
                  <Route
                    path="/admin/banners"
                    element={<BannerList />}
                    currentRole={["admin"]}
                  />
                  <Route
                    path="/admin/careers"
                    element={<CareerList />}
                    currentRole={["admin"]}
                  />
                  <Route
                    path="/admin/career/:careerId/view"
                    element={<CareererView />}
                    currentRole={["admin"]}
                  />
                  <Route
                    path="/admin/settings"
                    element={<AdminSettings />}
                    currentRole={["admin"]}
                  />
                  <Route
                    path="/admin/offer-tax"
                    element={<OfferTaxList />}
                    currentRole={["admin"]}
                  />
                  <Route
                    path="/admin/orders-list"
                    element={<AdminOrdersList />}
                    currentRole={["admin"]}
                  />
                  <Route
                    path="/admin/offer-reports"
                    element={<OfferReports />}
                    currentRole={["admin"]}
                  />
                  <Route
                    path="/admin/user-reports"
                    element={<UserReports />}
                    currentRole={["admin"]}
                  />
                  <Route
                    path="/admin/transaction-reports"
                    element={<TransactionReports />}
                    currentRole={["admin"]}
                  />
                  <Route
                    path="/admin/order-reports"
                    element={<OrdersReports />}
                    currentRole={["admin"]}
                  />
                  <Route
                    path="/admin/notifications"
                    element={<LazyAdminNotifications />}
                  />
                </Route>
                {/* Admin Routes Ends here*/}

                {/* User Routes Starts here*/}
                <Route element={<RequireAuth />}>
                  <Route
                    path="/:offerType/offers"
                    element={<LazyAllOffers />}
                    currentRole={[]}
                  />

                  <Route
                    path="/careerer/:Id/details"
                    element={<WebCareererView />}
                    currentRole={["student", "corporate"]}
                  />

                  <Route
                    path="/categories/:categoryName-coupons-and-deals/:categoryId"
                    element={<LazyCategoryRelatedOffers />}
                    currentRole={["student", "corporate"]}
                  />
                  <Route
                    path="/offer/:offerName/:offerId/pay-summary"
                    element={<Summary />}
                    currentRole={["student", "corporate"]}
                  />
                  <Route
                    path="/my-account/profile"
                    element={<LazyProfile />}
                    currentRole={["student", "corporate"]}
                  />

                  <Route
                    path="/my-account/orders"
                    element={<LazyMyOrders />}
                    currentRole={["student", "corporate"]}
                  />

                  <Route
                    path="/my-account/rate"
                    element={<Rate />}
                    currentRole={["student", "corporate"]}
                  />

                  <Route
                    path="/my-account/help"
                    element={<Help />}
                    currentRole={["student", "corporate"]}
                  />

                  <Route
                    path="/my-account/refer"
                    element={<Refer />}
                    currentRole={["student", "corporate"]}
                  />

                  <Route
                    path="/my-account/contact"
                    element={<ContactUS />}
                    currentRole={["student", "corporate"]}
                  />
                  <Route
                    path="/my-account/wallet"
                    element={<Wallet />}
                    currentRole={["student", "corporate"]}
                  />

                  <Route
                    path="/my-account/settings"
                    element={<Settings />}
                    currentRole={["student", "corporate"]}
                  />
                  <Route
                    path="/download-thrillh-app"
                    element={<LazyUserLandingPage />}
                    currentRole={["student", "corporate"]}
                  />
                  <Route
                    path="/partner-with-us"
                    element={<LazyVendorLandingPage />}
                    currentRole={["student", "corporate"]}
                  />
                </Route>
                {/* User Routes Ends here*/}

                {/* Vendor Routes Starts here*/}

                <Route element={<RequireAuth />}>
                  <Route
                    path="/vendor/dashboard"
                    element={<VendorDashboard />}
                    currentRole={["vendor"]}
                  />
                  <Route
                    path="/vendor/settings"
                    element={<VendorSettings />}
                    currentRole={["vendor"]}
                  />
                  <Route
                    path="/vendor/contact-us"
                    element={<VendorContactUs />}
                    currentRole={["vendor"]}
                  />
                  <Route
                    path="/vendor/orders-list"
                    element={<AdminOrdersList />}
                    currentRole={["vendor"]}
                  />
                  <Route
                    path="/vendor/offer/:offerName/:offerId"
                    element={<OfferView />}
                    currentRole={["vendor"]}
                  />
                </Route>
                {/* Vendor Routes ends here*/}
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route
                  path="/terms-of-services"
                  element={<TermsOfServices />}
                />
                <Route path="/disclaimer" element={<Disclaimer />} />
              </Route>
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Suspense>
        </Provider>
      </AppContextProvider>
    </>
  );
};

export default App;
