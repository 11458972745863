import { Box, Grid, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import thrillhlogo from "../../../../images/final logo.png";
import styles from "./invoice.module.css";
import {
  UTCformatDate,
  capitalizeFirstLetter,
} from "../../../utilities/date-utility";

const DownloadInvoice = (props) => {
  const { componentRef, currentOrderDetails, loader, clickedOn } = props;

  const [formattedDate, setFormattedDate] = useState("");

  useEffect(() => {
    if (currentOrderDetails?.createdAt) {
      setFormattedDate(UTCformatDate(currentOrderDetails?.createdAt));
    }
  }, [currentOrderDetails?.createdAt]);

  return (
    <>
      {loader ? (
        <h1>loading</h1>
      ) : (
        <Box
          ref={componentRef}
          sx={{
            "@media print": {
              m: 3,
            },
          }}
        >
          <Box>
            <img src={thrillhlogo} alt="" />
          </Box>
          <Box
            sx={{
              "@media print": {
                mt: "20px",
              },
            }}
          >
            <Box
              className={styles.heading}
              sx={{
                "@media print": {
                  border: "2px solid #E4E4E4",
                  padding: "10px",
                  borderRadius: "11px",
                },
              }}
            >
              <Grid container className={styles.heading}>
                <Grid item xs={6}>
                  <Typography
                    sx={{
                      "@media print": {
                        fontSize: "20px",
                      },
                    }}
                  >
                    <b>Order Id : </b>
                    {currentOrderDetails?.payment?.orderId ?? "NA"}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    sx={{
                      "@media print": {
                        fontSize: "20px",
                      },
                    }}
                  >
                    <b>Date : </b>
                    {formattedDate ?? "NA"}
                  </Typography>
                </Grid>
              </Grid>

              <Box mt={"10px"}>
                <Grid container>
                  <Grid item xs={6}>
                    <Typography
                      sx={{
                        "@media print": {
                          fontSize: "20px",
                        },
                      }}
                    >
                      <b>Offer Name : </b>
                      {currentOrderDetails?.offer?.name ?? "NA"}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      sx={{
                        "@media print": {
                          fontSize: "20px",
                        },
                      }}
                    >
                      <b>User name : </b>
                      {currentOrderDetails?.user?.name ?? "NA"}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>

              <Grid container mt={"10px"}>
                <Grid item xs={6}>
                  <Typography
                    sx={{
                      "@media print": {
                        fontSize: "20px",
                      },
                    }}
                  >
                    <b>Transaction ID : </b>{" "}
                    {currentOrderDetails?.payment?.response?.id ?? "NA"}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    sx={{
                      "@media print": {
                        fontSize: "20px",
                      },
                    }}
                  >
                    <b>Status : </b>{" "}
                    {capitalizeFirstLetter(currentOrderDetails?.status) ?? "NA"}
                  </Typography>
                </Grid>
              </Grid>
            </Box>

            <Box
              sx={{
                "@media print": {
                  border: "2px solid #E4E4E4",
                  borderRadius: "11px",
                  mt: "20px",
                },
              }}
            >
              <Box
                sx={{
                  "@media print": {
                    padding: "10px",
                    borderBottom: "2px solid #E4E4E4",
                  },
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography>
                      <b>ITEM</b>
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography>
                      <b>DESCRIPTION</b>
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography>
                      <b>AMOUNT</b>
                    </Typography>
                  </Grid>
                </Grid>
              </Box>

              <Box
                mt={"5px"}
                sx={{
                  "@media print": {
                    padding: "10px 10px 0px 10px",
                    borderRadius: "11px",
                  },
                }}
              >
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={6}
                    sx={{
                      "@media print": {
                        borderRight: "2px solid #E4E4E4",
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        "@media print": {
                          padding: "0px 0px 10px 0px",
                        },
                      }}
                    >
                      {" "}
                      {currentOrderDetails?.offer?.name ?? "NA"}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    sx={{
                      "@media print": {
                        borderRight: "2px solid #E4E4E4",
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        "@media print": {
                          padding: "0px 0px 10px 0px",
                        },
                      }}
                    >
                      Actual Bill Amount
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography>
                      {" "}
                      ₹ {currentOrderDetails?.actualPrice ?? "NA"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={6}
                    sx={{
                      "@media print": {
                        borderRight: "2px solid #E4E4E4",
                      },
                    }}
                  ></Grid>
                  <Grid
                    item
                    xs={3}
                    sx={{
                      "@media print": {
                        borderRight: "2px solid #E4E4E4",
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        "@media print": {
                          padding: "0px 0px 10px 0px",
                        },
                      }}
                    >
                      Offer Discount ({" "}
                      {currentOrderDetails?.discountInfo?.type ===
                        "percentage" &&
                        currentOrderDetails?.discountInfo?.value}
                      {currentOrderDetails?.discountInfo?.type ===
                        "percentage" && "%"}
                      )
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography>
                      ₹ {currentOrderDetails?.discountPrice ?? "NA"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={6}
                    sx={{
                      "@media print": {
                        borderRight: "2px solid #E4E4E4",
                      },
                    }}
                  ></Grid>
                  <Grid
                    item
                    xs={3}
                    sx={{
                      "@media print": {
                        borderRight: "2px solid #E4E4E4",
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        "@media print": {
                          padding: "0px 0px 10px 0px",
                        },
                      }}
                    >
                      After Discount
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography>
                      ₹ {currentOrderDetails?.totalPrice ?? "NA"}
                    </Typography>
                  </Grid>
                </Grid>
                {clickedOn === "userInvoice" && (
                  <>
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={6}
                        sx={{
                          "@media print": {
                            borderRight: "2px solid #E4E4E4",
                          },
                        }}
                      ></Grid>
                      <Grid
                        item
                        xs={3}
                        sx={{
                          "@media print": {
                            borderRight: "2px solid #E4E4E4",
                          },
                        }}
                      >
                        <Typography
                          sx={{
                            "@media print": {
                              padding: "0px 0px 10px 0px",
                            },
                          }}
                        >
                          Thrillh Coupon Discount
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography>
                          {" "}
                          ₹ {currentOrderDetails?.couponInfo?.value ?? "NA"}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={6}
                        sx={{
                          "@media print": {
                            borderRight: "2px solid #E4E4E4",
                          },
                        }}
                      ></Grid>
                      <Grid
                        item
                        xs={3}
                        sx={{
                          "@media print": {
                            borderRight: "2px solid #E4E4E4",
                          },
                        }}
                      >
                        <Typography
                          sx={{
                            "@media print": {
                              padding: "0px 0px 10px 0px",
                            },
                          }}
                        >
                          Wallet Points
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography>
                          {" "}
                          ₹ {currentOrderDetails?.walletPoints ?? "NA"}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={6}
                        sx={{
                          "@media print": {
                            borderRight: "2px solid #E4E4E4",
                          },
                        }}
                      ></Grid>
                      <Grid
                        item
                        xs={3}
                        sx={{
                          "@media print": {
                            borderRight: "2px solid #E4E4E4",
                          },
                        }}
                      >
                        <Typography
                          sx={{
                            "@media print": {
                              padding: "0px 0px 10px 0px",
                            },
                          }}
                        >
                          Customer Paid
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography>
                          {" "}
                          ₹ 
                          {/* {currentOrderDetails?.totalPrice ?? "NA"} */}
                          {currentOrderDetails?.totalPrice - 
                          (currentOrderDetails && currentOrderDetails?.couponInfo?.value ? currentOrderDetails?.couponInfo?.value : 0) - 
                          (currentOrderDetails && currentOrderDetails?.walletPoints ? currentOrderDetails?.walletPoints : 0)}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={6}
                        sx={{
                          "@media print": {
                            borderRight: "2px solid #E4E4E4",
                          },
                        }}
                      ></Grid>
                      <Grid
                        item
                        xs={3}
                        sx={{
                          "@media print": {
                            borderRight: "2px solid #E4E4E4",
                          },
                        }}
                      >
                        <Typography
                          sx={{
                            "@media print": {
                              padding: "0px 0px 10px 0px",
                            },
                          }}
                        >
                          Commission Base Fee (1%)
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography>
                          {/* ₹ {currentOrderDetails?.baseFeePrice ?? "NA"} */}
                          {
                            (currentOrderDetails?.totalPrice - 
                              (currentOrderDetails && currentOrderDetails?.couponInfo?.value ? currentOrderDetails?.couponInfo?.value : 0) - 
                              (currentOrderDetails && currentOrderDetails?.walletPoints ? currentOrderDetails?.walletPoints : 0)) / 100
                          }
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={6}
                        sx={{
                          "@media print": {
                            borderRight: "2px solid #E4E4E4",
                          },
                        }}
                      ></Grid>
                      <Grid
                        item
                        xs={3}
                        sx={{
                          "@media print": {
                            borderRight: "2px solid #E4E4E4",
                          },
                        }}
                      >
                        <Typography
                          sx={{
                            "@media print": {
                              padding: "0px 0px 10px 0px",
                            },
                          }}
                        >
                          IGST of Base Fee ({`18%`})
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography>
                          {" "}
                          {/* ₹ {currentOrderDetails?.gstForCustomerPrice ?? "NA"} */}
                          {
                            ((currentOrderDetails?.totalPrice - 
                              (currentOrderDetails && currentOrderDetails?.couponInfo?.value ? currentOrderDetails?.couponInfo?.value : 0) - 
                              (currentOrderDetails && currentOrderDetails?.walletPoints ? currentOrderDetails?.walletPoints : 0)) / 100) * 0.18
                          }
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={6}
                        sx={{
                          "@media print": {
                            borderRight: "2px solid #E4E4E4",
                          },
                        }}
                      ></Grid>
                      <Grid
                        item
                        xs={3}
                        sx={{
                          "@media print": {
                            borderRight: "2px solid #E4E4E4",
                          },
                        }}
                      >
                        <Typography
                          sx={{
                            "@media print": {
                              padding: "0px 0px 10px 0px",
                            },
                          }}
                        >
                          Convinence Fee (Base fee + IGST)
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography>
                          {" "}
                          ₹{" "}
                          {/* {currentOrderDetails?.gstForCustomerPrice +
                            currentOrderDetails?.baseFeePrice ?? "NA"} */}
                            {
                              
                              ((currentOrderDetails?.totalPrice - 
                                (currentOrderDetails && currentOrderDetails?.couponInfo?.value ? currentOrderDetails?.couponInfo?.value : 0) - 
                                (currentOrderDetails && currentOrderDetails?.walletPoints ? currentOrderDetails?.walletPoints : 0)) / 100) +
                              (((currentOrderDetails?.totalPrice - 
                                (currentOrderDetails && currentOrderDetails?.couponInfo?.value ? currentOrderDetails?.couponInfo?.value : 0) - 
                                (currentOrderDetails && currentOrderDetails?.walletPoints ? currentOrderDetails?.walletPoints : 0)) / 100) * 0.18)
                            }
                        </Typography>
                      </Grid>
                    </Grid>
                  </>
                )}
                {clickedOn === "vendorInvoice" && (
                  <>
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={6}
                        sx={{
                          "@media print": {
                            borderRight: "2px solid #E4E4E4",
                          },
                        }}
                      ></Grid>
                      <Grid
                        item
                        xs={3}
                        sx={{
                          "@media print": {
                            borderRight: "2px solid #E4E4E4",
                          },
                        }}
                      >
                        <Typography
                          sx={{
                            "@media print": {
                              padding: "0px 0px 10px 0px",
                            },
                          }}
                        >
                          Thrllh Commission (10%)
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography>
                          {" "}
                          ₹{" "}
                          {Number(currentOrderDetails?.partnerDetails?.commissionPrice).toFixed(
                            2
                          ) ?? "NA"}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={6}
                        sx={{
                          "@media print": {
                            borderRight: "2px solid #E4E4E4",
                          },
                        }}
                      ></Grid>
                      <Grid
                        item
                        xs={3}
                        sx={{
                          "@media print": {
                            borderRight: "2px solid #E4E4E4",
                          },
                        }}
                      >
                        <Typography
                          sx={{
                            "@media print": {
                              padding: "0px 0px 10px 0px",
                            },
                          }}
                        >
                          IGST on Thrllh Commission (18%)
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography>
                          {" "}
                          ₹{" "}
                          {Number(currentOrderDetails?.partnerDetails?.gstForPartnerPrice).toFixed(
                            2
                          ) ?? "NA"}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={6}
                        sx={{
                          "@media print": {
                            borderRight: "2px solid #E4E4E4",
                          },
                        }}
                      ></Grid>
                      <Grid
                        item
                        xs={3}
                        sx={{
                          "@media print": {
                            borderRight: "2px solid #E4E4E4",
                          },
                        }}
                      >
                        <Typography
                          sx={{
                            "@media print": {
                              padding: "0px 0px 10px 0px",
                            },
                          }}
                        >
                          TCS on After Discount (1%)
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography>
                          {" "}
                          ₹{" "}
                          {Number(currentOrderDetails?.partnerDetails?.tcsPrice).toFixed(
                            2
                          ) ?? "NA"}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={6}
                        sx={{
                          "@media print": {
                            borderRight: "2px solid #E4E4E4",
                          },
                        }}
                      ></Grid>
                      <Grid
                        item
                        xs={3}
                        sx={{
                          "@media print": {
                            borderRight: "2px solid #E4E4E4",
                          },
                        }}
                      >
                        <Typography
                          sx={{
                            "@media print": {
                              padding: "0px 0px 10px 0px",
                            },
                          }}
                        >
                          TDS on After Discount(1%)
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography>
                          {" "}
                          ₹{" "}
                          {Number(currentOrderDetails?.partnerDetails?.tdsPrice).toFixed(
                            2
                          ) ?? "NA"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </>
                )}
              </Box>
            </Box>
            {clickedOn === "userInvoice" && (
              <>
                <Grid
                  container
                  spacing={2}
                  sx={{
                    "@media print": {
                      mt: "15px",
                    },
                  }}
                >
                  <Grid item xs={6}>
                    <Typography
                      sx={{
                        "@media print": {
                          fontSize: "20px",
                          fontWeight: "600",
                        },
                      }}
                    >
                      {" "}
                      Amount Paid By Customer
                    </Typography>
                  </Grid>
                  <Grid item xs={3}></Grid>
                  <Grid item xs={3}>
                    <Typography
                      sx={{
                        "@media print": {
                          fontSize: "20px",
                          fontWeight: "600",
                        },
                      }}
                    >
                      {" "}
                      ₹ 
                      {/* {(currentOrderDetails?.payablePrice).toFixed(2) ?? "NA"} */}
                      {
                        // (currentOrderDetails?.totalPrice - 
                        //   (currentOrderDetails && currentOrderDetails?.couponInfo?.value ? currentOrderDetails?.couponInfo?.value : 0) - 
                        //   (currentOrderDetails && currentOrderDetails?.walletPoints ? currentOrderDetails?.walletPoints : 0)) +
                        // ((currentOrderDetails?.totalPrice - 
                        //   (currentOrderDetails && currentOrderDetails?.couponInfo?.value ? currentOrderDetails?.couponInfo?.value : 0) - 
                        //   (currentOrderDetails && currentOrderDetails?.walletPoints ? currentOrderDetails?.walletPoints : 0)) / 100) +
                        // (((currentOrderDetails?.totalPrice - 
                        //   (currentOrderDetails && currentOrderDetails?.couponInfo?.value ? currentOrderDetails?.couponInfo?.value : 0) - 
                        //   (currentOrderDetails && currentOrderDetails?.walletPoints ? currentOrderDetails?.walletPoints : 0)) / 100) * 0.18)
                        Number(currentOrderDetails?.payablePrice).toFixed(2)
                      }
                    </Typography>
                  </Grid>
                </Grid>
              </>
            )}
            {clickedOn === "vendorInvoice" && (
              <>
                <Grid
                  container
                  spacing={2}
                  sx={{
                    "@media print": {
                      mt: "15px",
                    },
                  }}
                >
                  <Grid item xs={6}>
                    <Typography
                      sx={{
                        "@media print": {
                          fontSize: "20px",
                          fontWeight: "600",
                        },
                      }}
                    >
                      {" "}
                      Amount paid to Vendor
                    </Typography>
                  </Grid>
                  <Grid item xs={3}></Grid>
                  <Grid item xs={3}>
                    <Typography
                      sx={{
                        "@media print": {
                          fontSize: "20px",
                          fontWeight: "600",
                        },
                      }}
                    >
                      ₹{" "}
                      {Number(currentOrderDetails?.partnerDetails?.payablePrice).toFixed(
                        2
                      ) ?? "NA"}
                    </Typography>
                  </Grid>
                </Grid>
              </>
            )}
          </Box>
        </Box>
      )}
    </>
  );
};

export default DownloadInvoice;
