// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (min-width: 320px) and (max-width: 990px) {
  .faqSec {
    width: 92% !important;
    padding: 20px 10px !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/app/screens/Faq/index.css"],"names":[],"mappings":"AAAA;EACE;IACE,qBAAqB;IACrB,6BAA6B;EAC/B;AACF","sourcesContent":["@media (min-width: 320px) and (max-width: 990px) {\n  .faqSec {\n    width: 92% !important;\n    padding: 20px 10px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
