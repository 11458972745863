// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (min-width: 320px) and (max-width: 990px) {
  .settingsCard {
    width: 90% !important;
    padding: 14px !important;
    margin: 24px 6px 6px 6px !important;
    height: 68% !important;
  }
  .settingsCard2 {
    width: 90% !important;
    padding: 14px !important;
    margin: 24px 6px 6px 6px !important;
    height: 14% !important;
  }

  .settingSingleField {
    width: 100% !important;
  }
  .settingFieldBox {
    flex-wrap: wrap !important;
    margin: 0px !important;
  }
  .deleteBtn {
    width: 40% !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/app/screens/Settings/index.css"],"names":[],"mappings":"AAAA;EACE;IACE,qBAAqB;IACrB,wBAAwB;IACxB,mCAAmC;IACnC,sBAAsB;EACxB;EACA;IACE,qBAAqB;IACrB,wBAAwB;IACxB,mCAAmC;IACnC,sBAAsB;EACxB;;EAEA;IACE,sBAAsB;EACxB;EACA;IACE,0BAA0B;IAC1B,sBAAsB;EACxB;EACA;IACE,qBAAqB;EACvB;AACF","sourcesContent":["@media (min-width: 320px) and (max-width: 990px) {\n  .settingsCard {\n    width: 90% !important;\n    padding: 14px !important;\n    margin: 24px 6px 6px 6px !important;\n    height: 68% !important;\n  }\n  .settingsCard2 {\n    width: 90% !important;\n    padding: 14px !important;\n    margin: 24px 6px 6px 6px !important;\n    height: 14% !important;\n  }\n\n  .settingSingleField {\n    width: 100% !important;\n  }\n  .settingFieldBox {\n    flex-wrap: wrap !important;\n    margin: 0px !important;\n  }\n  .deleteBtn {\n    width: 40% !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
