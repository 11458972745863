import React, { useEffect, useState } from "react";
import {
  Grid,
  Box,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography,
} from "@mui/material";
import Colors from "../../constants/Colors";
import CustomInput, { CustomInputWithIcon } from "../../components/Input";
import CustomButton from "../../components/Button";
import { useNavigate, useParams } from "react-router-dom";
import loginbg from "../../../images/bubbles.png";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import "./login.css";
import logo from "../../../images/final logo.png";
import { ApiRequest } from "../../../services/api-request";
import { useAppContext } from "../../../context/AppContext";
import { useSnackbar } from "../../../context/useSnackbar";

import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import ModelInfo from "../../components/modelInformation";
import CustomDialog from "../../components/Dialog/custom-dialog";
import MetaTags from "../../components/ReactMetaTags/meta-tags";
import FacebookLogin from 'react-facebook-login';
// import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'

const schema = yup
  .object({
    email: yup
      .string()
      .required("Mobile Number is required")
      .test("len", "Must be  10-digit mobile number", (val) => {
        if (!val) return true; // Allow empty values
        const isEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(val);
        const isMobileNumber = /^\d{10}$/.test(val);
        return isEmail || isMobileNumber;
      }),
    password: yup
      .string()
      .required("Password is required")
      .min(6, "The password must be of six characters."),
  })
  .required();

const styles = {
  cursor: {
    cursor: "pointer",
  },
};

export const LoginForm = (props) => {
  const [openUserStatusModal, setOpenUserStatusModal] = useState({
    open: false,
    data: {},
  });

  const [userDeniedModal, setUserDeniedModal] = useState({
    data: {},
    open: false,
  });

  const { openSnackbar } = useSnackbar();

  const { handleLoginSuccess } = useAppContext();

  const navigate = useNavigate();

  const { handleSubmit, control } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { email: "", password: "" },
  });

  useEffect(() => {
    window.fbAsyncInit = function() {
      window.FB.init({
        appId      : '3176088649351581',
        cookie     : true,
        xfbml      : true,
        version    : 'v20.0'
      });
      window.FB.AppEvents.logPageView();   
    };

    (function(d, s, id){
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {return;}
      js = d.createElement(s); js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
  }, []);

  const handleFBLogin1 = () => {
    window.FB.login(response => {
      if (response.authResponse) {
        window.FB.api('/me', { fields: 'name,email,picture' }, userData => {
          console.log(userData, "UUUUUSSSSSSEEEEEEERRRRRRRR")
        })
      } else {
        console.log('User cancelled login or did not fully authorize.');
      }
    }, { scope: 'public_profile,email' });
  };

  const checkLoginState = () => {
    window.FB.getLoginStatus(function(response) {
      statusChangeCallback(response);
    });
  }

  const statusChangeCallback = (response) => {
    if (response.status === 'connected') {
      console.log('Logged in and authenticated', response);
      fetchUserProfile();
    } else {
      console.log('Not authenticated');
    }
  }

  const fetchUserProfile = () => {
    window.FB.api('/me', function(response) {
      console.log('Successful login for: ' + response.name);
    });
  }

  const handleFBLogin = () => {
    window.FB.login(checkLoginState(), { scope: 'public_profile,email' });
  }

  const onSubmit = (data) => {
    const payload = {
      password: data.password,
      mobile: data.email,
    };
    setProfile(payload);
    ApiRequest({
      url: `user/login`,
      data: payload,
      method: "POST",
    })
      .then((res) => {
        if (res?.status === "pending") {
          setOpenUserStatusModal({ open: true, data: res });
        } else if (res?.status === "in-review") {
          openSnackbar(
            "Your account is under review please contact thrillh support team",
            "error"
          );
        } else if (res?.status === "deleted") {
          openSnackbar(
            "Your account is Deleted, Please Contact for any Queries",
            "error"
          );
        } else if (props.openFrom === "offerDetailsPage") {
          props.onClose();
          localStorage.setItem("user", JSON.stringify(res));
          handleLoginSuccess(res);
          window.location.reload();
        } else if (res?.status === "in-active") {
          openSnackbar(
            "Your account is In-Active, Please Contact Thrillh support team.",
            "error"
          );
        } else {
          localStorage.setItem("user", JSON.stringify(res));
          handleLoginSuccess(res);
          if (res) {
            if (res?.role?.code === "admin") {
              navigate("/admin/dashboard");
              window.location.reload();
            } else if (
              res?.role?.code === "student" ||
              res?.role?.code === "corporate"
            ) {
              navigate(`/`);
              window.location.reload();
              if (res?.status === "active") {
                openSnackbar(`${"Success! Logged in with Thrillh"}`, "success");
              }
            }
          }
        }
      })
      .catch((err) => {
        if (err?.response?.data?.message) {
          openSnackbar(`${err?.response?.data?.message}`, "error");
        } else {
          openSnackbar(`${err?.response?.data?.error?.description}`, "error");
        }
        if (err?.response?.data?.error?.data?.status === "denied") {
          setUserDeniedModal({
            data: err?.response?.data?.error?.data,
            open: true,
          });
        }
      });
  };

  const { referralCode } = useParams();

  const onClickRegister = () => {
    if (referralCode) {
      const code = referralCode && referralCode.split("=")[1];
      navigate(`/register/${code}`);
    } else {
      navigate("/register");
    }
  };

  const handleLoginOtp = () => {
    navigate("/login-with-otp");
  };

  const [user, setUser] = useState([]);

  const [profile, setProfile] = useState([]);

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      setUser(codeResponse);
    },
    onError: (error) => console.log("Login Failed:", error),
  });

  useEffect(() => {
    if (user?.access_token) {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          setProfile(res.data);
          const payload = {
            gid: res?.data?.id,
          };
          ApiRequest({
            url: `user/google-login`,
            method: "POST",
            data: payload,
          })
            .then((res) => {
              if (res?.status === "pending") {
                setOpenUserStatusModal({ open: true, data: res });
              } else if (res?.status === "in-review") {
                openSnackbar("Your account is under review", "error");
              } else if (res?.status === "deleted") {
                openSnackbar(
                  "Your account is Deleted, Please Contact for any Queries",
                  "error"
                );
              } else if (props.openFrom === "offerDetailsPage") {
                props.onClose();
                localStorage.setItem("user", JSON.stringify(res));
                localStorage.setItem("loginStatus", JSON.stringify(res));
                handleLoginSuccess(res);
              } else {
                localStorage.setItem("user", JSON.stringify(res));
                localStorage.setItem("loginStatus", JSON.stringify(res));
                handleLoginSuccess(res);
                if (res) {
                  if (res?.role?.code === "admin") {
                    navigate("/admin/dashboard");
                  } else if (
                    res?.role?.code === "student" ||
                    res?.role?.code === "corporate"
                  ) {
                    navigate(`/`);
                  } else {
                  }
                }
              }
            })
            .catch((err) => {
              openSnackbar(err?.response?.data?.message, "error");
            });
        })
        .catch((err) => {
          setProfile({});
          console.log(err);
        });
    }
  }, [user]);

  const redirectTo = () => {
    if (openUserStatusModal.data?.role?.code === "student") {
      navigate(`/register/student/${openUserStatusModal?.data?.id}/form`);
    } else {
      navigate(`/register/corporate/${openUserStatusModal?.data?.id}/form`);
    }
  };

  const redirectToDetailsPage = () => {
    if (userDeniedModal?.data?.role?.code === "student") {
      navigate(`/register/student/${userDeniedModal?.data?._id}/form`);
    } else {
      navigate(`/register/corporate/${userDeniedModal?.data?._id}/form`);
    }
  };

  const responseFacebook = (response) => {
    if (response?.status === "unknown") {
      console.error('Sorry!', 'Something went wrong with facebook Login.');
    return;
    }
    console.log(response);
  }

  return (
    <>
      <CustomDialog
        width={"400px"}
        open={openUserStatusModal.open}
        title={""}
        onClose={() => setOpenUserStatusModal({ open: false })}
      >
        <ModelInfo
          type={"login"}
          title={"Continue"}
          color={"#CB0000"}
          message={"Error"}
          handleClick={redirectTo}
        />
      </CustomDialog>

      <CustomDialog
        width={"400px"}
        open={userDeniedModal.open}
        title={""}
        onClose={() => setUserDeniedModal({ open: false })}
      >
        <ModelInfo
          type={"denied"}
          title={"Continue"}
          color={"#CB0000"}
          handleClick={redirectToDetailsPage}
        />
      </CustomDialog>

      <Box>
        <h2 className="heading">Login</h2>
        <h6 className="subHeading">
          Fill out your details and log in to access your specialized offers.
        </h6>
        <Box flexDirection={'row'} display={'flex'} justifyContent={'space-between'}>
          <Box
            style={styles.cursor}
            display={"flex"}
            gap={"10px"}
            justifyContent={"center"}
            alignItems={"center"}
            borderRadius={"20px"}
            borderColor={"#000000"}
            padding={"10px"}
            border={"1px solid"}
          >
            <img src={require("../../../images/google.png")} alt="google" width={"18px"} />
            <Typography
              fontFamily={"MetropolisRegular"}
              color={"#013047"}
              fontSize={"12px"}
              onClick={() => login()}
            >
              Continue with Google
            </Typography>
          </Box>

          {/* <Box
            style={styles.cursor}
            display={"flex"}
            gap={"10px"}
            justifyContent={"center"}
            alignItems={"center"}
            borderRadius={"20px"}
            borderColor={"#000000"}
            padding={"10px"}
            border={"1px solid"}
          >
            <img src={require("../../../images/google.png")} alt="google" width={"18px"} />
            <Typography
              fontFamily={"MetropolisRegular"}
              color={"#013047"}
              fontSize={"12px"}
              onClick={() => handleFBLogin1()}
            >
              Continue with Facebook
            </Typography>
          </Box> */}

          <Box style={{borderRadius: '15px', fontSize: '10px'}}>
            <FacebookLogin
              appId="3176088649351581"
              autoLoad={false}
              fields="name,email,picture"
              callback={responseFacebook}
              icon="fa-facebook"
              buttonStyle={{borderRadius: '15px', fontSize: '10px'}}
            />
          </Box>
        </Box>

        <Box mt={3} mb={3} textAlign={"center"}>
          <p className="emailLogin">
            <span className="borderLine">or login in with Mobile</span>
          </p>
        </Box>

        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <Box>
            <Controller
              name="email"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <CustomInput
                  {...field}
                  label={"Mobile Number"}
                  type={"text"}
                  placeHolder={"Mobile Number"}
                  error={!!error}
                  helperText={error ? error.message : ""}
                />
              )}
            />
          </Box>
          <Box mt={3}>
            <Controller
              name="password"
              type="password"
              defaultValue={""}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <CustomInputWithIcon
                  label={"Password"}
                  type={"password"}
                  {...field}
                  placeHolder={"Enter Password"}
                  error={!!error}
                  helperText={error ? error.message : ""}
                />
              )}
            />
          </Box>
          {props.openFrom === "offerDetailsPage" ? null : (

            <Box mt={2}>

              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}>

                <Box>
                  <FormGroup>
                    <FormControlLabel
                      value="end"
                      control={
                        <Checkbox
                          sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                        />
                      }
                      label={
                        <Typography
                          style={styles.cursor}
                          fontFamily={"MetropolisRegular"}
                          color={"#000000"}
                          fontSize={"12px"}
                        >
                          Remember me
                        </Typography>
                      }
                      labelPlacement="end"
                    />
                  </FormGroup>
                </Box>

                <Box
                  style={styles.cursor}
                  onClick={() => navigate("/forgot-password")}
                >
                  <Typography
                    fontFamily={"MetropolisRegular"}
                    color={"#000000"}
                    fontSize={"12px"}
                  >
                    Forgot Password ?
                  </Typography>
                </Box>

              </Box>

            </Box>
            
          )}

          <Box mt={2}>
            <CustomButton
              title={"Login"}
              borderButton={false}
              type={"submit"}
            />
          </Box>

        </form>
        {props.openFrom === "offerDetailsPage" ? null : (
          <>
            <Box mt={2}>
              <CustomButton
                title={"Login with Mobile Number OTP"}
                borderButton={true}
                handleClick={handleLoginOtp}
              />
            </Box>
          </>
        )}
        <Box mt={2}>
          <CustomButton
            title={"Register"}
            borderButton={true}
            handleClick={onClickRegister}
          />
        </Box>
        {props.openFrom === "offerDetailsPage" ? null : (
          <>
            <Box
              mt={4}
              onClick={() => window.open("/")}
              sx={{ cursor: "pointer" }}
            >
              <h6 className="skipText">Skip for now</h6>
            </Box>
            <Box onClick={() => navigate("/trouble-shoot")}>
              <Typography
                fontFamily={"MetropolisSemiBold"}
                color={"#FA8842"}
                fontSize={"12px"}
                sx={{ cursor: "pointer" }}
                textAlign={"right"}
                variant="h6"
              >
                Having Trouble Login ?
              </Typography>
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

const Login = () => {
  return (
    <>
      <MetaTags
        title={"Thrillh Login: Unlock Exclusive Offers, Discounts, & Coupons"}
        metaTitle={
          "Thrillh Login: Unlock Exclusive Offers, Discounts, & Coupons"
        }
        description={
          "Log in to Thrillh's user portal for students and corporates to access a world of exclusive offers, discounts, & coupon codes. Elevate your experience & enjoy."
        }
      />
      <Box height={"100vh"} bgcolor={Colors.white}>
        <Grid container>
          <Grid item md={6} width={"100%"}>
            <Box m={5}>
              <Box className="thrillhLogo">
                <img src={logo} alt={"Logo Image"} width={"150px"} />
              </Box>
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Box width={500} className={"loginBox"} marginTop={"40px"}>
                  <LoginForm />
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item md={6} className="loginRightSec">
            <Box position={"fixed"} width={"100%"}>
              <Box
                position={"relative"}
                borderRadius={"20px 0px 0px 20px"}
                style={{
                  width: "50%",
                  height: "100vh",
                  backgroundColor: "#c5c5c5",
                }}
              >
                <Box
                  style={{
                    backgroundImage: `url(${loginbg})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    width: "100%",
                    height: "100vh",
                    backgroundPosition: "center",
                  }}
                  position={"absolute"}
                  top={"0"}
                  zIndex={"1"}
                ></Box>
                <Box position={"absolute"} zIndex={"0"} top={"0"} right={"0"}>
                  <img
                    src={require("../../../images/topShape.png")}
                    alt={"Auth Bg"}
                    width={"300px"}
                  />
                </Box>
                <Box position={"absolute"} zIndex={"0"} bottom={"0"} left={"0"}>
                  <img
                    src={require("../../../images/bottomShape.png")}
                    alt={"Auth Bg"}
                    width={"300px"}
                  />
                </Box>

                <Box
                  position={"absolute"}
                  top={"0"}
                  width={"100%"}
                  height={"100%"}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <img
                    src={require("../../../images/userAuthenication/login.png")}
                    alt={"Auth Bg"}
                    width={"640px"}
                  />
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Login;
