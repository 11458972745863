import React from "react";
import { Grid, Box } from "@mui/material";
import Colors from "../../constants/Colors";
import CustomInput from "../../components/Input";
import CustomButton from "../../components/Button";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import loginbg from "../../../images/bubbles.png";
import logo from "../../../images/final logo.png";
import { ApiRequest } from "../../../services/api-request";
import { useSnackbar } from "../../../context/useSnackbar";
import CustomSelect from "../../components/Select/CustomSelect";
import { Trouble_Shoot_Issues } from "../../utilities/constants";

const TroubleShootForm = () => {
  const validateSchema = yup
    .object({
      mobile: yup.string().required("Mobile is required"),
      name: yup.string().required("Name is required"),
      email: yup.string().required("Email is required"),
      description: yup.string().required("Description is required"),
      type: yup.string().required("Issue Type is required"),
    })
    .required();

  const {
    handleSubmit,
    control,
  } = useForm({
    resolver: yupResolver(validateSchema),
  });

  const navigate = useNavigate();

  const { openSnackbar } = useSnackbar();

  const onSubmit = (data) => {
    const payload = {
      ...data,
    };
    ApiRequest({
      url: `user/issue/submit`,
      data: payload,
      method: "POST",
    })
      .then((res) => {
        if (res?.success === true) {
          openSnackbar(
            res?.message === "Issue submitted successfully"
              ? "Thankyou! Your message has been sent. We'll reach you soon."
              : res?.message,
            "success"
          );
          navigate("/login");
        }
      })
      .catch((err) => {
        if (err) {
          openSnackbar(err?.response?.data?.message, "error");
        }
      });
  };

  return (
    <>
      <Box>
        <h2 style={styles.heading}>Having trouble while login/Register?</h2>
        <p style={styles.subHeading}>
          Don’t worry, We got you! Kindly fill in your details below and our
          team will contact you soon.
        </p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box>
            <Box mt={4}>
              <Box>
                <Controller
                  name="name"
                  control={control}
                  defaultValue={""}
                  render={({ field, fieldState: { error } }) => (
                    <CustomInput
                      label={"Name"}
                      type={"text"}
                      placeHolder={"Enter Name"}
                      {...field}
                      error={!!error}
                      helperText={error ? error.message : ""}
                    />
                  )}
                />
              </Box>
              <Box>
                <Controller
                  name="email"
                  control={control}
                  defaultValue={""}
                  render={({ field, fieldState: { error } }) => (
                    <CustomInput
                      label={"Email"}
                      type={"text"}
                      placeHolder={"Enter Email"}
                      {...field}
                      error={!!error}
                      helperText={error ? error.message : ""}
                    />
                  )}
                />
              </Box>
              <Box>
                <Controller
                  name="mobile"
                  control={control}
                  defaultValue={""}
                  render={({ field, fieldState: { error } }) => (
                    <CustomInput
                      label={"Mobile Number"}
                      type={"text"}
                      placeHolder={"Enter Contact Number"}
                      {...field}
                      error={!!error}
                      helperText={error ? error.message : ""}
                    />
                  )}
                />
              </Box>
            </Box>
            <Box mt={3}>
              <Controller
                name="type"
                control={control}
                defaultValue=""
                render={({ field: { ...field }, fieldState: { error } }) => (
                  <CustomSelect
                    {...field}
                    labelText={"Select Issue"}
                    borderRadius={"25px"}
                    error={!!error}
                    helperText={error ? error.message : ""}
                    placeHolder={"Select Issue Type"}
                    options={Trouble_Shoot_Issues}
                  />
                )}
              />
            </Box>
            <Box mt={3}>
              <Controller
                defaultValue={""}
                name="description"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <CustomInput
                    label={"Description"}
                    type={"text"}
                    placeHolder={"Enter Description"}
                    {...field}
                    error={!!error}
                    helperText={error ? error.message : ""}
                  />
                )}
              />
            </Box>
            <Box mt={2}>
              <CustomButton
                title={"Submit"}
                borderButton={false}
                type={"submit"}
              />
            </Box>
            <Box mt={2}>
              <CustomButton
                title={"Cancel"}
                borderButton={true}
                handleClick={() => navigate("/")}
              />
            </Box>
          </Box>
        </form>
      </Box>
    </>
  );
};

const HavingTroubleLogin = () => {
  return (
    <>
      <Box height={"100vh"} bgcolor={Colors.white}>
        <Grid container>
          <Grid item md={6} width={"100%"}>
            <Box m={5}>
              <img src={logo} alt={"Logo Image"} width={179} />
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Box width={500}>
                  <TroubleShootForm />
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item md={6}>
            <Box position={"fixed"} width={"100%"}>
              <Box
                position={"relative"}
                borderRadius={"20px 0px 0px 20px"}
                style={{
                  width: "50%",
                  height: "100vh",
                  backgroundColor: "#c5c5c5",
                }}
              >
                <Box
                  style={{
                    backgroundImage: `url(${loginbg})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    width: "100%",
                    height: "100vh",
                    backgroundPosition: "center",
                  }}
                  position={"absolute"}
                  top={"0"}
                  zIndex={"1"}
                ></Box>
                <Box position={"absolute"} zIndex={"0"} top={"0"} right={"0"}>
                  <img
                    src={require("../../../images/topShape.png")}
                    alt={"Auth Bg"}
                    width={"300px"}
                  />
                </Box>
                <Box position={"absolute"} zIndex={"0"} bottom={"0"} left={"0"}>
                  <img
                    src={require("../../../images/bottomShape.png")}
                    alt={"Auth Bg"}
                    width={"300px"}
                  />
                </Box>
                <Box
                  position={"absolute"}
                  top={"0"}
                  width={"100%"}
                  height={"100%"}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <img
                    src={require("../../../images/havingTrouble.png")}
                    alt={"Auth Bg"}
                    width={"80%"}
                  />
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

const styles = {
  authBg: {
    height: "100vh",
  },
  innerAuthBg: {
    width: 800,
    height: 800,
  },
  heading: {
    fontFamily: "MetropolisExtraBold",
    color: Colors.textColor,
  },
  subHeading: {
    fontFamily: "MetropolisLight",
    color: Colors.textColor,
    fontSize: 13,
    fontWeight: "600",
  },
  googleText: {
    fontFamily: "MetropolisRegular",
    color: Colors.textColor,
    fontSize: 13,
  },
  checkboxText: {
    fontFamily: "MetropolisRegular",
    color: Colors.textColor,
    fontSize: 13,
  },
  skipText: {
    fontFamily: "MetropolisSemiBold",
    color: Colors.lightBlue,
    fontSize: 13,
    textAlign: "center",
  },
  radioText: {
    fontFamily: "MetropolisSemiBold",
    fontSize: 13,
    color: Colors.textColor,
  },
  photoText: {
    fontFamily: "MetropolisSemiBold",
    fontSize: 13,
    color: Colors.textColor,
  },
  photoSubDesc: {
    fontFamily: "MetropolisRegular",
    fontSize: 12,
    color: Colors.lightBlue,
  },
};

export default HavingTroubleLogin;
