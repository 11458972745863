import React, { useState } from "react";
import { Box } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ApiRequest } from "../../../services/api-request";
import { useSnackbar } from "../../../context/useSnackbar";
import CustomButton from "../../components/Button";
import CustomInput from "../../components/Input";
import { useAppContext } from "../../../context/AppContext";

const ApplyJobForm = (props) => {
  const { jobID } = props;

  const schema = yup
    .object({
      fullName: yup.string().required("Full Name is required"),
      mobile: yup.string().required("Mobile  is required"),
      yearsOfExperience: yup
        .string()
        .required("Years Of Experience  is required"),
      email: yup.string().required("email is required"),
    })
    .required();

  const { openSnackbar } = useSnackbar();

  const { user } = useAppContext();

  const { handleSubmit, control, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      yearsOfExperience: "",
      fullName: user?.name ? user?.name : "",
      mobile: user?.mobile ? user?.mobile : "",
      email: user?.personalEmail ? user?.personalEmail : "",
    },
  });

  const [isLogged, setIsLogged] = useState(null);

  React.useEffect(() => {
    const userString = localStorage.getItem("user");
    const user = JSON.parse(userString);

    setIsLogged(!!user?.accessToken);
  }, []);

  React.useEffect(() => {
    if (isLogged === true) {
      getUserDetails(user?.id);
    }
  }, [user?.id, isLogged]);

  const getUserDetails = () => {
    ApiRequest({
      url: `user/findBy/${user?.id}`,
      method: "GET",
    })
      .then((res) => {
        if (res) {
          reset({
            fullName: res?.name,
            mobile: res?.mobile,
            email: res?.personalEmail,
            yearsOfExperience: "",
          });
        } else {
          reset({
            fullName: "",
            mobile: "",
            email: "",
            yearsOfExperience: "",
          });
        }
      })
      .catch((err) => {});
  };

  const onSubmit = (data) => {
    const payload = {
      ...data,
      jobId: jobID.jobId,
    };
    ApiRequest({
      url: `career/job/apply`,
      data: payload,
      method: "POST",
    })
      .then((res) => {
        openSnackbar(res.message, "success");
        props.onClose();
        props.getAllCareererList();
      })
      .catch((err) => {});
  };

  return (
    <>
      <Box width={"100%"}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            display={"flex"}
            alignItems={"center"}
            gap={"10px"}
            width={"100%"}
          >
            <Box mt={2} width={"100%"}>
              <Controller
                name="fullName"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <CustomInput
                    {...field}
                    type={"text"}
                    error={!!error}
                    helperText={error ? error.message : ""}
                    inputLabel={"Enter Full Name"}
                    borderRadius={"5px"}
                  />
                )}
              />
            </Box>
          </Box>
          <Box
            display={"flex"}
            alignItems={"center"}
            gap={"10px"}
            width={"100%"}
          >
            <Box mt={2} width={"100%"}>
              <Controller
                name="mobile"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <CustomInput
                    {...field}
                    error={!!error}
                    helperText={error ? error.message : ""}
                    inputLabel={"Enter Mobile Number"}
                    type={"number"}
                    borderRadius={"5px"}
                  />
                )}
              />
            </Box>
          </Box>
          <Box display={"flex"} alignItems={"center"} gap={"10px"}>
            <Box mt={2} width={"100%"}>
              <Controller
                name="email"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <CustomInput
                    inputLabel={"Enter Email"}
                    type={"text"}
                    borderRadius={"5px"}
                    {...field}
                    error={!!error}
                    helperText={error ? error.message : ""}
                  />
                )}
              />
            </Box>
          </Box>
          <Box display={"flex"} alignItems={"center"} gap={"10px"}>
            <Box mt={2} width={"100%"}>
              <Controller
                name="yearsOfExperience"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <CustomInput
                    inputLabel={"Enter Years of Experience"}
                    type={"text"}
                    borderRadius={"5px"}
                    {...field}
                    error={!!error}
                    helperText={error ? error.message : ""}
                  />
                )}
              />
            </Box>
          </Box>
          <Box display={"flex"} justifyContent={"end"} gap={"10px"} mt={"20px"}>
            <Box width={"20%"}>
              <CustomButton
                border={"1px solid #000000"}
                color={"#000000"}
                title={"Cancel"}
                bgColor={"#F7F7F7"}
                handleClick={() => props.onClose()}
                borderRadius={"5px"}
              />
            </Box>
            <Box width={"20%"}>
              <CustomButton
                title={"Save"}
                type={"submit"}
                borderRadius={"5px"}
              />
            </Box>
          </Box>
        </form>
      </Box>
    </>
  );
};

export default ApplyJobForm;
