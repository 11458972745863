import React, { useEffect, useState } from "react";
import {
  Grid,
  Box,
  FormControlLabel,
  RadioGroup,
  FormControl,
  Radio,
  Typography,
} from "@mui/material";

import Colors from "../../constants/Colors";
import CustomInput, { CustomInputWithIcon } from "../../components/Input";
import { useLocation, useNavigate } from "react-router-dom";
import CustomButton from "../../components/Button";
import loginbg from "../../../images/bubbles.png";
import logo from "../../../images/final logo.png";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import "./register.css";
import { ApiRequest } from "../../../services/api-request";
import Otp from "../Otp";
import { useSnackbar } from "../../../context/useSnackbar";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import GoogleOTPForm from "./google-otpForm";
import MetaTags from "../../components/ReactMetaTags/meta-tags";

const schema = yup
  .object({
    mobile: yup
      .string()
      .required("Mobile Number is required")
      .test(
        "len",
        "Mobile Number must be exactly 10 digits",
        (val) => val && val.length === 10
      ),
    password: yup
      .string()
      .required("Password is required")
      .min(6, "Password must be of six characters.")
      .max(15, "Password should not be more than 15 characters."),
    userRole: yup
      .string()
      .required("Either Student or Corporate Selection is required"),
  })
  .required();

const GoogleRegistrationForm = (props) => {
  const search = useLocation().search;
  const referralCode = new URLSearchParams(search).get("referralCode");

  const { openSnackbar } = useSnackbar();
  const googleSchema = yup.object({
    mobile: yup.string().required("Mobile is required"),
    userRole: yup
      .string()
      .required("Either Student or Corporate Selection is required"),
    profileName: yup.string().required("Name is required"),
    email: yup
      .string()
      .email("Invalid email format")
      .required("Email is required"),
  });
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(googleSchema),
    defaultValues: { referralCode: referralCode },
  });

  const onSubmit = (data) => {
    const payload = {
      ctx: "REGISTRATION",
      mobile: data.mobile,
      payload: {
        ...data,
      },
    };
    ApiRequest({
      url: `user/send-otp`,
      data: payload,
      method: "POST",
    })
      .then((res) => {
        props.setGoogleRegisterResponse(res);
        openSnackbar(
          res?.message,
          `${res?.success === true ? "success" : "error"}`
        );
      })
      .catch((err) => {
        openSnackbar(err?.message, "error");
      });
  };

  const navigate = useNavigate();

  return (
    <>
      <Box mt={3} mb={3} textAlign={"center"}>
        <p className="emailLogin">
          <span className="borderLine">or Register with Mobile</span>
        </p>
      </Box>
      <p style={styles.radioText}> Select User</p>

      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <Box>
          <FormControl sx={{ width: "100%" }} component={"fieldset"}>
            <Controller
              rules={{ required: true }}
              control={control}
              name="userRole"
              defaultValue={""}
              render={({ field }) => (
                <RadioGroup
                  {...field}
                  sx={{
                    justifyContent: "space-between",
                    display: "flex",
                    padding: "0px",
                  }}
                  row
                >
                  <Box
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <FormControlLabel
                      sx={{ margin: "0px" }}
                      value="student"
                      control={<Radio />}
                    />
                    <Box mt={"3px"}>
                      <Typography
                        fontWeight={"600"}
                        color={"#000000"}
                        fontSize={"12px"}
                        fontFamily={"MetropolisRegular"}
                      >
                        Student
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <FormControlLabel
                      sx={{ margin: "0px" }}
                      value="corporate"
                      control={<Radio />}
                    />
                    <Box mt={"3px"}>
                      <Typography
                        fontWeight={"600"}
                        color={"#000000"}
                        fontSize={"12px"}
                        fontFamily={"MetropolisRegular"}
                      >
                        Corporate
                      </Typography>
                    </Box>
                  </Box>
                </RadioGroup>
              )}
            />
            {errors.userRole && (
              <Typography fontSize={"12px"} color={"red"}>
                {errors.userRole.message}
              </Typography>
            )}
          </FormControl>
        </Box>
        <Box mt={2}>
          <Controller
            defaultValue={props.profile?.name}
            name="profileName"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <CustomInput
                {...field}
                label={"Name"}
                type={"text"}
                placeHolder={"Enter Name"}
                error={!!error}
                helperText={error ? error.message : ""}
              />
            )}
          />
        </Box>
        <Box mt={2}>
          <Controller
            defaultValue={props.profile?.email}
            name="email"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <CustomInput
                label={"Email"}
                type={"text"}
                placeHolder={"Enter Email"}
                {...field}
                error={!!error}
                helperText={error ? error.message : ""}
              />
            )}
          />
        </Box>
        <Box mt={2}>
          <Controller
            name="mobile"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <CustomInput
                label={"Mobile"}
                type={"text"}
                placeHolder={"Enter mobile"}
                {...field}
                error={!!error}
                helperText={error ? error.message : ""}
              />
            )}
          />
        </Box>
        <Box mt={2}>
          <Controller
            name="referralCode"
            defaultValue={referralCode}
            control={control}
            render={({ field, fieldState: { error } }) => (
              <CustomInput
                {...field}
                defaultValue={referralCode}
                label={"Referral Code"}
                type={"text"}
                placeHolder={"Enter Referral Code"}
                error={!!error}
                helperText={error ? error.message : ""}
              />
            )}
          />
        </Box>
        <Box mt={2}>
          <CustomButton
            type={"submit"}
            title={"Register"}
            borderButton={false}
          />
        </Box>
        <Box mt={2}>
          <CustomButton
            title={"Back to Login"}
            borderButton={true}
            handleClick={() => navigate("/login")}
          />
        </Box>
      </form>
    </>
  );
};

const RegisterForm = (props) => {
  const search = useLocation().search;
  const referralCode = new URLSearchParams(search).get("referralCode");

  const [profile, setProfile] = useState({});

  const [googleRegisterResponse, setGoogleRegisterResponse] = useState({});

  const navigate = useNavigate();

  const { openSnackbar } = useSnackbar();

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { referralCode: referralCode },
  });

  const onSubmit = (data) => {
    props.onSubmitRegister(data);
  };

  const [user, setUser] = useState([]);

  const registerWithGoogle = useGoogleLogin({
    onSuccess: (codeResponse) => {
      setUser(codeResponse);
    },
    onError: (error) => console.log("Login Failed:", error),
  });

  useEffect(() => {
    if (user?.access_token) {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          setProfile(res.data);
          openSnackbar(
            "Success!, Please fill the below details to continue..",
            "success"
          );
        })
        .catch((err) => {
          setProfile({});
          console.log(err);
        });
    }
  }, [user]);

  return (
    <Box>
      {googleRegisterResponse?.payload?.email ? null : (
        <>
          <Box>
            <h2 style={styles.heading}>Register</h2>
            <h6 style={styles.subHeading}>
              Register now to take full advantage of our website/app!
            </h6>
          </Box>
          {/* <Box
            sx={{ cursor: "pointer" }}
            display={"flex"}
            gap={"10px"}
            justifyContent={"center"}
            alignItems={"center"}
            borderRadius={"20px"}
            borderColor={"#000000"}
            padding={"10px"}
            border={"1px solid"}
            onClick={() => registerWithGoogle()}
          >
            <img src={require("../../../images/google.png")} alt="google" width={"18px"} />
            <Typography
              fontFamily={"MetropolisRegular"}
              color={"#013047"}
              fontSize={"12px"}
            >
              Register with Google
            </Typography>
          </Box> */}
        </>
      )}

      {profile?.email ? (
        <>
          {googleRegisterResponse?.payload?.email ? (
            <GoogleOTPForm
              googleRegisterResponseDetails={googleRegisterResponse}
              profile={profile}
              requestOTP={onSubmit}
              setGoogleRegisterResponse={setGoogleRegisterResponse}
            />
          ) : (
            <GoogleRegistrationForm
              profile={profile}
              setGoogleRegisterResponse={setGoogleRegisterResponse}
            />
          )}
        </>
      ) : (
        <Box>
          <Box mt={3} mb={3} textAlign={"center"}>
            <p className="emailLogin">
              <span className="borderLine">or Register with Mobile</span>
            </p>
          </Box>
          <p style={styles.radioText}> Select User</p>

          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <Box>
              <FormControl sx={{ width: "100%" }} component={"fieldset"}>
                <Controller
                  rules={{ required: true }}
                  control={control}
                  name="userRole"
                  defaultValue={""}
                  render={({ field }) => (
                    <RadioGroup
                      {...field}
                      sx={{
                        justifyContent: "space-between",
                        display: "flex",
                        padding: "0px",
                      }}
                      row
                    >
                      <Box
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                      >
                        <FormControlLabel
                          sx={{ margin: "0px" }}
                          value="student"
                          control={<Radio />}
                        />
                        <Box mt={"3px"}>
                          <Typography
                            fontWeight={"600"}
                            color={"#000000"}
                            fontSize={"12px"}
                            fontFamily={"MetropolisRegular"}
                          >
                            Student
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                      >
                        <FormControlLabel
                          sx={{ margin: "0px" }}
                          value="corporate"
                          control={<Radio />}
                        />
                        <Box mt={"3px"}>
                          <Typography
                            fontWeight={"600"}
                            color={"#000000"}
                            fontSize={"12px"}
                            fontFamily={"MetropolisRegular"}
                          >
                            Corporate
                          </Typography>
                        </Box>
                      </Box>
                    </RadioGroup>
                  )}
                />
                {errors.userRole && (
                  <Typography fontSize={"12px"} color={"red"}>
                    {errors.userRole.message}
                  </Typography>
                )}
              </FormControl>
            </Box>

            <Box mt={2}>
              <Controller
                name="mobile"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <CustomInput
                    label={"Mobile"}
                    type={"text"}
                    placeHolder={"Enter mobile number"}
                    {...field}
                    error={!!error}
                    helperText={error ? error.message : ""}
                  />
                )}
              />
            </Box>

            <Box mt={3}>
              <Controller
                defaultValue={""}
                name="password"
                type="password"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <CustomInputWithIcon
                    label={"Password"}
                    type="password"
                    {...field}
                    placeHolder={"Create password"}
                    error={!!error}
                    helperText={error ? error.message : ""}
                  />
                )}
              />
            </Box>
            <Box mt={2}>
              <Controller
                name="referralCode"
                defaultValue={referralCode}
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <CustomInput
                    {...field}
                    defaultValue={referralCode}
                    label={"Referral Code"}
                    type={"text"}
                    placeHolder={"Enter Referral Code"}
                    error={!!error}
                    helperText={error ? error.message : ""}
                  />
                )}
              />
            </Box>
            <Box mt={2}>
              <CustomButton
                type={"submit"}
                title={"Register"}
                borderButton={false}
              />
            </Box>
            <Box mt={2}>
              <CustomButton
                title={"Back to Login"}
                borderButton={true}
                handleClick={() => navigate("/login")}
              />
            </Box>
          </form>
        </Box>
      )}

      <Box mt={4} onClick={() => window.open("/")} sx={{ cursor: "pointer" }}>
        <p style={styles.skipText}>Skip for now</p>
      </Box>
      <Box onClick={() => navigate("/trouble-shoot")}>
        <Typography
          fontFamily={"MetropolisSemiBold"}
          color={"#FA8842"}
          fontSize={"12px"}
          sx={{ cursor: "pointer" }}
          textAlign={"right"}
          variant="h6"
        >
          Having Trouble Login ?
        </Typography>
      </Box>
    </Box>
  );
};

const Register = (props) => {
  const [value, setValue] = React.useState("female");

  const [registrationResponse, setRegistrationResponse] = useState({});

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const { openSnackbar } = useSnackbar();

  const onSubmitForm = (data) => {
    const payload = {
      ctx: "REGISTRATION",
      mobile: data.mobile,
      payload: {
        ...data,
      },
    };
    ApiRequest({
      url: `user/send-otp`,
      data: payload,
      method: "POST",
    })
      .then((res) => {
        if (res?.success === true) {
          openSnackbar(
            "Success! An OTP has been sent to your registered mobile number.",
            "success"
          );
        } else {
          openSnackbar("Mobile Number Already Registered", "error");
        }
        setRegistrationResponse(res);
      })
      .catch((err) => {
        openSnackbar(err?.response?.data?.message, "error");
      });
  };
  return (
    <>
      <MetaTags
        title={"Thrillh Registration: Register for Thrilling Discounts"}
        metaTitle={"Thrillh Registration: Register for Thrilling Discounts"}
        description={
          "Join ThrillH today! Students and corporates, register on our user-friendly platform to access exciting offers, discounts, and exclusive coupon codes."
        }
      />
      {registrationResponse?.success === true ? (
        <>
          <Otp
            verifyType={"registration"}
            registrationResponse={registrationResponse}
            details={{ mobile: registrationResponse?.payload?.mobile }}
            requestOTP={onSubmitForm}
          />
        </>
      ) : (
        <Box height={"100vh"} bgcolor={Colors.white}>
          <Grid container>
            <Grid item md={6} width={"100%"}>
              <Box m={5}>
                <Box className={"thrillhLogo"}>
                  <img src={logo} alt={"Logo Image"} width={"150px"} />
                </Box>
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Box width={500} marginTop={"40px"} className={"loginBox"}>
                    <RegisterForm
                      setRegistrationResponse={setRegistrationResponse}
                      radioValue={value}
                      setRadioValue={(val) => handleChange(val)}
                      onSubmitRegister={(data) => onSubmitForm(data)}
                    />
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item md={6} className="registerRightSec">
              <Box position={"fixed"} width={"100%"}>
                <Box
                  position={"relative"}
                  borderRadius={"20px 0px 0px 20px"}
                  style={{
                    width: "50%",
                    height: "100vh",
                    backgroundColor: "#c5c5c5",
                  }}
                >
                  <Box
                    style={{
                      backgroundImage: `url(${loginbg})`,
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      width: "100%",
                      height: "100vh",
                      backgroundPosition: "center",
                    }}
                    position={"absolute"}
                    top={"0"}
                    zIndex={"1"}
                  ></Box>
                  <Box position={"absolute"} zIndex={"0"} top={"0"} right={"0"}>
                    <img
                      src={require("../../../images/topShape.png")}
                      alt={"Auth Bg"}
                      width={"300px"}
                    />
                  </Box>
                  <Box
                    position={"absolute"}
                    zIndex={"0"}
                    bottom={"0"}
                    left={"0"}
                  >
                    <img
                      src={require("../../../images/bottomShape.png")}
                      alt={"Auth Bg"}
                      width={"300px"}
                    />
                  </Box>
                  <Box
                    position={"absolute"}
                    top={"0"}
                    width={"100%"}
                    height={"100%"}
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <img
                      src={require("../../../images/userAuthenication/register.png")}
                      alt={"Auth Bg"}
                      width={"640px"}
                      style={{ objectFit: "cover" }}
                    />
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};

const styles = {
  authBg: {
    height: "100vh",
  },
  innerAuthBg: {
    width: 800,
    height: 800,
  },
  heading: {
    fontFamily: "MetropolisExtraBold",
    color: Colors.textColor,
  },
  subHeading: {
    fontFamily: "MetropolisLight",
    color: Colors.textColor,
    fontSize: 13,
    fontWeight: "600",
  },
  googleText: {
    fontFamily: "MetropolisRegular",
    color: Colors.textColor,
    fontSize: 13,
  },
  checkboxText: {
    fontFamily: "MetropolisRegular",
    color: Colors.textColor,
    fontSize: 13,
  },
  skipText: {
    fontFamily: "MetropolisSemiBold",
    color: Colors.lightBlue,
    fontSize: 13,
    textAlign: "center",
  },
  radioText: {
    fontFamily: "MetropolisSemiBold",
    fontSize: 13,
    color: Colors.textColor,
  },
};

export default Register;
