import { combineReducers } from "redux";

import TodoReducer from "./slicers/ToDoSlicer";
import { SOCKET_FEATURE_KEY, socketReducer } from "./slicers/socketSlicer";

const rootReducer = combineReducers({
  todo: TodoReducer,
  [SOCKET_FEATURE_KEY]: socketReducer,
});

export default rootReducer;
