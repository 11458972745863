import { Box, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import eyeSvg from "../../../images/orange eye.svg";
import CustomInput from "../../components/Input";
import CustomSelect from "../../components/Select/CustomSelect";
import CustomButton from "../../components/Button";
import AddEditOffer from "./add-edit-offer";
import CustomDialog from "../../components/Dialog/custom-dialog";
import { ApiRequest } from "../../../services/api-request";
import { useNavigate } from "react-router-dom";
import EditSvg from "../../../images/admin/edit (4).svg";
import CustomToolTip from "../../components/ToolTip";
import { useSnackbar } from "../../../context/useSnackbar";
import Pagination from "../../components/Pagination";
import CustomSpinner from "../../components/Spinner";
import { useAppContext } from "../../../context/AppContext";
import { removeSpacesAndGaps } from "../../utilities/common-utilities";

const OfferList = (props) => {
  const { openSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const { handleLogoutSuccess } = useAppContext();

  const { bgcolor } = props;

  const offerType = [
    {
      name: "Online",
      value: "online",
    },
    {
      name: "In-Store",
      value: "in-store",
    },
    {
      name: "All",
      value: "all",
    },
    {
      name: "Top Offers",
      value: "topOffers",
    },
    {
      name: "Featured Offers",
      value: "featured",
    },
    {
      name: "In-store Payable",
      value: "payable",
    },
    {
      name: "In-store Non-Payable",
      value: "non-payable",
    },

    {
      name: "Thrillh-coupon",
      value: "true",
    },
    {
      name: "Without-ThrillhCoupon",
      value: "false",
    },
  ];

  const OfferStatus = [
    {
      name: "Active",
      value: "active",
    },
    {
      name: "In-Active",
      value: "in-active",
    },
  ];

  const userType = [
    {
      name: "Both",
      value: "ALL",
    },
    {
      name: "Student",
      value: "STUDENT_ONLY",
    },
    {
      name: "Corporate",
      value: "CORPORATE_ONLY",
    },
  ];
  const [offersList, setOffersList] = useState({});
  
  const [offersList1, setOffersList1] = useState([]);

  const [pages, setPages] = useState(1);

  const [showAddOfferModal, setShowAddOfferModal] = useState(false);

  const [currentOffer, setCurrentOffer] = useState({});

  const [categoryList, setCategoryList] = useState([]);

  const [loader, setLoader] = useState(false);

  const [filter, setFilter] = useState({
    search: "",
    type: "",
    userType: "",
    withCoupon: "",
    subType: "",
    category: "",
    status: "",
    topOffers: "",
    featuredOffers: "",
  });



  const limit = 1000;

  const [skip, setSkip] = useState(0);

  useEffect(() => {
    getAllCategories();
  }, []);

  useEffect(() => {
    getAllOffers();
    setPages(1);
  }, [skip, filter]);

  const getAllOffers = () => {
    const searchTextParam = filter.search && filter.search.trim() !== "" ? filter.search : null;
    setLoader(true);
    const payload = {
      expired: true,
      skip: skip,
      limit: limit,
      endsAt: 'NA',
      startsAt: 'NA',
      ...(filter.search && {
        searchText: searchTextParam,
      }),
      ...(filter.status && {
        status: filter.status,
      }),

      ...(filter.userType && {
        userType: filter.userType,
      }),
      ...(filter.subType && {
        subType: filter.subType,
      }),
      ...(filter.category && {
        categories: [filter.category],
      }),
      ...(filter.withCoupon && {
        withCoupon:
          filter.withCoupon === "true"
            ? true
            : filter.withCoupon === "false"
              ? false
              : null,
      }),
      ...(filter.topOffers && {
        topOffers:
          filter.topOffers === "topOffers"
            ? true
            : filter.topOffers === "false"
              ? false
              : null,
      }),
    };
    if (filter.type && filter.type !== "all") {
      payload.type = filter.type;
    }

    const featuredPayload = {
      skip: 0,
      limit: 32,
      status: "active",
      sort_field: "createdAt",
      sort_order: "desc",
      ...(filter.search && {
        searchText: filter.search,
      }),
      ...(filter.status && {
        status: filter.status,
      }),
      ...(filter.userType && {
        userType: filter.userType,
      }),
      ...(filter.subType && {
        subType: filter.subType,
      }),
      ...(filter.category && {
        categories: [filter.category],
      }),
      ...(filter.withCoupon && {
        withCoupon:
          filter.withCoupon === "true"
            ? true
            : filter.withCoupon === "false"
              ? false
              : null,
      }),
      ...(filter.topOffers && {
        topOffers:
          filter.topOffers === "topOffers"
            ? true
            : filter.topOffers === "false"
              ? false
              : null,
      }),
    };

    if (filter.type && filter.type !== "all") {
      featuredPayload.type = filter.type;
    }
    if(payload && payload.type) {
      payload.limit = 1000
    }
    // console.log(featuredPayload, payload, "PPPPPPPPPPPP")
    ApiRequest({
      url: `offer/list`,
      data: filter.featuredOffers ? featuredPayload : payload,
      method: "POST",
    })
      .then((res) => {
        var cnt = 0
        // res.result.map((item, index)=> {
        //   if(item.name == "aaa") {
        //     console.log(item, index, "RTRTRTRTRT")
        //   }
        // })
        var filteredEvents = res.result
        setOffersList1(res.result)
        // filteredEvents = filteredEvents.sort(function(a,b){
        //   return new Date(b.createdAt) - new Date(a.createdAt);
        // });
        var filteredEvents = filteredEvents.slice(pages==1 ? 0 : pages, pages + 150)
        if(payload && payload.type)
        {
          filteredEvents = res.result.filter(function(event){
            return event.type == payload.type;
          });
          // filteredEvents = filteredEvents.sort(function(a,b){
          //   return new Date(b.createdAt) - new Date(a.createdAt);
          // });
          setOffersList1(filteredEvents)
          cnt = filteredEvents.length
          filteredEvents = filteredEvents.slice(pages==1 ? 0 : pages, pages + 150)
        }
        // console.log(filteredEvents.length, "LLLLLLLLLLLLLL")
        // console.log(payload, payload.type, "PPPPPPPPPPPPPPPPP")
        var arr = filteredEvents
        var set = {
          result: arr,
          totalCount: cnt !=0 ? cnt : res.result.length,
          err: res.err
        }
        setOffersList(set);
        setLoader(false);
      })
      .catch((err) => {
        openSnackbar(err?.response?.data?.message, "error");
        setLoader(false);
        if (err?.statusCode === 403 || err?.statusCode === 401) {
          if (navigate) {
            navigate("/login"); // Navigate to "/login" route
            handleLogoutSuccess();
          }
        }
      });
  };

  const closeAddOfferModal = () => {
    setShowAddOfferModal(false);
  };

  const handleSearch = (e) => {
    const searchValue = e.target.value;
    setFilter((prevFilter) => ({
      ...prevFilter,
      search: searchValue.length ? searchValue : null,
    }));
  };

  const getAllCategories = () => {
    ApiRequest({
      url: `category/all?skip=0&limit=100`,
      method: "GET",
    })
      .then((res) => {
        const modifiedCategoryList = res?.map((category) => ({
          name: category?.name,
          value: category?._id,
        }));
        setCategoryList(modifiedCategoryList);
      })
      .catch((err) => {
        openSnackbar(err?.response?.data?.message, "error");
      });
  };

  const onSelectedCategory = (e) => {
    const selectedCategory = e.target.value;
    setFilter((prevFilter) => ({
      ...prevFilter,
      category: selectedCategory,
    }));
  };

  const onSelectedStatus = (e) => {
    const selectedStatus = e.target.value;
    const validTypes = ["ALL", "STUDENT_ONLY", "CORPORATE_ONLY"];

    if (validTypes.includes(selectedStatus)) {
      setFilter((prevFilter) => ({
        ...prevFilter,
        userType: selectedStatus,
      }));
    }
  };

  const onSelectedType = (e) => {
    const selectedType = e.target.value;
    const type = ["online", "in-store", "all"];
    const subType = ["payable", "non-payable"];
    const coupon = ["true", "false"];
    const topOffers = ["topOffers"];
    const featuredOffers = ["featured"];
    if (type.includes(selectedType)) {
      setFilter((prevFilter) => ({
        ...prevFilter,
        type: selectedType === "all" ? "all" : selectedType,
        withCoupon: "",
        subType: "",
        topOffers: "",
        featuredOffers: "",
      }));
    } else if (subType.includes(selectedType)) {
      setFilter((prevFilter) => ({
        ...prevFilter,
        subType: selectedType,
        type: "",
        withCoupon: "",
        topOffers: "",
        featuredOffers: "",
      }));
    } else if (coupon.includes(selectedType)) {
      setFilter((prevFilter) => ({
        ...prevFilter,
        withCoupon: selectedType,
        type: "",
        subType: "",
        topOffers: "",
        featuredOffers: "",
      }));
    } else if (topOffers.includes(selectedType)) {
      setFilter((prevFilter) => ({
        ...prevFilter,
        topOffers: selectedType,
        withCoupon: "",
        type: "",
        subType: "",
        featuredOffers: "",
      }));
    } else if (featuredOffers.includes(selectedType)) {
      setFilter((prevFilter) => ({
        ...prevFilter,
        topOffers: "",
        withCoupon: "",
        type: "",
        subType: "",
        featuredOffers: selectedType,
      }));
    }
  };

  const clearFilters = () => {
    setFilter({
      search: "",
      type: "",
      withCoupon: "",
      subType: "",
      userType: "",
      category: "",
      status: "",
      featuredOffers: "",
      topOffers: "",
    });
    getAllOffers({
      search: "",
      type: "",
      category: "",
      status: "",
      withCoupon: "",
      subType: "",
      userType: "",
      featuredOffers: "",
      topOffers: "",
    });
  };

  const handlePageChange = (page) => {
    setPages(page);
    // console.log(offersList1, page, ((page-1)*150), (page*150), "QQQQQQQQQQQQQQQQ")
    var res = offersList1
    var arr = res.slice(((page-1)*150), (page*150))
    // console.log(res.slice(((page-1)*150), (page*150)));
    var set = {
      result: arr,
      totalCount: offersList.totalCount,
      err: offersList.err
    }
    setOffersList(set);
    // const newSkip = (page - 1) * limit;
    // setSkip(newSkip);
  };

  const onSelectedOfferStatus = (e) => {
    const selectedStatus = e.target.value;
    
    const activeStatus = ["active", "in-active"];
    if (activeStatus.includes(selectedStatus)) {
      setFilter((prevFilter) => ({
        ...prevFilter,
        status: selectedStatus,
      }));
    }
  };

  return (
    <>
      <CustomDialog
        open={showAddOfferModal}
        fullScreen
        title={currentOffer?.open === "add" ? "Add Offer" : "Edit Offer"}
        onClose={closeAddOfferModal}
      >
        <AddEditOffer
          onClose={closeAddOfferModal}
          isEdit={currentOffer?.open === "add" ? false : true}
          currentOffer={currentOffer ?? {}}
          onGetAllOffers={() => getAllOffers(filter)}
        />
      </CustomDialog>

      <Box>
        <Box
          bgcolor={bgcolor ? bgcolor : "#FFEDE3"}
          padding={"20px"}
          borderRadius={"40px 0px 0px 40px"}
        >
          <Box
            display={"flex"}
            width={"100%"}
            justifyContent={"end"}
            alignItems={"right"}
          >
            <Box width={"10%"}>
              <CustomButton
                title={"+ Add Offer"}
                borderRadius={"5px"}
                handleClick={() => {
                  setShowAddOfferModal(true);
                  setCurrentOffer({ open: "add" });
                }}
              />
            </Box>
          </Box>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography
              fontFamily={"MetropolisBold"}
              fontSize={"20px"}
              color={"#000000"}
            >
              Offers
            </Typography>
          </Box>
          <Box bgcolor={"#fff"} padding={"10px"} borderRadius={"10px"} mt={2}>
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"end"}
              width={"100%"}
            >
              <Box width={"25%"}>
                <CustomInput
                  value={filter.search}
                  placeHolder={"Search with name"}
                  borderRadius={"5px"}
                  onChange={handleSearch}
                />
              </Box>
              <Box
                display={"flex"}
                justifyContent={"end"}
                width={"75%"}
                gap={"10px"}
              >
                <Box width={"20%"}>
                  <CustomSelect
                    value={`${filter.status}`}
                    placeHolder={"Offer Status"}
                    options={OfferStatus}
                    borderRadius={"5px"}
                    onChange={(e) => onSelectedOfferStatus(e)}
                  />
                </Box>
                <Box width={"20%"}>
                  <CustomSelect
                    value={`${filter.userType}`}
                    placeHolder={"User Type"}
                    options={userType}
                    borderRadius={"5px"}
                    onChange={(e) => onSelectedStatus(e)}
                  />
                </Box>
                <Box width={"20%"}>
                  <CustomSelect
                    value={`${filter.subType}${filter.withCoupon}${filter.type}${filter.featuredOffers}${filter.topOffers}`}
                    placeHolder={"Filter by Offer type"}
                    options={offerType}
                    borderRadius={"5px"}
                    onChange={(e) => onSelectedType(e)}
                  />
                </Box>
                <Box width={"20%"}>
                  <CustomSelect
                    value={`${filter.category}`}
                    placeHolder={"By Category"}
                    options={categoryList}
                    borderRadius={"5px"}
                    onChange={(e) => onSelectedCategory(e)}
                  />
                </Box>
                <Box width={"10%"} display={"flex"} alignItems={"end"}>
                  <CustomButton title={"Clear"} handleClick={clearFilters} />
                </Box>
              </Box>
            </Box>

            <Box
              mt={"12px"}
              padding={"5px 10px"}
              borderRadius={"5px"}
              bgcolor={"#FFEDE3"}
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Box width={"10%"}>
                <Typography color={"#000000"} fontFamily={"MetropolisSemiBold"}>
                  Id
                </Typography>
              </Box>
              <Box width={"20%"}>
                <Typography color={"#000000"} fontFamily={"MetropolisSemiBold"}>
                  Offer Title
                </Typography>
              </Box>
              <Box width={"10%"}>
                <Typography color={"#000000"} fontFamily={"MetropolisSemiBold"}>
                  Type
                </Typography>
              </Box>
              <Box width={"15%"}>
                <Typography color={"#000000"} fontFamily={"MetropolisSemiBold"}>
                  Category
                </Typography>
              </Box>
              <Box width={"15%"}>
                <Typography color={"#000000"} fontFamily={"MetropolisSemiBold"}>
                  Sub Category
                </Typography>
              </Box>
              <Box width={"10%"}>
                <Typography color={"#000000"} fontFamily={"MetropolisSemiBold"}>
                  Offer Value
                </Typography>
              </Box>
              <Box width={"15%"}>
                <Typography color={"#000000"} fontFamily={"MetropolisSemiBold"}>
                  Applicable For
                </Typography>
              </Box>
              <Box width={"10%"}>
                <Typography color={"#000000"} fontFamily={"MetropolisSemiBold"}>
                  Status
                </Typography>
              </Box>
              <Box width={"5%"}>
                <Typography color={"#000000"} fontFamily={"MetropolisSemiBold"}>
                  Action
                </Typography>
              </Box>
            </Box>
            {loader ? (
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                height={"300px"}
              >
                <CustomSpinner />
              </Box>
            ) : (
              <>
                {offersList?.result?.map((item, index) => {
                  // const adjustedIndex = index + skip + 1;
                  const adjustedIndex = ((pages-1) * 150) + 1 + index;
                  // console.log(offersList.result.length, adjustedIndex, pages, "IJIJIJIJ")
                  return (
                    <Box
                      key={index}
                      padding={"5px 10px"}
                      borderRadius={"5px"}
                      bgcolor={"#FFFFFF"}
                      border={"1px solid #FEECE3"}
                      mt={"5px"}
                      display={"flex"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <Box width={"10%"}>
                        <Typography
                          color={"#000000"}
                          fontFamily={"MetropolisMedium"}
                        >
                          {adjustedIndex}
                        </Typography>
                      </Box>
                      <Box width={"20%"}>
                        <Typography
                          color={"#000000"}
                          fontFamily={"MetropolisMedium"}
                        >
                          {item.name}
                        </Typography>
                      </Box>
                      <Box width={"10%"}>
                        <Typography
                          color={"#000000"}
                          fontFamily={"MetropolisMedium"}
                        >
                          {item.type}
                        </Typography>
                      </Box>

                      <Box width={"15%"}>
                        <Typography
                          color={"#000000"}
                          fontFamily={"MetropolisMedium"}
                        >
                          {item.category?.name ?? "NA"}
                        </Typography>
                      </Box>
                      <Box width={"15%"}>
                        <Box display={"flex"} gap={"10px"}>
                          <Typography
                            color={"#000000"}
                            fontFamily={"MetropolisMedium"}
                          >
                            {item?.subCategories
                              ?.filter((item, index) => {
                                return index < 1;
                              })
                              ?.map((item) => item?.name) ?? "NA"}
                          </Typography>
                          {item?.subCategories?.length > 1 && (
                            <CustomToolTip
                              data={item?.subCategories
                                ?.filter((item, index) => {
                                  return index > 0;
                                })
                                ?.map((item) => item?.name)}
                              title={"+ 1"}
                            ></CustomToolTip>
                          )}
                        </Box>
                      </Box>

                      <Box width={"10%"}>
                        <Typography
                          color={"#000000"}
                          fontFamily={"MetropolisMedium"}
                        >
                          {item?.discount?.type && item?.discount?.value
                            ? item.discount?.type === "price"
                              ? `₹${item?.discount?.value}`
                              : `${item?.discount?.value}%`
                            : "NA"}
                        </Typography>
                      </Box>
                      <Box width={"15%"}>
                        <Typography
                          color={"#000000"}
                          fontFamily={"MetropolisMedium"}
                        >
                          {item?.toShow === "all" ? "Both" : item?.toShow}
                        </Typography>
                      </Box>
                      <Box width={"10%"}>
                        <Typography
                          color={"#000000"}
                          fontFamily={"MetropolisMedium"}
                        >
                          {item?.status}
                        </Typography>
                      </Box>

                      <Box
                        display={"flex"}
                        sx={{ cursor: "pointer" }}
                        gap={"10px"}
                        alignItems={"center"}
                        width={"5%"}
                      >
                        <img
                          src={eyeSvg}
                          alt=""
                          width={"20px"}
                          onClick={() =>
                            navigate(
                              `/admin/offer/${encodeURIComponent(
                                removeSpacesAndGaps(item?.name)
                              )}/${item?._id}`
                            )
                          }
                        />
                        <img
                          src={EditSvg}
                          alt="image"
                          width={"20px"}
                          onClick={() => {
                            setShowAddOfferModal(true);
                            setCurrentOffer({ ...item, open: "edit" });
                          }}
                        />
                      </Box>
                    </Box>
                  );
                })}
                

                {
                  filter.featuredOffers ? null: (
                    <>
                    
                <Box mt={2} display={"flex"} justifyContent={"right"}>
                  {offersList && offersList?.totalCount && offersList?.totalCount > 5 && (
                    <Pagination
                      // totalCount={Number(offersList?.totalCount)}
                      totalCount={Number(offersList.totalCount)}
                      skip={(pages-1)*150}
                      limit={150}
                      onPageChange={handlePageChange}
                    />
                  )}
                </Box>
                    </>
                  )
                }



              </>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default OfferList;