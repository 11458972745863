import React, { useState } from "react";
import { Grid, Box } from "@mui/material";
import Colors from "../../constants/Colors";
import CustomInput, { CustomInputWithIcon } from "../../components/Input";
import CustomButton from "../../components/Button";
import { useNavigate } from "react-router-dom";
import loginbg from "../../../images/bubbles.png";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import logo from "../../../images/final logo.png";
import { ApiRequest } from "../../../services/api-request";
import Otp from "../Otp";
import "./index.css";
import { useSnackbar } from "../../../context/useSnackbar";

const schema = yup.object({
  password: yup
  .string()
  .required("Password is required")
  .min(6, "The password must be at least six characters."),
  confirmPassword: yup
    .string()
    .required("Confirm Password is required")
    .test("passwords-match", "Passwords must match", function (value) {
      return this.parent.password === value;
    }),
});

const ForgotPasswordForm = (props) => {
  const navigate = useNavigate();

  const { handleSubmit, control } = useForm({
    resolver: yupResolver(schema),
    // defaultValues: { password: "", confirmPassword: "" },
  });
  const { openSnackbar } = useSnackbar();

  const onSubmit = (data) => {
    const forgotPasswordData = {
      mobile: props.details?.mobile,
      password: data.password,
    };
    ApiRequest({
      url: `user/reset-password`,
      data: forgotPasswordData,
      method: "POST",
    })
      .then((res) => {
        openSnackbar(
          "Success! Password updated. Use new password to login now.",
          "success"
        );
        navigate("/login");
        console.log(res?.data?.status);
      })
      .catch((err) => {
        openSnackbar(err?.response?.data?.message, "error");
      });
  };
  return (
    <>
      <Box height={"100vh"} bgcolor={Colors.white}>
        <Grid container>
          <Grid item md={6}>
            <Box m={5}>
              <Box className={"thrillhLogo"}>
                <img src={logo} alt={"Logo"} width={"150px"} />
              </Box>
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Box width={500} marginTop={"40px"}>
                  <h2 className="heading">Set Password</h2>
                  <p className="subHeading">Enter password to set</p>
                  <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                    <Box mt={3}>
                      <Controller
                        name="password"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <CustomInputWithIcon
                            label={" New Password"}
                            type="password"
                            {...field}
                            placeHolder={"Enter Password"}
                            error={!!error}
                            helperText={error ? error.message : ""}
                          />
                        )}
                      />
                    </Box>
                    <Box>
                      <Controller
                        name="confirmPassword"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <CustomInputWithIcon
                            label={"Confirm  Password"}
                            type={"password"}
                            placeHolder={"Enter Confirm Password"}
                            {...field}
                            error={!!error}
                            helperText={error ? error.message : ""}
                          />
                        )}
                      />
                    </Box>

                    <Box mt={2}>
                      <CustomButton
                        title={"Submit"}
                        borderButton={false}
                        type={"submit"}
                      />
                    </Box>
                    <Box mt={2}>
                      <CustomButton
                        title={"Back To Login"}
                        borderButton={true}
                        handleClick={() => navigate("/login")}
                      />
                    </Box>
                  </form>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item md={6} className="fpRightSec">
            <Box position={"fixed"} width={"100%"}>
              <Box
                position={"relative"}
                borderRadius={"20px 0px 0px 20px"}
                style={{
                  width: "50%",
                  height: "100vh",
                  backgroundColor: "#c5c5c5",
                }}
              >
                <Box
                  style={{
                    backgroundImage: `url(${loginbg})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    width: "100%",
                    height: "100vh",
                    backgroundPosition: "center",
                  }}
                  position={"absolute"}
                  top={"0"}
                  zIndex={"1"}
                ></Box>
                <Box position={"absolute"} zIndex={"0"} top={"0"} right={"0"}>
                  <img
                    src={require("../../../images/topShape.png")}
                    alt={"Auth Bg"}
                    width={"300px"}
                  />
                </Box>
                <Box position={"absolute"} zIndex={"0"} bottom={"0"} left={"0"}>
                  <img
                    src={require("../../../images/bottomShape.png")}
                    alt={"Auth Bg"}
                    width={"300px"}
                  />
                </Box>
                <Box
                  position={"absolute"}
                  top={"0"}
                  width={"100%"}
                  height={"100%"}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <img
                    src={require("../../../images/forogtPAssword.png")}
                    alt={"Auth Bg"}
                    width={"640px"}
                  />
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

const ForgotPassword = (props) => {
  const [mobileNumberResponse, setMobileNumberResponse] = useState({});

  const [forgotResponse, setForgotResponse] = useState({});

  const schema = yup
    .object({
      mobile: yup
        .string()
        .required("Mobile Number is required")
        .test(
          "len",
          "Mobile Number must be exactly 10 digits",
          (val) => val && val.length === 10
        ),
    })
    .required();

  const { handleSubmit, control } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { mobile: "" },
  });

  const onSubmit = (data) => {
    UserForgotPassword(data);
  };

  const { openSnackbar } = useSnackbar();

  const UserForgotPassword = (data) => {
    const forgotPasswordData = {
      ...data,
      ctx: "RESET",
    };
    ApiRequest({
      url: `user/send-otp`,
      data: forgotPasswordData,
      method: "POST",
    })
      .then((res) => {
        if (res?.success === true) {
          openSnackbar(res.message, "success");
        } else if (res?.success === false) {
          openSnackbar(res.message, "error");
        }
        setMobileNumberResponse({ ...res, mobile: data.mobile });
      })
      .catch((err) => {
        openSnackbar(err?.response?.data?.message, "error");
      });
  };
  return (
    <>
      {mobileNumberResponse?.otp_token ? (
        <>
          {forgotResponse?.success === true ? (
            <>
              <ForgotPasswordForm details={mobileNumberResponse} />
            </>
          ) : (
            <Otp
              forgotPassword={"forgotPassword"}
              setForgotResponse={setForgotResponse}
              details={mobileNumberResponse}
              requestOTP={UserForgotPassword}
            />
          )}
        </>
      ) : (
        <Box height={"100vh"} bgcolor={Colors.white}>
          <Grid container>
            <Grid item md={6} xs={12}>
              <Box m={5}>
                <Box className={"thrillhLogo"}>
                  <img src={logo} alt={"Logo"} width={"150px"} />
                </Box>
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Box width={500} marginTop={"40px"} className={"loginBox"}>
                    <h2 className="heading">Forgot Password</h2>
                    <p className="subHeading">Enter Mobile Number to get OTP</p>
                    <>
                      <form
                        onSubmit={handleSubmit(onSubmit)}
                        autoComplete="off"
                      >
                        <Box mt={3}>
                          <Controller
                            name="mobile"
                            type="mobile"
                            defaultValue={""}
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                              <CustomInput
                                label={"Mobile Number"}
                                type="text"
                                {...field}
                                placeHolder={"Enter Mobile Number"}
                                error={!!error}
                                helperText={error ? error.message : ""}
                              />
                            )}
                          />
                        </Box>
                        <Box mt={2}>
                          <CustomButton
                            title={"Send OTP"}
                            borderButton={false}
                            type={"submit"}
                          />
                        </Box>
                      </form>
                    </>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item md={6} className="fpRightSec">
              <Box position={"fixed"} width={"100%"}>
                <Box
                  position={"relative"}
                  borderRadius={"20px 0px 0px 20px"}
                  style={{
                    width: "50%",
                    height: "100vh",
                    backgroundColor: "#c5c5c5",
                  }}
                >
                  <Box
                    style={{
                      backgroundImage: `url(${loginbg})`,
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      width: "100%",
                      height: "100vh",
                      backgroundPosition: "center",
                    }}
                    position={"absolute"}
                    top={"0"}
                    zIndex={"1"}
                  ></Box>
                  <Box position={"absolute"} zIndex={"0"} top={"0"} right={"0"}>
                    <img
                      src={require("../../../images/topShape.png")}
                      alt={"Auth Bg"}
                      width={"300px"}
                    />
                  </Box>
                  <Box
                    position={"absolute"}
                    zIndex={"0"}
                    bottom={"0"}
                    left={"0"}
                  >
                    <img
                      src={require("../../../images/bottomShape.png")}
                      alt={"Auth Bg"}
                      width={"300px"}
                    />
                  </Box>
                  <Box
                    position={"absolute"}
                    top={"0"}
                    width={"100%"}
                    height={"100%"}
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <img
                      src={require("../../../images/forogtPAssword.png")}
                      alt={"Auth"}
                      width={"640px"}
                    />
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};

export default ForgotPassword;
