import React, { useState } from "react";
import { Box } from "@mui/material";
import CustomInput from "../../../components/Input";
import CustomButton from "../../../components/Button";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ApiRequest } from "../../../../services/api-request";
import FileUploader from "../../../components/FileUploader/file-uploder";
import { useSnackbar } from "../../../../context/useSnackbar";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../../../context/AppContext";

const schema = yup
  .object({
    categoryName: yup.string().required("Category Name is required"),
    metaTitle: yup.string().required("MetaTitle is required"),
    metaDescription: yup.string().required("Meta Description is required"),
  })
  .required();

const AddEditCategory = (props) => {
  const navigate = useNavigate();

  const { handleLogoutSuccess } = useAppContext();

  const { isEdit, list } = props;

  const { openSnackbar } = useSnackbar();
  const [uploadLogo, setUploadLogo] = useState("");

  const onHandleUploadedFile = (file) => {
    if (file) {
      setUploadLogo(file?.url);
    } else {
      setUploadLogo([]);
    }
  };

  const { handleSubmit, control } = useForm({
    resolver: yupResolver(schema),
    defaultValues: !isEdit
      ? { categoryName: "", metaTitle: "", metaDescription: "" }
      : {
          categoryName: list?.name,
          metaTitle: list?.metaTitle,
          metaDescription: list?.metaDescription,
        },
  });

  const onSubmit = (data) => {
    if (!uploadLogo?.logoResponseFiles?.url && !list?.image) {
      openSnackbar("Category Logo is required", "error");
      return;
    } else {
      if (isEdit && list) {
        const modifiedFormData = {
          name: data.categoryName,
          image: uploadLogo?.logoResponseFiles?.url,
          metaTitle: data?.metaTitle,
          metaDescription: data?.metaDescription,
        };
        ApiRequest({
          url: `category/${list?._id}`,
          data: modifiedFormData,
          method: "PUT",
        })
          .then((res) => {
            if (res) {
              openSnackbar(
                "Categories has been updated successfully",
                "success"
              );
            }
            props.onClose();
            props.getAllCategories();
          })
          .catch((err) => {
            openSnackbar(err?.response?.data?.message, "error");
            if (err?.statusCode === 403 || err?.statusCode === 401) {
              if (navigate) {
                navigate("/login"); // Navigate to "/login" route
                handleLogoutSuccess();
              }
            }
          });
      } else {
        const modifiedFormData = {
          name: data.categoryName,
          image: uploadLogo?.logoResponseFiles?.url,
          metaTitle: data?.metaTitle,
          metaDescription: data?.metaDescription,
        };
        ApiRequest({
          url: `category`,
          data: modifiedFormData,
          method: "POST",
        })
          .then((res) => {
            if (res) {
              openSnackbar(
                "Categories has been created successfully",
                "success"
              );
            }
            props.onClose();
            props.getAllCategories();
          })
          .catch((err) => {
            openSnackbar(err?.response?.data?.message, "error");
            if (err?.statusCode === 403 || err?.statusCode === 401) {
              if (navigate) {
                navigate("/login"); // Navigate to "/login" route
                handleLogoutSuccess();
              }
            }
          });
      }
    }
  };

  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <Box>
          <Controller
            name="categoryName"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <CustomInput
                {...field}
                label={"Category Name"}
                borderRadius={"5px"}
                type={"text"}
                placeHolder={"Enter Category Name"}
                error={!!error}
                helperText={error ? error.message : ""}
              />
            )}
          />
        </Box>
        <Box mt={2}>
          <FileUploader
            onSelectedFile={(file) => onHandleUploadedFile(file)}
            accept={"image/png,image/jpeg,image/jpg"}
            displayType={"square"}
            padding={"30px"}
            setUploadedFiles={setUploadLogo}
            uploadType={"logo"}
            heading={
              uploadLogo?.logoResponseFiles?.originalname
                ? uploadLogo?.logoResponseFiles?.originalname
                : list?.image
                ? list?.image
                : "Upload Logo"
            }
          />
        </Box>
        <Box>
          <Controller
            name="metaTitle"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <CustomInput
                {...field}
                label={"Meta Title"}
                borderRadius={"5px"}
                type={"text"}
                placeHolder={"Enter Meta Title"}
                error={!!error}
                helperText={error ? error.message : ""}
              />
            )}
          />
        </Box>
        <Box>
          <Controller
            name="metaDescription"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <CustomInput
                {...field}
                label={"Meta Description"}
                borderRadius={"5px"}
                type={"text"}
                placeHolder={"Enter Meta Description"}
                error={!!error}
                helperText={error ? error.message : ""}
              />
            )}
          />
        </Box>
        <Box display={"flex"} justifyContent={"end"} gap={"10px"} mt={"20px"}>
          <Box width={"20%"}>
            <CustomButton
              border={"1px solid #000000"}
              handleClick={() => props.onClose()}
              color={"#000000"}
              title={"Cancel"}
              bgColor={"#F7F7F7"}
              borderRadius={"5px"}
            />
          </Box>
          <Box width={"20%"}>
            <CustomButton
              title={list?.openType === "add" ? "Create" : "Save"}
              type={"submit"}
              borderRadius={"5px"}
            />
          </Box>
        </Box>
      </form>
    </Box>
  );
};

const styles = {
  cursor: {
    cursor: "pointer",
  },
};

export default AddEditCategory;
