import React from "react";
import { Box, Breadcrumbs, Typography } from "@mui/material";

const Breadcrumb = (props) => {
  const { title, breadcrumbsArr } = props;
  return (
    <Box>
      <Typography
        sx={{
          fontFamily: "MetropolisLight",
          fontSize: "14px",
          color: "#00000",
        }}
      >
        {title}
      </Typography>
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        {breadcrumbsArr.map((breadcrumb, index) => (
          <span key={index}>{breadcrumb}</span>
        ))}
      </Breadcrumbs>
    </Box>
  );
};

export default Breadcrumb;
