export const BACKEND_API_URL = "https://api.Thrillh.com";
// export const BACKEND_API_URL = "http://localhost:3001";

export const URLS = {
  STUDENT_URL: "student",
  CORPORATE_URL: "corporate",
};

export const GENDER_DATA = [
  {
    id: "male",
    name: "Male",
  },
  {
    id: "female",
    name: "Female",
  },
];

export const OFFER_TYPE_DATA = [
  {
    id: "online",
    name: "Online",
  },
  {
    id: "offline",
    name: "Offline",
  },
];

export const IMAGE_MIME_TYPES = [
  "image/bmp",
  "image/jpeg",
  "image/x-png",
  "image/png",
  "image/gif",
];

export const TITLES = [
  {
    id: "mr",
    name: "Mr",
  },
  {
    id: "mrs",
    name: "Mrs",
  },
  {
    id: "ms",
    name: "Ms",
  },
];

export const RELATION_TITLES = [
  {
    id: "s/o",
    name: "S/O",
  },
  {
    id: "c/o",
    name: "C/O",
  },
  {
    id: "d/o",
    name: "D/O",
  },
];

export const NATIONALITY = [
  {
    id: "india",
    name: "India",
  },
  {
    id: "other",
    name: "Other",
  },
];

export const MARTIAL_STATUS = [
  {
    id: "married",
    name: "Married",
  },
  {
    id: "single",
    name: "UnMarried",
  },
  {
    id: "widowed",
    name: "Widowed",
  },
  {
    id: "divorced",
    name: "Divorced",
  },
  {
    id: "separated",
    name: "Separated",
  },
];

export const GENDER = [
  {
    id: "male",
    name: "Male",
  },
  {
    id: "female",
    name: "Female",
  },
  {
    id: "other",
    name: "Other",
  },
];

export const USER_ROLES = [
  {
    id: "doctor",
    name: "Doctor",
  },
  {
    id: "assistant",
    name: "Assistant",
  },
  {
    id: "frontdesk",
    name: "Front Desk",
  },
];

export const Trouble_Shoot_Issues = [
  {
    name: "Login or Registration related Issue",
    value: "LOGIN_REGISTRATION",
  },
  {
    name: "Offer related issue",
    value: "OFFER",
  },
  {
    name: "Location related issue",
    value: "LEGAL_POLICY",
  },
  {
    name: "Legal or policy- related issue",
    value: "LOCATION",
  },
  {
    name: "User profile or settings configuration related issue",
    value: "PROFILE_SETTINGS",
  },
  {
    name: "Other",
    value: "OTHER",
  },
];

export const Contact_Us_ISSUES = [
  {
    name: "Login or Registration related Issue",
    value: "LOGIN_REGISTRATION",
  },
  {
    name: "Offer related issue",
    value: "OFFER",
  },
  {
    name: "Payment related issue",
    value: "PAYMENT",
  },
  {
    name: "Location related issue",
    value: "LEGAL_POLICY",
  },
  {
    name: "Legal or policy- related issue",
    value: "LOCATION",
  },
  {
    name: "User profile or settings configuration related issue",
    value: "PROFILE_SETTINGS",
  },
  {
    name: "Other",
    value: "OTHER",
  },
];

export const UserType = [
  {
    name: "Student",
    value: "student",
  },
  {
    name: "Corporate",
    value: "corporate",
  },
  {
    name: "Both",
    value: "all",
  },
];

export const SelectOfferType = [
  {
    name: "Online",
    value: "online",
  },
  {
    name: "in-store",
    value: "in-store",
  },
];

export const SelectSubType = [
  {
    name: "Payable",
    value: "payable",
  },
  {
    name: "Non-Payable",
    value: "non-payable",
  },
];

export const GST_TYPES = [
  {
    name: "0%",
    value: "0",
  },
  {
    name: "5%",
    value: "5",
  },
  {
    name: "12%",
    value: "12",
  },
  {
    name: "18%",
    value: "18",
  },
  {
    name: "28%",
    value: "28",
  },
];
export const Discount = [
  {
    name: "Price",
    value: "price",
  },
  {
    name: "Percentage",
    value: "percentage",
  },
];

export const ConditionType = [
  {
    name: "Maximum-Bill",
    value: "maximum-bill",
  },
  {
    name: "Minimum-Bill",
    value: "minimum-bill",
  },
  {
    name: "Text",
    value: "text",
  },
  {
    name: "Days",
    value: "days",
  },
];

export const Days = [
  {
    name: "Monday",
    value: "monday",
  },
  {
    name: "Tuesday",
    value: "tuesday",
  },
  {
    name: "Wednesday",
    value: "wednesday",
  },
  {
    name: "Thursday",
    value: "thursday",
  },
  {
    name: "Fridays",
    value: "friday",
  },
  {
    name: "Saturday",
    value: "saturday",
  },
  {
    name: "Sunday",
    value: "sunday",
  },
];

export const getForbiddenDomains = () => [
  "gmail.com",
  "hotmail.com",
  // "yopmail.com",
  "yahoo.com",
  "aol.com",
  "outlook.com",
  "icloud.com",
  "protonmail.com",
  "zoho.com",
  "mail.com",
  "gmx.com",
  "fastmail.com",
  "inbox.com",
  "live.com",
  "msn.com",
  "rocketmail.com",
  "yandex.com",
  "rediffmail.com",
  "cox.net",
  "att.net",
  "verizon.net",
  "bellsouth.net",
  "earthlink.net",
  "mail.ru",
  "rambler.ru",
  "tutanota.com",
  "runbox.com",
  "mailinator.com",
  "guerrillamail.com",
  "temp-mail.org",
  "dispostable.com",
  "gmail.com",
  "googlemail.com",
  "Outlook.com",
  "Yahoo.com",
  "Protonmail.com",
  "Proton.me",
  "Pm.me",
  "Protonmail.ch",
  "Thunderbird.net",
  "MsgSafe.io",
  "spikenow.com",
  "startmail.com",
  "Bluemail.me",
  "edisonmail.com",
  "zohomail.in",
  "Zohomail.com",
  "Rediff.com",
  "AOL.com",
  "GMX.com",
  "Mail.com",
  "Tutanota.com",
  "FastMail.com",
  "Yandex.com",
  "Rackspace.com",
  "HEY.com",
  "Posteo.de",
  "Mail.ru",
  "iCloud.com",
  "Me.com",
  "mac.com",
  "Yandex.com",
  "Mailfence.com",
  "nym.hush.com",
  "Mailbox.org",
  "navercorp.com",
  "K9mail.com",
  "K9mail.app",
  "Runbox.com",
  "Disroot.org",
  "Mailo.com",
  "Cock.li",
  "Skiff.com",
  "Infomaniak.com",
  "M.kuku.lu",
  "Horde.org",
  "Dlg.im",
  "Inbox.lv",
  "Criptext.com",
  "Onmail.com",
  "Startmail.com",
  "Typeapp.com",
  "Systemli.org",
  "memail.com",
  "Hushmail.com",
  "Purelymail.com",
  "Vfemail.net",
  "Librem.one",
  "Mesign.com",
  "Polymail.io",
  "Afterlogic.org",
  "Postale.io",
  "Freron.com",
  "Speaking.email",
  "Greatmail.com",
  "Soverin.net",
  "Mail2world.com",
  "Webmail.co.za",
  "Email.163.com",
  "Fripost.org",
  "Email.it",
  "Denysdovhan.com",
  "Countermail.com",
  "Mail.be",
  "Atmail.com",
  "Hiddenpineapple.com",
  "Eclipso.eu",
  "Neomailbox.com",
  "Mailture.com",
  "Cloudemail.io",
  "Thexyz.com",
  "Mailcheap.co",
  "Servermx.com",
  "Yaanimail.com",
  "Mrmail.com",
  "Wordsecure.com",
  "Rework.so",
  "Kerjamail.co",
  "Offilive.com",
  "Luxsci.com",
  "Titan.email",
  "Inoutscripts.com",
  "Mux.to",
  "Telious.io",
  "Topmail.com",
  "Websitesmail.att.com",
  "att.com",
];

export const PHONE_REG_EXP =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
export const PINCODE_REG_EXP = /^[1-9][0-9]{5}$/;
export const AADHAR_REG_EXP = /^[2-9]{1}[0-9]{3}\s[0-9]{4}\s[0-9]{4}$/gm;

const parseError = (actualError) => {
  let err = actualError;
  if (err instanceof Array) {
    err = err?.length > 0 ? err[0] : err;
  }
  if (!err) {
    return "Please Check the Network";
  } else if (err?.graphQLErrors?.length > 0) {
    return err?.graphQLErrors[0]?.message;
  } else if (err?.networkError) {
    return err?.networkError?.message;
  } else if (err?.errorMessage) {
    return err.errorMessage;
  } else if (err?.message) {
    return err?.message;
  } else {
    return err;
  }
};

export function ErrorMessage(err) {
  const parsedError = parseError(err);

  if (parsedError) {
    if (parsedError.includes("Unauthorized")) {
      console.error("Actual Error:", err, "Parsed Error:", parsedError);
      //forward to home by clearing the token
      const { origin } = window.location;
      localStorage.removeItem("user"); // clear all expired token and push user to login page
      window.location.replace(`${origin}`);
      return "Something has gone wrong";
    }
    if (
      parsedError.includes("Response not successful: Received status code 400")
    ) {
      console.error("Actual Error:", err, "Parsed Error:", parsedError);
      return "Something has gone wrong";
    } else if (parsedError.includes("Failed to fetch")) {
      console.error("Actual Error", err, "Parsed Error:", parsedError);
      return "Oops!! server did not respond, please try again";
    } else {
      return parsedError;
    }
  } else {
    console.error("Actual Error", err, "Parsed Error:", parsedError);
    return "Something has gone wrong";
  }
}

export const Offerlocations = [
  {
    name: "In-Store",
    value: "in-store",
  },
  {
    name: "Online",
    value: "online",
  },
  {
    name: "Top-Offers",
    value: "top",
  },
  {
    name: "Featured Offers",
    value: "featured",
  },
  {
    name: "Student Offers",
    value: "student",
  },
  {
    name: "Corporate Offers",
    value: "corporate",
  },
];
