import React from "react";
import { Grid, Box } from "@mui/material";
import Colors from "../../constants/Colors";
import finalWhiteLogo from "../../../images/finalwhiteLogo.png";
import { useNavigate } from "react-router-dom";
import linkedIn from "../../../images/linkedin.svg";
import "./footer.css";
import youtubeSvg from "../../../../src/images/youtube.png";
const Footer = (props) => {
  const { from } = props;

  const navigate = useNavigate();

  const navigateToTerms = () => {
    navigate("/terms-of-services");
  };
  const navigateToDisclaimer = () => {
    navigate("/disclaimer");
  };
  const navigateTheTerms = () => {
    navigate("/privacy-policy");
  };

  const navigateToCareers = () => {
    navigate("/careers");
  };

  const containerClass =
    from === "partner"
      ? "partner"
      : from === "about"
      ? "about"
      : from === "terms"
      ? "terms"
      : "home";

  const Container =
    from === "partner"
      ? "partner"
      : from === "about"
      ? "about"
      : from === "career"
      ? "career"
      : from === "terms"
      ? "terms"
      : "home";

  return (
    <Box className={`${containerClass}-container`}>
      <Box
        backgroundColor={Colors.textColor}
        className={`${Container}-footerContent`}
      >
        <Grid
          container
          justifyContent={"space-between"}
          alignItems={"center"}
          className="footLogoSec"
        >
          <Grid item className="footLogoImg">
            <img src={finalWhiteLogo} alt={"Logo Image"} />
          </Grid>
          <Grid item gap={"10px"} flexDirection={"column"} display={"flex"} mb={4}>
            <Grid container className="socialIcons">
              <Grid
                item
                sx={{ cursor: "pointer" }}
                onClick={() =>
                  window.open(
                    "https://www.facebook.com/profile.php?id=100075955356480"
                  )
                }
              >
                <img
                  src={require("../../../images/fb_trans.png")}
                  width={20}
                  height={20}
                  alt="fb"
                />
              </Grid>
              <Grid
                item
                sx={{ cursor: "pointer" }}
                onClick={() =>
                  window.open("https://www.instagram.com/thrillh_/")
                }
              >
                <img
                  src={require("../../../images/insta_trans.png")}
                  width={20}
                  height={20}
                  alt="insta"
                />
              </Grid>
              <Grid
                item
                sx={{ cursor: "pointer" }}
                onClick={() => window.open("https://twitter.com/Thrillh_")}
              >
                <img
                  src={require("../../../images/twitter_trans.png")}
                  width={20}
                  alt="twiiter"
                  height={20}
                />
              </Grid>
              <Grid
                item
                sx={{ cursor: "pointer" }}
                onClick={() =>
                  window.open("https://www.linkedin.com/company/thrillh/")
                }
              >
                <img src={linkedIn} width={20} height={20} alt="linkedIn" />
              </Grid>
            </Grid>
            <Grid container className="socialIcons">
              <Grid
                item
                sx={{ cursor: "pointer" }}
                onClick={() => window.open("https://www.youtube.com/@Thrillh")}
              >
                <img src={youtubeSvg} width={28} alt="youtube" />
              </Grid>
              <Grid
                item
                sx={{ cursor: "pointer" }}
                onClick={() =>
                  window.open("https://in.pinterest.com/Thrillh_/")
                }
              >
                <img
                  src={require("../../../images/pinterest.png")}
                  width={20}
                  alt="image"
                />
              </Grid>
              <Grid
                item
                sx={{ cursor: "pointer" }}
                onClick={() => window.open("https://www.threads.net/@thrillh_")}
              >
                <img
                  src={require("../../../images/threads.png")}
                  width={20}
                  alt="thread"
                />
              </Grid>
              <Grid
                item
                sx={{ cursor: "pointer" }}
                onClick={() =>
                  window.open(
                    "https://play.google.com/store/apps/details?id=com.thrillh&hl=en-IN"
                  )
                }
              >
                <img
                  src={require("../../../images/google-play.png")}
                  width={20}
                  alt="google"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent={"center"}
          alignItems={"center"}
          className="footCont"
        >
          <Grid item>
            <Grid container className="footerSection">
              <Grid item onClick={() => navigate("/about-us")}>
                <h5 style={styles.copyright}>About us</h5>
              </Grid>
              <Grid item onClick={() => navigate("/join-with-us")}>
                <h5 style={styles.copyright}>Join with us</h5>
              </Grid>
              <Grid
                item
                onClick={() => window.open("https://blog.thrillh.com/")}
              >
                <h5 style={styles.copyright}>Blogs</h5>
              </Grid>
              <Grid
                item
                onClick={() => window.open("https://blog.thrillh.com/news-room")}
              >
                <h5 style={styles.copyright}>News Room</h5>
              </Grid>
              <Grid item onClick={() => navigate("/faqs")}>
                <h5 style={styles.copyright}>Faqs</h5>
              </Grid>
              <Grid item onClick={() => navigate("/contact-us")}>
                <h5 style={styles.copyright}>Contact Us</h5>
              </Grid>
              <Grid item onClick={navigateToTerms}>
                <h5 style={styles.copyright}>Terms of Service</h5>
              </Grid>
              <Grid item onClick={navigateTheTerms}>
                <h5 style={styles.copyright}>Privacy Policy</h5>
              </Grid>
              <Grid item onClick={navigateToDisclaimer}>
                <h5 style={styles.copyright}>Disclaimer</h5>
              </Grid>
              <Grid item onClick={navigateToCareers}>
                <h5 style={styles.copyright}>Careers</h5>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent={"center"}
          alignItems={"center"}
          className="footCont"
        >
          <Grid item>
            <p style={styles.copyright} className="copyRight">
              Copyright © Thrillh. All rights reserved, Powered by Udify
              Technologies.
            </p>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Footer;

const styles = {
  footerCardImg: {
    borderRadius: 30,
  },
  footerCardSubText: {
    fontFamily: "MetropolisRegular",
    fontSize: 15,
    color: Colors.textColor,
    textAlign: "center",
  },
  registerBrand: {
    fontFamily: "MetropolisRegular",
    fontSize: 15,
    color: Colors.textColor,
    marginRight: 5,
  },
  copyright: {
    fontFamily: "MetropolisRegular",
    color: Colors.white,
    fontSize: 15,
    cursor: "pointer",
    fontWeight: "500",
  },
  footerCardText: {
    fontFamily: "MetropolisMedium",
    color: Colors.lightBlue,
    fontSize: 23,
    textAlign: "center",
    fontWeight: "500",
  },
  footerPartnerText: {
    fontFamily: "MetropolisMedium",
    color: Colors.lightBlue,
    fontSize: 23,
    textAlign: "center",
  },
};
