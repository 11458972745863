// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.heading {
  font-family: "MetropolisExtraBold";
  color: #000000;
}
.subHeading {
  font-family: "MetropolisLight";
  font-size: 12px;
  color: #000000;
  font-weight: 600;
}
.emailLogin {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #d0bcbc;
  line-height: 0.1em;
  font-family: "MetropolisLight";
  font-size: 12px;
}
.borderLine {
  background: #fff;
  padding: 0 10px;
  font-family: "MetropolisLight";
  font-size: 12px;
  font-weight: 600;
}
.skipText {
  font-family: "MetropolisSemiBold";
  color: #fa8842;
  font-size: 12px;
  text-align: center;
  cursor: pointer;
}
.checkboxText {
  font-family: "MetropolisRegular";
  font-size: 12px;
  color: #000000;
}

@media (min-width: 320px) and (max-width: 990px) {
  .registerRightSec {
    display: none;
  }
  .thrillhLogo {
    display: flex;
    justify-content: center;
  }
  .loginBox {
    width: 100% !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/app/screens/Register/register.css"],"names":[],"mappings":"AAAA;EACE,kCAAkC;EAClC,cAAc;AAChB;AACA;EACE,8BAA8B;EAC9B,eAAe;EACf,cAAc;EACd,gBAAgB;AAClB;AACA;EACE,WAAW;EACX,kBAAkB;EAClB,gCAAgC;EAChC,kBAAkB;EAClB,8BAA8B;EAC9B,eAAe;AACjB;AACA;EACE,gBAAgB;EAChB,eAAe;EACf,8BAA8B;EAC9B,eAAe;EACf,gBAAgB;AAClB;AACA;EACE,iCAAiC;EACjC,cAAc;EACd,eAAe;EACf,kBAAkB;EAClB,eAAe;AACjB;AACA;EACE,gCAAgC;EAChC,eAAe;EACf,cAAc;AAChB;;AAEA;EACE;IACE,aAAa;EACf;EACA;IACE,aAAa;IACb,uBAAuB;EACzB;EACA;IACE,sBAAsB;EACxB;AACF","sourcesContent":[".heading {\n  font-family: \"MetropolisExtraBold\";\n  color: #000000;\n}\n.subHeading {\n  font-family: \"MetropolisLight\";\n  font-size: 12px;\n  color: #000000;\n  font-weight: 600;\n}\n.emailLogin {\n  width: 100%;\n  text-align: center;\n  border-bottom: 1px solid #d0bcbc;\n  line-height: 0.1em;\n  font-family: \"MetropolisLight\";\n  font-size: 12px;\n}\n.borderLine {\n  background: #fff;\n  padding: 0 10px;\n  font-family: \"MetropolisLight\";\n  font-size: 12px;\n  font-weight: 600;\n}\n.skipText {\n  font-family: \"MetropolisSemiBold\";\n  color: #fa8842;\n  font-size: 12px;\n  text-align: center;\n  cursor: pointer;\n}\n.checkboxText {\n  font-family: \"MetropolisRegular\";\n  font-size: 12px;\n  color: #000000;\n}\n\n@media (min-width: 320px) and (max-width: 990px) {\n  .registerRightSec {\n    display: none;\n  }\n  .thrillhLogo {\n    display: flex;\n    justify-content: center;\n  }\n  .loginBox {\n    width: 100% !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
