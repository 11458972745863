import { Box, Typography } from "@mui/material";
import React, { useState, useEffect, useCallback } from "react";
import eyeSvg from "../../../../../images/orange eye.svg";
import CustomInput from "../../../../components/Input";
import CustomSelect from "../../../../components/Select/CustomSelect";
import { ApiRequest } from "../../../../../services/api-request";
import { useNavigate } from "react-router-dom";
import { capitalizeFirstLetter } from "../../../../utilities/date-utility";
import { useSnackbar } from "../../../../../context/useSnackbar";
import CustomButton from "../../../../components/Button";
import Pagination from "../../../../components/Pagination";
import { useAppContext } from "../../../../../context/AppContext";
import { downloadFile } from "../../../../components/ExportToCSV/export-to-csv";

const UserReports = (props) => {
  const { openSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const { handleLogoutSuccess } = useAppContext();

  const { bgcolor, openedFrom } = props;

  const userStatus = [
    {
      name: "In-review",
      value: "in-review",
    },
    {
      name: "Active",
      value: "active",
    },
    {
      name: "In-Active",
      value: "in-active",
    },
  ];

  const userTypes = [
    {
      name: "Student",
      value: "student",
    },
    {
      name: "Corporate",
      value: "corporate",
    },
  ];

  const limit = 100; // Users per page
  const [skip, setSkip] = useState(0);

  const [userList, setUserList] = useState([]);

  const [csvUsersList, setCsvUsersList] = useState([]);

  useEffect(() => {
    if (Array.isArray(csvUsersList?.result)) {
      let headers = [
        "Id,User Name,User Type,Mobile Number,User Status,Orders,Viewed,Redeemed,Verified With,Referral Id,Personal Email,Official Email,Registered From,School,Company",
      ];

      let offersCsv = userList?.result?.reduce((acc, offer, index) => {
        const {
          id,
          name,
          role: { name: roleName },
          mobile,
          status,
          ordersCount,
          viewCount,
          redeemedCount,
          verifiedWith,
          referralId,
          personalEmail,
          email,
          registeredFrom,
          school,
          company,
        } = offer;

        const modifiedIndex = index + 1;
        const officialEmail = email ? email : "NA";
        const schoolName = school ? `"${school}"` : "NA";
        const companyName = company ? `"${company}"` : "NA";
        acc.push(
          [
            modifiedIndex,
            name,
            roleName,
            mobile,
            status,
            ordersCount,
            viewCount,
            redeemedCount,
            verifiedWith,
            referralId,
            personalEmail,
            officialEmail,
            registeredFrom,
            schoolName,
            companyName,
          ].join(",")
        );
        return acc;
      }, []);

      downloadFile({
        data: [...headers, ...offersCsv].join("\n"),
        fileName: "Users Reports.csv",
        fileType: "text/csv",
      });
    }
  }, [csvUsersList]);

  const [filter, setFilter] = useState({
    search: "",
    status: "",
    userType: "",
  });

  function usersURL(filter = null) {
    const { search = "", status = null, userType = null } = filter || {};
    if (filter?.search?.length || status || userType) {
      if (search?.length && !userType && !status) {
        return `user/list?skip=${skip}&limit=${limit}${
          search ? `&search=${search}` : ""
        }`;
      } else if (status && !search?.length && !userType) {
        return `user/list?skip=${skip}&limit=${limit}${
          status ? `&status=${status}` : ""
        }`;
      } else if (userType && !status && !search?.length) {
        return `user/list?skip=${skip}&limit=${limit}${
          userType ? `&userType=${userType}` : ""
        }`;
      } else if (
        (search?.length && status) ||
        (search?.length && userType) ||
        (search?.length && status && userType)
      ) {
        return `user/list?skip=${skip}&limit=${limit}${
          search ? `&search=${search}` : ""
        }${status ? `&status=${status}` : ""}${
          userType ? `&userType=${userType}` : ""
        }`;
      } else if (status && userType) {
        return `user/list?skip=${skip}&limit=${limit}${
          status ? `&status=${status}` : ""
        }${userType ? `&userType=${userType}` : ""}`;
      }
    } else {
      return `user/list?skip=${skip}&limit=${limit}`;
    }
  }

  useEffect(() => {
    getAllUsers(filter);
  }, [skip, filter]);

  const getAllUsers = (filter) => {
    ApiRequest({
      url: usersURL(filter),
      method: "GET",
    })
      .then((res) => {
        setUserList(res);
      })
      .catch((err) => {
        openSnackbar(err?.response?.data?.message, "error");
        if (err?.statusCode === 403 || err?.statusCode === 401) {
          if (navigate) {
            navigate("/login"); // Navigate to "/login" route
            handleLogoutSuccess();
          }
        }
      });
  };

  const handlePageChange = (page) => {
    const newSkip = (page - 1) * limit;
    setSkip(newSkip);
  };

  const onUserStatusSelected = (e) => {
    const selectedStatus = e.target.value;
    setFilter((prevFilter) => ({
      ...prevFilter,
      status: selectedStatus,
    }));
    getAllUsers({ ...filter, status: selectedStatus });
  };

  const userTypeSelected = (e) => {
    const selectedUserType = e.target.value;
    setFilter((prevFilter) => ({
      ...prevFilter,
      userType: selectedUserType,
    }));
    getAllUsers({ ...filter, userType: selectedUserType });
  };

  const handleSearchChange = (e) => {
    const searchValue = e.target.value;
    setFilter((prevFilter) => ({
      ...prevFilter,
      search: searchValue,
    }));
    getAllUsers({ ...filter, search: searchValue });
  };

  const clearFilters = () => {
    getAllUsers({
      search: "",
      type: "",
      userType: "",
    });
    setFilter({
      search: "",
      type: "",
      userType: "",
    });
  };

  const exportToCsv = useCallback(() => {
    const payload = {
      limit: 1000000000,
      skip: 0,
    };

    ApiRequest({
      url: "user/list",
      data: payload,
      method: "GET",
    })
      .then((res) => {
        setCsvUsersList(res);
      })
      .catch((err) => {
        openSnackbar(err?.response?.data?.message, "error");
      });
  }, []);

  return (
    <>
      <Box
        bgcolor={bgcolor ? bgcolor : "#FFEDE3"}
        padding={"20px"}
        borderRadius={"40px 0px 0px 40px"}
      >
        <Box
          display={"flex"}
          width={"100%"}
          justifyContent={"end"}
          alignItems={"right"}
        >
          <Box width={"10%"}>
            <CustomButton
              handleClick={exportToCsv}
              title={"+ Export CSV"}
              borderRadius={"5px"}
            />
          </Box>
        </Box>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography
            fontFamily={"MetropolisBold"}
            fontSize={"20px"}
            color={"#000000"}
          >
            Users Reports
          </Typography>
        </Box>
        <Box bgcolor={"#fff"} padding={"10px"} borderRadius={"10px"} mt={2}>
          <>
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              width={"100%"}
            >
              <Box width={"25%"}>
                <CustomInput
                  placeHolder={"Search with name"}
                  borderRadius={"5px"}
                  onChange={handleSearchChange}
                  value={filter.search}
                />
              </Box>
              <Box
                display={"flex"}
                justifyContent={"end"}
                gap={"10px"}
                width={"75%"}
              >
                <Box width={"30%"}>
                  <CustomSelect
                    placeHolder={"Status"}
                    value={filter.status}
                    options={userStatus}
                    borderRadius={"5px"}
                    onChange={(e) => onUserStatusSelected(e)}
                  />
                </Box>
                <Box width={"30%"}>
                  <CustomSelect
                    placeHolder={"UserType"}
                    value={filter.userType}
                    options={userTypes}
                    borderRadius={"5px"}
                    onChange={(e) => userTypeSelected(e)}
                  />
                </Box>
                <Box width={"10%"} display={"flex"} alignItems={"end"}>
                  <CustomButton title={"Clear"} handleClick={clearFilters} />
                </Box>
              </Box>
            </Box>
          </>

          <Box
            mt={"12px"}
            padding={"5px 10px"}
            borderRadius={"5px"}
            bgcolor={"#FFEDE3"}
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Box width={"10%"}>
              <Typography color={"#000000"} fontFamily={"MetropolisSemiBold"}>
                Id
              </Typography>
            </Box>
            <Box width={"15%"}>
              <Typography color={"#000000"} fontFamily={"MetropolisSemiBold"}>
                User Name
              </Typography>
            </Box>
            <Box width={"10%"}>
              <Typography color={"#000000"} fontFamily={"MetropolisSemiBold"}>
                User Type
              </Typography>
            </Box>

            <Box width={"15%"}>
              <Typography color={"#000000"} fontFamily={"MetropolisSemiBold"}>
                Regd. Mob. No
              </Typography>
            </Box>
            <Box width={"10%"}>
              <Typography color={"#000000"} fontFamily={"MetropolisSemiBold"}>
                User Status
              </Typography>
            </Box>
            <Box width={"8%"}>
              <Typography color={"#000000"} fontFamily={"MetropolisSemiBold"}>
                Orders
              </Typography>
            </Box>
            <Box width={"10%"}>
              <Typography color={"#000000"} fontFamily={"MetropolisSemiBold"}>
                Offers Viewed
              </Typography>
            </Box>
            <Box width={"10%"}>
              <Typography color={"#000000"} fontFamily={"MetropolisSemiBold"}>
                Redeemed
              </Typography>
            </Box>
            <Box width={"10%"}>
              <Typography color={"#000000"} fontFamily={"MetropolisSemiBold"}>
                Action
              </Typography>
            </Box>
          </Box>
          {userList?.result?.map((item, index) => {
            const adjustedIndex = index + skip + 1;
            return (
              <Box
                key={index}
                padding={"5px 10px"}
                borderRadius={"5px"}
                bgcolor={"#FFFFFF"}
                border={"1px solid #FEECE3"}
                mt={"5px"}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Box width={"10%"}>
                  <Typography color={"#000000"} fontFamily={"MetropolisMedium"}>
                    {adjustedIndex}
                  </Typography>
                </Box>
                <Box width={"15%"}>
                  <Typography
                    sx={{ wordBreak: "break-word" }}
                    color={"#000000"}
                    fontFamily={"MetropolisMedium"}
                  >
                    {item.name ?? "NA"}
                  </Typography>
                </Box>
                <Box width={"10%"}>
                  <Typography color={"#000000"} fontFamily={"MetropolisMedium"}>
                    {item.role?.name ?? "NA"}
                  </Typography>
                </Box>

                <Box width={"15%"}>
                  <Typography color={"#000000"} fontFamily={"MetropolisMedium"}>
                    {item.mobile}
                  </Typography>
                </Box>
                <Box width={"10%"}>
                  <Typography color={"#000000"} fontFamily={"MetropolisMedium"}>
                    {capitalizeFirstLetter(item?.status)}
                  </Typography>
                </Box>
                <Box width={"8%"}>
                  <Typography color={"#000000"} fontFamily={"MetropolisMedium"}>
                    {item?.ordersCount ?? "NA"}
                  </Typography>
                </Box>
                <Box width={"10%"}>
                  <Typography color={"#000000"} fontFamily={"MetropolisMedium"}>
                    {item?.viewCount ?? "NA"}
                  </Typography>
                </Box>
                <Box width={"10%"}>
                  <Typography color={"#000000"} fontFamily={"MetropolisMedium"}>
                    {item?.redeemedCount ?? "NA"}
                  </Typography>
                </Box>

                <Box
                  sx={{ cursor: "pointer" }}
                  display={"flex"}
                  gap={"10px"}
                  alignItems={"center"}
                  width={"10%"}
                >
                  <Box width={"10%"}>
                    <img
                      src={eyeSvg}
                      alt=""
                      width={"20px"}
                      onClick={() =>
                        navigate(`/admin/users/${item?._id}/details`)
                      }
                    />
                  </Box>
                </Box>
              </Box>
            );
          })}
          <Box mt={2} display={"flex"} justifyContent={"right"}>
            {openedFrom != "dashboard" && userList?.totalCount > 5 && (
              <Pagination
                totalCount={Number(userList?.totalCount)}
                skip={skip}
                limit={limit}
                onPageChange={handlePageChange}
              />
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default UserReports;
